import { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { IconButton, Paper, Select, MenuItem } from '@mui/material';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import VideoBasicInfoPanel from './VideoBasicInfoPanel/VideoBasicInfoPanel';
import VideoAnalysisPanel from './VideoAnalysisPanel/VideoAnalysisPanel';
import ScorePanel from './ScorePanel/ScorePanel';
import VideoHighlightPanel from './VideoHighlightPanel/VideoHighlightPanel';
import VideoSettingPanel from './VideoSettingPanel/VideoSettingPanel';
import ManualInput from './ManualInput/ManualInput';

import './VideoDynamicPanel.scss';

import {
  ballAwayPlayerScoreIndex,
  ballHomePlayerScoreIndex,
} from 'src/constants';

const translations = {
  infoPanel: '資訊面板',
  scorePanel: '得分面板',
  analysisPanel: '分析面板',
  highlightPanel: '亮點面板',
  playlistPanel: '播放列表',
  settingPanel: '視頻設置',
  manualInputPanel: '手動輸入',
};

const tabDescriptions = {
  settingPanel: '(重播,視頻資料,視角,設置)',
  infoPanel: '(比賽資, 評論, 熱度圖)',
  scorePanel: '(比賽得分, 回合比分, 得分圖表)',
  analysisPanel: '(軌跡圖)',
  highlightPanel: '(回合評述,亮點評述)',
  playlistPanel: 'Playlist Panel Description',
  manualInputPanel: '(落點/比賽得分輸入)',
};

const VideoDynamicPanel = ({
  roundResults,
  matchData,
  rawDpResults,
  dpResults,
  videoRef,
  token,
  userInfo,
  videoData,
  setVideoData,
  currentTransformation,
  replayData,
  setReplayData,
  topPlayer,
}) => {
  const [score1, setScore1] = useState(null);
  const [score2, setScore2] = useState(null);
  const [xAxis, setXAxis] = useState(null);
  const [yAxis, setYAxis] = useState(null);
  const [chartWidth, setChartWidth] = useState(500);
  const [chartHeight, setChartHeight] = useState(300);
  const [ttPlotWidth, setTTPlotWidth] = useState(300);
  const [ttPlotHeight, setTTPlotHeight] = useState(600);
  const [isDraggable, setIsDraggable] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  // const isAuthorized = userInfo.isCoach || userInfo.admin || userInfo.subadmin;
  const isAuthorized = true; // all ok

  const allTabs = [
    'infoPanel',
    'settingPanel',
    'scorePanel',
    'analysisPanel',
    'highlightPanel',
    'manualInputPanel',
  ];

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const tabs = isAuthorized
    ? allTabs
    : allTabs.filter(
        (tab) => tab !== 'highlightPanel' && tab !== 'manualInputPanel',
      );
  const toggleDraggable = () => {
    const newDraggableState = !isDraggable;
    setIsDraggable(newDraggableState);
    setScale(1);
    setVideoData((prevState) => ({
      ...prevState,
      isFs: newDraggableState,
    }));
  };

  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  const handleScaleIncrease = () => {
    setScale((prevScale) => Math.min(prevScale + 0.2, 2)); // Max scale: 2
  };

  const handleScaleDecrease = () => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.6)); // Min scale: 0.6
  };

  const getXAxis = (scoreArray) => {
    const arrayLength = scoreArray.length;
    const XArray = [];

    for (var i = 0; i < arrayLength; i++) {
      const initialValue = 1;
      const endValue = scoreArray[i].length;
      const XRoundArray = [];

      for (let n = initialValue; n <= endValue; n += 1) {
        XRoundArray.push(n);
      }

      XArray.push(XRoundArray);
    }
    return XArray;
  };

  // roundata to scoredata
  const extractScoreData = (gameArray) => {
    const HomePlayerScore = [];
    const AwayPlayerScore = [];
    const scoreYAxis = [];

    gameArray.forEach((round) => {
      // round[1] = ball arrays
      if (Array.isArray(round) && round.length > 0) {
        const nested_round = round[1];
        const roundHomePlayer = [];
        const roundAwayPlayer = [];
        let PrevScore = 0;
        const maxScore = [0];
        if (Array.isArray(nested_round) && nested_round.length > 0) {
          nested_round.forEach((ball) => {
            roundHomePlayer.push(ball[ballHomePlayerScoreIndex]);
            roundAwayPlayer.push(ball[ballAwayPlayerScoreIndex]);
            if (ball[ballHomePlayerScoreIndex] > PrevScore) {
              maxScore.push(ball[ballHomePlayerScoreIndex]);
              PrevScore = ball[ballHomePlayerScoreIndex];
            } else if (ball[ballAwayPlayerScoreIndex] > PrevScore) {
              maxScore.push(ball[ballAwayPlayerScoreIndex]);
              PrevScore = ball[ballAwayPlayerScoreIndex];
            }
          });
          scoreYAxis.push(maxScore);
          HomePlayerScore.push(roundHomePlayer);
          AwayPlayerScore.push(roundAwayPlayer);
        }
      }
    });

    const scoreXAxis = getXAxis(HomePlayerScore);
    setXAxis(scoreXAxis);
    setYAxis(scoreYAxis);
    setScore1(HomePlayerScore);
    setScore2(AwayPlayerScore);
  };

  // for plotting score plot / round
  useEffect(() => {
    if (roundResults) {
      extractScoreData(roundResults);
    }
  }, [roundResults]);

  // check orientation and change trajectory and score plot

  const handleResize = () => {
    const { innerWidth, innerHeight } = window;
    const isPortrait = innerHeight > innerWidth;
    const xyRatio = 1280 / 800;
    // Calculate the desired height based on orientation and screen size
    if (isPortrait) {
      if (isDraggable === true) {
        toggleDraggable();
      }
      if (innerWidth >= 600) {
        setTTPlotWidth((innerHeight * 0.3) / xyRatio);
        setTTPlotHeight(innerHeight * 0.3);
        setChartWidth(innerWidth * 0.9);
        setChartHeight(innerHeight * 0.3);
      } else {
        setTTPlotWidth((innerHeight * 0.5) / xyRatio);
        setTTPlotHeight(innerHeight * 0.5);
        setChartWidth(innerWidth);
        setChartHeight(innerHeight * 0.5);
      }
    } else {
      setChartWidth(innerWidth * 0.4);
      setChartHeight(innerWidth * 0.4);
      setTTPlotWidth(innerWidth * 0.25);
      setTTPlotHeight(innerWidth * 0.25 * xyRatio);
    }
  };

  // Initial resize
  useEffect(
    () => (
      handleResize(),
      window.addEventListener('resize', handleResize),
      () => window.removeEventListener('resize', handleResize),
      window.addEventListener('orientationchange', handleResize),
      () => window.removeEventListener('orientationchange', handleResize)
    ),
    [],
  );

  const handleTabsChange = (event) => {
    setVideoData((prevState) => ({
      ...prevState,
      tabIndex: event.target.value,
    }));
  };

  const handleFullscreen = () => {
    setVideoData((prevState) => ({
      ...prevState,
      isFs: !videoData.isFs,
    }));
  };

  const menuItems = tabs.map((tab, index) => (
    <MenuItem key={tab} value={index}>
      {translations[tab]}
      <span style={{ marginLeft: '8px', color: 'gray' }}>
        {tabDescriptions[tab]}
      </span>
    </MenuItem>
  ));

  const panelContent = (
    <div className={`video-side-panel-main ${videoData.isFs ? 'fs' : ''}`}>
      <div className={`comment-panel ${tabs[videoData.tabIndex]}`}>
        <div
          className="content-menu"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isMenuVisible && (
            <>
              <div className="panel-options">
                <IconButton
                  className="fs-toggle"
                  title={videoData.isFs ? '退出全屏' : '全屏顯示'}
                  onClick={handleFullscreen}
                >
                  {videoData.isFs ? (
                    <MenuOpenOutlinedIcon sx={{ color: 'white' }} />
                  ) : (
                    <KeyboardArrowRightOutlinedIcon sx={{ color: 'white' }} />
                  )}
                </IconButton>

                <IconButton onClick={toggleDraggable}>
                  <DragIndicatorIcon
                    sx={{ color: isDraggable ? 'green' : 'grey' }}
                  />
                </IconButton>

                {isDraggable && (
                  <>
                    <IconButton
                      onClick={handleScaleIncrease}
                      title="Increase size"
                    >
                      <AddIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton
                      onClick={handleScaleDecrease}
                      title="Decrease size"
                    >
                      <RemoveIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </>
                )}
              </div>
              <Select
                value={videoData.tabIndex}
                onChange={handleTabsChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select tab' }}
              >
                {menuItems}
              </Select>
            </>
          )}
          <IconButton
            onClick={toggleMenuVisibility}
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              color: 'white',
              zIndex: '99',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
            className="hide-menu-button"
          >
            {isMenuVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </div>
        {tabs[videoData.tabIndex] === 'infoPanel' && (
          <VideoBasicInfoPanel
            matchData={matchData}
            roundResults={roundResults}
            accountType={userInfo.accountType}
            videoRef={videoRef}
            userInfo={userInfo}
            setVideoData={setVideoData}
          />
        )}
        {tabs[videoData.tabIndex] === 'scorePanel' && (
          <ScorePanel
            roundResults={roundResults}
            matchData={matchData}
            score1={score1}
            score2={score2}
            videoRef={videoRef}
            xAxis={xAxis}
            yAxis={yAxis}
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            videoData={videoData}
            setVideoData={setVideoData}
          />
        )}
        {tabs[videoData.tabIndex] === 'analysisPanel' && (
          <VideoAnalysisPanel
            dpResults={dpResults[videoData.currentRound]}
            dpIndex={videoData.currentBall}
            ttPlotHeight={ttPlotHeight}
            ttPlotWidth={ttPlotWidth}
            homePlayer={matchData.homePlayer}
            awayPlayer={matchData.awayPlayer}
            topPlayer={topPlayer}
          />
        )}
        {tabs[videoData.tabIndex] === 'highlightPanel' && (
          <VideoHighlightPanel
            roundResults={roundResults}
            videoData={videoData}
            setVideoData={setVideoData}
            userInfo={userInfo}
            token={token}
            videoRef={videoRef}
            matchId={matchData.matchId}
            currentTransformation={currentTransformation}
          />
        )}
        {tabs[videoData.tabIndex] === 'playlistPanel' && <p>coming soon</p>}
        {tabs[videoData.tabIndex] === 'settingPanel' && (
          <VideoSettingPanel
            roundResults={roundResults}
            userInfo={userInfo}
            matchData={matchData}
            videoData={videoData}
            setVideoData={setVideoData}
            replayData={replayData}
            setReplayData={setReplayData}
          />
        )}
        {tabs[videoData.tabIndex] === 'manualInputPanel' && (
          <ManualInput
            havePlayerinfo={
              matchData.homePlayer !== '_' && matchData.awayPlayer !== '_'
            }
            videoData={videoData}
            setVideoData={setVideoData}
            matchId={matchData.matchId}
            roundResults={roundResults}
            videoRef={videoRef}
            dpResults={rawDpResults[videoData.currentRound]}
          />
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`video-dynamic-panel-container ${
        isDraggable ? 'overlay' : ''
      }`}
      style={{
        transform: `scale(${scale}) `,
        transformOrigin: 'top left',
      }}
    >
      {isDraggable ? (
        <Draggable
          position={position}
          onDrag={handleDrag}
          handle=".draggable-panel-handle"
          scale={scale}
        >
          <Paper elevation={3} className="draggable-panel">
            <div className="draggable-panel-handle">
              <h2>按住此處拖曳</h2>
            </div>
            <div className="draggable-panel-content">{panelContent}</div>
          </Paper>
        </Draggable>
      ) : (
        panelContent
      )}
    </div>
  );
};

export default VideoDynamicPanel;
