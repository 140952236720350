import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {
  IconButton,
  TextField,
  FormHelperText,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import Stack from '@mui/material/Stack';
import Cookies from 'js-cookie';
import axios from 'axios';

function ChangePasswordDialog({ open, onClose, username }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState({
    type: '', // 'success' or 'error'
    message: '',
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: {
      length: false,
      upperCase: false,
      lowerCase: false,
      number: false,
      specialChar: false,
    },
    confirmPassword: '',
  });

  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  // Real-time validation for new password
  useEffect(() => {
    if (newPassword) {
      const validations = validatePassword(newPassword);
      setErrors((prev) => ({
        ...prev,
        newPassword: validations,
      }));
    }
  }, [newPassword]);

  // Real-time validation for confirm password
  useEffect(() => {
    if (confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: confirmPassword !== newPassword ? '新密碼不相符' : '',
      }));
    }
  }, [confirmPassword, newPassword]);

  // Real-time validation for current password
  useEffect(() => {
    if (currentPassword) {
      setErrors((prev) => ({
        ...prev,
        currentPassword: currentPassword.length === 0 ? '請輸入目前密碼' : '',
      }));
    }
  }, [currentPassword]);

  const isFormValid = () => {
    const passwordValidations = errors.newPassword;
    return (
      currentPassword.length !== 0 &&
      newPassword &&
      confirmPassword &&
      !errors.confirmPassword &&
      passwordValidations.length &&
      passwordValidations.upperCase &&
      passwordValidations.lowerCase &&
      passwordValidations.number &&
      passwordValidations.specialChar
    );
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;

    setStatusMessage({ type: '', message: '' });

    try {
      const token = Cookies.get('token');
      const response = await axios.post(
        '/api/changePassword',
        {
          identifier: username,
          password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      setStatusMessage({
        type: 'success',
        message: '密碼更改成功',
      });

      // Close dialog after successful password change (with delay to show success message)
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      setStatusMessage({
        type: 'error',
        message: err.response?.data?.message || '更改密碼失敗',
      });
    }
  };

  const getPasswordStrengthColor = (isValid) => {
    return isValid ? 'green' : 'red';
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Stack spacing={2} sx={{ p: 3 }}>
        {statusMessage.message && (
          <Alert
            severity={statusMessage.type}
            sx={{ mb: 2 }}
            onClose={() => setStatusMessage({ type: '', message: '' })}
          >
            {statusMessage.message}
          </Alert>
        )}
        <Box sx={{ mb: 2 }}></Box>
        <TextField
          label="目前密碼"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          error={!!errors.currentPassword}
          helperText={errors.currentPassword}
          fullWidth
        />

        <TextField
          label="新密碼"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
        />
        <Stack spacing={0.5}>
          <FormHelperText
            style={{
              color: getPasswordStrengthColor(errors.newPassword.length),
            }}
          >
            ✓ 至少8個字元
          </FormHelperText>
          <FormHelperText
            style={{
              color: getPasswordStrengthColor(errors.newPassword.upperCase),
            }}
          >
            ✓ 至少一個大寫字母
          </FormHelperText>
          <FormHelperText
            style={{
              color: getPasswordStrengthColor(errors.newPassword.lowerCase),
            }}
          >
            ✓ 至少一個小寫字母
          </FormHelperText>
          <FormHelperText
            style={{
              color: getPasswordStrengthColor(errors.newPassword.number),
            }}
          >
            ✓ 至少一個數字
          </FormHelperText>
          <FormHelperText
            style={{
              color: getPasswordStrengthColor(errors.newPassword.specialChar),
            }}
          >
            {'✓ 至少一個特殊符號 (!@#$%^&*(),.?":{}|<>)'}
          </FormHelperText>
        </Stack>

        <TextField
          label="確認新密碼"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          fullWidth
        />

        {errors.general && (
          <FormHelperText error>{errors.general}</FormHelperText>
        )}

        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disabled={!isFormValid()}
          >
            確認
          </Button>
          <Button variant="outlined" onClick={onClose} fullWidth>
            取消
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

function SimpleDialog(props) {
  const {
    onClose,
    setToken,
    admin,
    subadmin,
    open,
    user_player_id,
    setUserInfo,
    username,
  } = props;

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const handleLogout = () => {
    Cookies.remove('token');
    setToken(null);
    setUserInfo({
      identifier: null,
      isCoach: null,
      accountType: null,
      admin: null,
      subadmin: null,
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <IconButton
            sx={{ fontSize: '1.5em', borderRadius: '0', flexDirection: 'row' }}
            onClick={() => setChangePasswordOpen(true)}
            color="inherit"
          >
            <LockIcon sx={{ fontSize: '1.5em' }} />
            更改密碼
          </IconButton>
          <IconButton
            sx={{ fontSize: '1.5em', borderRadius: '0', flexDirection: 'row' }}
            onClick={handleLogout}
            color="inherit"
          >
            <LogoutIcon sx={{ fontSize: '1.5em' }} />
            登出
          </IconButton>
          {subadmin === true || admin === true ? (
            <Link
              style={{
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
              }}
              to="/admin-page"
              onClick={handleClose}
            >
              <AdminPanelSettingsOutlinedIcon sx={{ fontSize: '1.5em' }} />
              管理頁面
            </Link>
          ) : null}
          {!(admin || user_player_id === 0) && (
            <Link
              style={{
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
              }}
              to={`/player-analysis/${user_player_id}`}
              onClick={handleClose}
            >
              <FolderSharedOutlinedIcon sx={{ fontSize: '1.5em' }} />
              運動員頁面
            </Link>
          )}
          <Button variant="contained" color="inherit" onClick={handleClose}>
            關閉
          </Button>
        </Stack>
      </Dialog>
      <ChangePasswordDialog
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
        username={username}
      />
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function UserOption({ setToken, userInfo, setUserInfo }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chunkName = (name) => {
    if (name.length > 8) {
      return name.slice(0, 8) + '...';
    }
    return name;
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        title="帳號"
        startIcon={<ManageAccountsOutlinedIcon />}
        color="inherit"
      >
        {chunkName(userInfo.identifier)}
      </Button>
      <SimpleDialog
        setToken={setToken}
        admin={userInfo.admin}
        subadmin={userInfo.subadmin}
        open={open}
        onClose={handleClose}
        user_player_id={userInfo.user_player_id}
        setUserInfo={setUserInfo}
        username={userInfo.identifier}
      />
    </div>
  );
}
