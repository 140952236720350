export const EDGE_COLORS = {
  win: "#34a853", // Green
  loss: "#ea4335", // Red
  neutral: "#D3D3D3", // Default gray
  selected: "#999999", // Selected edge
  yellowOrange: "#FFB266", // Yellow-orange for common opponents
};

export const NODE_STYLES = {
  player: {
    mobile: {
      size: 9,
      color: "#4169E1", // Default blue
      highlighted: {
        color: "#32CD32", // Green
        selectedColor: "#32CD32",
        connectedColor: "#32CD32",
        disabled: "#c6d2f6",
        irrelevant: "#E2E2E2",
      },
    },
    desktop: {
      size: 15,
      color: "#4169E1",
      highlighted: {
        color: "#32CD32",
        selectedColor: "#32CD32",
        connectedColor: "#32CD32",
        disabled: "#c6d2f6",
        irrelevant: "#E2E2E2",
      },
    },
    states: {
      selected: "#FF4444", // Red for selected player
      opponent: "#FF8C00", // Orange for opponent
      commonOpponent: "#FFD700", // Gold for common opponent
      connected: "#6495ED", // Blue for connected players
      disabled: "#c6d2f6", // Light blue-grey for disabled
      irrelevant: "#E2E2E2", // Light grey for irrelevant
    },
  },
  game: {
    mobile: {
      size: 4,
      color: "#B0C4DE",
      states: {
        default: "#B0C4DE",
        connected: "#6495ED",
        yellowOrange: "#FFB266",
        disabled: "#E2E2E2",
      },
    },
    desktop: {
      size: 8,
      color: "#B0C4DE",
      states: {
        default: "#B0C4DE",
        connected: "#6495ED",
        yellowOrange: "#FFB266",
        disabled: "#E2E2E2",
      },
    },
  },
  edge: {
    default: "#D3D3D3",
    selected: "#999999",
    yellowOrange: "#FFB266",
  },
} as const;

export const MATCH_RESULT_COLORS = {
  win: {
    background: "#e6f4ea", // Light green background
    border: "#34a853", // Green border
    text: "#1e4620", // Dark green text
    dark: {
      background: "#0f311d",
      border: "#34a853",
      text: "#e6f4ea",
    },
  },
  loss: {
    background: "#fce8e6", // Light red background
    border: "#ea4335", // Red border
    text: "#8d261b", // Dark red text
    dark: {
      background: "#3d1411",
      border: "#ea4335",
      text: "#fce8e6",
    },
  },
  draw: {
    background: "#f1f3f4", // Light gray background
    border: "#9aa0a6", // Gray border
    text: "#3c4043", // Dark gray text
    dark: {
      background: "#202124",
      border: "#9aa0a6",
      text: "#f1f3f4",
    },
  },
} as const;
