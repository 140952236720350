import EditPlayerDialog from '../AnalysisMainPanel/EditPlayerDialog';
// import PlayerProfileCard from '../PlayerProfileCard/PlayerProfileCard';
import PlayerProfilePanel from '../PlayerProfileCard/PlayerProfilePanel';

import './PlayerInfoPanel.scss';

const PlayerInfoPanel = ({ playerData, userInfo, playerStats }) => {
  return (
    <>
      <PlayerProfilePanel
        playerData={playerData}
        accountType={userInfo.accountType}
        playerStats={playerStats}
      />
      {userInfo.isCoach === true && (
        <EditPlayerDialog
          playerData={playerData}
          accountType={userInfo.accountType}
        />
      )}
      {/* <PlayerProfileCard
          playerData={playerData}
          accountType={userInfo.accountType}
        /> */}
    </>
  );
};

export default PlayerInfoPanel;
