// DpInputOL.tsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Button from '@mui/material/Button';
import './DpInputOL.scss';
import { formatTime } from 'src/utils/utils';
import { TimedAlert } from 'src/components/alert/alert';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { updateDPDataAPI, updateBallDataAPI } from 'src/api/videoService';
import { UpdateBallDataParams, UpdateDPDataParams } from 'types/videoTypes';
import { PerspectiveTransform } from './utils/transformation';
import Cookies from 'js-cookie';

interface Corner {
    position: string;
    point: Point | null;
}

interface Point {
    x: number;
    y: number;
}
interface dpPoint {
    x: number;
    y: number;
    dpTime: number;
}
interface VideoData {
    refresh: boolean;
}

interface DpOLProps {
    videoRef: React.RefObject<HTMLVideoElement>;
    setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
    matchId: number;
}

const DpInputOL: React.FC<DpOLProps> = ({ videoRef, setVideoData, matchId }) => {

    const token = Cookies.get('token') as string;
    const initialPoints: Point[] = []; // Initial state for points
    const initialRectangle = null; // Initial state for rectangle
    const initialTransformedPoints: dpPoint[] = []; // Initial state for transformedPoints

    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    // States for points, rectangle, and transformedPoints
    const [points, setPoints] = useState<Point[]>(initialPoints);
    const [rectangle, setRectangle] = useState<{ corners: Point[] } | null>(initialRectangle);
    const [transformedPoints, setTransformedPoints] = useState<dpPoint[]>(initialTransformedPoints);
    const [round, setRound] = useState<number>(1);
    const [hScore, setHScore] = useState<number>(0);
    const [aScore, setAScore] = useState<number>(0);
    const isMobile = window.innerWidth < 900;
    const isTablet = window.innerWidth < 1200;
    const minimapRef = useRef<HTMLCanvasElement>(null);

    const [corners, setCorners] = useState<Corner[]>([
        { position: 'top-left', point: null },
        { position: 'top-right', point: null },
        { position: 'bottom-right', point: null },
        { position: 'bottom-left', point: null }
    ]);

    const getCurrentCornerIndex = useCallback(() => {
        return corners.findIndex(corner => corner.point === null);
    }, [corners]);



    const [coorTransformer, setCoorTransformer] = useState<any>();

    const handleClearAll = () => {
        setPoints(initialPoints);
        setRectangle(initialRectangle);
        setTransformedPoints(initialTransformedPoints);
        setCorners([
            { position: 'top-left', point: null },
            { position: 'top-right', point: null },
            { position: 'bottom-right', point: null },
            { position: 'bottom-left', point: null }
        ]);
    };

    const handleClearDP = () => {
        setTransformedPoints(initialTransformedPoints);
    };



    const handleSave = async () => {

        const convertedArray: [number, number, number][] = transformedPoints.map(
            (dp) => [
                dp.x,
                dp.y,
                dp.dpTime,
            ],
        );

        const nonNullDpTimes = transformedPoints.map(point => point.dpTime).filter(time => time !== null);
        const minTime = nonNullDpTimes.length > 0 ? Math.min(...nonNullDpTimes) - 1 : 0;
        const maxTime = nonNullDpTimes.length > 0 ? Math.max(...nonNullDpTimes) + 1 : 0;

        const startTime = minTime >= 0 ? minTime : 0;
        const endTime = maxTime >= 0 ? maxTime : 0;

        const ballParams: UpdateBallDataParams = {
            filename: null,
            match_id: matchId,
            primary_attribute: `video edited  : ${matchId}`,
            secondary_attribute: `add ball to round  : ball${hScore + aScore} round${round}`,
            new_row: {
                ball_id: -1,
                round_id: -1,
                round_number: round,
                start_time: startTime,
                end_time: endTime,
                home_player_score: hScore,
                away_player_score: aScore,
                highlight: false,
                critical_point: false,
                deuce: false,
            },
        };

        try {
            const dpData: UpdateDPDataParams = {
                match_id: matchId,
                new_dp: {
                    round_num: round - 1,
                    ball_num: hScore + aScore - 1,
                    droppoints: convertedArray,
                },
            };

            const ballResponse = await updateBallDataAPI(ballParams, token);
            const dpResponse = await updateDPDataAPI(dpData, token);

            handleClearDP();

            setAlertMessage('Data saved successfully!');

            setVideoData((prevState: any) => ({
                ...prevState,
                refresh: true,
            }));
        } catch (error) {
            console.error('Error saving DP data:', error);
            setAlertMessage('Error saving data. Please try again.');
        }
    };


    const handleRoundIncrement = () => {
        setRound(prevRound => Math.max(prevRound + 1, 1));
    };

    const handleRoundDecrement = () => {
        setRound(prevRound => Math.max(prevRound - 1, 1));
    };

    const handleHScoreIncrement = () => {
        setHScore(prevScore => Math.max(prevScore + 1, 0));
    };

    const handleHScoreDecrement = () => {
        setHScore(prevScore => Math.max(prevScore - 1, 0));
    };

    const handleAScoreIncrement = () => {
        setAScore(prevScore => Math.max(prevScore + 1, 0));
    };

    const handleAScoreDecrement = () => {
        setAScore(prevScore => Math.max(prevScore - 1, 0));
    };

    const MINIMAP_WIDTH = isMobile ? 100 : isTablet ? 150 : 200;
    const MINIMAP_HEIGHT = (MINIMAP_WIDTH / 800) * 1280; // Maintaining the 800:1280 aspect ratio
    const TABLE_WIDTH = 800;
    const TABLE_HEIGHT = 1280;

    useEffect(() => {
        if (minimapRef.current && transformedPoints.length >= 0) {
            const ctx = minimapRef.current.getContext('2d');
            if (!ctx) return;

            // Clear the canvas
            ctx.clearRect(0, 0, MINIMAP_WIDTH, MINIMAP_HEIGHT);

            // Draw table outline
            ctx.strokeStyle = '#ffffff';
            ctx.strokeRect(0, 0, MINIMAP_WIDTH, MINIMAP_HEIGHT);

            // Draw center line
            ctx.beginPath();
            ctx.moveTo(0, MINIMAP_HEIGHT / 2);
            ctx.lineTo(MINIMAP_WIDTH, MINIMAP_HEIGHT / 2);
            ctx.strokeStyle = '#ffffff';
            ctx.setLineDash([5, 5]);
            ctx.stroke();
            ctx.setLineDash([]);

            // Draw corner indicators
            const cornerPositions = [
                { x: 0, y: 0 },                           // top-left
                { x: MINIMAP_WIDTH, y: 0 },               // top-right
                { x: MINIMAP_WIDTH, y: MINIMAP_HEIGHT },  // bottom-right
                { x: 0, y: MINIMAP_HEIGHT }               // bottom-left
            ];

            const currentCornerIndex = getCurrentCornerIndex();
            const cornerRadius = 70;

            cornerPositions.forEach((pos, index) => {
                ctx.beginPath();
                ctx.arc(pos.x, pos.y, cornerRadius, 0, 2 * Math.PI);

                if (index < currentCornerIndex) {
                    // Already selected corners
                    ctx.fillStyle = 'green';
                    ctx.fill();
                } else if (index === currentCornerIndex) {
                    // Currently active corner
                    ctx.fillStyle = 'red';
                    const timestamp = Date.now();
                    ctx.globalAlpha = 1;
                    ctx.fill();
                } else {
                    // Not yet selected corners
                    ctx.fillStyle = 'white';
                }
                ctx.globalAlpha = 1.0;
            });


            // Draw points
            transformedPoints.forEach((point, index) => {
                // Scale the coordinates to fit the minimap
                const x = (point.x / TABLE_WIDTH) * MINIMAP_WIDTH;
                const y = (point.y / TABLE_HEIGHT) * MINIMAP_HEIGHT;
                const totalSeconds = point.dpTime ?? 0.1;


                const timeLabel = formatTime(totalSeconds)

                ctx.beginPath();
                ctx.arc(x, y, 3, 0, 2 * Math.PI);
                ctx.fillStyle = index === transformedPoints.length - 1 ? '#ff0000' : '#ffff00';
                ctx.fill();

                // Display time label in mm:ss format next to the point
                ctx.font = '12px Arial';
                ctx.fillStyle = '#FFFFFF';
                ctx.fillText(`${timeLabel}`, x + 10, y - 10);
            });
        }
    }, [transformedPoints, corners]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const point = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };

        if (!rectangle) {
            const currentCornerIndex = getCurrentCornerIndex();

            if (currentCornerIndex !== -1) {
                const newCorners = [...corners];
                newCorners[currentCornerIndex].point = point;
                setCorners(newCorners);

                if (currentCornerIndex === 3) {
                    const cornerPoints = newCorners.map(corner => corner.point!);
                    const transformer = new PerspectiveTransform(cornerPoints);
                    setCoorTransformer(transformer);
                    setRectangle({ corners: cornerPoints });
                }
            }
        } else {
            // Check if point is within rectangle
            const isInside = isPointInRectangle(point, rectangle.corners);
            if (isInside) {
                const dpTime = (videoRef.current?.currentTime ?? 0) as number;
                const transformedPointss = coorTransformer.transformPoint(point, dpTime);
                setTransformedPoints([...transformedPoints, transformedPointss]);
            }
        }
    }, [points, rectangle, transformedPoints]);

    const isPointInRectangle = (point: Point, corners: Point[]): boolean => {
        const minX = Math.min(...corners.map(p => p.x));
        const maxX = Math.max(...corners.map(p => p.x));
        const minY = Math.min(...corners.map(p => p.y));
        const maxY = Math.max(...corners.map(p => p.y));

        return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
    };

    const getCornerClassName = (index: number) => {
        const currentCornerIndex = getCurrentCornerIndex();
        if (corners[index].point === null) {
            return index === currentCornerIndex ? 'point-marker blink' : 'point-marker';
        }
        return 'point-marker selected';
    };

    return (
        <div className="dp-overlay" title="edit-dp" onClick={handleClick}>
            {alertMessage && (
                <TimedAlert
                    message={alertMessage}
                    onClose={() => setAlertMessage(null)}
                />
            )}
            {corners.map((corner, index) => (
                corner.point && (
                    <div
                        key={index}
                        className={getCornerClassName(index)}
                        style={{
                            left: `${corner.point.x}px`,
                            top: `${corner.point.y}px`
                        }}
                    />
                )
            ))}

            {corners.findIndex(corner => corner.point === null) !== -1 && (
                <div className="prompt-message">
                    請按照順序點擊球桌的四個角落：
                    <br />
                    {corners[getCurrentCornerIndex()]?.position}
                    <br />
                    已選擇 {corners.filter(corner => corner.point !== null).length} / 4 個點
                </div>
            )}
            {points.map((point, index) => (
                <div
                    key={index}
                    className="point-marker"
                    style={{
                        position: 'absolute', // Position the markers absolutely
                        left: `${point.x}px`,
                        top: `${point.y}px`
                    }}
                />
            ))}
            {rectangle && (
                <>
                    <div className='overlay-match-info'>
                        <div className='overlay-buttons-panel'>
                            <button className='clear-all' onClick={handleClearAll}><ClearOutlinedIcon /> All</button>
                            <button className='clear-dp' onClick={handleClearDP}><ClearOutlinedIcon />Dp</button>
                            <button className='save' onClick={handleSave}><CheckOutlinedIcon /></button>
                        </div>
                        <span className='overlay-ball-time'>
                            <span style={{ color: '#058a7b' }}>
                                {transformedPoints.length > 0 ?
                                    formatTime(Math.max(0, Math.min(...transformedPoints.map(point => point.dpTime !== null ? point.dpTime : Infinity)) - 1)) : 'N/A'}
                            </span>
                            -
                            <span style={{ color: '#b81d24' }}>
                                {transformedPoints.length > 0 ?
                                    formatTime(Math.max(...transformedPoints.map(point => point.dpTime !== null ? point.dpTime : -Infinity)) + 1) : 'N/A'}
                            </span>
                        </span>
                        <div className='overlay-round-panel'>
                            <span className='overlay-round-toggle'>
                                <Button onClick={handleRoundDecrement}>-</Button>
                                R{round}
                                <Button onClick={handleRoundIncrement}>+</Button>
                            </span>
                            <span className='overlay-ball-toggle'>
                                B{hScore + aScore}
                            </span>
                        </div>
                        <div className='overlay-score-panel'>
                            <span>score</span>
                            <span className='overlay-score-toggle'>
                                <Button onClick={handleHScoreDecrement}>-</Button>
                                {hScore}
                                <Button onClick={handleHScoreIncrement}>+</Button>
                            </span>
                            <span className='overlay-score-toggle'>
                                <Button onClick={handleAScoreDecrement}>-</Button>
                                {aScore}
                                <Button onClick={handleAScoreIncrement}>+</Button>
                            </span>
                        </div>
                    </div>
                    <svg className="rectangle-overlay">
                        <polygon
                            points={rectangle.corners.map(p => `${p.x},${p.y}`).join(' ')}
                            className="table-rectangle"
                        />
                    </svg>
                </>
            )}
            <div className="minimap-container">
                <div className="corner-labels top">
                    <span>top(V)/right(H)</span>
                </div>
                <canvas
                    ref={minimapRef}
                    className="minimap"
                    width={MINIMAP_WIDTH}
                    height={MINIMAP_HEIGHT}
                />
                <div className="corner-labels bottom">
                    <span>bottom(V)/left(H)</span>
                </div>
            </div>
        </div>
    );
};

export default DpInputOL;