import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GameClickInfo } from "../../types/graph.types";
import { isPlayerInTeam } from "./getWinner";

interface GameDetailsBoxProps {
  game: GameClickInfo;
  onViewDetails: (game: GameClickInfo) => void;
  playerName?: string; // Add player name to check win/loss
}

export const GameDetailsBox: React.FC<GameDetailsBoxProps> = ({
  game,
  onViewDetails,
  playerName,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // Then use it in your component:
  const isTeam1 = playerName && isPlayerInTeam(game.metadata.team1, playerName);
  const isTeam2 = playerName && isPlayerInTeam(game.metadata.team2, playerName);
  // console.log("GameDetailsBox", playerName, game.metadata, isTeam1, isTeam2);

  // Determine win/loss/neutral color
  const getBoxResultColor = () => {
    if (!playerName || (!isTeam1 && !isTeam2)) return theme.palette.grey[300];

    if (isTeam1) {
      return game.metadata.finalscore1 > game.metadata.finalscore2
        ? theme.palette.success.light
        : theme.palette.error.light;
    } else {
      return game.metadata.finalscore2 > game.metadata.finalscore1
        ? theme.palette.success.light
        : theme.palette.error.light;
    }
  };

  return (
    <Box
      key={game.gameId}
      sx={{
        mb: 2,
        p: 2,
        bgcolor: "background.default",
        borderRadius: 1,
        border: 1,
        borderColor: getBoxResultColor(),
        borderLeftWidth: 4,
      }}
    >
      <Typography variant="subtitle2">{game.metadata.eventName}</Typography>
      <Typography variant="body2" color="text.secondary">
        {game.metadata.date} - {game.metadata.round}
      </Typography>
      <Typography variant="body2">
        <Box
          component="span"
          sx={{
            fontWeight: isTeam1 ? "bold" : "regular",
          }}
        >
          {game.metadata.team1}
        </Box>
        {" vs "}
        <Box
          component="span"
          sx={{
            fontWeight: isTeam2 ? "bold" : "regular",
          }}
        >
          {game.metadata.team2}
        </Box>
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Score: {game.metadata.finalscore1} - {game.metadata.finalscore2}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={() => onViewDetails(game)}
        sx={{ mt: 1 }}
      >
        {t("selectionDrawer.viewDetails")}
      </Button>
    </Box>
  );
};
