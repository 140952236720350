import React, { useState, useEffect, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SimpleSpinner from 'src/components/loading/SimpleLoading';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import NavBar from 'src/components/NavBar/NavBar';

import './App.scss';
import './variables.scss';

const HomeNew = React.lazy(() => import('./pages/Home/HomeNew.js'));
const Home2 = React.lazy(() => import('./pages/Home2/Home2.tsx'));
const VideoInterface = React.lazy(
  () => import('./videoPages/VideoInterface/VideoInterface.js'),
);
const VideoUploadForm = React.lazy(
  () => import('./videoPages/UploadVideo/VideoUploadForm.js'),
);
const LoginForm = React.lazy(() => import('./pages/Login/Login.js'));
const SignUp = React.lazy(() => import('./pages/SignUp/SignUp.js'));
const EditVideoData = React.lazy(
  () => import('./videoPages/EditVideo/EditVideoData.js'),
);
const PlayerHome = React.lazy(() => import('./playerPages/PlayerHome.js'));
const PlayerAnalysis = React.lazy(
  () => import('./playerPages/PlayerAnalysis.js'),
);
const AdminPage = React.lazy(
  () => import('./userPages/AdminPage/AdminPage.js'),
);
const PlaylistPage = React.lazy(
  () => import('./videoPages/Playlist/Playlist.tsx'),
);
const PlaylistManagement = React.lazy(
  () => import('./videoPages/PlaylistManagement/playlistManagement.tsx'),
);
const SignalGraphWrapperDev = React.lazy(
  () =>
    import(
      './playerPages/PlayerGraphDev/common/components/core/SignalGraphWrapper.tsx'
    ),
);
const SignalGraphWrapperV3 = React.lazy(
  () =>
    import(
      './playerPages/PlayerGraphV3/common/components/core/SignalGraphWrapper.tsx'
    ),
);
const SignalGraphWrapperEX = React.lazy(
  () =>
    import(
      './playerPages/PlayerGraphEX/common/components/Graph/GraphWrapper.tsx'
    ),
);

const App = () => {
  const [userInfo, setUserInfo] = useState({
    identifier: null,
    isCoach: null,
    accountType: null,
    admin: null,
    subadmin: null,
    user_player_id: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(Cookies.get('token'));
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const newToken = Cookies.get('token');
    setIsLoading(true);
    if (newToken) {
      const decodedToken = jwtDecode(newToken);
      setUserInfo((prevState) => ({
        ...prevState,
        identifier: decodedToken.identifier,
        isCoach: decodedToken.is_coach,
        accountType: decodedToken.account_type,
        admin: decodedToken.admin,
        subadmin: decodedToken.subadmin,
        user_player_id: decodedToken.user_player_id,
      }));
    }
    setIsLoading(false);
    // Handle ResizeObserver error
    const handleResizeError = (e) => {
      if (e.message.includes('ResizeObserver')) {
        const overlay = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (overlay) overlay.style.display = 'none';
      }
    };

    window.addEventListener('error', handleResizeError);
    return () => window.removeEventListener('error', handleResizeError);
  }, [token]);

  const PrivateRoute = ({ element }) =>
    token ? element : <Navigate to="/login" />;
  const CoachRoute = ({ element }) =>
    userInfo.isCoach ? element : <Navigate to="/" />;
  const AdminRoute = ({ element }) => {
    if (isLoading) {
      return <SimpleSpinner />;
    }

    if (!token) {
      return <Navigate to="/login" />;
    }

    return userInfo.admin || userInfo.subadmin ? element : <Navigate to="/" />;
  };
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <div className="App">
        <NavBar
          setToken={setToken}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
        <Suspense fallback={<SimpleSpinner />}>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute element={<HomeNew userInfo={userInfo} />} />
              }
            />
            <Route
              path="/home"
              element={
                <PrivateRoute
                  element={
                    <Home2
                      userType={userInfo.accountType}
                      userName={userInfo.identifier}
                    />
                  }
                />
              }
            />
            <Route
              path="/video-player/:videoId/:playlistId?"
              element={
                <PrivateRoute
                  element={<VideoInterface userInfo={userInfo} />}
                />
              }
            />
            <Route
              path="/video-upload"
              element={
                <PrivateRoute
                  element={<VideoUploadForm userInfo={userInfo} />}
                />
              }
            />
            <Route
              path="/playlist"
              element={
                <PrivateRoute
                  element={
                    <PlaylistPage
                      userType={userInfo.accountType}
                      userName={userInfo.identifier}
                    />
                  }
                />
              }
            />
            <Route
              path="/playlist/:playlistId"
              element={
                <PrivateRoute
                  element={
                    <PlaylistManagement
                      userType={userInfo.accountType}
                      userName={userInfo.identifier}
                    />
                  }
                />
              }
            />
            <Route
              path="/edit-metadata/:videoId/:step"
              element={
                <PrivateRoute element={<EditVideoData userInfo={userInfo} />} />
              }
            />
            <Route
              path="/player-analysis"
              element={
                <PrivateRoute element={<PlayerHome userInfo={userInfo} />} />
              }
            />
            <Route
              path="/player-analysis/:playerId"
              element={
                <PrivateRoute
                  element={<PlayerAnalysis userInfo={userInfo} />}
                />
              }
            />
            <Route path="/login" element={<LoginForm setToken={setToken} />} />
            <Route path="/sign-up/:hashedInfo" element={<SignUp />} />
            <Route
              path="/admin-page"
              element={
                <AdminRoute element={<AdminPage userInfo={userInfo} />} />
              }
            />
            <Route
              path="/player-graphdev"
              element={
                <PrivateRoute
                  element={
                    <SignalGraphWrapperDev
                      userInfo={userInfo}
                      setToken={setToken}
                    />
                  }
                />
              }
            />
            <Route
              path="/player-graph"
              element={
                <PrivateRoute
                  element={
                    <SignalGraphWrapperV3
                      userInfo={userInfo}
                      setToken={setToken}
                    />
                  }
                />
              }
            />
            <Route
              path="/player-graphEX"
              element={<PrivateRoute element={<SignalGraphWrapperEX />} />}
            />
          </Routes>
        </Suspense>
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
