import { FetchPlaylistParams, Playlist, CreatePlaylistData, EditPlaylistData } from "types/playlist";
import { HandleAPIError } from './auth';
import axios from 'axios';

const BASE_URL = '/api/playlistManagement';

interface MatchDict {
    [key: string]: string;
}

export const fetchPlaylistDataAPI = async (
    params: FetchPlaylistParams,
    token: string
): Promise<[Playlist[], MatchDict]> => {
    try {
        const response = await axios.get(`${BASE_URL}`, {
            params: params,
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const createPlaylistAPI = async (
    data: CreatePlaylistData,
    token: string
): Promise<{ message: string; playlist_id: number }> => {
    try {
        const response = await axios.post(`${BASE_URL}`, data, {
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const editPlaylistAPI = async (
    playlistId: number,
    data: EditPlaylistData,
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.put(`${BASE_URL}?playlist_id=${playlistId}`, data, {
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const deletePlaylistAPI = async (
    playlistId: number,
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.delete(`${BASE_URL}?playlist_id=${playlistId}`, {
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const addMatchToPlaylistAPI = async (
    playlistId: number,
    matchId: number,
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.post(`${BASE_URL}/add_match`, null, {
            params: { playlist_id: playlistId, match_id: matchId },
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const removeMatchFromPlaylistAPI = async (
    playlistId: number,
    matchId: number,
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.post(`${BASE_URL}/remove_match`, null, {
            params: { playlist_id: playlistId, match_id: matchId },
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const changePlaylistOrderAPI = async (
    playlistId: number,
    newOrder: number[],
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.put(`${BASE_URL}/change_order?playlist_id=${playlistId}`, { new_order: newOrder }, {
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const pinVideoAPI = async (
    playlistId: number,
    matchId: number,
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.post(`${BASE_URL}/pin_video`, null, {
            params: { playlist_id: playlistId, match_id: matchId },
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};

export const unpinVideoAPI = async (
    playlistId: number,
    matchId: number,
    token: string
): Promise<{ message: string }> => {
    try {
        const response = await axios.post(`${BASE_URL}/unpin_video`, null, {
            params: { playlist_id: playlistId, match_id: matchId },
            headers: { Authorization: `${token}` },
        });
        return response.data;
    } catch (error) {
        HandleAPIError(error);
        throw error;
    }
};