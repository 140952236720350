import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Cookies from 'js-cookie';

import { addMatchToPlaylistAPI } from 'src/api/playlistService';
import uploadFile from './utils';

import './UploadStatus.scss';

export const UploadStatus = ({
  userInfo,
  files,
  matchData,
  selectedPlaylists,
}) => {
  const token = Cookies.get('token');

  const [progressArray, setProgressArray] = useState([]);
  const [totalProgress, setTotalProgress] = useState(0);
  const [backdropError, setBackdropError] = useState(false);
  const [error, setError] = useState('');

  const handleCloseBackdrop = () => {
    setBackdropError(false); // Reset the error state to close the backdrop
  };

  const UploadErrorBackdrop = ({ onClose }) => {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }}>
        <div>
          <Typography variant="h6" component="div" color="error">
            失败，请重试。
            {backdropError.message}
          </Typography>
          <IconButton color="primary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Backdrop>
    );
  };

  // update multiple meta data
  const uploadMetaData = ({ filename, index }) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('new_results', JSON.stringify(matchData[index]));
      formData.append('filename', filename);
      formData.append('accountType', userInfo.accountType);
      formData.append('uploadUser', userInfo.identifier);
      axios
        .put('/api/uploadMetaData', formData, {
          params: {
            primary_attribute: 'video metadata updated: ' + filename,
          },
          headers: {
            Authorization: token, // Assuming `token` variable is defined
          },
        })
        .then((response) => {
          console.log('Metadata uploaded successfully!');
          resolve(response.data.match_id);
        })
        .catch((error) => {
          setError(error);
          console.error('Error occurred during metadata upload:', error);
          reject('Error occurred during metadata upload.');
        });
    });
  };

  const processFile = async (file, count, index, setProgressArray, matchId) => {
    return new Promise((resolve) => {
      uploadFile(file, index, userInfo.identifier, setProgressArray, matchId)
        .then(() => {
          resolve(count + 1); // Resolve the promise with incremented count
        })
        .catch((error) => {
          resolve(count + 1);
          setError(error);
        });
    });
  };

  const uploadPlaylist = async ({ match_id, playlist_id }) => {
    try {
      const responseData = await addMatchToPlaylistAPI(
        playlist_id,
        match_id,
        token,
      );
    } catch (error) {
      setError(error);
      console.log('error updating playlist');
    }
  };

  useEffect(() => {
    const processFiles = async (files) => {
      const totalFiles = files.length;
      let count = 0;
      const matchIds = []; // Array to store match_ids

      // First loop: Upload metadata and store match_ids
      for (const [index, file] of files.entries()) {
        // update database first then upload file
        const match_id = await uploadMetaData({
          filename: file.name,
          index: index,
        });

        matchIds[index] = match_id; // Store match_id at corresponding index

        // Handle playlists
        if (selectedPlaylists[index]) {
          for (const playlist_id of selectedPlaylists[index]) {
            uploadPlaylist({
              playlist_id: parseInt(playlist_id, 10),
              match_id: parseInt(match_id, 10),
            });
          }
        }
      }

      // Second loop: Process files using stored match_ids
      for (const [index, file] of files.entries()) {
        count = await processFile(
          file,
          count,
          index,
          setProgressArray,
          matchIds[index], // Use the stored match_id for this file
        );

        const curProgress = Math.round((count / totalFiles) * 100);
        setTotalProgress(curProgress);
      }

      // Set the state after all files are processed
      setTotalProgress(101);
    };

    if (files && files.length > 0) {
      processFiles(files);
    }
  }, [files]);

  return (
    <div className="upload-progress">
      <div className="upload-progress__content">
        {totalProgress > 100 ? (
          <>
            {error !== '' ? (
              <Typography variant="h5" className="upload-progress__error">
                Error: {error}
              </Typography>
            ) : (
              <>
                <Typography variant="h5" className="upload-progress__success">
                  視頻上傳成功!
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="upload-progress__message"
                >
                  您的視頻已成功上傳，結果將很快在平台上可用。
                </Typography>
                <Link
                  href="/"
                  variant="body2"
                  className="upload-progress__link"
                >
                  返回主頁
                </Link>
              </>
            )}
          </>
        ) : (
          <>
            {backdropError && (
              <UploadErrorBackdrop onClose={handleCloseBackdrop} />
            )}
            <Typography variant="h5" className="upload-progress__title">
              視頻上傳中...
            </Typography>
            {progressArray.map((progress, index) => (
              <div key={index} className="upload-progress__file">
                <Typography
                  variant="body2"
                  className="upload-progress__file-name"
                >
                  檔案上傳進度 {files[index].name}: {progress}%
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  className="upload-progress__progress-bar"
                />
              </div>
            ))}
            <div className="upload-progress__total">
              <Typography
                variant="body1"
                className="upload-progress__total-text"
              >
                整體上傳進度: {totalProgress}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={totalProgress}
                className="upload-progress__progress-bar upload-progress__progress-bar--total"
              />
            </div>
            <Typography variant="body2" className="upload-progress__warning">
              不要刷新頁面!! 請稍等 .........
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
