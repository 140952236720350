import { useState } from 'react';
import CommentDisplay from './utils/CommentDisplay';
import CommentEdit from './utils/CommentEdit';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { TextField } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import axios from 'axios';

import {
  ballIdIndex,
  roundEndTimeIndex,
  roundIdIndex,
  roundNumberIndex,
  roundStartTimeIndex,
} from 'src/constants';

import BallEditDisplay from './utils/BallEditDisplay';
import BallDisplay from './utils/BallDisplay';
import { formatTime } from 'src/utils/utils';

import './VideoRoundCommentary.scss';

const VideoRoundCommentary = ({
  commentData,
  setCommentData,
  userInfo,
  token,
  ballData,
  setBallData,
  videoRef,
  videoData,
  setVideoData,
  matchId,
  setFixedRound,
  fixedRound,
  roundData,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingBall, setIsEditingBall] = useState(false);
  const [editingBallIndex, setEditingBallIndex] = useState(-1);
  const [commentIndex, setCommentIndex] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInputValue, setDialogInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [expanded, setExpanded] = useState(false);

  const [isEditDialog, setIsEditDialog] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleFixedRoundChange = (event) => {
    setFixedRound(event.target.value);
  };

  const handleDialogOpen = (type) => {
    setDialogOpen(true);
    if (type === 'edit') {
      setIsEditDialog(true);
    } else {
      setIsEditDialog(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogInputValue('');
    setErrorMessage('');
  };

  const handleDialogInputChange = (event) => {
    setDialogInputValue(event.target.value);
  };

  const handleInsertBall = () => {
    axios
      .post(
        `/api/editballdata`,
        {
          new_row: [
            -1,
            roundData[0][roundIdIndex],
            roundData[0][roundStartTimeIndex],
            roundData[0][roundEndTimeIndex],
            0,
            0,
          ],
        },
        {
          params: {
            match_id: matchId,
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `new ball insert to round : ${roundData[0][roundNumberIndex]} ${roundData[0][roundStartTimeIndex]}s to ${roundData[0][roundEndTimeIndex]}s`,
          },
          headers: { Authorization: token },
        },
      )
      .then((response) => {
        setVideoData((prevState) => ({
          ...prevState,
          refresh: true,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create an array of numbers from 1 to videoData.numberOfRound
  const roundNumbers = Array.from(
    { length: videoData.numberOfRound },
    (_, index) => index + 1,
  );

  const commentOwner = userInfo.identifier;
  const permission = userInfo.isCoach || userInfo.admin;

  return (
    <div className="ball-commentary-main-container">
      {ballData[0] ? (
        <div className="ball-commentary-main-panel">
          <div className="round-details-panel">
            <span>回合:{roundData[0][roundNumberIndex]} </span>
            <span>
              開始 : {formatTime(roundData[0][roundStartTimeIndex])} 結束:{' '}
              {formatTime(roundData[0][roundEndTimeIndex])}
            </span>
          </div>
          {ballData.map((row, index) => (
            <div className="ball-commentary-ind-container" key={index}>
              {isEditingBall === `ballPanel${index}` ? (
                <BallEditDisplay
                  index={index}
                  videoData={videoData}
                  setVideoData={setVideoData}
                  ballData={ballData}
                  setBallData={setBallData}
                  setIsEditingBall={setIsEditingBall}
                  videoRef={videoRef}
                  matchId={matchId}
                  fixedRound={fixedRound}
                />
              ) : (
                <BallDisplay
                  index={index}
                  videoData={videoData}
                  setVideoData={setVideoData}
                  ballData={ballData}
                  setBallData={setBallData}
                  commentData={commentData}
                  setIsEditingBall={setIsEditingBall}
                  permission={permission}
                  matchId={matchId}
                  fixedRound={fixedRound}
                />
              )}
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{ width: '100%' }}
              >
                <AccordionSummary
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  sx={{ width: '100%' }}
                  title="顯示評論"
                >
                  {expanded === `panel${index}` ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {isEditing && editingBallIndex === index ? (
                    <CommentEdit
                      commentData={commentData}
                      currentBallIndex={index}
                      setIsEditing={setIsEditing}
                      commentIndex={commentIndex}
                      setCommentData={setCommentData}
                      commentOwner={commentOwner}
                      ballId={row[ballIdIndex]}
                      token={token}
                      setVideoData={setVideoData}
                    />
                  ) : (
                    <CommentDisplay
                      setIsEditing={setIsEditing}
                      commentData={commentData}
                      setCommentData={setCommentData}
                      setCommentIndex={setCommentIndex}
                      setEditingBallIndex={setEditingBallIndex}
                      currentBallIndex={index}
                      userInfo={userInfo}
                      token={token}
                      setVideoData={setVideoData}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default VideoRoundCommentary;
