import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Switch,
  CssBaseline,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  GameClickInfo,
  PlayerOption,
  SelectionEdgeDrawerProps,
} from "../../types/graph.types";
import GameInfoRenderer from "../game/renderGameInfo";
import { useTranslation } from "react-i18next";
import {
  getAvailableOpponents,
  getAvailablePlayers,
  getCommonOpponents,
  getGamesPlayedCount,
} from "./SelectionHelpers";
import { CommonOpponentDetails } from "../game/CommonOpponentDetails";
import { useGameDetails } from "../../hooks/useGameDetails";
import { GameDetailsSection } from "../game/GameDetailsSection";
import { PlayerSelection } from "./PlayerSelectionSection";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: grey[100],
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[900],
  }),
}));

const SelectionEdgeDrawer: React.FC<SelectionEdgeDrawerProps> = ({
  isOpen,
  onClose,
  onOpen,
  selection,
  playerData,
  connectionsData,
  showCommonOpponents,
  setShowCommonOpponents,
  handleReset,
  isMobile,
  handleCloseInfo,
  onPlayerSelect,
  showShortestPath,
  setShowShortestPath,
}) => {
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = React.useState<GameClickInfo[]>([]);

  const handlePlayerOneSelect = (
    event: React.SyntheticEvent,
    value: PlayerOption | null
  ) => {
    onPlayerSelect({
      selectedPlayer: value?.id || null,
      selectedOpponent: selection.selectedOpponent,
    });
  };

  const handlePlayerTwoSelect = (
    event: React.SyntheticEvent,
    value: PlayerOption | null
  ) => {
    onPlayerSelect({
      selectedPlayer: selection.selectedPlayer,
      selectedOpponent: value?.id || null,
    });
  };

  // Add handler for common opponent selection
  const handleCommonOpponentSelect = (
    event: React.SyntheticEvent,
    value: PlayerOption | null
  ) => {
    if (value) {
      onPlayerSelect({
        selectedPlayer: selection.selectedPlayer,
        selectedOpponent: selection.selectedOpponent,
        selectedCommonOpponent: value.id,
      });
    }
  };

  const SelectionContent = () => {
    // Load game details when players are selected
    const { gameDetails, isLoading } = useGameDetails(
      selection,
      connectionsData,
      playerData
    );

    const availablePlayers = getAvailablePlayers(connectionsData, playerData);
    // Get opponents based on selected player
    const availableOpponents = selection.selectedPlayer
      ? getAvailableOpponents(
          selection.selectedPlayer,
          connectionsData,
          playerData
        )
      : [];

    const handleViewDetails = (game: GameClickInfo) => {
      setOpenDetails([...openDetails, game]);
    };

    return (
      <Box sx={{ width: "100%" }}>
        {/* Add shortest path switch before the common opponents switch */}
        <FormControlLabel
          control={
            <Switch
              checked={showShortestPath}
              onChange={(e) => {
                setShowShortestPath(e.target.checked);
                if (e.target.checked) {
                  // When enabling shortest path, disable common opponents
                  setShowCommonOpponents(false);
                }
              }}
              color="primary"
              size={isMobile ? "small" : "medium"}
            />
          }
          label={"顯示最短路徑"}
          // label={
          //   t("selectionDrawer.showShortestPath") || "顯示最短路徑" // Fallback text if translation fails
          // }
          sx={{ mt: 1, display: "block" }}
        />
        {/* Player Selection Section */}
        <PlayerSelection
          label={t("selectionDrawer.selectFirstPlayer")}
          value={
            selection.selectedPlayer
              ? availablePlayers.find(
                  (p) => p.id === selection.selectedPlayer
                ) || null
              : null
          }
          onChange={handlePlayerOneSelect}
          options={availablePlayers}
          isMobile={isMobile}
          placeholderKey="selectionDrawer.searchPlayers"
        />

        <PlayerSelection
          label={t("selectionDrawer.selectSecondPlayer")}
          value={
            selection.selectedOpponent
              ? availableOpponents.find(
                  (p) => p.id === selection.selectedOpponent
                ) || null
              : null
          }
          onChange={handlePlayerTwoSelect}
          options={availableOpponents}
          disabled={!selection.selectedPlayer}
          isMobile={isMobile}
          placeholderKey="selectionDrawer.searchPlayers"
          altPlaceholderKey="selectionDrawer.selectFirstPlayerFirst"
        />

        {/* Common Opponents Section */}
        {showCommonOpponents &&
          selection.selectedPlayer &&
          selection.selectedOpponent && (
            <PlayerSelection
              label={t("selectionDrawer.selectCommonOpponent")}
              value={
                selection.selectedCommonOpponent
                  ? getCommonOpponents(
                      selection.selectedPlayer,
                      selection.selectedOpponent,
                      connectionsData,
                      playerData
                    ).find(
                      (opponent) =>
                        opponent.id === selection.selectedCommonOpponent
                    ) ?? null
                  : null
              }
              onChange={handleCommonOpponentSelect}
              options={getCommonOpponents(
                selection.selectedPlayer,
                selection.selectedOpponent,
                connectionsData,
                playerData
              )}
              isMobile={isMobile}
              placeholderKey="selectionDrawer.searchCommonOpponents"
            />
          )}

        {/* Common Opponents Details */}
        {showCommonOpponents &&
          selection.selectedPlayer &&
          selection.selectedOpponent &&
          selection.selectedCommonOpponent && (
            <CommonOpponentDetails
              commonOpponentId={selection.selectedCommonOpponent}
              commonGames={
                getCommonOpponents(
                  selection.selectedPlayer,
                  selection.selectedOpponent,
                  connectionsData,
                  playerData
                ).find(
                  (opponent) => opponent.id === selection.selectedCommonOpponent
                )?.commonGames!
              }
              firstPlayerId={selection.selectedPlayer}
              secondPlayerId={selection.selectedOpponent}
              playerData={playerData}
              connectionsData={connectionsData}
              onViewDetails={handleViewDetails}
            />
          )}

        {/* Game Details Section */}
        {selection.selectedPlayer && selection.selectedOpponent && (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2, mb: 2 }}
            >
              {t("selectionDrawer.gamesPlayed")}:{" "}
              {getGamesPlayedCount(selection, connectionsData)}
            </Typography>
            <GameDetailsSection
              gameDetails={gameDetails}
              isLoading={isLoading}
              onViewDetails={(game) => setOpenDetails([...openDetails, game])}
              playerName={playerData[selection.selectedPlayer || ""]}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showCommonOpponents}
                  onChange={(e) => {
                    setShowCommonOpponents(e.target.checked);
                    if (!e.target.checked) {
                      onPlayerSelect({
                        selectedPlayer: selection.selectedPlayer,
                        selectedOpponent: selection.selectedOpponent,
                        selectedCommonOpponent: null,
                      });
                    }
                  }}
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                />
              }
              label={t("selectionDrawer.showCommonOpponents")}
              sx={{ mt: 1, display: "block" }}
            />

            <Button
              variant="contained"
              color="error"
              size={isMobile ? "small" : "medium"}
              onClick={handleReset}
              sx={{ mt: 2, width: "100%" }}
            >
              {t("selectionDrawer.resetSelection")}
            </Button>
          </>
        )}
      </Box>
    );
  };

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {selection.selectedGameInfo
              ? t("selectionDrawer.gameDetails")
              : t("selectionDrawer.selectPlayers")}
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {selection.selectedGameInfo ? (
            <GameInfoRenderer
              gameInfo={selection.selectedGameInfo}
              onClose={handleCloseInfo}
              variant="drawer"
            />
          ) : (
            <SelectionContent />
          )}
        </StyledBox>
      </SwipeableDrawer>

      {openDetails.map((gameInfo, index) => (
        <SwipeableDrawer
          key={gameInfo.gameId} // Use the gameId as key
          anchor="bottom"
          open={true} // Keep it open as it's being rendered from the array
          onClose={() => {
            // Close the specific drawer and remove it from the state
            setOpenDetails(openDetails.filter((_, i) => i !== index));
          }}
          onOpen={() => {}}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: "absolute",
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: "text.secondary" }}>
              {gameInfo.metadata.eventName}
            </Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: "100%",
              overflow: "auto",
            }}
          >
            <GameInfoRenderer
              gameInfo={gameInfo}
              onClose={() => {
                // Close this specific drawer and remove it from the state
                setOpenDetails(openDetails.filter((_, i) => i !== index));
              }}
              variant="drawer"
            />
          </StyledBox>
        </SwipeableDrawer>
      ))}
    </Root>
  );
};

export default SelectionEdgeDrawer;
