import React from 'react';
import Flag from 'react-world-flags';
import EditPlayerDialog from 'src/playerPages/AnalysisMainPanel/EditPlayerDialog';
import './PlayerPanel.scss';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { downloadPlayersDataAPI } from 'src/api/playerService';
import Cookies from 'js-cookie';
interface Player {
    age: number;
    country: string;
    countryCode: string;
    head_shot: string;
    gender: string;
    player_name: string;
    player_id: number;
    country_code: string;
    handedness: string;
    grip: string;
    head_shot_file: string;
    player2_head_shot_file?: string;
    bio: string;
    win_rate: number;
    current_rank: number;
    dob: string;
    rubber_type?: string;
    flipped_rubber_type?: string;
}

interface PlayerPanelProps {
    playerList: Player[];
    accountType: string;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const getGenderDisplay = (gender: string) => {
    const genderMap: { [key: string]: string } = {
        'M': '男單',
        'F': '女單',
        'MM': '男雙',
        'FF': '女雙',
        'MF': '混雙'
    };
    return genderMap[gender] || '無';
};

const PlayerPanel: React.FC<PlayerPanelProps> = ({ playerList, accountType, setRefresh }) => {
    const token = Cookies.get('token');
    const handleDownloadPlayersSummary = async () => {
        try {
            const response = await downloadPlayersDataAPI(
                token as string,
            );

            // Get current date in dd_mm_yyyy format
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();
            const dateStr = `${dd}_${mm}_${yyyy}`;

            // Create filename with traditional Chinese and date
            const filename = `選手數據_${dateStr}.csv`;

            // Create a temporary URL for the downloaded file
            const url = window.URL.createObjectURL(new Blob([response]));
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            // Trigger the click event on the link to initiate the file download
            link.click();
            // Clean up the temporary link and URL
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="admin-player-panel">
            <button className='download-players-button' onClick={() => handleDownloadPlayersSummary()}>
                下載選手數據<DownloadOutlinedIcon />
            </button>
            <div className="player-grid">
                {playerList.map((player, index) => (
                    <div className="player-card" key={player.player_id || index}>
                        <div className="player-content">
                            <div
                                className="player-avatar"
                                style={{
                                    backgroundImage: `url(${player.head_shot})`,
                                    backgroundSize: 'cover',
                                }}
                            />

                            <div className="player-info">
                                <div className="name-section">
                                    <h3 className="player-name">{player.player_name}</h3>
                                    <div className="edit-controls">
                                        <EditPlayerDialog
                                            playerData={player}
                                            accountType={accountType}
                                            setRefresh={setRefresh}
                                        />
                                    </div>
                                </div>

                                <div className="country-info">
                                    {player.countryCode && (
                                        <Flag
                                            code={player.countryCode}
                                            className="country-flag"
                                        />
                                    )}
                                    <span className="country-name">{player.country}</span>
                                </div>

                                <div className="player-details">
                                    <span className="player-age">Age: {player.age}</span>
                                    <span className="player-gender">
                                        {getGenderDisplay(player.gender)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlayerPanel;