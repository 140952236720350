import { FC, useEffect, useCallback, useState } from "react";
import {
  useSigma,
  useLoadGraph,
  useRegisterEvents,
  useSetSettings,
} from "@react-sigma/core";
import { GraphDataLoaderProps } from "../../types/graph.types";
import {
  findCommonGameIds,
  getEnhancedLabelStyle,
  getGameCount,
} from "../../utils/graph/graph.utils";
import { getGraphReducers } from "../../constants/graph.constants";
import { animateNodes } from "sigma/utils";
import { useGraphLayouts } from "../../hooks/useGraphLayouts";
import { getGraphSettings } from "../../constants/GraphSetting";
import { useConnectedPlayers } from "../../hooks/useConnectedPlayers";
import { handleGameNodeClick } from "../../handlers/gameNodeHandler";
import { initializeGraph } from "../../handlers/graphInitializer";
import { useGameDetailsMap } from "../../hooks/useGameDetails";

export const MyGraphFromCombinedData: FC<GraphDataLoaderProps> = ({
  onSelectionChange,
  selection, // Add this
  shouldReset,
  currentLayout,
  isMobile = false,
  showCommonOpponents = false, // Add showCommonOpponents to destructured props
  playerData,
  connectionsData,
  showShortestPath,
}) => {
  // Hooks
  const sigma = useSigma();
  const loadGraph = useLoadGraph();
  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const layouts = useGraphLayouts(); // Call hook at component level
  const findConnectedPlayers = useConnectedPlayers(connectionsData, playerData);

  // State
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [selectedOpponent, setSelectedOpponent] = useState<string | null>(null);
  const [selectedCommonOpponent, setSelectedCommonOpponent] = useState<
    string | null
  >(null);
  const [connectedPlayers, setConnectedPlayers] = useState<string[]>([]);
  const [initialLayoutApplied, setInitialLayoutApplied] = useState(false);

  // Node Click Handler
  const handleNodeClick = useCallback(
    async (event: { node: string }) => {
      const nodeId = event.node;
      const nodeType = playerData[nodeId] !== undefined ? "player" : "game";

      switch (nodeType) {
        // Handle Game Node Clicks
        case "game": {
          const gameInfo = await handleGameNodeClick(
            nodeId,
            connectionsData,
            playerData
          );
          if (gameInfo && onSelectionChange) {
            onSelectionChange({
              selectedPlayer: selectedNode,
              selectedOpponent,
              selectedCommonOpponent,
              connectedPlayers,
              selectedGameInfo: gameInfo,
            });
          }
          return;
        }

        // Handle Player Node Clicks
        case "player": {
          switch (true) {
            // Case 1: No player selected yet (first selection)
            case !selectedNode: {
              const connected = findConnectedPlayers(nodeId);
              setSelectedNode(nodeId);
              setConnectedPlayers(connected);
              setSelectedOpponent(null);
              if (onSelectionChange) {
                onSelectionChange({
                  selectedPlayer: nodeId,
                  connectedPlayers: connected,
                  selectedOpponent: null,
                  selectedCommonOpponent: null,
                  selectedGameInfo: null,
                });
              }
              return;
            }

            // Case 2: Different player selected
            case nodeId !== selectedNode: {
              if (showCommonOpponents) {
                setSelectedCommonOpponent(nodeId);
                if (onSelectionChange) {
                  onSelectionChange({
                    selectedPlayer: selectedNode,
                    connectedPlayers,
                    selectedOpponent,
                    selectedCommonOpponent: nodeId,
                    selectedGameInfo: null,
                  });
                }
              } else {
                setSelectedOpponent(nodeId);
                if (onSelectionChange) {
                  onSelectionChange({
                    selectedPlayer: selectedNode,
                    connectedPlayers,
                    selectedOpponent: nodeId,
                    selectedCommonOpponent: null,
                    selectedGameInfo: null,
                  });
                }
              }
              return;
            }

            // Case 3: Same player selected
            default:
              return;
          }
        }
      }
    },
    [
      playerData,
      selectedNode,
      showCommonOpponents,
      findConnectedPlayers,
      connectionsData,
      connectedPlayers,
      onSelectionChange,
      selectedOpponent,
      selectedCommonOpponent,
    ]
  );

  // Reset Selection
  const resetSelection = useCallback(() => {
    if (!sigma) return;
    setSelectedNode(null);
    setSelectedOpponent(null);
    setSelectedCommonOpponent(null);
    setConnectedPlayers([]);
  }, [sigma]);

  // Effects
  useEffect(() => {
    if (
      selection.selectedPlayer === selectedNode &&
      selection.selectedOpponent === selectedOpponent
    ) {
      return;
    }

    setSelectedNode(selection.selectedPlayer);
    setSelectedOpponent(selection.selectedOpponent);
    setSelectedCommonOpponent(selection.selectedCommonOpponent);

    if (selection.selectedPlayer) {
      const connected = findConnectedPlayers(selection.selectedPlayer);
      setConnectedPlayers(connected);
    } else {
      setConnectedPlayers([]);
    }
  }, [selection, findConnectedPlayers, selectedNode, selectedOpponent]);

  useEffect(() => {
    if (initialLayoutApplied) return;

    const graph = initializeGraph(playerData, connectionsData, isMobile);
    loadGraph(graph);
    setInitialLayoutApplied(true);
  }, [loadGraph, initialLayoutApplied, isMobile, connectionsData, playerData]);

  useEffect(() => {
    registerEvents({
      clickNode: handleNodeClick,
    });
  }, [registerEvents, handleNodeClick]);

  useEffect(() => {
    if (shouldReset) {
      resetSelection();
    }
  }, [shouldReset, resetSelection]);

  const { gameDetailsMap, isLoading: isLoadingGameDetails } = useGameDetailsMap(
    selectedNode,
    selectedOpponent,
    connectionsData,
    findCommonGameIds
  );

  useEffect(() => {
    if (!sigma) return;

    const reducers = getGraphReducers({
      isMobile,
      selectedNode,
      selectedOpponent,
      selectedCommonOpponent,
      showCommonOpponents,
      playerData,
      connectionsData,
      findCommonGameIds,
      findConnectedPlayers,
      getGameCount,
      getEnhancedLabelStyle,
      sigma,
      gameDetailsMap, // Add this
      showShortestPath,
    });

    setSettings({
      ...getGraphSettings(isMobile),
      ...reducers,
    });
  }, [
    selectedNode,
    selectedOpponent,
    setSettings,
    sigma,
    findConnectedPlayers,
    isMobile,
    showCommonOpponents,
    selectedCommonOpponent,
    connectionsData,
    playerData,
    gameDetailsMap,
    showShortestPath,
  ]);

  useEffect(() => {
    if (!sigma || !initialLayoutApplied || !currentLayout) return; // Add currentLayout check

    // Use type assertion for currentLayout since we know it exists at this point
    const layout = layouts[currentLayout as keyof typeof layouts]?.layout;
    if (layout) {
      const positions = layout.positions();
      animateNodes(sigma.getGraph(), positions, { duration: 1000 });
    }
  }, [currentLayout, sigma, initialLayoutApplied, layouts]);

  useEffect(() => {
    console.log(showShortestPath);
  }, [showShortestPath]);

  return null;
};

// TODO: unstable code
// const handleNodeClick = useCallback(
//   async (event: { node: string }) => {
//     const nodeId = event.node;
//     const nodeType = playerData[nodeId] !== undefined ? "player" : "game";

//     switch (nodeType) {
//       // Handle Game Node Clicks
//       case "game": {
//         // Convert nodeId to number for comparison
//         const gameIdNum = parseInt(nodeId);

//         // Find the two players involved in this game
//         const gamePlayers = Object.keys(connectionsData).filter(
//           (playerId) =>
//             playerData[playerId] && // Ensure it's a player node
//             connectionsData[playerId]?.includes(gameIdNum) // Compare with number
//         );

//         if (gamePlayers.length >= 2) {
//           // Set the first player as main selection
//           const firstPlayer = gamePlayers[0];
//           const secondPlayer = gamePlayers[1];
//           const connected = findConnectedPlayers(firstPlayer);

//           // Update state to highlight both players
//           setSelectedNode(firstPlayer);
//           setSelectedOpponent(secondPlayer);
//           setConnectedPlayers(connected);
//           setSelectedCommonOpponent(null);
//         }

//         // Handle game info display
//         const gameInfo = await handleGameNodeClick(
//           nodeId,
//           connectionsData,
//           playerData
//         );

//         if (gameInfo && onSelectionChange) {
//           onSelectionChange({
//             selectedPlayer: gamePlayers[0],
//             selectedOpponent: gamePlayers[1],
//             selectedCommonOpponent: null,
//             connectedPlayers: findConnectedPlayers(gamePlayers[0]),
//             selectedGameInfo: gameInfo,
//           });
//         }
//         return;
//       }

//       // Handle Player Node Clicks
//       case "player": {
//         switch (true) {
//           case !selectedNode: {
//             const connected = findConnectedPlayers(nodeId);
//             setSelectedNode(nodeId);
//             setConnectedPlayers(connected);
//             setSelectedOpponent(null);
//             if (onSelectionChange) {
//               onSelectionChange({
//                 selectedPlayer: nodeId,
//                 connectedPlayers: connected,
//                 selectedOpponent: null,
//                 selectedCommonOpponent: null,
//                 selectedGameInfo: null,
//               });
//             }
//             return;
//           }

//           case nodeId !== selectedNode: {
//             if (showCommonOpponents) {
//               setSelectedCommonOpponent(nodeId);
//               if (onSelectionChange) {
//                 onSelectionChange({
//                   selectedPlayer: selectedNode,
//                   connectedPlayers,
//                   selectedOpponent,
//                   selectedCommonOpponent: nodeId,
//                   selectedGameInfo: null,
//                 });
//               }
//             } else {
//               setSelectedOpponent(nodeId);
//               if (onSelectionChange) {
//                 onSelectionChange({
//                   selectedPlayer: selectedNode,
//                   connectedPlayers,
//                   selectedOpponent: nodeId,
//                   selectedCommonOpponent: null,
//                   selectedGameInfo: null,
//                 });
//               }
//             }
//             return;
//           }

//           default:
//             return;
//         }
//       }
//     }
//   },
//   [
//     playerData,
//     selectedNode,
//     showCommonOpponents,
//     findConnectedPlayers,
//     connectionsData,
//     connectedPlayers,
//     onSelectionChange,
//     selectedOpponent,
//   ]
// );

// import { FC, useEffect, useCallback, useState, useMemo } from "react";
// import { flushSync } from "react-dom";
// import { debounce } from "lodash";
// import {
//   useSigma,
//   useLoadGraph,
//   useRegisterEvents,
//   useSetSettings,
// } from "@react-sigma/core";
// import { GameClickInfo, GraphDataLoaderProps } from "../../types/graph.types";
// import {
//   findCommonGameIds,
//   getEnhancedLabelStyle,
//   getGameCount,
// } from "../../utils/graph/graph.utils";
// import { getGraphReducers } from "../../constants/graph.constants";
// import { animateNodes } from "sigma/utils";
// import { useGraphLayouts } from "../../hooks/useGraphLayouts";
// import { getGraphSettings } from "../../constants/GraphSetting";
// import { useConnectedPlayers } from "../../hooks/useConnectedPlayers";
// import { handleGameNodeClick } from "../../handlers/gameNodeHandler";
// import { initializeGraph } from "../../handlers/graphInitializer";
// import { useGameDetailsMap } from "../../hooks/useGameDetails";
// import { Box } from "@mui/material";
// import GraphLoader from "../../utils/graph/GraphLoader";

// export const MyGraphFromCombinedData: FC<GraphDataLoaderProps> = ({
//   onSelectionChange,
//   selection,
//   shouldReset,
//   currentLayout,
//   isMobile = false,
//   showCommonOpponents = false,
//   playerData,
//   connectionsData,
//   showShortestPath,
// }) => {
//   // Hooks
//   const sigma = useSigma();
//   const loadGraph = useLoadGraph();
//   const registerEvents = useRegisterEvents();
//   const setSettings = useSetSettings();
//   const layouts = useGraphLayouts();
//   const findConnectedPlayers = useConnectedPlayers(connectionsData, playerData);

//   // State
//   const [selectedNode, setSelectedNode] = useState<string | null>(null);
//   const [selectedOpponent, setSelectedOpponent] = useState<string | null>(null);
//   const [selectedCommonOpponent, setSelectedCommonOpponent] = useState<
//     string | null
//   >(null);
//   const [connectedPlayers, setConnectedPlayers] = useState<string[]>([]);
//   const [initialLayoutApplied, setInitialLayoutApplied] = useState(false);

//   // Loading states
//   const [isGraphLoading, setIsGraphLoading] = useState(true);
//   const [isLayoutLoading, setIsLayoutLoading] = useState(false);
//   const [isDataUpdating, setIsDataUpdating] = useState(false);

//   // Add this at the top level of your component
//   const initializeGameCache = (
//     selectedNode: string | null,
//     selectedOpponent: string | null,
//     connectionsData: Record<string, number[]>,
//     findCommonGameIds: (
//       player1: string,
//       player2: string,
//       connections: any
//     ) => number[]
//   ) => {
//     if (!selectedNode || !selectedOpponent) return {};

//     const commonGames = findCommonGameIds(
//       selectedNode,
//       selectedOpponent,
//       connectionsData
//     );
//     return commonGames.reduce((acc, gameId) => {
//       acc[gameId.toString()] = null;
//       return acc;
//     }, {} as Record<string, GameClickInfo | null>);
//   };

//   // Near your other state declarations
//   const [gameDetailsCache, setGameDetailsCache] = useState<
//     Record<string, GameClickInfo>
//   >({});

//   // Use useGameDetailsMap before graphReducers
//   const { gameDetailsMap, isLoading: isLoadingGameDetails } = useGameDetailsMap(
//     selectedNode,
//     selectedOpponent,
//     connectionsData,
//     findCommonGameIds
//   );

//   // Update your effect to handle game details changes
//   useEffect(() => {
//     if (gameDetailsMap && Object.keys(gameDetailsMap).length > 0) {
//       setGameDetailsCache((prev) => ({
//         ...prev,
//         ...gameDetailsMap,
//       }));
//     }
//   }, [gameDetailsMap]);

//   // Memoized values
//   const graphReducers = useMemo(
//     () =>
//       getGraphReducers({
//         isMobile,
//         selectedNode,
//         selectedOpponent,
//         selectedCommonOpponent,
//         showCommonOpponents,
//         playerData,
//         connectionsData,
//         findCommonGameIds,
//         findConnectedPlayers,
//         getGameCount,
//         getEnhancedLabelStyle,
//         sigma,
//         gameDetailsMap: gameDetailsCache, // Use cached details
//         showShortestPath,
//       }),
//     [
//       isMobile,
//       selectedNode,
//       selectedOpponent,
//       selectedCommonOpponent,
//       showCommonOpponents,
//       playerData,
//       connectionsData,
//       sigma,
//       gameDetailsCache,
//       showShortestPath,
//       findConnectedPlayers,
//     ]
//   );

//   const graphSettings = useMemo(() => getGraphSettings(isMobile), [isMobile]);

//   // Connected Players Cache
//   const connectedPlayersCache = useMemo(() => {
//     if (!selectedNode || !connectionsData || !playerData) return new Map();

//     const cache = new Map();
//     const connected = findConnectedPlayers(selectedNode);
//     cache.set(selectedNode, connected);
//     return cache;
//   }, [selectedNode, connectionsData, playerData, findConnectedPlayers]);

//   // Optimized helper functions
//   const getConnectedPlayers = useCallback(
//     (nodeId: string) => {
//       if (connectedPlayersCache.has(nodeId)) {
//         return connectedPlayersCache.get(nodeId);
//       }
//       const connected = findConnectedPlayers(nodeId);
//       connectedPlayersCache.set(nodeId, connected);
//       return connected;
//     },
//     [connectedPlayersCache, findConnectedPlayers]
//   );

//   const updateSelectionState = useCallback(
//     (
//       nodeId: string | null,
//       oppId: string | null,
//       commonOppId: string | null,
//       connected: string[]
//     ) => {
//       setIsDataUpdating(true);
//       try {
//         flushSync(() => {
//           setSelectedNode(nodeId);
//           setSelectedOpponent(oppId);
//           setSelectedCommonOpponent(commonOppId);
//           setConnectedPlayers(connected);
//         });
//       } finally {
//         setTimeout(() => setIsDataUpdating(false), 200);
//       }
//     },
//     []
//   );

//   // Node Click Handler
//   const handleNodeClick = useCallback(
//     async (event: { node: string }) => {
//       setIsDataUpdating(true);

//       try {
//         const nodeId = event.node;
//         const nodeType = playerData[nodeId] !== undefined ? "player" : "game";

//         switch (nodeType) {
//           case "game": {
//             const gameInfo = await handleGameNodeClick(
//               nodeId,
//               connectionsData,
//               playerData
//             );
//             if (gameInfo && onSelectionChange) {
//               onSelectionChange({
//                 selectedPlayer: selectedNode,
//                 selectedOpponent,
//                 selectedCommonOpponent,
//                 connectedPlayers,
//                 selectedGameInfo: gameInfo,
//               });
//             }
//             break;
//           }

//           case "player": {
//             if (!selectedNode) {
//               const connected = getConnectedPlayers(nodeId);
//               updateSelectionState(nodeId, null, null, connected);
//               onSelectionChange?.({
//                 selectedPlayer: nodeId,
//                 connectedPlayers: connected,
//                 selectedOpponent: null,
//                 selectedCommonOpponent: null,
//                 selectedGameInfo: null,
//               });
//             } else if (nodeId !== selectedNode) {
//               if (showCommonOpponents) {
//                 updateSelectionState(
//                   selectedNode,
//                   selectedOpponent,
//                   nodeId,
//                   connectedPlayers
//                 );
//                 onSelectionChange?.({
//                   selectedPlayer: selectedNode,
//                   connectedPlayers,
//                   selectedOpponent,
//                   selectedCommonOpponent: nodeId,
//                   selectedGameInfo: null,
//                 });
//               } else {
//                 updateSelectionState(
//                   selectedNode,
//                   nodeId,
//                   null,
//                   connectedPlayers
//                 );
//                 onSelectionChange?.({
//                   selectedPlayer: selectedNode,
//                   connectedPlayers,
//                   selectedOpponent: nodeId,
//                   selectedCommonOpponent: null,
//                   selectedGameInfo: null,
//                 });
//               }
//             }
//             break;
//           }
//         }
//       } catch (error) {
//         console.error("Error handling node click:", error);
//       } finally {
//         setIsDataUpdating(false);
//       }
//     },
//     [
//       playerData,
//       selectedNode,
//       showCommonOpponents,
//       getConnectedPlayers,
//       updateSelectionState,
//       connectionsData,
//       connectedPlayers,
//       onSelectionChange,
//       selectedOpponent,
//       selectedCommonOpponent,
//     ]
//   );

//   // Reset Selection
//   const resetSelection = useCallback(() => {
//     if (!sigma) return;
//     updateSelectionState(null, null, null, []);
//   }, [sigma, updateSelectionState]);

//   // Layout change handler
//   const debouncedLayoutChange = useMemo(
//     () =>
//       debounce((layout: any, graph: any) => {
//         if (!layout) return;
//         const positions = layout.positions();
//         animateNodes(graph, positions, {
//           duration: 1000,
//           easing: "quadraticInOut",
//         } as any);
//       }, 250),
//     []
//   );

//   // Effects
//   useEffect(() => {
//     if (
//       selection.selectedPlayer === selectedNode &&
//       selection.selectedOpponent === selectedOpponent
//     ) {
//       return;
//     }

//     const connected = selection.selectedPlayer
//       ? getConnectedPlayers(selection.selectedPlayer)
//       : [];

//     updateSelectionState(
//       selection.selectedPlayer,
//       selection.selectedOpponent,
//       selection.selectedCommonOpponent,
//       connected
//     );
//   }, [
//     selection,
//     getConnectedPlayers,
//     updateSelectionState,
//     selectedNode,
//     selectedOpponent,
//   ]);

//   useEffect(() => {
//     if (initialLayoutApplied) return;

//     setIsGraphLoading(true);
//     const timer = setTimeout(() => {
//       try {
//         const graph = initializeGraph(playerData, connectionsData, isMobile);
//         loadGraph(graph);
//         setInitialLayoutApplied(true);
//       } finally {
//         setIsGraphLoading(false);
//       }
//     }, 300);

//     return () => clearTimeout(timer);
//   }, [loadGraph, initialLayoutApplied, isMobile, connectionsData, playerData]);

//   useEffect(() => {
//     registerEvents({
//       clickNode: handleNodeClick,
//     });
//   }, [registerEvents, handleNodeClick]);

//   useEffect(() => {
//     if (shouldReset) {
//       resetSelection();
//     }
//   }, [shouldReset, resetSelection]);

//   useEffect(() => {
//     if (!sigma) return;

//     setIsDataUpdating(true);
//     const updateTimer = requestAnimationFrame(() => {
//       try {
//         setSettings({
//           ...graphSettings,
//           ...graphReducers,
//         });
//       } finally {
//         setTimeout(() => setIsDataUpdating(false), 200);
//       }
//     });

//     return () => cancelAnimationFrame(updateTimer);
//   }, [graphReducers, graphSettings, sigma, setSettings]);

//   useEffect(() => {
//     if (!sigma || !initialLayoutApplied || !currentLayout) return;

//     setIsLayoutLoading(true);
//     const graph = sigma.getGraph();
//     const layout = layouts[currentLayout as keyof typeof layouts]?.layout;

//     debouncedLayoutChange(layout, graph);

//     const timer = setTimeout(() => {
//       setIsLayoutLoading(false);
//     }, 1000);

//     return () => {
//       clearTimeout(timer);
//       debouncedLayoutChange.cancel();
//     };
//   }, [
//     currentLayout,
//     sigma,
//     initialLayoutApplied,
//     layouts,
//     debouncedLayoutChange,
//   ]);

//   // Cleanup
//   useEffect(() => {
//     return () => {
//       if (sigma) {
//         sigma.getGraph().clear();
//       }
//     };
//   }, [sigma]);

//   // Combined loading state
//   const isLoading =
//     isGraphLoading || isLayoutLoading || isDataUpdating || isLoadingGameDetails;

//   return (
//     <Box sx={{ width: "100%", height: "100%" }}>
//       <GraphLoader isLoading={isLoading}>
//         <Box sx={{ width: "100%", height: "100%" }}>
//           {/* Sigma graph will be rendered here by parent component */}
//         </Box>
//       </GraphLoader>
//     </Box>
//   );
// };
