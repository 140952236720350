// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
  height: 100%;
  min-height: -webkit-fill-available; /* For iOS Safari */
  overflow: auto;
  position: fixed; /* This helps prevent elastic scrolling on iOS */
  width: 100%;
  top: 0;
  left: 0;
  background-color: #141414;
}

/* Make sure the html and body are also set correctly */
html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,kCAAA,EAAA,mBAAA;EACA,cAAA;EACA,eAAA,EAAA,gDAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,yBAAA;AACF;;AAEA,uDAAA;AACA;EACE,8BAAA;AACF;;AAEA;EACE,iBAAA;EACA,kCAAA;EACA,gBAAA;AACF;;AAEA;EACE,wBAAA;AACF","sourcesContent":[".App {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  max-height: 100%;\n  height: 100%;\n  min-height: -webkit-fill-available;  /* For iOS Safari */\n  overflow: auto;\n  position: fixed;  /* This helps prevent elastic scrolling on iOS */\n  width: 100%;\n  top: 0;\n  left: 0;\n  background-color: #141414;\n}\n\n/* Make sure the html and body are also set correctly */\nhtml {\n  height: -webkit-fill-available;\n}\n\nbody {\n  min-height: 100vh;\n  min-height: -webkit-fill-available;\n  overflow: hidden;\n}\n\niframe#webpack-dev-server-client-overlay {\n  display: none !important;\n}\n\n// .App {\n//   display: flex;\n//   flex-direction: column;\n//   justify-content: flex-start;\n//   align-items: center;\n//   max-height: 100vh;\n//   overflow: hidden;\n// }\n\n// iframe#webpack-dev-server-client-overlay {\n//   display: none !important;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
