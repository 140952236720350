import { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  IconButton,
  SwipeableDrawer,
  List,
  Divider,
} from '@mui/material';
import Padding from 'src/components/padding';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Dashboard from './DashBoard/dashboard';
import VideoManagement from './Videos/VideoManagement';
import PlayerManagement from './Players/PlayerManagement';
import Users from './Users/Users';
import { fetchUserListAPI } from 'src/api/userService';
import Cookies from 'js-cookie';
import { mainListItems } from './listItems';
import SportsTennisOutlinedIcon from '@mui/icons-material/SportsTennisOutlined';
import './AdminPage.scss';

const TabPanel = ({ children, value, index, ...props }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    className="tab-panel"
    {...props}
  >
    {value === index && children}
  </div>
);

export default function AdminPage({ userInfo }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [refreshData, setRefreshData] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const token = Cookies.get('token');

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await fetchUserListAPI(token);
        setUserData(response);
        const usernames = response.map((user) => user.username);
        sessionStorage.setItem('userList', JSON.stringify(usernames));
      } catch (error) {
        console.error(error);
      }
    };

    if (refreshData) {
      fetchUserList();
      setRefreshData(false);
    }
  }, [refreshData, token]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const tabs = [
    { label: 'Dashboard', icon: <DashboardIcon />, component: <Dashboard /> },
    {
      label: 'Users',
      icon: <PeopleIcon />,
      component: <Users userData={userData} setRefreshData={setRefreshData} />,
    },
    {
      label: 'Videos',
      icon: <VideoLibraryIcon />,
      component: (
        <VideoManagement
          userName={userInfo.identifier}
          accountType={userInfo.accountType}
        />
      ),
    },
    {
      label: 'Players',
      icon: <SportsTennisOutlinedIcon />,
      component: (
        <PlayerManagement
          userName={userInfo.identifier}
          accountType={userInfo.accountType}
        />
      ),
    },
  ];

  return (
    <div className="admin-dashboard">
      {/* <AppBar
        position="static"
        color="transparent"
        elevation={0}
        className="header"
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          className="header__menu-button"
        >
          <MenuIcon />
        </IconButton>
      </AppBar> */}

      <SwipeableDrawer
        className="drawer"
        classes={{
          paper: 'drawer__paper',
        }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="drawer__header">
          <IconButton onClick={toggleDrawer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List component="nav">
          {mainListItems({ handleListItemClick: () => {}, selectedItem: '' })}
        </List>
      </SwipeableDrawer>

      <div className="content-wrapper">
        <div className="tabs-container">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant={isMobile ? 'scrollable' : 'fullWidth'}
            scrollButtons={isMobile ? 'auto' : false}
            aria-label="admin navigation tabs"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                icon={tab.icon}
                label={tab.label}
                iconPosition="start"
              />
            ))}
          </Tabs>

          {tabs.map((tab, index) => (
            <TabPanel key={tab.label} value={selectedTab} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </div>
      </div>
      <Padding />
    </div>
  );
}
