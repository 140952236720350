import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';

export const FileUploadStatus = ({ matchData, reportFile, token }) => {
  const [progress, setProgress] = useState(0);
  const [backdropError, setBackdropError] = useState(false);

  const handleCloseBackdrop = () => {
    setBackdropError(false); // Reset the error state to close the backdrop
  };

  const UploadErrorBackdrop = ({ onClose }) => {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }}>
        <div>
          <Typography variant="h6" component="div" color="error">
            失败，请重试。
            {backdropError.message}
          </Typography>
          <IconButton color="primary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Backdrop>
    );
  };

  // upload files
  const handleConfirmUpload = () => {
    const formData = new FormData();
    let filename = [];

    if (reportFile) {
      formData.append('reportFile', reportFile);
      filename.push(reportFile.name);
    }

    formData.append('new_results', JSON.stringify(matchData));
    formData.append('match_id', matchData.matchId);

    axios
      .put(`/api/uploadFile`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(percentCompleted);
        },
        timeout: 600000, // Set an appropriate timeout value
        params: {
          primary_attribute: 'files uploaded : ' + filename,
        },
        headers: {
          Authorization: `${token}`,
        },
      })
      .then(() => {
        setProgress(101);
        console.log('File uploaded successfully!');
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          // Handle timeout error
          console.log('File upload timed out.');
        } else {
          console.error('Error occurred during file upload.', error);
        }
        setBackdropError(error);
      });
  };

  // check if there is any file to upload or data to update
  useEffect(() => {
    if (reportFile || matchData) {
      handleConfirmUpload();
    }
  }, [reportFile, matchData]);

  if (progress === 101) {
    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          檔案上傳成功!
        </Typography>
        <Typography variant="subtitle1">您的檔案已成功上傳。</Typography>
        <Link href="/" variant="body2">
          返回主頁。
        </Link>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {backdropError && <UploadErrorBackdrop onClose={handleCloseBackdrop} />}
        <Typography variant="h5" gutterBottom>
          檔案上傳中...
        </Typography>
        <Typography variant="subtitle1">您的檔案正在处理中.</Typography>
        <progress value={progress} max="100" />
      </React.Fragment>
    );
  }
};
