import { useState } from 'react';

import { IconButton } from '@mui/material';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';

import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import AddPlaylist from 'src/components/AddPlaylist/AddPlaylist';

import Cookies from 'js-cookie';

import {
  handleSeekBackward,
  handleSeekForward,
  handleSeekBackwardFrame,
  handleSeekForwardFrame,
  handleSeekBackwardBall,
  handleSeekForwardBall,
} from 'src/utils/videoControl';

import './VideoControlPanel.scss';

const VideoControlPanel = ({
  videoRef,
  roundResults,
  videoData,
  setVideoData,
  commentaryTimes,
  onZoomChange,
  scale,
  setScale,
  userInfo,
  matchData,
  currentTransformation,
}) => {
  const token = Cookies.get('token') || '';
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleZoomChange = (newScale) => {
    const clampedScale = Math.max(1, Math.min(newScale, 10));
    setScale(clampedScale);
    onZoomChange(clampedScale);
  };

  return (
    <div className={`video-control ${isMenuVisible ? 'visible' : 'hidden'}`}>
      {videoData.isFs !== null ? (
        <div
          className={`control-panel ${isMenuVisible ? 'visible' : 'hidden'}`}
        >
          <div className="options">
            <div
              className={`left-options ${isMenuVisible ? 'visible' : 'hidden'}`}
            >
              <IconButton
                title="上一个球"
                onClick={() =>
                  handleSeekBackwardBall(
                    videoRef,
                    commentaryTimes,
                    roundResults,
                    videoData.currentRound,
                    videoData.currentBall,
                    setVideoData,
                  )
                }
              >
                <SkipPreviousIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="前幀"
                onClick={() => handleSeekBackwardFrame(videoRef, setVideoData)}
              >
                <FastRewindIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="前5秒"
                onClick={() => handleSeekBackward(videoRef, setVideoData)}
              >
                <Replay5Icon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下5秒"
                onClick={() => handleSeekForward(videoRef, setVideoData)}
              >
                <Forward5Icon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下帧"
                onClick={() => handleSeekForwardFrame(videoRef, setVideoData)}
              >
                <FastForwardIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下一个球"
                onClick={() =>
                  handleSeekForwardBall(
                    videoRef,
                    commentaryTimes,
                    roundResults,
                    videoData.currentRound,
                    videoData.currentBall,
                    setVideoData,
                  )
                }
              >
                <SkipNextIcon sx={{ color: 'white' }} />
              </IconButton>
            </div>

            <div
              className={`others-menu ${isMenuVisible ? 'visible' : 'hidden'}`}
            >
              <div className="zoom-controls">
                <IconButton onClick={() => handleZoomChange(scale - 0.5)}>
                  <RemoveIcon className="zoom-icon" />
                </IconButton>
                <span
                  className="zoom-scale"
                  onClick={() => handleZoomChange(1)}
                >
                  {currentTransformation.scale.toFixed(1)}x
                </span>
                <IconButton onClick={() => handleZoomChange(scale + 0.5)}>
                  <AddIcon className="zoom-icon" />
                </IconButton>
              </div>
            </div>
            <div
              className={`right-content ${
                isMenuVisible ? 'visible' : 'hidden'
              }`}
            >
              <IconButton
                onClick={toggleMenu}
                className="toggle-button"
                aria-label={isMenuVisible ? 'Hide menu' : 'Show menu'}
              >
                {isMenuVisible ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
              {!isMenuVisible && (
                <>
                  <AddPlaylist
                    userInfo={userInfo}
                    matchId={matchData.matchId}
                  />
                  <FlagVideo matchId={matchData.matchId} defaultOpen={false} />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default VideoControlPanel;
