import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Groups2Icon from '@mui/icons-material/Groups2';
import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchPlayerDataAPI } from 'src/api/playerService';

import AnalysisMainPanel from './AnalysisMainPanel/AnalysisMainPanel';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import Padding from 'src/components/padding';

import './PlayerAnalysis.scss';

const PlayerAnalysis = ({ userInfo }) => {
  const { playerId } = useParams();

  const [error, setError] = useState('');
  const [playerData, setPlayerData] = useState({});
  const [playerList, setPlayerList] = useState([]);

  const token = Cookies.get('token');
  const [showSecondaryCondition, setShowSecondaryCondition] = useState(false);

  // get player data
  useEffect(() => {
    const fetchPlayersData = async () => {
      try {
        const response = await fetchPlayerDataAPI(
          {
            player_id: playerId,
            accountType: userInfo.accountType,
          },
          token,
        );

        if (!response.data) {
          return;
        }
        const responseData = response.data[0];

        console.log(responseData);

        const birthDate = responseData.dob
          ? new Date(responseData.dob)
          : new Date('2000-01-01');
        const currentDate = new Date();
        let actual_age = currentDate.getFullYear() - birthDate.getFullYear();

        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDay = currentDate.getDate();
        const birthDay = birthDate.getDate();
        const last_updated_date = new Date(
          responseData.last_updated,
        ).toLocaleDateString('en-GB');

        if (
          currentMonth < birthMonth ||
          (currentMonth === birthMonth && currentDay < birthDay)
        ) {
          actual_age--;
        }

        let head_shot_path = responseData.head_shot;
        let player2_head_shot_path = responseData.player2_head_shot;

        if (head_shot_path && !head_shot_path.startsWith('https')) {
          head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${responseData.head_shot}`;
        }

        if (
          player2_head_shot_path &&
          !player2_head_shot_path.startsWith('https')
        ) {
          player2_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${responseData.player2_head_shot}`;
        }

        const updatedResponseData = {
          ...responseData,
          last_updated: last_updated_date,
          actual_age: actual_age,
          head_shot: head_shot_path,
          player2_head_shot: player2_head_shot_path,
          head_shot_file: responseData.head_shot,
          player2_head_shot_file: responseData.player2_head_shot,
        };
        setPlayerData(updatedResponseData); // Set playerData state
      } catch (error) {
        setError(error.message);
      }
    };

    const timeout = setTimeout(() => {
      setShowSecondaryCondition(true);
    }, 10000);

    fetchPlayersData();
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const fetchPlayerList = async () => {
      try {
        const category = playerData.gender ? playerData.gender : 'none';
        const response = await axios.get(`/api/playersList`, {
          params: {
            accountType: userInfo.accountType,
            category: category,
          },
          headers: {
            Authorization: token,
          },
        });
        const responseData = response.data;
        if (responseData.length > 0) {
          responseData.sort((a, b) => {
            const playerA = a[0].toLowerCase();
            const playerB = b[0].toLowerCase();

            if (playerA < playerB) {
              return -1;
            }
            if (playerA > playerB) {
              return 1;
            }
            return 0;
          });
          setPlayerList(responseData);
        } else {
          setPlayerList([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (Object.keys(playerData).length > 0) {
      fetchPlayerList();
    }
  }, [playerData]);

  return (
    <div className="player-analysis">
      {Object.keys(playerData).length > 0 ? (
        <AnalysisMainPanel
          playerData={playerData}
          playerList={playerList}
          userInfo={userInfo}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#000000',
          }}
        >
          {showSecondaryCondition ? (
            <>
              <p style={{ color: '#ffffff' }}>找不到球員，點擊這裡返回主頁</p>
              <Link to="/player-analysis" className="player">
                <IconButton sx={{ color: 'red', fontSize: '2vw' }}>
                  <Groups2Icon sx={{ fontSize: '2vw' }} />
                  球員
                </IconButton>
              </Link>
            </>
          ) : (
            <LoadingScreen option={2} color={2} />
          )}
        </div>
      )}
      <Padding />
    </div>
  );
};

export default PlayerAnalysis;
