// shared/useGameDetails.ts
import { useState, useEffect } from "react";
import {
  GameClickInfo,
  Selection_,
  ConnectionsData,
  PlayerData,
} from "../types/graph.types";
import { fetchVideoInfoData } from "../data/VideoInfoData";

export const useGameDetails = (
  selection: Selection_,
  connectionsData: ConnectionsData,
  playerData: PlayerData
) => {
  const [gameDetails, setGameDetails] = useState<GameClickInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadGameDetails = async () => {
      if (!selection.selectedPlayer || !selection.selectedOpponent) return;

      setIsLoading(true);
      try {
        const playerGames = connectionsData[selection.selectedPlayer] || [];
        const opponentGames = connectionsData[selection.selectedOpponent] || [];
        const commonGames = playerGames.filter((gameId) =>
          opponentGames.includes(gameId)
        );

        const details = await Promise.all(
          commonGames.map(async (gameId) => {
            const response = await fetchVideoInfoData(gameId);
            if (!response.data) return null;

            const parsed = response.data;
            return {
              gameId: gameId.toString(),
              metadata: {
                filename: parsed.metadata.filename,
                eventName: parsed.metadata.eventName,
                finalscore1: parsed.metadata.finalscore1,
                finalscore2: parsed.metadata.finalscore2,
                id: parsed.metadata.id,
                location: parsed.metadata.location,
                round: parsed.metadata.round,
                team1: parsed.metadata.team1,
                team2: parsed.metadata.team2,
                date: parsed.metadata.date,
              },
              gameScores: parsed.games.map((game) => ({
                gameNumber: game?.gameInfo?.gameNumber ?? -1,
                finalTeam1Score:
                  game?.points?.[game.points.length - 1]?.team1Score ?? null,
                finalTeam2Score:
                  game?.points?.[game.points.length - 1]?.team2Score ?? null,
              })),
              players: Object.entries(connectionsData)
                .filter(([, games]) => games.includes(gameId))
                .map(([playerId]) => ({
                  playerId,
                  playerName: playerData[playerId],
                })),
            };
          })
        );

        setGameDetails(details.filter(Boolean) as GameClickInfo[]);
      } catch (error) {
        console.error("Error loading game details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadGameDetails();
  }, [
    selection.selectedPlayer,
    selection.selectedOpponent,
    connectionsData,
    playerData,
  ]);

  return { gameDetails, isLoading };
};
