import { useSigma } from "@react-sigma/core";
import { useLayoutCircular } from "@react-sigma/layout-circular";
import { useLayoutCirclepack } from "@react-sigma/layout-circlepack";
import { useLayoutRandom } from "@react-sigma/layout-random";
import {
  useLayoutNoverlap,
  useWorkerLayoutNoverlap,
} from "@react-sigma/layout-noverlap";
import {
  useLayoutForce,
  useWorkerLayoutForce,
} from "@react-sigma/layout-force";
import {
  useLayoutForceAtlas2,
  useWorkerLayoutForceAtlas2,
} from "@react-sigma/layout-forceatlas2";
import { useCallback, useMemo } from "react";
import { calculateCircleLayout } from "../utils/layouts/layoutUtils";

export const useGraphLayouts = () => {
  const sigma = useSigma();

  const layoutCircular = useLayoutCircular();
  const layoutCirclepack = useLayoutCirclepack();
  const layoutRandom = useLayoutRandom();
  const layoutNoverlap = useLayoutNoverlap();
  const layoutForce = useLayoutForce({ maxIterations: 100 });

  // const layoutForceAtlas2 = useLayoutForceAtlas2({
  //   iterations: 100,
  //   settings: {
  //     gravity: 20,
  //     scalingRatio: 10,
  //   },
  // });

  const layoutForceAtlas2 = useLayoutForceAtlas2({
    iterations: 250,
    settings: {
      gravity: 12, // Reduce gravity to prevent nodes from clustering too tightly.
      scalingRatio: 20, // Increase scaling ratio to spread nodes further apart.
      strongGravityMode: true, // Enable strong gravity mode for better node separation.
      barnesHutOptimize: true, // Optimize repulsion calculations for larger graphs.
      barnesHutTheta: 0.6, // Adjust Barnes-Hut approximation for better repulsion accuracy.
      adjustSizes: true, // Consider node sizes to prevent overlap.
      slowDown: 0.5, // Reduce the slow-down factor for a faster and smoother spread.
    },
  });

  const getTwoLayerCircleLayout = useCallback(() => {
    return calculateCircleLayout(sigma.getGraph(), false, true);
  }, [sigma]);

  return useMemo(
    () => ({
      twoLayerCircle: {
        layout: {
          positions: getTwoLayerCircleLayout,
        },
      },
      circular: {
        layout: layoutCircular,
      },
      circlepack: {
        layout: layoutCirclepack,
      },
      random: {
        layout: layoutRandom,
      },
      noverlaps: {
        layout: layoutNoverlap,
        worker: useWorkerLayoutNoverlap,
      },
      forceDirected: {
        layout: layoutForce,
        worker: useWorkerLayoutForce,
      },
      forceAtlas: {
        layout: layoutForceAtlas2,
        worker: useWorkerLayoutForceAtlas2,
      },
    }),
    [
      layoutForceAtlas2,
      getTwoLayerCircleLayout,
      layoutCircular,
      layoutCirclepack,
      layoutRandom,
      layoutNoverlap,
      layoutForce,
    ]
  );
};
