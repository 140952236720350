import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Button,
  Link as MuiLink,
} from '@mui/material';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import UserOption from 'src/components/UserOption/UserOption';
import Cookies from 'js-cookie';
import { styled } from '@mui/material/styles';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import SmartScreenOutlinedIcon from '@mui/icons-material/SmartScreenOutlined';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#212529', // Set the AppBar background color to black
  '.MuiToolbar-dense': {
    minHeight: 'unset',
  },
}));

const NavBar = ({ setToken, userInfo, setUserInfo }) => {
  const token = Cookies.get('token');

  const chunkName = (name) => {
    if (name.length > 8) {
      return name.slice(0, 8) + '...';
    }
    return name;
  };

  const handleLogout = () => {
    Cookies.remove('token');
    setToken(null);
    setUserInfo({
      identifier: null,
      isCoach: null,
      accountType: null,
      admin: null,
      subadmin: null,
    });
  };

  const renderUserOptions = () => {
    if (!userInfo.identifier) {
      return (
        <MuiLink
          component={RouterLink}
          to="/login"
          color="inherit"
          underline="none"
        >
          登入
        </MuiLink>
      );
    }

    if (userInfo.accountType === 'm' && !userInfo.admin && !userInfo.subadmin) {
      return (
        <Button
          variant="contained"
          className="logout-button"
          onClick={handleLogout}
        >
          登出
        </Button>
      );
    }

    return (
      <UserOption
        setToken={setToken}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
    );
  };

  return (
    <>
      {token && (
        <CustomAppBar position="static">
          <Toolbar variant="dense" sx={{ minHeight: 'unset' }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <IconButton
                edge="start"
                color="inherit"
                component={RouterLink}
                to="/"
                title="返回主頁"
              >
                <EmojiEventsOutlinedIcon sx={{ color: '#dbbb06' }} />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {userInfo.accountType === 'a' && (
                <IconButton
                  color="inherit"
                  component={RouterLink}
                  to="/player-graph"
                  title="關係圖"
                >
                  <SettingsEthernetIcon />
                </IconButton>
              )}

              <IconButton
                color="inherit"
                component={RouterLink}
                to="/home"
                title="返回主頁"
              >
                <SmartScreenOutlinedIcon />
              </IconButton>

              <IconButton
                color="inherit"
                component={RouterLink}
                to="/player-analysis"
                title="選手分析"
              >
                <Groups2Icon />
              </IconButton>
              <IconButton
                color="inherit"
                component={RouterLink}
                to="/playlist"
                title="影片列表"
              >
                <SubscriptionsOutlinedIcon />
              </IconButton>

              <IconButton
                color="inherit"
                component={RouterLink}
                to="/video-upload"
                title="上傳視頻"
              >
                <DriveFolderUploadIcon />
              </IconButton>

              {renderUserOptions()}
            </Box>
          </Toolbar>
        </CustomAppBar>
      )}
    </>
  );
};

export default NavBar;
