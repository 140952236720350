import React from 'react';
import { TextField } from '@mui/material';
import { formatTime } from 'src/utils/utils';

import {
  ballAwayPlayerScoreIndex,
  ballHomePlayerScoreIndex,
} from 'src/constants';

import './InputPanel.scss';

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
}

interface BallData {
  ball_id: number;
  round_id: number;
  start_time: number;
  end_time: number;
  home_player_score: number;
  away_player_score: number;
  highlight: boolean;
  critical_point: boolean;
  deuce: boolean;
}

interface InputPanelProps {
  ballData: BallData | null;
  videoRef: React.RefObject<HTMLVideoElement>;
  setNewBallData: React.Dispatch<React.SetStateAction<BallData>>;
}

const InputPanel: React.FC<InputPanelProps> = ({
  ballData,
  videoRef,
  setNewBallData,
}) => {
  const handleScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    scoreIndex: number,
  ) => {
    if (!ballData) return;

    const newScore = Math.min(parseInt(event.target.value, 10), 50);
    if (isNaN(newScore)) return;

    const updatedBallData = { ...ballData };
    if (scoreIndex === ballHomePlayerScoreIndex) {
      updatedBallData.home_player_score = newScore;
    } else if (scoreIndex === ballAwayPlayerScoreIndex) {
      updatedBallData.away_player_score = newScore;
    }

    setNewBallData(updatedBallData);
  };

  const handleTimeUpdate = (isStartTime: boolean) => {
    if (!videoRef.current || !ballData) return;

    const currentTime = videoRef.current.currentTime;
    const updatedBallData = { ...ballData };

    if (isStartTime) {
      updatedBallData.start_time = currentTime;
    } else {
      updatedBallData.end_time = currentTime;
    }

    setNewBallData(updatedBallData);
  };

  return (
    <div className="input-ball-edit-container">
      <div className="ball-score-edit-panel">
        <TextField
          type="number"
          value={ballData?.home_player_score ?? ''}
          onChange={(e) =>
            handleScoreChange(
              e as React.ChangeEvent<HTMLInputElement>,
              ballHomePlayerScoreIndex,
            )
          }
          inputProps={{ min: 0, max: 50 }}
          className="score-input"
        />
        <span className="score-separator">:</span>
        <TextField
          type="number"
          value={ballData?.away_player_score ?? ''}
          onChange={(e) =>
            handleScoreChange(
              e as React.ChangeEvent<HTMLInputElement>,
              ballAwayPlayerScoreIndex,
            )
          }
          inputProps={{ min: 0, max: 50 }}
          className="score-input"
        />
      </div>
      <div className="ball-info-edit-panel">
        <button
          onClick={() => handleTimeUpdate(true)}
          title="Set current video time as start time"
          className="time-button"
        >
          {formatTime(ballData?.start_time)}
        </button>
        <span className="time-separator">:</span>
        <button
          onClick={() => handleTimeUpdate(false)}
          title="Set current video time as end time"
          className="time-button"
        >
          {formatTime(ballData?.end_time)}
        </button>
      </div>
    </div>
  );
};

export default InputPanel;
