import React from 'react';
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled version if you want custom styling
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(1.5),
}));

interface DrawerProps {
  drawerOpen: boolean;
  handleDrawerClose: () => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const DrawerComponent: React.FC<DrawerProps> = ({
  drawerOpen,
  handleDrawerClose,
  handleTabChange,
}) => {
  const menuItems = [
    '全部雙打',
    '全部單打',
    '男單打',
    '女單打',
    '男雙打',
    '女雙打',
    '混合雙打',
  ];

  return (
    <Drawer 
      anchor="left" 
      open={drawerOpen} 
      onClose={handleDrawerClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 250,
        },
      }}
    >
      <Box>
        <List>
          {menuItems.map((text, index) => (
            <StyledListItemButton
              key={text}
              onClick={(event) => handleTabChange(event, index)}
            >
              <ListItemText 
                primary={text}
                primaryTypographyProps={{
                  sx: { fontWeight: 500 } // Optional: make text slightly bolder
                }}
              />
            </StyledListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;