import React from 'react';
import './customToolTip.scss';

interface TooltipInfo {
  numBall: number;
  coordinates: [number, number];
  time: string;
  player: string;
  balltraj: string;
}

interface CustomTooltipProps {
  info: TooltipInfo;
  style?: React.CSSProperties;
  onClose: () => void;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ info, onClose }) => (
  <div className="custom-tooltip">
    <div className="tooltip-header">
      <span className="ball-number">第 {info.numBall} 拍</span>
      <span className="player-traj-name">{info.player}</span>
      <button className="close-button" onClick={onClose}>
        ×
      </button>
    </div>
    <div className="tooltip-body">
      <div className="tooltip-row">
        <span className="tooltip-label">落點座標：</span>
        <span className="tooltip-value">
          x: {info.coordinates[0]}, y: {info.coordinates[1]}
        </span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-label">落點時間：</span>
        <span className="tooltip-value">{info.time}</span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-label">球路：</span>
        <span className="tooltip-value">{info.balltraj}</span>
      </div>
    </div>
  </div>
);

export default CustomTooltip;
