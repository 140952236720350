// src/handlers/gameNodeHandler.ts
import { fetchVideoInfoData } from '../data/VideoInfoData';
import { GameClickInfo, ConnectionsData, PlayerData } from '../types/graph.types';

export const handleGameNodeClick = async (
  nodeId: string,
  connectionsData: ConnectionsData,
  playerData: PlayerData
): Promise<GameClickInfo | null> => {
  const response = await fetchVideoInfoData(parseInt(nodeId));
  if (!response.data) return null;

  const parsed = response.data;
  const gameScores = parsed.games.map(game => ({
    gameNumber: game?.gameInfo?.gameNumber ?? -1,
    finalTeam1Score: game?.points?.[game.points.length - 1]?.team1Score ?? null,
    finalTeam2Score: game?.points?.[game.points.length - 1]?.team2Score ?? null,
  }));

  return {
    gameId: nodeId,
    metadata: {
      ...parsed.metadata,
    },
    gameScores,
    players: Object.entries(connectionsData)
      .filter(([playerId, games]) => games.includes(parseInt(nodeId)))
      .map(([playerId]) => ({
        playerId,
        playerName: playerData[playerId],
      })),
  };
};