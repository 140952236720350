import { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';

import { countryCodeMapping } from 'src/constants';
import * as Flags from 'country-flag-icons/react/3x2';

import './VideoMetaDataForm.scss';

export default function VideoMetaDataForm({
  matchData,
  setMatchData,
  playerList,
  CustomTagList,
}) {
  const categories = ['男單打', '男雙打', '女單打', '女雙打', '混合雙打'];

  const translationDict = {
    MSingles: '男單打',
    MDouble: '男雙打',
    FSingle: '女單打',
    FDouble: '女雙打',
    MixedDouble: '混合雙打',
    男單打: 'MSingles',
    男雙打: 'MDouble',
    女單打: 'FSingle',
    女雙打: 'FDouble',
    混合雙打: 'MixedDouble',
  };
  const stages = [
    'Final',
    'Semi-Final',
    'Quarter-Final',
    'R16',
    'R32',
    'R64',
    'R128',
  ];

  const event = [
    { subEvent: 'Contender', parentEvent: 'WTT' },
    { subEvent: 'Star Contender', parentEvent: 'WTT' },
    { subEvent: 'Champions', parentEvent: 'WTT' },
    { subEvent: 'Cup Finals', parentEvent: 'WTT' },
    { subEvent: 'Grand Smash', parentEvent: 'WTT' },
    { subEvent: 'Others(WTT)', parentEvent: 'WTT' },
    { subEvent: 'National Games', parentEvent: 'Games' },
    { subEvent: 'Asian Games', parentEvent: 'Games' },
    { subEvent: 'Olympic Games', parentEvent: 'Games' },
    { subEvent: 'Universiade', parentEvent: 'Games' },
    { subEvent: 'Asian Para Games', parentEvent: 'Games' },
    { subEvent: 'Paralympic Games', parentEvent: 'Games' },
    { subEvent: 'World Abilitysport Games', parentEvent: 'Games' },
    { subEvent: 'Virtus Global Games', parentEvent: 'Games' },
    { subEvent: 'Others(Games)', parentEvent: 'Games' },
    { subEvent: 'ITTF World Championships', parentEvent: 'ITTF' },
    { subEvent: 'ITTF Mixed Team World Cup', parentEvent: 'ITTF' },
    { subEvent: 'ITTF World Youth Championships', parentEvent: 'ITTF' },
    {
      subEvent: 'Asia Continental Stage of 2023 WTTC Finals',
      parentEvent: 'ITTF',
    },
    { subEvent: 'ITTF Para Open', parentEvent: 'ITTF' },
    { subEvent: 'championships', parentEvent: 'ITTF' },
    { subEvent: 'Others(ITTF)', parentEvent: 'ITTF' },
    { subEvent: 'Others', parentEvent: 'Others' },
  ];

  const handleEventInputChange = (event, value) => {
    if (value) {
      const selectedSubEvent = value.subEvent;
      const selectedParentEvent = value.parentEvent;
      setMatchData((prevData) => ({
        ...prevData,
        matchEvent: selectedSubEvent,
        matchParentEvent: selectedParentEvent,
      }));
    }
  };

  const handleCatInputChange = (event) => {
    const { name, value } = event.target;
    const translatedValue = translationDict[value];
    setMatchData((prevData) => ({
      ...prevData,
      matchCategory: translatedValue,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMatchData((prevData) => ({ ...prevData, [name]: value }));
  };

  if (!matchData.videoName && !matchData.matchDate) {
    // Render a loading state or return null while matchData is loading
    return null;
  }

  return (
    <Fragment>
      <Grid
        container
        item
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid
          container
          item
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <Typography className="metadata-title" gutterBottom>
              比賽資訊
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <TextField
              required
              id="videoName"
              name="videoName"
              label="视频名称"
              fullWidth
              autoComplete="video-name"
              variant="standard"
              onChange={handleInputChange}
              defaultValue={matchData.videoName}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <TextField
              id="matchDescription"
              name="videoDescription"
              label="比赛详情"
              fullWidth
              autoComplete="matchDescription"
              variant="standard"
              multiline
              onChange={handleInputChange}
              defaultValue={matchData.videoDescription}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TextField
              id="matchDate"
              name="matchDate"
              label="比赛日期"
              fullWidth
              autoComplete="matchDate"
              variant="standard"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}
              defaultValue={matchData.matchDate}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TextField
              id="location"
              name="location"
              label="比赛地点"
              fullWidth
              variant="standard"
              onChange={handleInputChange}
              defaultValue={matchData.location}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <Typography className="metadata-title" gutterBottom>
              比賽類別
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <InputLabel id="stage-simple-select-helper-label">
              賽事:{matchData.matchEvent}
            </InputLabel>
            <Autocomplete
              id="grouped-match"
              name="matchEvent"
              options={event}
              groupBy={(option) => option.parentEvent}
              getOptionLabel={(option) => option.subEvent}
              renderInput={(params) => (
                <TextField {...params} label="乒乓球賽事" />
              )}
              onChange={handleEventInputChange}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <InputLabel id="stage-simple-select-helper-label">
              阶段:{matchData.stage}
            </InputLabel>
            <Select
              id="stage"
              name="stage"
              onChange={handleInputChange}
              input={<OutlinedInput label="Stage" />}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
              value={matchData.stage}
            >
              <MenuItem value="" disabled>
                <em>請选择阶段</em>
              </MenuItem>
              {stages.map((stage) => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <InputLabel id="cat-simple-select-helper-label">
              比賽類型:{translationDict[matchData.matchCategory]}
            </InputLabel>
            <Select
              id="categories"
              name="categories"
              label="阶段"
              onChange={handleCatInputChange}
              input={<OutlinedInput label="Category" />}
              value={translationDict[matchData.matchCategory]}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <InputLabel id="aPlayer-simple-select-helper-label">
              自訂標籤
            </InputLabel>
            <Autocomplete
              id="customTag"
              options={CustomTagList}
              value={matchData.customTag || null}
              onChange={(event, newValue) => {
                handleInputChange({
                  target: {
                    name: 'customTag',
                    value: newValue || '',
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="customTag"
                  variant="outlined"
                  fullWidth
                />
              )}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TextField
              id="textfield"
              name="customTag"
              label="新自訂標籤"
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <Typography className="metadata-title" gutterBottom>
              比賽選手
            </Typography>
          </Grid>
          <Grid
            className="edit-video-home-player-grid"
            item
            xs={2}
            sm={4}
            md={6}
          >
            <InputLabel id="hPlayer-simple-select-helper-label">
              主場球員:{matchData.homePlayer}
            </InputLabel>
            <Autocomplete
              id="hPlayer"
              options={playerList}
              getOptionLabel={(option) => option[0]}
              value={
                playerList.find(
                  (player) => player[0] === matchData.homePlayer,
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange({
                  target: {
                    name: 'homePlayer',
                    value: newValue ? newValue[0] : '',
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="homePlayer"
                  variant="outlined"
                  fullWidth
                />
              )}
              renderOption={(props, option) => {
                const countryCode = countryCodeMapping[option[1]] || '';
                const Flag = Flags[countryCode];

                return (
                  <li {...props} className="edit-video-home-player-select">
                    {Flag && <Flag className="player-smallflag" />}
                    {option[0]}
                  </li>
                );
              }}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            />
          </Grid>
          <Grid
            className="edit-video-away-player-grid"
            item
            xs={2}
            sm={4}
            md={6}
          >
            <InputLabel id="aPlayer-simple-select-helper-label">
              客場球員:{matchData.awayPlayer}
            </InputLabel>
            <Autocomplete
              id="aPlayer"
              options={playerList}
              getOptionLabel={(option) => option[0]}
              value={
                playerList.find(
                  (player) => player[0] === matchData.awayPlayer,
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange({
                  target: {
                    name: 'awayPlayer',
                    value: newValue ? newValue[0] : '',
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="awayPlayer"
                  variant="outlined"
                  fullWidth
                />
              )}
              renderOption={(props, option) => {
                const countryCode = countryCodeMapping[option[1]] || '';
                const Flag = Flags[countryCode];

                return (
                  <li {...props} className="edit-video-away-player-select">
                    {Flag && <Flag className="player-smallflag" />}
                    {option[0]}
                  </li>
                );
              }}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TextField
              id="input-with-icon-textfield"
              name="awayPlayer"
              label="新主場球員"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <TextField
              id="input-with-icon-textfield"
              name="awayPlayer"
              label="新客場球員"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
