import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { updateProgressDataAPI, getProgressDataAPI } from 'src/api/vqService';
import Cookies from 'js-cookie';

import './VideoFlag.scss';

interface FlagVideoProps {
  defaultOpen: boolean;
  matchId: number;
}

const FlagVideo: React.FC<FlagVideoProps> = ({
  defaultOpen = false,
  matchId,
}) => {
  const token = Cookies.get('token');
  const [open, setOpen] = useState(defaultOpen);
  const [flagComment, setFlagComment] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProgressDataAPI(
          { matchId: matchId, videoId: null },
          token as string,
        );
        setFlagComment(response.flagged_comment);
      } catch (error) {
        console.log('error getting progress data');
      }
    };

    fetchData();
  }, [matchId, token]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsEditing(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  const handleSubmitComment = async () => {
    try {
      const params = {
        matchId,
        flaggedcomment: flagComment,
      };
      await updateProgressDataAPI(params, token as string);
      handleClose();
    } catch (error) {
      console.error('Error updating flag comment:', error);
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFlagComment(event.target.value);
  };

  const handleRemoveFlag = async () => {
    try {
      const params = {
        matchId,
        flaggedcomment: '_',
      };
      await updateProgressDataAPI(params, token as string);
      setFlagComment('');
      handleClose();
    } catch (error) {
      console.error('Error removing flag:', error);
    }
  };

  const handleEditFlag = () => {
    setIsEditing(true);
  };

  return (
    <div className="flag-video" title="flag video">
      <button onClick={handleClickOpen}>
        <FlagOutlinedIcon
          className={flagComment === '' || flagComment === '_' ? 'not-flagged' : 'flagged'}
        />
      </button>
      <Dialog open={open} onClose={handleClose} className="flag-video-dialog">
        {isEditing ? (
          <>
            <DialogTitle>
              {isEditing ? '編輯舉報原因' : '舉報視頻的原因'}
            </DialogTitle>
            <DialogContent>
              <TextField
                className="reasons"
                placeholder="原因"
                value={flagComment}
                onChange={handleTextFieldChange}
                fullWidth
                multiline
                rows={3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>取消</Button>
              <Button onClick={handleSubmitComment}>
                {isEditing ? '更新' : '確認'}
              </Button>
            </DialogActions>
          </>
        ) : (
          <div className="flag-comment-container">
            <DialogTitle>舉報原因</DialogTitle>
            <DialogContent>
              <p>{flagComment ? flagComment : '_'}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditFlag} startIcon={<EditIcon />}>
                編輯
              </Button>
              <Button
                onClick={handleRemoveFlag}
                startIcon={<DoneOutlineOutlinedIcon />}
              >
                問題已解決
              </Button>
              <Button onClick={handleClose} startIcon={<CloseIcon />}>
                關閉
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default FlagVideo;
