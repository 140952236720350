import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Switch,
  CssBaseline,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { getGameCount } from "../utils/graph.utils";
import { SelectionEdgeDrawerProps } from "../types/graph.types";
import GameInfoRenderer from "./renderGameInfo";
import { useTranslation } from "react-i18next";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: grey[100],
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[900],
  }),
}));

const SelectionEdgeDrawer: React.FC<SelectionEdgeDrawerProps> = ({
  isOpen,
  onClose,
  onOpen,
  selection,
  playerData,
  connectionsData,
  showCommonOpponents,
  setShowCommonOpponents,
  handleReset,
  isMobile,
  handleCloseInfo,
}) => {
  const { t } = useTranslation();

  const getGamesPlayedCount = () => {
    if (!selection.selectedPlayer || !selection.selectedOpponent) return 0;
    return getGameCount(
      selection.selectedPlayer,
      selection.selectedOpponent,
      connectionsData
    );
  };

  const SelectionContent = () => (
    <Box sx={{ width: "100%" }}>
      {selection.selectedPlayer && (
        <Typography variant="body1" color="text.primary">
          {t("selectionDrawer.selected")}:{" "}
          {playerData[selection.selectedPlayer]}
        </Typography>
      )}
      {selection.selectedPlayer && selection.selectedOpponent && (
        <>
          <Typography variant="body1" color="text.primary" sx={{ mt: 1 }}>
            {t("selectionDrawer.opponent")}:{" "}
            {playerData[selection.selectedOpponent]}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {t("selectionDrawer.gamesPlayed")}: {getGamesPlayedCount()}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={showCommonOpponents}
                onChange={(e) => setShowCommonOpponents(e.target.checked)}
                color="primary"
                size={isMobile ? "small" : "medium"}
              />
            }
            label={t("selectionDrawer.showCommonOpponents")}
            sx={{ mt: 1, display: "block" }}
          />
          <Button
            variant="contained"
            color="error"
            size={isMobile ? "small" : "medium"}
            onClick={handleReset}
            sx={{ mt: 2, width: "100%" }}
          >
            {t("selectionDrawer.resetSelection")}
          </Button>
        </>
      )}
    </Box>
  );

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={
          !selection.selectedPlayer && !selection.selectedGameInfo
        }
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {selection.selectedGameInfo
              ? t("selectionDrawer.gameDetails")
              : selection.selectedPlayer
              ? `${t("selectionDrawer.selected")}: ${
                  playerData[selection.selectedPlayer]
                }`
              : t("selectionDrawer.selectToBegin")}
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {selection.selectedGameInfo ? (
            <GameInfoRenderer
              gameInfo={selection.selectedGameInfo}
              onClose={handleCloseInfo}
              variant="drawer"
            />
          ) : (
            <SelectionContent />
          )}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

export default SelectionEdgeDrawer;
