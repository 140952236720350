import axios from 'axios';
import { HandleAPIError } from './auth';

import {
  UserUploadStatusParams,
  NewUploadParams,
  UploadProgressParams,
  GetChunkDataParams,
  GetAnalysisDataParams,
  upsertAnalysisDataParams,
  UpdateDataParams,
} from 'types/vqTypes';

export const getUserUploadStatusAPI = async (
  params: UserUploadStatusParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/userUploadStatus`, {
      params,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const getUploadProgressAPI = async (
  params: UploadProgressParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/uploadProgress`, {
      params,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const newUploadAPI = async (
  params: NewUploadParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(`/api/newUpload`, null, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const getChunkDataAPI = async (
  params: GetChunkDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/chunkData`, {
      params,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const getAnalysisDataAPI = async (
  params: GetAnalysisDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/analysisData`, {
      params,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const getProgressDataAPI = async (
  params: GetAnalysisDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/progressData`, {
      params,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const updateProgressDataAPI = async (
  params: UpdateDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(
      `/api/updateProgressData`,
      { data: params },
      {
        headers: {
          Authorization: token,
        },
      },
    );
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const upsertAnalysisDataAPI = async (
  params: upsertAnalysisDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(
      `/api/updateAnalysisData`,
      { data: params },
      {
        headers: {
          Authorization: token,
        },
      },
    );
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};
