import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import axios from 'axios';
import Cookies from 'js-cookie';
import Title from './Title';
import './UserManagement.scss';

const formatDate = (dateString) => {
  if (!dateString) return '';
  return new Date(dateString).toLocaleDateString('en-US', {
    timeZone: 'Asia/Singapore',
  });
};

export default function UserManagement({ userData, setRefreshData }) {
  const token = Cookies.get('token');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [user_account_type, setUserAccountType] = useState('');
  const [user_category, setUserCategory] = useState('');
  const [user_id, setUserId] = useState('');
  const [username, setUsername] = useState('');

  const handleEditOpen = (username, category, account_type, user_id) => {
    setUsername(username);
    setUserCategory(category);
    setUserAccountType(account_type);
    setUserId(user_id);
    setEditOpen(true);
  };

  const handleEditClose = () => setEditOpen(false);

  const handleDeleteOpen = (user_id) => {
    setUserId(user_id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteInputValue('');
    setErrorMessage('');
  };

  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  };

  const handleDeleteUser = (user_id, username, accountType, accountCat) => {
    if (deleteInputValue === 'delete') {
      axios
        .delete('/api/updateUserData', {
          params: {
            user_id: user_id,
            primary_attribute: `deleted username: ${username}`,
            secondary_attribute: `deleted account details: ${accountType}, ${accountCat}`,
          },
          headers: { Authorization: token },
        })
        .then(() => {
          handleDeleteClose();
          setRefreshData(true);
        });
    } else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    }
  };

  const handleEditUser = (
    user_id,
    username,
    user_account_type,
    user_category,
  ) => {
    axios
      .post(
        '/api/updateUserData',
        {
          data: [user_id, username, user_account_type, user_category],
        },
        {
          params: {
            primary_attribute: `edited username: ${username}`,
            secondary_attribute: `edited account details: ${user_account_type}, ${user_category}`,
          },
          headers: { Authorization: token },
        },
      )
      .then(() => {
        handleEditClose();
        setRefreshData(true);
      });
  };

  return (
    <Fragment>
      <div className="user-management-header">
        <Title>Current Users</Title>
        <Link to="/admin-sign-up" className="add-user-link">
          添加用戶
        </Link>
      </div>
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>用戶名</th>
              <th>帳戶類型</th>
              <th>類別</th>
              <th>最後位置</th>
              <th>最後登入</th>
              <th>總登入</th>
              <th>觀看影片</th>
              <th>已觀影片</th>
              <th>最常觀看</th>
              <th>已上傳</th>
              <th>編輯</th>
              <th>刪除</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user) => (
              <tr key={user.user_id}>
                <td>{user.username}</td>
                <td>{user.account_type}</td>
                <td>{user.category}</td>
                <td>{user.last_ip_address}</td>
                <td>{formatDate(user.last_log_in)}</td>
                <td>{user.log_in_count}</td>
                <td>{user.total_video_views}</td>
                <td>{user.unique_video_viewed}</td>
                <td>{user.most_viewed_video}</td>
                <td>{user.video_uploaded}</td>
                <td>
                  <button
                    className="icon-button"
                    onClick={() =>
                      handleEditOpen(
                        user.username,
                        user.category,
                        user.account_type,
                        user.user_id,
                      )
                    }
                    title="編輯數據"
                  >
                    <EditOutlinedIcon />
                  </button>
                </td>
                <td>
                  <button
                    className="icon-button"
                    onClick={() => handleDeleteOpen(user.user_id)}
                    title="刪除此用戶"
                  >
                    <CloseOutlinedIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {deleteOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>請輸入「delete」以刪除视频</h2>
              <div className="modal-content">
                <input
                  type="text"
                  value={deleteInputValue}
                  onChange={handleDeleteInputChange}
                  className={`delete-input ${errorMessage ? 'error' : ''}`}
                  placeholder="Type 'delete' to confirm"
                />
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </div>
              <div className="modal-actions">
                <button
                  onClick={handleDeleteClose}
                  className="button secondary"
                >
                  取消
                </button>
                <button
                  onClick={() =>
                    handleDeleteUser(
                      user_id,
                      username,
                      user_account_type,
                      user_category,
                    )
                  }
                  className="button primary"
                >
                  刪除
                </button>
              </div>
            </div>
          </div>
        )}

        {editOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>在此編輯用戶</h2>
              <div className="modal-content">
                <div className="input-group">
                  <label>Username</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Account type</label>
                  <select
                    value={user_account_type}
                    onChange={(e) => setUserAccountType(e.target.value)}
                  >
                    <option value="sub_admin">sub_admin</option>
                    <option value="coach">coach</option>
                    <option value="player">player</option>
                  </select>
                </div>
                <div className="input-group">
                  <label>Category</label>
                  <select
                    value={user_category}
                    onChange={(e) => setUserCategory(e.target.value)}
                  >
                    <option value="able">able</option>
                    <option value="intellectual">intellectual</option>
                    <option value="physical">physical</option>
                  </select>
                </div>
              </div>
              <div className="modal-actions">
                <button onClick={handleEditClose} className="button secondary">
                  取消
                </button>
                <button
                  onClick={() =>
                    handleEditUser(
                      user_id,
                      username,
                      user_account_type,
                      user_category,
                    )
                  }
                  className="button primary"
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
