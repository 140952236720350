import React, { useState } from 'react';
import axios from 'axios';
import { MenuItem } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { formatTime } from 'src/utils/utils';

import './EditRoundPanel.scss';

import {
  roundEndTimeIndex,
  roundStartTimeIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';
import { LargeNumberLike } from 'crypto';

interface RoundInfo {
  [key: number]: string | number;
}

interface RoundData {
  [key: number]: [RoundInfo, any];
}

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
  filename: string;
  currentTotalScore: number;
}

interface Props {
  currentRound: number;
  roundNumbers: number[];
  roundResults: RoundData;
  matchId: number;
  token: string | null;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
  setSelectedRound: React.Dispatch<React.SetStateAction<number>>;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const EditRoundPanel: React.FC<Props> = ({
  currentRound,
  roundNumbers,
  roundResults,
  matchId,
  token,
  setVideoData,
  setSelectedRound,
  videoRef,
}) => {

  const skipToTime = (time: number, round: number) => {
    if (videoRef.current) {
      setSelectedRound(round);
      videoRef.current.currentTime = time + 0.3;
    }
  };

  const getLastScore = (roundIndex: number) => {
    const mappedBallData = roundResults[roundIndex][1];
    if (mappedBallData && mappedBallData.length > 0) {
      const lastBall = mappedBallData[mappedBallData.length - 1];
      return `${lastBall[ballHomePlayerScoreIndex]}:${lastBall[ballAwayPlayerScoreIndex]}`;
    }
    return 'N/A';
  };

  const handleInsertRound = async (roundIndex: number) => {
    try {
      await insertRound(roundIndex);
    } catch (error) {
      console.error('添加回合時發生錯誤');
    }
  };

  const handleDeleteRound = async (roundIndex: number) => {
    try {
      const confirmDelete = window.confirm(
        `確定要刪除回合 ${roundIndex + 1} 嗎？`,
      );
      if (confirmDelete) {
        await axios.delete(`/api/editrounddata`, {
          params: {
            round_number: roundIndex + 1,
            match_id: matchId,
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `delete round : ${roundIndex + 1}`,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        // delete current round
        if (roundIndex + 1 === currentRound) {
          setVideoData((prevState) => ({
            ...prevState,
            refresh: true,
            currentBall: 0,
            currentRound: Math.max(0, roundIndex),
          }));
        } else {
          setVideoData((prevState) => ({
            ...prevState,
            refresh: true,
          }));
        }
      }
    } catch (error) {
      console.error('刪除回合時發生錯誤');
    }
  };

  const insertRound = async (roundIndex: number): Promise<void> => {
    try {
      await axios.put(
        `/api/editrounddata`,
        {
          round_number: roundIndex + 1,
          match_id: matchId,
        },
        {
          params: {
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `add round : ${roundIndex + 1}`,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));

      if (videoRef.current) {
        await videoRef.current.play();
      } else {
        console.warn('Video reference is not available');
      }
    } catch (error) {
      console.error('Error inserting round:', error);
    }
  };
  const renderInsertButton = (
    roundIndex: number,
    position: 'before' | 'after',
  ) => (
    <button
      onClick={() =>
        handleInsertRound(position === 'before' ? roundIndex : roundIndex + 1)
      }
      title={`在回合${position === 'before' ? '前' : '後'}添加新回合`}
      className={`insert-button insert-${position}`}
    >
      <AddOutlinedIcon />
    </button>
  );

  if (roundNumbers.length === 0) {
    return (
      <div className="empty-rounds">
        <p style={{ color: 'white', textAlign: 'center', width: '100%' }}>
          加回合 {renderInsertButton(0, 'before')}
        </p>
      </div>
    );
  }

  return (
    <>
      {roundNumbers.map((round, arrayIndex) => {
        const roundIndex = round - 1;
        const mappedRoundData = roundResults[roundIndex];
        if (!mappedRoundData || mappedRoundData.length < 2) return null;
        const [roundInfo, mappedBallData] = mappedRoundData;
        if (!roundInfo || !mappedBallData) return null;

        const isCurrentRound = currentRound === round - 1;

        return (
          <div className="round-summary-panel" key={round}>
            {renderInsertButton(roundIndex, 'before')}
            <div
              className={`round-input-panel ${isCurrentRound ? 'flashing' : ''
                }`}
              onClick={() =>
                skipToTime(roundInfo[roundStartTimeIndex] as number, roundIndex as number)
              }
            >
              <MenuItem value={roundIndex}>
                <span className="round-number">
                  回合 <span className="number">{round}</span>
                </span>
                <span className="time-info">
                  開始:{' '}
                  <span className="start-time">
                    {formatTime(roundInfo[roundStartTimeIndex])}
                  </span>{' '}
                  結束:{' '}
                  <span className="end-time">
                    {formatTime(roundInfo[roundEndTimeIndex])}
                  </span>
                </span>
                <span className="score-info">
                  比分:{' '}
                  <span className="score">{getLastScore(roundIndex)}</span>
                </span>
              </MenuItem>
              <button
                onClick={() => handleDeleteRound(roundIndex)}
                title="刪除此回合"
                className="delete-button"
              >
                <CloseOutlinedIcon />
              </button>
            </div>
            {renderInsertButton(roundIndex, 'after')}
          </div>
        );
      })}
    </>
  );
};

export default EditRoundPanel;
