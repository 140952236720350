import React, { useState, useEffect } from 'react';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import Cookies from 'js-cookie';
import { fetchMatchDataAPI } from 'src/api/videoService';
import VideoPanel from './VideoPanel/VideoPanel';
import {
    Box,
    Button,
    ButtonGroup,
    Typography,
    Chip,
    TextField,
    InputAdornment,
    Collapse,
    IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    matchIdIndex,
    videoNameIndex,
    homePlayerIndex,
    awayPlayerIndex,
    homePlayerMatchScoreIndex,
    awayPlayerMatchScoreIndex,
    categoryIndex,
} from 'src/constants';
import './VideoManagement.scss';

interface VideoManagementProps {
    userName: string;
    accountType: string;
}


interface MergedVideoData {
    // Video Data fields
    matchId: string;
    videoName: string;
    homePlayer: string;
    awayPlayer: string;
    category: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;

    // VQ Data fields
    balldetectioncompleted: boolean;
    balltrackdate: string | null;
    completiondate: string | null;
    dpchecked: boolean;
    dpcheckeddate: string | null;
    dpcompleted: boolean;
    dpdate: string | null;
    flagged_comment: string;
    formatchecked: boolean;
    formatcheckeddate: string | null;
    ispriority: boolean;
    ocrchecked: boolean;
    ocrcheckeddate: string | null;
    ocrcompleted: boolean;
    ocrdate: string | null;
    score_coordinates: number[][];
    table_coordinates: number[][];
    starting_player_id: number;
    topright_player_id: number;
    uploaded_size: number;
}


const bytesToGB = (bytes: number): number => {
    return Number((bytes / (1024 * 1024 * 1024)).toFixed(2));
};

const VideoManagement: React.FC<VideoManagementProps> = ({ userName, accountType }) => {
    const token = Cookies.get('token');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [videoList, setVideoList] = useState<MergedVideoData[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeFilters, setActiveFilters] = useState(new Set<string>());
    const [isExpanded, setIsExpanded] = useState(true);
    const [filteredVideos, setFilteredVideos] = useState<MergedVideoData[]>([]);

    useEffect(() => {
        fetchVideos();
    }, [token]);

    // Simple debounce for search
    useEffect(() => {
        filterVideos(videoList, searchQuery, activeFilters);
    }, [searchQuery, activeFilters, videoList]);

    const fetchVideos = async () => {
        try {
            const response = await fetchMatchDataAPI(
                {
                    accountType: accountType,
                    videoQueue: true,
                },
                token as string,
            );
            const videosData = response.videoData;
            const vqData = response.vqData;
            const mergedData = videosData.map((videoData: any) => {
                const matchId = videoData[matchIdIndex];
                const vqDataForMatch = vqData[matchId];
                const sizeGB = bytesToGB(vqDataForMatch.uploaded_size);

                return {
                    // Video Data fields
                    matchId: matchId,
                    videoName: videoData[videoNameIndex],
                    homePlayer: videoData[homePlayerIndex],
                    awayPlayer: videoData[awayPlayerIndex],
                    category: videoData[categoryIndex],
                    homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
                    awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],

                    // VQ Data fields
                    balldetectioncompleted: vqDataForMatch.balldetectioncompleted,
                    balltrackdate: vqDataForMatch.balltrackdate,
                    completiondate: vqDataForMatch.completiondate,
                    dpchecked: vqDataForMatch.dpchecked,
                    dpcheckeddate: vqDataForMatch.dpcheckeddate,
                    dpcompleted: vqDataForMatch.dpcompleted,
                    dpdate: vqDataForMatch.dpdate,
                    flagged_comment: vqDataForMatch.flagged_comment,
                    formatchecked: vqDataForMatch.formatchecked,
                    formatcheckeddate: vqDataForMatch.formatcheckeddate,
                    ispriority: vqDataForMatch.ispriority,
                    ocrchecked: vqDataForMatch.ocrchecked,
                    ocrcheckeddate: vqDataForMatch.ocrcheckeddate,
                    ocrcompleted: vqDataForMatch.ocrcompleted,
                    ocrdate: vqDataForMatch.ocrdate,
                    score_coordinates: vqDataForMatch.score_coordinates,
                    table_coordinates: vqDataForMatch.table_coordinates,
                    starting_player_id: vqDataForMatch.starting_player_id,
                    topright_player_id: vqDataForMatch.topright_player_id,
                    uploaded_size: sizeGB,
                };
            });
            setVideoList(mergedData);
            setFilteredVideos(mergedData);
            setLoading(false);
        } catch (api_error) {
            console.error('Error fetching user list:', api_error);
            setError(api_error);
        }
    };
    const filterVideos = (videos: MergedVideoData[], search: string, filters: Set<string>) => {
        const searchTerm = search.toLowerCase();
        const filtered = videos.filter(video => {
            // Search filter
            const matchesSearch = !searchTerm ||
                video.videoName?.toLowerCase().includes(searchTerm) ||
                video.homePlayer?.toLowerCase().includes(searchTerm) ||
                video.awayPlayer?.toLowerCase().includes(searchTerm);

            if (!matchesSearch) return false;

            // If no filters are active, return all videos
            if (filters.size === 0) return true;

            return Array.from(filters).some(filter => {
                switch (filter) {
                    // Existing filters
                    case 'noCategory':
                        return !video.category?.trim();
                    case 'invalidPlayer':
                        const home = video.homePlayer?.trim();
                        const away = video.awayPlayer?.trim();
                        return !home || !away || home === '_' || away === '_';
                    case 'noScore':
                        return !video.homePlayerMatchScore && !video.awayPlayerMatchScore;

                    // New filters for VQ data
                    case 'noBallDetection':
                        return !video.balldetectioncompleted;
                    case 'noDP':
                        return !video.dpcompleted;
                    case 'noOCR':
                        return !video.ocrcompleted;
                    case 'noFormat':
                        return !video.formatchecked;
                    case 'hasFlaggedComment':
                        return video.flagged_comment && video.flagged_comment !== "_";
                    case 'isPriority':
                        return video.ispriority;
                    case 'noScoreCoordinates':
                        return !video.score_coordinates || video.score_coordinates.length === 0;
                    case 'noTableCoordinates':
                        return !video.table_coordinates || video.table_coordinates.length === 0;
                    case 'noStartingPlayer':
                        return !video.starting_player_id;
                    case 'noTopRightPlayer':
                        return !video.topright_player_id;
                    default:
                        return false;
                }
            });
        });
        setFilteredVideos(filtered);
    };


    const filterLabels = {
        'noCategory': '未分類',
        'invalidPlayer': '無效選手名稱',
        'noScore': '未有比分',
        'noDP': '未完成球偵測',
        'noFormat': '未完成格式檢查',
        'hasFlaggedComment': '有回饋',
        'isPriority': '優先處理',
        'noScoreCoordinates': '無比分座標',
        'noTableCoordinates': '無球台座標',
        'noStartingPlayer': '無開始選手ID',
        'noTopRightPlayer': '無右上選手ID',
    };

    const toggleFilter = (filterKey: string) => {
        const newFilters = new Set(activeFilters);
        if (newFilters.has(filterKey)) {
            newFilters.delete(filterKey);
        } else {
            newFilters.add(filterKey);
        }
        setActiveFilters(newFilters);
    };

    if (loading) return <LoadingScreen />;
    if (error) return <div className="playlist-error">Error: {error}</div>;

    return (
        <div className="video-management">
            <Box className="filter-panel">
                <Box
                    className="filter-header-container"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <Box className="header-content">
                        <Typography variant="h6">篩選器</Typography>
                        <IconButton size="small">
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                </Box>

                <Collapse in={isExpanded}>
                    <Box className="filter-content">
                        <Box className="search-section">
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="搜尋影片名稱或選手名稱..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    className: 'search-input'
                                }}
                            />
                        </Box>

                        <Box className="filter-header">
                            {activeFilters.size > 0 && (
                                <Box className="active-filters">
                                    {Array.from(activeFilters).map(filter => (
                                        <Chip
                                            key={filter}
                                            label={filterLabels[filter as keyof typeof filterLabels]}
                                            onDelete={() => toggleFilter(filter)}
                                            color="primary"
                                            variant="outlined"
                                            className="filter-chip"
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>
                        <ButtonGroup className="filter-buttons">
                            {Object.entries(filterLabels).map(([key, label]) => (
                                <Button
                                    key={key}
                                    onClick={() => toggleFilter(key)}
                                    variant={activeFilters.has(key) ? "contained" : "outlined"}
                                    className={`filter-button ${activeFilters.has(key) ? 'active' : ''}`}
                                >
                                    {label}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Box>
                </Collapse>
            </Box>
            <VideoPanel videoList={filteredVideos} addSizeConstant={activeFilters.size === 0} />
        </div>
    );
};

export default VideoManagement;