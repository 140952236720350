import {
  CustomAttributes,
  ExtendedNodeDisplayData,
  GraphReducerParams,
} from "../GraphType";
import { isHighlightedPlayer } from "../isHighlightedPlayer";
import {
  handleDefaultState,
  handleGameNode,
  handleGamePath,
  handlePlayerPath,
  handleRemainingCases,
  handleSelectedCommonOpponent,
  handleSelectedNode,
  handleSelectedOpponent,
} from "./nodeHelpers";


export const createNodeReducer = ({
  isMobile,
  selectedNode,
  selectedOpponent,
  selectedCommonOpponent,
  showCommonOpponents,
  playerData,
  connectionsData,
  findCommonGameIds,
  findConnectedPlayers,
  getGameCount,
  getEnhancedLabelStyle,
  showShortestPath,
}: Omit<GraphReducerParams, "sigma">) => {
  return (
    node: string,
    data: CustomAttributes
  ): Partial<ExtendedNodeDisplayData> => {
    const newData = { ...data } as Partial<ExtendedNodeDisplayData>;
    const sizeMultiplier = isMobile ? 1.2 : 1.4;
    const deviceType = isMobile ? "mobile" : "desktop";
    const isPlayer = playerData[node] !== undefined;
    const isHighlighted = isPlayer && isHighlightedPlayer(playerData[node]);

    // Default state (no selection)
    if (!selectedNode) {
      return handleDefaultState({
        newData,
        isPlayer,
        isHighlighted,
        deviceType,
        playerData,
        node,
      });
    }

    // Get connected players information
    const firstPlayerOpponents = findConnectedPlayers(selectedNode);
    let commonOpponents: string | string[] = [];
    if (selectedOpponent && showCommonOpponents) {
      const secondPlayerOpponents = findConnectedPlayers(selectedOpponent);
      commonOpponents = firstPlayerOpponents.filter(
        (id) =>
          secondPlayerOpponents.includes(id) &&
          id !== selectedNode &&
          id !== selectedOpponent
      );
    }

    // Determine node type and state
    const nodeType = isPlayer ? "player" : "game";
    const state = determineState();

    function determineState(): string {
      if (showShortestPath && selectedNode) {
        return selectedOpponent ? "shortestPathBoth" : "shortestPathSingle";
      }
      if (showCommonOpponents && node === selectedCommonOpponent)
        return "commonOpponent";
      if (node === selectedNode) return "selectedMain";
      if (selectedOpponent && node === selectedOpponent)
        return "selectedOpponent";
      return "default";
    }

    switch (nodeType) {
      case "player":
        switch (state) {
          case "shortestPathSingle":
          case "shortestPathBoth":
            return handlePlayerPath({
              node,
              newData,
              data,
              deviceType,
              selectedNode,
              selectedOpponent,
              connectionsData,
              playerData,
              isHighlighted,
              sizeMultiplier,
              getEnhancedLabelStyle,
            });

          case "commonOpponent":
            return handleSelectedCommonOpponent({
              node,
              data: newData,
              isHighlighted,
              deviceType,
              sizeMultiplier,
              selectedNode,
              selectedOpponent,
              playerData,
              connectionsData,
              getGameCount,
              getEnhancedLabelStyle,
            });

          case "selectedMain":
            return handleSelectedNode({
              data: newData,
              sizeMultiplier,
              getEnhancedLabelStyle,
            });

          case "selectedOpponent":
            return handleSelectedOpponent({
              data: newData,
              sizeMultiplier,
              getEnhancedLabelStyle,
            });

          default:
            return handleRemainingCases({
              node,
              data: newData,
              isHighlighted,
              deviceType,
              sizeMultiplier,
              showCommonOpponents,
              commonOpponents,
              firstPlayerOpponents,
              selectedNode,
              selectedOpponent,
              playerData,
              connectionsData,
              getGameCount,
            });
        }

      case "game":
        switch (state) {
          case "shortestPathSingle":
          case "shortestPathBoth":
            return handleGamePath({
              node,
              data,
              deviceType,
              selectedNode,
              selectedOpponent,
              connectionsData,
              playerData,
              isShortestPath: state === "shortestPathBoth",
            });
          default:
            return handleGameNode({
              node,
              data: newData,
              deviceType,
              selectedNode,
              selectedOpponent,
              selectedCommonOpponent,
              connectionsData,
              findCommonGameIds,
            });
        }
    }
  };
};
