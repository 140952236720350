// File: app/common/constants/reducers/edgeReducer.ts
import { CustomAttributes, ExtendedEdgeDisplayData } from "../GraphType";
import { NODE_STYLES } from "../GraphStyles";
import { EdgeReducerParams } from "./types";
import { handleMultipleSelection, handleSingleSelection } from "./edgeHelpers";
import { findShortestPath, isEdgeInPath } from "./shortestPath";

export const createEdgeReducer = ({
  selectedNode,
  selectedOpponent,
  selectedCommonOpponent,
  showCommonOpponents,
  connectionsData,
  findCommonGameIds,
  sigma,
  gameDetailsMap = {}, // Add this parameter
  playerData,
  showShortestPath,
}: // onSelectionChange,
EdgeReducerParams) => {
  return (
    edge: string,
    data: CustomAttributes
  ): Partial<ExtendedEdgeDisplayData> => {
    // console log onSelectionChange

    const newData = { ...data } as Partial<ExtendedEdgeDisplayData>;
    const graph = sigma.getGraph();
    const [source, target] = graph.extremities(edge);

    // In edgeReducer.ts
    if (showShortestPath && selectedNode && selectedOpponent) {
      const { edges, isDirectPath } = findShortestPath(
        selectedNode,
        selectedOpponent,
        connectionsData,
        playerData
      );

      if (isEdgeInPath(source, target, edges)) {
        return {
          ...newData,
          hidden: false,
          color: isDirectPath ? "#4CAF50" : "#FFA726", // Green for direct path, Orange for indirect
          size: 2.5,
        };
      }

      // Fade other edges
      return {
        ...newData,
        hidden: false,
        color: NODE_STYLES.edge.default,
        size: 0.2,
      };
    }

    if (selectedNode && selectedOpponent) {
      return handleMultipleSelection({
        edge,
        newData,
        source,
        target,
        selectedNode,
        selectedOpponent,
        selectedCommonOpponent,
        showCommonOpponents,
        connectionsData,
        findCommonGameIds,
        gameDetailsMap,
        playerData,
      });
    }

    if (selectedNode) {
      return handleSingleSelection({
        newData,
        source,
        target,
        selectedNode,
      });
    }

    return {
      ...newData,
      hidden: false,
      color: NODE_STYLES.edge.default,
      size: 0.5,
    };
  };
};
