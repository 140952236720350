import { useState, useRef, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';

import { getAnalysisDataAPI, upsertAnalysisDataAPI } from 'src/api/vqService';

import './InputVideoPanel.scss';

export default function InputVideoPanel({ matchData }) {
  const token = Cookies.get('token');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [isScoreCoordinates, setIsScoreCoordinates] = useState(false);
  const [analysisData, setAnalysisData] = useState({
    matchid: matchData.matchId,
    tableCoordinates: [],
    scoreCoordinates: [],
    startingPlayer: '',
    topRightPlayer: '',
  });

  const updateCanvas = useCallback(() => {
    const canvasElement = canvasRef.current;
    const videoElement = videoRef.current;
    if (!canvasElement || !videoElement) return;

    videoElement.pause();

    const context = canvasElement.getContext('2d');
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;

    context.drawImage(
      videoElement,
      0,
      0,
      canvasElement.width,
      canvasElement.height,
    );

    const drawCoordinates = (coordinates, color) => {
      context.fillStyle = color;
      coordinates.forEach(({ x, y }) => {
        context.beginPath();
        context.arc(
          x * canvasElement.width,
          y * canvasElement.height,
          5,
          0,
          Math.PI * 2,
        );
        context.fill();
      });
    };

    drawCoordinates(analysisData.tableCoordinates, 'red');
    drawCoordinates(analysisData.scoreCoordinates, 'green');
  }, [analysisData.tableCoordinates, analysisData.scoreCoordinates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAnalysisDataAPI(
          { matchId: matchData.matchId },
          token,
        );
        const tableCoordinates = response.table_coordinates.map((coord) => ({
          x: coord[0] / 1000,
          y: coord[1] / 1000,
        }));
        const scoreCoordinates = response.score_coordinates.map((coord) => ({
          x: coord[0] / 1000,
          y: coord[1] / 1000,
        }));

        setAnalysisData((prevData) => ({
          ...prevData,
          topRightPlayer: response.topright_player_id,
          startingPlayer: response.starting_player_id,
          tableCoordinates: [...tableCoordinates],
          scoreCoordinates: [...scoreCoordinates],
        }));
      } catch (error) {
        console.log('error getting analysis data');
      }
    };

    fetchData();
  }, [matchData.matchId, matchData.videoName, token]);

  useEffect(() => {
    if (showCanvas) {
      updateCanvas();
    }
  }, [showCanvas, updateCanvas]);

  const handleSave = async () => {
    if (
      analysisData.tableCoordinates.length === 4 &&
      analysisData.scoreCoordinates.length === 4
    ) {
      try {
        const response = await upsertAnalysisDataAPI(analysisData, token);
        setDialogOpen(false);
        setSnackbar({ open: true, message: '成功', severity: 'success' });
      } catch (error) {
        console.error('Error saving data:', error);
        setSnackbar({ open: true, message: '保存失敗', severity: 'error' });
      }
    } else {
      setSnackbar({
        open: true,
        message: '失敗,確保兩組座標都有4對',
        severity: 'error',
      });
    }
  };

  const handleCoordinateSelection = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;

    setAnalysisData((prev) => ({
      ...prev,
      [isScoreCoordinates ? 'scoreCoordinates' : 'tableCoordinates']: [
        ...prev[isScoreCoordinates ? 'scoreCoordinates' : 'tableCoordinates'],
        { x, y },
      ],
    }));
  };

  const handleCaptureImage = () => {
    setShowCanvas(true);
    updateCanvas();
  };

  const handleStartOver = () => {
    setAnalysisData((prev) => ({
      ...prev,
      tableCoordinates: [],
      scoreCoordinates: [],
    }));
    updateCanvas();
  };

  const handlePlayerChange = (event) => {
    setAnalysisData((prev) => ({
      ...prev,
      startingPlayer: event.target.value,
    }));
  };

  const handletopRightPlayerChange = (event) => {
    setAnalysisData((prev) => ({
      ...prev,
      topRightPlayer: event.target.value,
    }));
  };

  console.log(matchData);

  return (
    <div className="input-analysis-main-container">
      <Button variant="outlined" onClick={() => setDialogOpen(true)}>
        輸入分析數據
      </Button>
      <Dialog fullScreen open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <AppBar className="video-input-headbar" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              分析數據
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              保存
            </Button>
          </Toolbar>
        </AppBar>
        <div className="input-analysis-container">
          <div className="content-area">
            <div className="media-wrapper">
              <div className={`video-wrapper ${showCanvas ? 'hidden' : ''}`}>
                <video
                  ref={videoRef}
                  className="input-video-player"
                  playsInline
                  controls
                  preload="auto"
                >
                  <source
                    src={matchData.videoUrl || `${matchData.fileUrl}#t=0.001`}
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className={`canvas-wrapper ${showCanvas ? '' : 'hidden'}`}>
                <canvas
                  ref={canvasRef}
                  className="canvas-player"
                  onClick={handleCoordinateSelection}
                />
              </div>
            </div>
            <div className="controls-panel">
              <div className="button-group">
                {!showCanvas ? (
                  <Button variant="contained" onClick={handleCaptureImage}>
                    使用當前畫面設置桌面座標
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => setShowCanvas(false)}
                    >
                      選擇新畫面
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setIsScoreCoordinates((prev) => !prev)}
                    >
                      {isScoreCoordinates ? 'Set 乒乓球桌' : 'Set 分板'}
                    </Button>
                    <Button
                      className="delete-coor-button"
                      variant="outlined"
                      onClick={handleStartOver}
                    >
                      刪除選擇
                    </Button>
                  </>
                )}
              </div>
              <FormControl className="player-selection">
                <FormLabel id="starting-player-group">開球選手</FormLabel>
                <RadioGroup
                  aria-labelledby="starting-player-group"
                  name="starting-player"
                  value={analysisData.startingPlayer}
                  onChange={handlePlayerChange}
                >
                  <FormControlLabel
                    value={matchData.homePlayerId || 'Home'}
                    control={<Radio />}
                    label={matchData.homePlayer || 'Home'}
                  />
                  <FormControlLabel
                    value={matchData.awayPlayerId || 'Away'}
                    control={<Radio />}
                    label={matchData.awayPlayer || 'Away'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className="physical-position-selection">
                <FormLabel id="physical-position-group">上/右的球員</FormLabel>
                <RadioGroup
                  aria-labelledby="physical-position-group"
                  name="physical-position"
                  value={analysisData.topRightPlayer}
                  onChange={handletopRightPlayerChange}
                >
                  <FormControlLabel
                    value={matchData.homePlayerId || 'Home'}
                    control={<Radio />}
                    label={matchData.homePlayer || 'Home'}
                  />
                  <FormControlLabel
                    value={matchData.awayPlayerId || 'Away'}
                    control={<Radio />}
                    label={matchData.awayPlayer || 'Away'}
                  />
                </RadioGroup>
              </FormControl>
              {showCanvas && (
                <div className="coordinates-display">
                  <h3>座標資訊</h3>
                  <p>
                    乒乓球桌: {JSON.stringify(analysisData.tableCoordinates)}
                  </p>
                  <p>記分板: {JSON.stringify(analysisData.scoreCoordinates)}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
