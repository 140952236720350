const normalizeString = (str: string) => {
  return str.toLowerCase().replace(/[\s\-\/]/g, "");
};

export const isPlayerInTeam = (teamStr: string, playerName: string) => {
  const playerParts = playerName.split(/[\s\|\/-]+/).filter(Boolean);
  const teamPlayers = teamStr.split(/[\s\|\/-]+/).filter(Boolean);

  if (playerParts.length > 1) {
    return playerParts.every((playerPart) =>
      teamPlayers.some((teamPlayer) =>
        normalizeString(teamPlayer).includes(normalizeString(playerPart))
      )
    );
  } else {
    return teamPlayers.some((teamPlayer) =>
      normalizeString(teamPlayer).includes(normalizeString(playerName))
    );
  }
};
