import React, { useState } from 'react';
import { Box, Chip, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './HomeFilterPanel.scss';


interface FilterCriteria {
    matchName: string;
    matchDate: string;
    stages: string[];
    location: string;
    events: string[];
    categories: string[];
    startDate: string;
    endDate: string;
    customTags: string[];
}

interface TranslationDictionary {
    [key: string]: string;
}

interface ActiveFiltersProps {
    filterCriteria: FilterCriteria;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria>>;
}



const translationDict: TranslationDictionary = {
    Final: '決賽',
    'Semi-Final': '半決賽',
    'Quarter-Final': '4強',
    R16: '16強',
    R32: '32強',
    R64: '64強',
    R128: '128強',
    MSingles: '男單打',
    MDouble: '男雙打',
    FSingle: '女單打',
    FDouble: '女雙打',
    MixedDouble: '混合雙打',
};


const ActiveFilters: React.FC<ActiveFiltersProps> = ({
    filterCriteria,
    setFilterCriteria,
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [removingChips, setRemovingChips] = useState<string[]>([]);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const removeFilter = (filterType: keyof FilterCriteria, value: string): void => {
        setFilterCriteria(prev => ({
            ...prev,
            [filterType]: Array.isArray(prev[filterType])
                ? (prev[filterType] as string[]).filter(item => item !== value)
                : '',
        }));
    };

    const translateValue = (value: string): string => {
        return translationDict[value] || value;
    };

    const renderFilterChip = (
        label: string,
        value: string,
        filterType: keyof FilterCriteria
    ): React.ReactNode => {
        if (!value) return null;

        const chipId = `${filterType}-${value}`;
        const isRemoving = removingChips.includes(chipId);

        return (
            <Chip
                className={`filter-chip ${isRemoving ? 'removing' : ''}`}
                label={
                    <div className="chip-label">
                        <Typography className="label-text" variant="body2">
                            {label}:
                        </Typography>
                        <Typography variant="body2">
                            {translateValue(value)}
                        </Typography>
                    </div>
                }
                onDelete={() => removeFilter(filterType, value)}
                deleteIcon={<CloseIcon />}
            />
        );
    };

    const renderArrayFilterChips = (
        label: string,
        values: string[],
        filterType: keyof FilterCriteria
    ): React.ReactNode => {
        if (!values || values.length === 0) return null;

        return values.map((value, index) => (
            <Chip
                key={`${filterType}-${index}`}
                className="filter-chip"
                label={
                    <div className="chip-label">
                        <Typography className="label-text" variant="body2">
                            {label}:
                        </Typography>
                        <Typography variant="body2">
                            {translateValue(value)}
                        </Typography>
                    </div>
                }
                onDelete={() => removeFilter(filterType, value)}
                deleteIcon={<CloseIcon />}
            />
        ));
    };

    const hasActiveFilters = Object.values(filterCriteria).some(value =>
        (Array.isArray(value) && value.length > 0) ||
        (!Array.isArray(value) && value)
    );

    if (!hasActiveFilters) {
        return (
            <div className="filter-panel-container collapsed">

            </div>
        );
    }

    const clearAllFilters = (): void => {
        setFilterCriteria({
            matchName: '',
            matchDate: '',
            stages: [],
            location: '',
            events: [],
            categories: [],
            startDate: '',
            endDate: '',
            customTags: [],
        });
    };

    return (
        <div className={`filter-panel-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <div className="filter-header" onClick={toggleExpanded}>
                <Typography className="header-title" variant="h6">
                    篩選條件
                </Typography>
                <div className="header-controls">
                    <Button
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            clearAllFilters();
                        }}
                    >
                        <CloseIcon />
                    </Button>
                    <IconButton
                        className="expand-button"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleExpanded();
                        }}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </div>
            </div>

            <div className="filter-content">
                <div className="filter-chips-container hover-reveal">
                    {renderFilterChip('影片', filterCriteria.matchName, 'matchName')}
                    {renderFilterChip('日期', filterCriteria.matchDate, 'matchDate')}
                    {renderFilterChip('地點', filterCriteria.location, 'location')}
                    {renderFilterChip('開始日期', filterCriteria.startDate, 'startDate')}
                    {renderFilterChip('結束日期', filterCriteria.endDate, 'endDate')}
                    {renderArrayFilterChips('階段', filterCriteria.stages, 'stages')}
                    {renderArrayFilterChips('賽事', filterCriteria.events, 'events')}
                    {renderArrayFilterChips('類別', filterCriteria.categories, 'categories')}
                    {renderArrayFilterChips('標籤', filterCriteria.customTags, 'customTags')}
                </div>
            </div>
        </div>
    );
};

export default ActiveFilters;