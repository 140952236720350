import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GameClickInfo } from "../../types/graph.types";
import { GameDetailsBox } from "./GameDetailsBox";

interface GameDetailsSectionProps {
  gameDetails: GameClickInfo[];
  isLoading: boolean;
  onViewDetails: (game: GameClickInfo) => void;
  playerName?: string;
}

export const GameDetailsSection = ({
  gameDetails,
  isLoading,
  onViewDetails,
  playerName,
}: GameDetailsSectionProps) => {
  // console.log("GameDetailsSection", playerName);
  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", py: 2 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <>
      {gameDetails.map((game) => (
        <GameDetailsBox
          key={game.gameId}
          game={game}
          onViewDetails={onViewDetails}
          playerName={playerName}
        />
      ))}
    </>
  );
};
