import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chart } from 'react-google-charts';
import { formatTime } from 'src/utils/utils';
import CustomTooltip from './customToolTip';
import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import './VideoAnalysisPanel.scss';

const VideoAnalysisPanel = ({
  dpResults,
  dpIndex,
  ttPlotHeight,
  ttPlotWidth,
  homePlayer,
  awayPlayer,
  topPlayer,
  matchId,
  setVideoData,
}) => {
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const labelsRef = useRef(new Set());

  const currentDpResult =
    dpResults?.find((result) => result[0] === dpIndex) ?? null;

  const clearExistingLabels = useCallback(() => {
    // Clear tracked labels
    labelsRef.current.forEach((label) => {
      if (label && label.parentNode) {
        label.parentNode.removeChild(label);
      }
    });
    labelsRef.current.clear();
  }, []);

  const options = useMemo(
    () => ({
      width: ttPlotWidth,
      height: ttPlotHeight,
      legend: 'none',
      seriesType: 'line',
      chartArea: {
        width: '100%',
        height: '100%',
        backgroundColor: {
          fillOpacity: 0,
        },
      },
      series: {
        0: { type: 'scatter', pointSize: 25 }, // Increased point size to fit text
        1: { type: 'scatter', pointSize: 1 },
        2: { type: 'scatter', pointSize: 25 }, // Increased point size to fit text
        3: {
          type: 'line',
          lineWidth: 1,
          opacity: 0.2,
        },
        5: {
          type: 'line',
          lineDashStyle: [4, 4],
          lineWidth: 1,
          opacity: 0.6,
        },
      },
      vAxis: {
        viewWindow: {
          max: 1280,
          min: 0,
        },
        ticks: [0, 320, 640, 960, 1280],
        textPosition: 'none',
        textStyle: { color: '#ffffff' },
        baselineColor: '#B36B00',
        gridlineColor: '#B36B00',
      },
      hAxis: {
        viewWindow: {
          max: 800,
          min: 0,
        },
        ticks: [0, 266, 533, 800],
        textPosition: 'none',
        baselineColor: '#B36B00',
        gridlineColor: '#B36B00',
      },
      colors: [
        '#38BDF8', // series 0 - iOS blue
        '#FC6C85', // series 1 - iOS pink
        '#34D399', // series 2 - iOS green
        '#E50914', // series 3 - Netflix red (alone)
        '#2563EB', // series 4 - pairs with 0 - Deeper iOS blue
        '#FF3B81', // series 5 - pairs with 1 - Deeper iOS pink
        '#10B981', // series 6 - pairs with 2 - Deeper iOS green
      ],
      tooltip: { trigger: 'none' },
    }),
    [ttPlotWidth, ttPlotHeight],
  );
  const applyCircleLabels = useCallback(
    (animate = true) => {
      setTimeout(() => {
        const chartContainer = document.getElementsByClassName(
          'trajectory-plot-container',
        )[0];
        if (!chartContainer) return;

        const svgElement = chartContainer.querySelector('svg');
        if (!svgElement) return;

        // Clear all existing labels first
        clearExistingLabels();

        // Get circles within the SVG
        const circles = svgElement.getElementsByTagName('circle');
        const currentDataLength = currentDpResult[1].length;
        const isSinglePoint = currentDataLength === 4;

        let labelIndex = 1;

        Array.from(circles).forEach((circle) => {
          const fill = circle.getAttribute('fill');
          if (fill === '#78909C') return; // Skip transition points

          // Check if a label already exists at this position
          const cx = circle.getAttribute('cx');
          const cy = circle.getAttribute('cy');
          const existingLabel = Array.from(
            svgElement.getElementsByClassName('circle-label'),
          ).find(
            (label) =>
              label.getAttribute('x') === cx && label.getAttribute('y') === cy,
          );

          if (existingLabel) return; // Skip if label already exists

          circle.style.cursor = 'pointer';

          const text = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'text',
          );
          text.setAttribute(
            'class',
            `circle-label ${
              animate ? 'circle-label-animate' : 'circle-label-no-animation'
            }`,
          );
          text.style.pointerEvents = 'none';

          // Position text at circle center
          text.setAttribute('x', cx);
          text.setAttribute('y', cy);

          // Style text
          text.setAttribute('text-anchor', 'middle');
          text.setAttribute('dominant-baseline', 'middle');
          text.setAttribute('fill', '#000000');
          text.setAttribute('font-size', '1.3rem');

          // Set label number
          text.textContent = isSinglePoint ? '1' : labelIndex.toString();
          labelIndex++;

          // Add to SVG and track the label
          svgElement.appendChild(text);
          labelsRef.current.add(text);
        });
      }, 100);
    },
    [currentDpResult, dpIndex, clearExistingLabels],
  );

  const handleSelect = useCallback(
    ({ chartWrapper }) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();

      // Clear existing labels before proceeding
      clearExistingLabels();

      if (selection.length === 1) {
        const [selectedItem] = selection;
        const dataTable = chartWrapper.getDataTable();
        const { row, column } = selectedItem;
        const botPlayer =
          (topPlayer === awayPlayer ? homePlayer : awayPlayer) || '';
        const playerName =
          (currentDpResult[3][row - 2] === 'top' ? topPlayer : botPlayer) || '';
        const truncatedPlayerName =
          playerName.length > 15 ? playerName.slice(0, 15) + '...' : playerName;

        const trajectory =
          row > 2
            ? currentDpResult[4][row - 3]
              ? '斜線'
              : '直線'
            : '斜線/直線';

        const newInfo = {
          numBall: row - 1,
          coordinates: [
            Math.floor(dataTable.getValue(row, 0)),
            Math.floor(dataTable.getValue(row, column)),
          ],
          time: formatTime(currentDpResult[2][row - 2][0]),
          player: truncatedPlayerName,
          balltraj: trajectory,
        };

        setTooltipInfo(newInfo);

        const bounds = chart
          .getChartLayoutInterface()
          .getBoundingBox('chartarea');
        const x = chart
          .getChartLayoutInterface()
          .getXLocation(dataTable.getValue(row, 0));
        const y = chart
          .getChartLayoutInterface()
          .getYLocation(dataTable.getValue(row, column));

        setTooltipPosition({ x: bounds.left + x, y: bounds.top + y });

        // Apply new labels after a short delay
        setTimeout(() => {
          applyCircleLabels(false);
        }, 50);
      } else {
        setTooltipInfo(null);
      }
    },
    [
      currentDpResult,
      homePlayer,
      awayPlayer,
      topPlayer,
      applyCircleLabels,
      clearExistingLabels,
    ],
  );

  const handleCloseTooltip = useCallback(() => {
    setTooltipInfo(null);
    clearExistingLabels();
    applyCircleLabels(false);
  }, [applyCircleLabels, clearExistingLabels]);

  useEffect(() => {
    clearExistingLabels();
    applyCircleLabels(true);

    const resizeObserver = new ResizeObserver(() => {
      clearExistingLabels();
      applyCircleLabels(false);
    });

    const chartContainer = document.getElementsByClassName(
      'trajectory-plot-container',
    )[0];
    if (chartContainer) {
      resizeObserver.observe(chartContainer);
    }

    return () => {
      resizeObserver.disconnect();
      clearExistingLabels();
    };
  }, [
    currentDpResult,
    dpIndex,
    ttPlotHeight,
    ttPlotWidth,
    applyCircleLabels,
    clearExistingLabels,
  ]);

  const chartEvents = useMemo(
    () => [
      {
        eventName: 'select',
        callback: handleSelect,
      },
      {
        eventName: 'ready',
        callback: () => applyCircleLabels(true),
      },
    ],
    [handleSelect, applyCircleLabels],
  );

  const memoizedChart = useMemo(() => {
    if (currentDpResult && currentDpResult[1]) {
      return (
        <>
          <span style={{ color: 'white' }}>上/右</span>
          <div style={{ position: 'relative' }}>
            <Chart
              chartType="ComboChart"
              data={currentDpResult[1]}
              options={options}
              chartEvents={chartEvents}
              width={ttPlotWidth}
              height={ttPlotHeight}
            />
            {tooltipInfo && (
              <CustomTooltip info={tooltipInfo} onClose={handleCloseTooltip} />
            )}
          </div>
          <span style={{ color: 'white' }}>下/左</span>
        </>
      );
    } else {
      return <p className="missing-dp">無法檢測到落點。</p>;
    }
  }, [
    dpIndex,
    currentDpResult,
    ttPlotHeight,
    ttPlotWidth,
    tooltipInfo,
    tooltipPosition,
    options,
    chartEvents,
  ]);

  return (
    <div className="video-analysis-main-container">
      <FlagVideo
        matchId={matchId}
        defaultOpen={false}
        ocr={false}
        dp={true}
        setVideoData={setVideoData}
      />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>軌跡圖</Typography>
        </AccordionSummary>
        <div className="trajectory-plot-container">{memoizedChart}</div>
      </Accordion>
      <p className="padding"></p>
    </div>
  );
};

export default VideoAnalysisPanel;
