// src/hooks/useConnectedPlayers.ts
import { useCallback } from 'react';
import { PlayerData, ConnectionsData } from '../types/graph.types';

export const useConnectedPlayers = (
  connectionsData: ConnectionsData,
  playerData: PlayerData
) => {
  return useCallback((nodeId: string) => {
    const connectedPlayers = new Set<string>();

    Object.entries(connectionsData).forEach(([playerId, connections]) => {
      if (playerData[playerId]) {
        const selectedConnections = connectionsData[nodeId] || [];
        const hasCommonGame = connections.some(connection =>
          selectedConnections.includes(connection)
        );
        if (hasCommonGame && playerId !== nodeId) {
          connectedPlayers.add(playerId);
        }
      }
    });

    return Array.from(connectedPlayers);
  }, [connectionsData, playerData]);
};