import { FC } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Dialog,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  GameClickInfo,
  PlayerOption,
  SelectionPaperProps,
} from "../../types/graph.types";
import GameInfoRenderer from "../game/renderGameInfo";
import React from "react";
import {
  getAvailableOpponents,
  getAvailablePlayers,
  getCommonOpponents,
  getGamesPlayedCount,
} from "./SelectionHelpers";
import { CommonOpponentDetails } from "../game/CommonOpponentDetails";
import { useGameDetails } from "../../hooks/useGameDetails";
import { PlayerSelection } from "./PlayerSelectionSection";
import { GameDetailsSection } from "../game/GameDetailsSection";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[800],
  }),
}));

const SelectionPaper: FC<SelectionPaperProps> = ({
  selection,
  playerData,
  connectionsData,
  showCommonOpponents,
  setShowCommonOpponents,
  handleReset,
  isMobile,
  handleCloseInfo,
  onPlayerSelect,
  showShortestPath,
  setShowShortestPath,
}) => {
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = React.useState<GameClickInfo[]>([]);

  const handlePlayerOneSelect = (
    event: React.SyntheticEvent,
    value: PlayerOption | null
  ) => {
    onPlayerSelect({
      selectedPlayer: value?.id || null,
      selectedOpponent: selection.selectedOpponent,
    });
  };

  const handlePlayerTwoSelect = (
    event: React.SyntheticEvent,
    value: PlayerOption | null
  ) => {
    onPlayerSelect({
      selectedPlayer: selection.selectedPlayer,
      selectedOpponent: value?.id || null,
    });
  };

  const handleCommonOpponentSelect = (
    event: React.SyntheticEvent,
    value: PlayerOption | null
  ) => {
    if (value) {
      onPlayerSelect({
        selectedPlayer: selection.selectedPlayer,
        selectedOpponent: selection.selectedOpponent,
        selectedCommonOpponent: value.id,
      });
    }
  };

  const SelectionContent = () => {
    // Load game details when players are selected
    const { gameDetails, isLoading } = useGameDetails(
      selection,
      connectionsData,
      playerData
    );

    const availablePlayers = getAvailablePlayers(connectionsData, playerData);
    // Get opponents based on selected player
    const availableOpponents = selection.selectedPlayer
      ? getAvailableOpponents(
          selection.selectedPlayer,
          connectionsData,
          playerData
        )
      : [];

    const handleViewDetails = (game: GameClickInfo) => {
      setOpenDetails([...openDetails, game]);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showShortestPath}
              onChange={(e) => {
                setShowShortestPath(e.target.checked);
                if (e.target.checked) {
                  setShowCommonOpponents(false);
                }
              }}
              color="primary"
              size={isMobile ? "small" : "medium"}
            />
          }
          label={"顯示最短路徑"}
          // label={
          //   t("selectionDrawer.showShortestPath") || "顯示最短路徑" // Fallback text if translation fails
          // }
          sx={{ mt: 1, display: "block" }}
        />
        {/* Player Selection Section */}
        <PlayerSelection
          label={t("selectionDrawer.selectFirstPlayer")}
          value={
            selection.selectedPlayer
              ? availablePlayers.find(
                  (p) => p.id === selection.selectedPlayer
                ) || null
              : null
          }
          onChange={handlePlayerOneSelect}
          options={availablePlayers}
          isMobile={isMobile}
          placeholderKey="selectionDrawer.searchPlayers"
        />

        <PlayerSelection
          label={t("selectionDrawer.selectSecondPlayer")}
          value={
            selection.selectedOpponent
              ? availableOpponents.find(
                  (p) => p.id === selection.selectedOpponent
                ) || null
              : null
          }
          onChange={handlePlayerTwoSelect}
          options={availableOpponents}
          disabled={!selection.selectedPlayer}
          isMobile={isMobile}
          placeholderKey="selectionDrawer.searchPlayers"
          altPlaceholderKey="selectionDrawer.selectFirstPlayerFirst"
        />

        {/* Common Opponents Section */}
        {showCommonOpponents &&
          selection.selectedPlayer &&
          selection.selectedOpponent && (
            <PlayerSelection
              label={t("selectionDrawer.selectCommonOpponent")}
              value={
                selection.selectedCommonOpponent
                  ? getCommonOpponents(
                      selection.selectedPlayer,
                      selection.selectedOpponent,
                      connectionsData,
                      playerData
                    ).find(
                      (opponent) =>
                        opponent.id === selection.selectedCommonOpponent
                    ) ?? null
                  : null
              }
              onChange={handleCommonOpponentSelect}
              options={getCommonOpponents(
                selection.selectedPlayer,
                selection.selectedOpponent,
                connectionsData,
                playerData
              )}
              isMobile={isMobile}
              placeholderKey="selectionDrawer.searchCommonOpponents"
            />
          )}

        {/* Common Opponents Details */}
        {showCommonOpponents &&
          selection.selectedPlayer &&
          selection.selectedOpponent &&
          selection.selectedCommonOpponent && (
            <CommonOpponentDetails
              commonOpponentId={selection.selectedCommonOpponent}
              commonGames={
                getCommonOpponents(
                  selection.selectedPlayer,
                  selection.selectedOpponent,
                  connectionsData,
                  playerData
                ).find(
                  (opponent) => opponent.id === selection.selectedCommonOpponent
                )?.commonGames!
              }
              firstPlayerId={selection.selectedPlayer}
              secondPlayerId={selection.selectedOpponent}
              playerData={playerData}
              connectionsData={connectionsData}
              onViewDetails={handleViewDetails}
            />
          )}

        {/* Game Details Section */}
        {selection.selectedPlayer && selection.selectedOpponent && (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2, mb: 2 }}
            >
              {t("selectionDrawer.gamesPlayed")}:{" "}
              {getGamesPlayedCount(selection, connectionsData)}
            </Typography>

            <GameDetailsSection
              gameDetails={gameDetails}
              isLoading={isLoading}
              onViewDetails={(game) => setOpenDetails([...openDetails, game])}
              playerName={playerData[selection.selectedPlayer || ""]}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showCommonOpponents}
                  onChange={(e) => {
                    setShowCommonOpponents(e.target.checked);
                    if (!e.target.checked) {
                      onPlayerSelect({
                        selectedPlayer: selection.selectedPlayer,
                        selectedOpponent: selection.selectedOpponent,
                        selectedCommonOpponent: null,
                      });
                    }
                  }}
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                />
              }
              label={t("selectionDrawer.showCommonOpponents")}
              sx={{ mt: 1, display: "block" }}
            />

            {/* <Button
              variant="contained"
              color="error"
              size={isMobile ? "small" : "medium"}
              onClick={handleReset}
              sx={{ mt: 2, width: "100%" }}
            >
              {t("selectionDrawer.resetSelection")}
            </Button> */}
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: 24,
          left: 24,
          p: 3,
          borderRadius: 2,
          zIndex: (theme) => theme.zIndex.drawer + 2,
          bgcolor: "background.paper",
          minWidth: "320px", // Minimum width to match the design
          width: "25%", // Percentage width for larger screens
          maxWidth: "480px", // Maximum width constraint
          maxHeight: "calc(80vh - 48px)", // Adjust max height to leave space
          overflow: "auto",
          "@media (max-width: 600px)": {
            top: 16,
            left: 16,
            p: 2,
            width: "calc(50% - 32px)",
            maxWidth: "none",
            height: "auto",
            maxHeight: "calc(80vh - 32px)",
            marginBottom: "16px",
          },
        }}
      >
        {selection.selectedGameInfo ? (
          <GameInfoRenderer
            gameInfo={selection.selectedGameInfo}
            onClose={handleCloseInfo}
            variant="paper"
          />
        ) : (
          <SelectionContent />
        )}
        <Button
          variant="contained"
          color="error"
          size={isMobile ? "small" : "medium"}
          onClick={handleReset}
          sx={{ mt: 2, width: "100%" }}
        >
          {t("selectionDrawer.resetSelection")}
        </Button>
      </Paper>

      {openDetails.map((gameInfo, index) => (
        <Dialog
          key={`${gameInfo.gameId}-${index}`}
          open={true}
          onClose={() =>
            setOpenDetails(openDetails.filter((_, i) => i !== index))
          }
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              width: "80%", // Make dialog wider
              maxWidth: "none", // Override default max width
              m: 2,
            },
          }}
        >
          <StyledBox
            sx={{
              p: 3,
              height: "80vh",
              overflow: "auto",
            }}
          >
            <GameInfoRenderer
              gameInfo={gameInfo}
              onClose={() =>
                setOpenDetails(openDetails.filter((_, i) => i !== index))
              }
              variant="drawer"
            />
          </StyledBox>
        </Dialog>
      ))}
    </>
  );
};
export default SelectionPaper;
