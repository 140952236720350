const transformTrajectoryData = (matchDp) => {
  if (!Array.isArray(matchDp)) {
    console.error('Invalid input: matchDp must be an array');
    return [];
  }

  try {
    return matchDp.map((ballDp) => {
      const [
        ball_index,
        drop_points,
        startingPlayer,
        ball_num,
        ball_id,
        round_num,
      ] = ballDp;

      // Initialize base data structure with headers and all lines
      const trajectoryData = [
        [
          'x',
          'First3Balls',
          'MiddleBalls',
          'Last3Balls',
          'middleLine',
          'First3BallsLine',
          'MiddleBallsLine',
          'Last3BallsLine',
        ],
        [0, null, null, null, 640, null, null, null], // Start point
        [800, null, null, null, 640, null, null, null], // End point
      ];

      if (!Array.isArray(drop_points) || drop_points.length === 0) {
        return createEmptyDataPoint(round_num, ball_num);
      }

      const totalPoints = drop_points.length;
      const trajectory = [];
      let previousX = null;

      // Process each drop point
      drop_points.forEach((point, index) => {
        const [xRatio, yRatio, time] = point;
        const x = Math.floor(800 * xRatio);
        const y = Math.floor(1280 - 1280 * yRatio);

        // Handle invalid coordinates
        if (x < 0 || y < 0) {
          trajectoryData.push([9999, 9999, 9999, 9999, null, null, null, null]);
          return;
        }

        if (previousX !== null) {
          if ((previousX <= 400 && x > 400) || (previousX >= 400 && x < 400)) {
            trajectory.push(1);
          } else {
            trajectory.push(0);
          }
        }
        previousX = x;

        // Determine which series this point belongs to
        let dataPoint;
        if (totalPoints > 6) {
          if (index < 3) {
            // First 3 balls with corresponding line

            // join third ball with middleline
            if (index === 2) {
              dataPoint = [x, y, null, null, null, y, y, null];
            } else {
              dataPoint = [x, y, null, null, null, y, null, null];
            }
          } else if (index >= totalPoints - 3) {
            // Last 3 balls with corresponding line

            // third ball from last join with middle line
            if (index === totalPoints - 3) {
              dataPoint = [x, null, null, y, null, null, y, y];
            } else {
              dataPoint = [x, null, null, y, null, null, null, y];
            }
          } else {
            // Middle balls with corresponding line
            dataPoint = [x, null, y, null, null, null, y, null];
          }
        } else {
          // If 6 or fewer balls, show all in first series with first line
          dataPoint = [x, y, 9999, 9999, null, y, 9999, 9999];
        }

        trajectoryData.push(dataPoint);
      });

      // Add player and timing information
      const timeData = drop_points.map((point) => [point[2]]);
      const ballPlayers = [];
      let currentPlayer = startingPlayer;

      drop_points.forEach(() => {
        ballPlayers.push(currentPlayer);
        currentPlayer = currentPlayer === 'top' ? 'bottom' : 'top';
      });

      return [
        ball_num,
        trajectoryData,
        timeData,
        ballPlayers,
        trajectory,
        round_num,
      ];
    });
  } catch (error) {
    console.error('Error transforming trajectory data:', error);
    return [];
  }
};

const createEmptyDataPoint = (round_num, ball_num) => {
  const emptyData = [
    [
      'x',
      'First3Balls',
      'MiddleBalls',
      'Last3Balls',
      'middleLine',
      'First3BallsLine',
      'MiddleBallsLine',
      'Last3BallsLine',
    ],
    [0, null, null, null, 640, null, null, null],
    [800, null, null, null, 640, null, null, null],
    [9999, 9999, 9999, 9999, null, 9999, 9999, 9999],
    [9999, 9999, 9999, 9999, null, 9999, 9999, 9999],
  ];

  return [ball_num, emptyData, [[0]], [], round_num];
};

const groupByRound = (data) => {
  // Create an object to store grouped elements by round number
  return data.reduce((acc, item) => {
    // Get the round number (last element)
    const roundNumber = item[item.length - 1];
    // Get elements excluding the round number
    const elements = item.slice(0, -1);

    // Initialize array for this round if it doesn't exist
    if (!acc[roundNumber]) {
      acc[roundNumber] = [];
    }

    // Add elements to the corresponding round
    acc[roundNumber].push(elements);

    return acc;
  }, {});
};

export { transformTrajectoryData, groupByRound };
