/* eslint-disable */

import { Link } from 'react-router-dom';
import PlayerProfileCard from '../PlayerProfileCard/PlayerProfileCard';
import { updateVideoDataAPI } from 'src/api/videoService';
import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import Flag from 'react-world-flags';
import Cookies from 'js-cookie';

import './PlayerVideoBlock.scss';
import { countryCodeMapping } from 'src/constants';

const translationDict = {
  Final: '决赛',
  'Semi-Final': '半决赛',
  'Quarter-Final': '4强',
  R16: '16强',
  R32: '32强',
  R64: '64强',
  R128: '128强',
  MSingles: '男單打',
  MDouble: '男雙打',
  FSingle: '女單打',
  FDouble: '女雙打',
  MixedDouble: '混合雙打',
};

export default function PlayerVideoBlock({
  matchDetails,
  playerData,
  accountType,
  videoPage = true,
  setVideoData,
  isAdmin = false,
}: {
  matchDetails: Record<string, any>;
  playerData: Record<string, any>;
  accountType: string;
  videoPage: boolean;
  setVideoData: React.Dispatch<React.SetStateAction<Record<string, unknown>>> | null;
  isAdmin: boolean;
}): React.JSX.Element {
  const code =
    countryCodeMapping[
    playerData.country_code as keyof typeof countryCodeMapping
    ] || '';
  const token = Cookies.get('token');


  const handleSwapScore = async () => {
    try {
      // Guard clause to ensure all required data is present
      if (!matchDetails?.matchId) {
        console.error('Missing required data for player swap');
        return;
      }

      const response = await updateVideoDataAPI(
        {
          match_id: matchDetails.matchId,
          swap_score: true,
        },
        token as string,
      );

      // Only update state if the API call was successful
      if (response && setVideoData) {
        setVideoData((prevState) => ({
          ...prevState,
          refresh: true,
        }));
      }
    } catch (error) {
      console.error('Error swapping players:', error);
      // Consider adding error handling UI feedback here
    }
  };

  function renderOpponentBlock(): React.JSX.Element {
    const convertedOppCountryCode =
      countryCodeMapping[
      matchDetails.opponentCountryCode as keyof typeof countryCodeMapping
      ] || '';

    return (
      <div className="opp-panel">
        <div className="player-img">
          {matchDetails.opponentImg ? (
            matchDetails.opponentGender === 'MM' ||
              matchDetails.opponentGender === 'FF' ||
              matchDetails.opponentGender === 'MF' ? (
              <div className="double-img-container">
                <img
                  className="player1-img"
                  src={matchDetails.opponentImg}
                  alt="Player Headshot"
                />
                <img
                  className="player2-img"
                  src={matchDetails.opponentPlayer2Img}
                  alt="Player Headshot"
                />
              </div>
            ) : (
              <img
                className="player-img"
                src={matchDetails.opponentImg}
                alt="Player Headshot"
              />
            )
          ) : (
            <img
              className="player-img"
              src="https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg"
              alt="Default Opponent"
            />
          )}
        </div>

        {matchDetails.opponentName === 'empty' ? (
          <Link
            to={`/edit-metadata/${matchDetails.matchId}/0`}
            className="player-card-name"
            title="add opponent name"
          >
            添加名稱
          </Link>
        ) : (
          <Link
            to={`/player-analysis/${matchDetails['opponentId']}`}
            className="opponent-card-name"
            title={matchDetails.opponentName}
          >
            <span className="opponent-name">
              {matchDetails.opponentName &&
                matchDetails.opponentName.length > 15
                ? `${matchDetails.opponentName.substring(0, 15)}...`
                : matchDetails.opponentName}{' '}
              {convertedOppCountryCode && (
                <span className="countryCode">({convertedOppCountryCode})</span>
              )}
            </span>
            {matchDetails.opponentCountryCode === 'TPE' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
                className="player-smallflag"
              />
            )}
            {convertedOppCountryCode ? (
              <Flag
                code={convertedOppCountryCode}
                className="player-smallflag"
              />
            ) : null}
          </Link>
        )}

        {/* {newPage && (
          <Link
            to={`/match-analysis/${matchDetails['matchId']}`}
            className="match-analysis-button"
          >
            分析
          </Link>
        )} */}
      </div>
    );
  }

  return (
    <div className="matches-container">
      <div className="video-link">
        <Link
          to={`/video-player/${matchDetails.matchId}`}
          className="player-video-link"
        >
          {matchDetails.videoName}
        </Link>
      </div>
      {videoPage === false ? (
        <div className="player-match-panel">
          <div className="middle-panel">
            <div className="additional-info-panel">
              <span>{matchDetails.location}</span>
              <span>
                {translationDict[
                  matchDetails.stage as keyof typeof translationDict
                ] || matchDetails.stage}
                ({matchDetails.transformedMatchDate})
              </span>
            </div>
            <div className="score-panel">
              <span
                className={`${matchDetails.playerScore > matchDetails.opponentScore
                  ? 'player-match-score-win'
                  : 'player-match-score'
                  }`}
              >
                {matchDetails.playerScore ? matchDetails.playerScore : 0}
              </span>
              <div className="round-score">
                {matchDetails.roundData &&
                  matchDetails.roundData.map(
                    (data: Record<string, number>, index: number) => {
                      const playerScore = data[playerData.player_name]
                        ? data[playerData.player_name]
                        : data[Object.keys(data)[0]];
                      const opponentScore = data[matchDetails.opponentName]
                        ? data[matchDetails.opponentName]
                        : data[Object.keys(data)[1]];

                      return (
                        <div key={index} className="ind-round-score">
                          <span
                            className={`${playerScore > opponentScore
                              ? 'player-round-score-win'
                              : 'player-round-score'
                              }`}
                          >
                            {playerScore}
                          </span>
                          <span>:</span>
                          <span
                            className={`${playerScore < opponentScore
                              ? 'opponent-round-score-win'
                              : 'opponent-round-score'
                              }`}
                          >
                            {opponentScore ? opponentScore : 0}
                          </span>
                        </div>
                      );
                    },
                  )}
              </div>
              <span
                className={`${matchDetails.playerScore < matchDetails.opponentScore
                  ? 'opponent-match-score-win'
                  : 'opponent-match-score'
                  }`}
              >
                {matchDetails.opponentScore}
              </span>
            </div>
            <div className="playersHistory">
              {/* <Button>比賽歷史</Button> */}
            </div>
          </div>
          <div className="players-profile-container">
            <PlayerProfileCard
              playerData={playerData}
              accountType={accountType}
              opponent={false}
            />

            <PlayerProfileCard
              playerData={{
                gender: matchDetails.opponentGender,
                country_code: matchDetails.opponentCountryCode,
                actual_age: matchDetails.opponentAge,
                head_shot: matchDetails.opponentImg,
                player2_head_shot: matchDetails.opponentPlayer2Img,
                player_name: matchDetails.opponentName,
                country: matchDetails.opponentCountry,
                last_updated: matchDetails.opponentLastUpdated,
                grip: matchDetails.opponentStyle,
                handedness: matchDetails.opponentHand,
                player_id: matchDetails.opponentId,
              }}
              accountType={accountType}
              opponent={true}
            />
          </div>
        </div>
      ) : (
        <div className="match-panel">
          <div className="player-panel">
            <div className="player-img">
              {playerData.head_shot ? (
                playerData.gender === 'MM' ||
                  playerData.gender === 'FF' ||
                  playerData.gender === 'MF' ? (
                  <div className="double-img-container">
                    <img
                      className="player1-img"
                      src={playerData.head_shot}
                      alt="Player Headshot"
                    />
                    <img
                      className="player2-img"
                      src={playerData.player2_head_shot}
                      alt="Player Headshot"
                    />
                  </div>
                ) : (
                  <img
                    className="player-img"
                    src={playerData.head_shot}
                    alt="Player Headshot"
                  />
                )
              ) : (
                <img
                  className="player-img"
                  src="https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg"
                  alt="Default Opponent"
                />
              )}
            </div>
            {playerData.player_name === 'empty' ? (
              <Link
                to={`/edit-metadata/${matchDetails.matchId}/0`}
                className="player-card-name"
                title="add player name"
              >
                添加名稱
              </Link>
            ) : (
              <Link
                to={`/player-analysis/${playerData['player_id']}`}
                className="player-card-name"
                title={playerData.player_name}
              >
                <span className="player-name">
                  {playerData.player_name && playerData.player_name.length > 15
                    ? `${playerData.player_name.substring(0, 15)}...`
                    : playerData.player_name}
                  {code && <span className="countryCode">({code})</span>}
                </span>
                {playerData.country_code === 'TPE' && (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
                    className="player-smallflag"
                  />
                )}
                {code ? (
                  <Flag code={code} className="player-smallflag" />
                ) : null}
              </Link>
            )}

            {/* {newPage && (
            <Link
              to={`/match-analysis/${matchDetails['matchId']}`}
              className="match-analysis-button"
            >
              分析
            </Link>
          )} */}
          </div>
          <div className="middle-panel">
            <div className="additional-info-panel">
              <span>{matchDetails.location}</span>
              <span>
                {translationDict[
                  matchDetails.stage as keyof typeof translationDict
                ] || matchDetails.stage}
                ({matchDetails.transformedMatchDate})
              </span>
            </div>
            <div className="score-panel">
              <span
                className={`${matchDetails.playerScore > matchDetails.opponentScore
                  ? 'player-match-score-win'
                  : 'player-match-score'
                  }`}
              >
                {matchDetails.playerScore ? matchDetails.playerScore : 0}
              </span>
              <div className="round-score">
                {matchDetails.roundData &&
                  matchDetails.roundData.map(
                    (data: Record<string, number>, index: number) => {
                      const playerScore = data[playerData.player_name]
                        ? data[playerData.player_name]
                        : data[Object.keys(data)[0]];
                      const opponentScore = data[matchDetails.opponentName]
                        ? data[matchDetails.opponentName]
                        : data[Object.keys(data)[1]];

                      return (
                        <div key={index} className="ind-round-score">
                          <span
                            className={`${playerScore > opponentScore
                              ? 'player-round-score-win'
                              : 'player-round-score'
                              }`}
                          >
                            {playerScore}
                          </span>
                          <span>:</span>
                          <span
                            className={`${playerScore < opponentScore
                              ? 'opponent-round-score-win'
                              : 'opponent-round-score'
                              }`}
                          >
                            {opponentScore ? opponentScore : 0}
                          </span>
                        </div>
                      );
                    },
                  )}
              </div>
              <span
                className={`${matchDetails.playerScore < matchDetails.opponentScore
                  ? 'opponent-match-score-win'
                  : 'opponent-match-score'
                  }`}
              >
                {matchDetails.opponentScore}
              </span>
            </div>
            {videoPage && isAdmin === true && (
              <div className="playerSwap">
                <button
                  onClick={handleSwapScore}
                  className="swap-button" // Add appropriate styling class
                >
                  {'<'}交換分數{'>'}
                </button>
                <FlagVideo matchId={matchDetails.matchId} defaultOpen={false} ocr={true} dp={false} setVideoData={setVideoData} />
              </div>
            )}
          </div>
          {renderOpponentBlock()}
        </div>
      )}
    </div>
  );
}
