import {
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

const chineseTranslations = {
  'overall statistics': '整體統計',
  'total matches': '總場次',
  wins: '勝場',
  losses: '敗場',
  'win rate': '勝率',
  'stage statistics': '賽段統計',
  'event statistics': '賽事統計',
  'grip statistics': '握拍方式統計',
  'handedness statistics': '慣用手統計',
  'country statistics': '國家/地區統計',
  'opponent statistics': '對手統計',
  _: '其他',
  undefined: '其他',
  others: '其他',
  null: '其他',
  '': '其他',
  m: '男單',
  mm: '男雙',
  f: '女單',
  ff: '女雙',
  mf: '混雙',
  final: '決賽',
  r32: '32強',
  r128: '128強',
  r64: '64強',
  'quarter-final': '四分之一決賽',
  'semi-final': '準決賽',
  shakehand: '直拍',
  penhold: '橫拍',
  'right hand': '右手',
  'left hand': '左手',
};

const getLastScore = (roundResults, roundIndex) => {
  const round = roundResults[roundIndex];
  if (round && round[1] && round[1].length > 0) {
    const mappedBallData = round[1];
    const lastBall = mappedBallData[mappedBallData.length - 1];
    return `${lastBall[ballHomePlayerScoreIndex]}:${lastBall[ballAwayPlayerScoreIndex]}`;
  }
  return 'N/A';
};

const translateToChinese = (text) => {
  const lowercaseText = text.toLowerCase();
  return chineseTranslations[lowercaseText] || text;
};

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
};

const detectDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const { outerWidth, outerHeight } = window;
  const aspectRatio = outerWidth / outerHeight;

  // Regular expression for mobile devices
  const mobileRegex =
    /iphone|ipod|android|iemobile|blackberry|webos|kindle|opera mini/i;

  // Regular expression for tablets
  const tabletRegex = /ipad|tablet|playbook|silk|\b(tablet)\b/i;

  // Check if the device is an iPad
  const isIPad =
    /ipad/i.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  // Check if it's a large screen device (potentially a tablet)
  const isLargeScreen = Math.min(outerWidth, outerHeight) >= 768;

  // Determine if it's a mobile device
  const isMobile = mobileRegex.test(userAgent);

  // Determine if it's a tablet
  const isTablet =
    tabletRegex.test(userAgent) ||
    isIPad ||
    (isLargeScreen && aspectRatio > 0.6 && aspectRatio < 1.6);

  return {
    checkedMobileBrowser: isMobile || isTablet,
    checkedMobile: isMobile,
  };
};

export default detectDevice;

export { formatTime, getLastScore, detectDevice, translateToChinese };
