import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Cookies from 'js-cookie';

import BallDetailsBar from './InputPanel/BallDetailsBar';
import EditDpPanel from './InputPanel/EditDpPanel';
import EditRoundPanel from './EditRoundPanel';
import { formatTime, getLastScore } from 'src/utils/utils';

import {
  roundEndTimeIndex,
  roundStartTimeIndex,
  ballDataIndex,
  roundDataIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

import './ManualInput.scss';

interface UserInfo {
  isCoach: boolean;
  admin: boolean;
}

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
  filename: string;
}

interface ManualInputProps {
  userInfo: UserInfo;
  havePlayerinfo: boolean;
  videoData: VideoData;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
  matchId: number;
  roundResults: any[];
  videoRef: React.RefObject<HTMLVideoElement>;
  dpResults: any[];
}

const ManualInput: React.FC<ManualInputProps> = ({
  havePlayerinfo,
  videoData,
  setVideoData,
  matchId,
  roundResults,
  videoRef,
  dpResults,
}) => {
  const token = Cookies.get('token') || '';
  const [ballData, setBallData] = useState<any[]>([]);
  const [roundData, setRoundData] = useState<any[]>([]);

  useEffect(() => {
    if (roundResults && roundResults[videoData.currentRound]) {
      setBallData(roundResults[videoData.currentRound][ballDataIndex]);
      setRoundData(roundResults[videoData.currentRound]);
    } else {
      setBallData([]);
      setRoundData([]);
    }
  }, [roundResults, videoData.currentRound]);

  const roundNumbers = Array.from(
    { length: videoData.numberOfRound },
    (_, rIndex) => rIndex + 1,
  );

  // const permission = userInfo.isCoach || userInfo.admin;
  const permission = true;



  //prettier-ignore
  return (
    <>
      {permission && (
        <div className="manual-input-main-container">
          {havePlayerinfo ? (<>
            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="edit-round-panel-content"
                id="edit-round-panel-header"
              >
                {roundResults[videoData.currentRound] ? (
                  <div className="current-round-info-panel">
                    <span className="round-number">
                      回合{' '}
                      <span className="number">{videoData.currentRound + 1}</span>
                    </span>
                    <span className="time-info">
                      開始:{' '}
                      <span className="start-time">
                        {formatTime(
                          roundResults[videoData.currentRound][roundDataIndex][
                          roundStartTimeIndex
                          ],
                        )}
                      </span>{' '}
                      結束:{' '}
                      <span className="end-time">
                        {formatTime(
                          roundResults[videoData.currentRound][roundDataIndex][
                          roundEndTimeIndex
                          ],
                        )}
                      </span>
                    </span>
                    <span className="score-info">
                      比分:{' '}
                      <span className="score">
                        {getLastScore(roundResults, videoData.currentRound)}
                      </span>
                    </span>
                  </div>
                ) : (
                  <p
                    style={{ color: 'white', textAlign: 'center', width: '100%' }}
                  >
                    沒有回合
                  </p>
                )}
              </AccordionSummary>
              <AccordionDetails className="round-summary-container">
                <EditRoundPanel
                  currentRound={videoData.currentRound}
                  roundNumbers={roundNumbers}
                  roundResults={roundResults}
                  matchId={matchId}
                  token={token}
                  setVideoData={setVideoData}
                  videoRef={videoRef}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {roundResults[videoData.currentRound] &&
                  roundResults[videoData.currentRound][ballDataIndex][
                  videoData.currentBall
                  ] ? (
                  <div className="current-round-info-panel">
                    <span className="round-number">
                      球{' '}
                      <span className="number">{videoData.currentBall + 1}</span>
                    </span>
                    <span className="time-info">
                      開始:{' '}
                      <span className="start-time">
                        {formatTime(
                          roundResults[videoData.currentRound][ballDataIndex][
                          videoData.currentBall
                          ][ballStartTimeIndex],
                        )}
                      </span>{' '}
                      結束:{' '}
                      <span className="end-time">
                        {formatTime(
                          roundResults[videoData.currentRound][ballDataIndex][
                          videoData.currentBall
                          ][ballEndTimeIndex],
                        )}
                      </span>
                    </span>
                    <span className="score-info">
                      比分:{' '}
                      <span className="score">
                        {
                          roundResults[videoData.currentRound][ballDataIndex][
                          videoData.currentBall
                          ][ballHomePlayerScoreIndex]
                        }{' '}
                        :{' '}
                        {
                          roundResults[videoData.currentRound][ballDataIndex][
                          videoData.currentBall
                          ][ballAwayPlayerScoreIndex]
                        }
                      </span>
                    </span>
                  </div>
                ) : (
                  <p
                    style={{ color: 'white', textAlign: 'center', width: '100%' }}
                  >
                    沒有球
                  </p>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {roundResults && (
                  <BallDetailsBar
                    videoData={videoData}
                    roundResults={roundResults}
                    videoRef={videoRef}
                    token={token}
                    matchId={matchId}
                    setVideoData={setVideoData}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            {ballData[videoData.currentBall] && (
              <div className="ball-input-main-panel">
                <EditDpPanel
                  currentRound={videoData.currentRound}
                  currentBall={videoData.currentBall}
                  setVideoData={setVideoData}
                  filename={videoData.filename}
                  ballData={ballData[videoData.currentBall]}
                  videoRef={videoRef}
                  dpResults={dpResults ? dpResults[videoData.currentBall] : []}
                />
              </div>
            )}
          </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p>
                no player info
              </p>
              <Link
                to={`/edit-metadata/${matchId}/0`}
                style={{ color: 'white' }}
                title="add players"
              >
                添加名稱
              </Link>
            </div>)}
          <div className="padding"></div>
        </div>
      )}
    </>
  );
};

export default ManualInput;
