import axios from 'axios';
import Cookies from 'js-cookie';

import {
  getUploadProgressAPI,
  newUploadAPI,
  getChunkDataAPI,
} from 'src/api/vqService';

const uploadFile = async (file, index, username, setProgressArray, matchId) => {
  const token = Cookies.get('token');
  const totalSize = file && file.size ? file.size : 0;
  const minChunkSize = 1 * 1048576; // 1 MB
  const maxChunkSize = 50 * 1048576; // 50 MB
  let chunkSize = 4 * 1048576; // Start with 4 MB chunks
  let initialConcurrentUploads = 3; // Start with 3 concurrent uploads
  const minConcurrentUploads = 1;
  const maxConcurrentUploads = 20; // Maximum allowed concurrent uploads
  const maxRetries = 3;
  const retryDelay = 1000; // 1 second

  // Function to update progress values
  const updateProgress = (index, value) => {
    setProgressArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = value;
      return newArray;
    });
  };

  // merge 1 file
  const mergeFileChunks = async (file, videoId) => {
    const formData = new FormData();
    formData.append('filename', file.name);
    formData.append('video_id', videoId);
    axios
      .post('/api/mergeFile', formData, {
        params: {
          primary_attribute: 'filename : ' + file.name,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log('Error occurred during file merge.');
      });
  };

  const getUploadProgress = async () => {
    try {
      const response = await getUploadProgressAPI(
        { videoname: file.name },
        token,
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // No existing progress found, which is expected for new uploads
        return null;
      }
      console.error('Error fetching upload progress:', error);
      throw error;
    }
  };

  const initializeUpload = async () => {
    const existingProgress = await getUploadProgress();
    if (existingProgress.progress >= 0 && existingProgress.total_size > 0) {
      return existingProgress;
    } else {
      const newProgress = await newUploadAPI(
        {
          username: username,
          videoname: file.name,
          totalSize: file.size,
          matchId: matchId,
        },
        token,
      );
      return newProgress;
    }
  };

  // Fetch chunk data from the server
  const fetchChunkData = async (videoId) => {
    const response = await getChunkDataAPI({ videoId: videoId }, token);
    return response;
  };

  // Analyze chunk data to identify gaps and next upload position
  const analyzeChunkData = (chunkData, fileSize) => {
    const sortedChunks = Object.entries(chunkData)
      .map(([index, { start, end }]) => ({
        index: parseInt(index),
        start,
        end,
      }))
      .sort((a, b) => a.start - b.start);

    const largestIndex =
      sortedChunks.length > 0 ? sortedChunks[sortedChunks.length - 1].index : 0;

    const gaps = [];
    let nextUploadPosition = 0;

    for (let i = 0; i < sortedChunks.length; i++) {
      const chunk = sortedChunks[i];
      if (chunk.start > nextUploadPosition) {
        gaps.push({
          start: nextUploadPosition,
          end: chunk.start,
          index: chunk.index,
        });
      }
      nextUploadPosition = Math.max(nextUploadPosition, chunk.end);
    }

    if (nextUploadPosition < fileSize) {
      gaps.push({
        start: nextUploadPosition,
        end: fileSize,
        index: largestIndex,
      });
    }

    return { gaps, nextUploadPosition };
  };

  // Upload a single chunk with retry logic
  const uploadChunkWithRetry = async (start, end, index, videoId) => {
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const { success, uploadTime } = await uploadChunk(
          start,
          end,
          index,
          videoId,
        );
        if (success) {
          return { success: true, uploadTime };
        }
      } catch (error) {
        console.error(
          `Attempt ${attempt + 1} failed for chunk ${index}:`,
          error,
        );
        if (attempt < maxRetries - 1) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
      }
    }
    return { success: false, uploadTime: 0 };
  };

  // Update upload speeds and adjust concurrency
  const updateUploadMetrics = (
    uploadSpeeds,
    uploadTime,
    chunkSize,
    concurrentUploads,
  ) => {
    const currentSpeed = chunkSize / uploadTime;
    uploadSpeeds.push(currentSpeed);
    if (uploadSpeeds.length > 10) {
      // Keep last 10 speeds
      uploadSpeeds.shift();
    }

    const avgSpeed =
      uploadSpeeds.reduce((a, b) => a + b, 0) / uploadSpeeds.length;
    const speedRatio = avgSpeed / uploadSpeeds[0]; // Compare to oldest speed in the array

    let newConcurrentUploads = concurrentUploads;

    if (speedRatio > 1) {
      // Speed is improving, increase concurrency
      const increase = Math.ceil((speedRatio - 1) * 5); // Adjust the multiplier as needed
      newConcurrentUploads = Math.min(
        concurrentUploads + increase,
        maxConcurrentUploads,
      );
    } else if (speedRatio < 1) {
      // Speed is decreasing, reduce concurrency
      const decrease = Math.ceil((1 - speedRatio) * 5); // Adjust the multiplier as needed
      newConcurrentUploads = Math.max(
        concurrentUploads - decrease,
        minConcurrentUploads,
      );
    }

    // console.log(
    //   `Speed ratio: ${speedRatio.toFixed(
    //     2,
    //   )}, New concurrency: ${newConcurrentUploads}`,
    // );

    return { uploadSpeeds, newConcurrentUploads };
  };

  const adaptChunkSize = (uploadTime) => {
    const targetTime = 5000; // 5 seconds
    const newChunkSize = Math.round((chunkSize * targetTime) / uploadTime);
    return Math.max(minChunkSize, Math.min(maxChunkSize, newChunkSize));
  };

  const uploadChunk = async (start, end, chunkIndex, videoId) => {
    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append('videoFile', chunk, file.name);
    formData.append('chunkIndex', chunkIndex);
    formData.append('chunkStart', start);
    formData.append('chunkEnd', end);
    formData.append('videoId', videoId);

    const startTime = Date.now();
    try {
      const response = await axios.put('/api/uploadVideoChunk', formData, {
        headers: { Authorization: `${token}` },
      });
      const percentCompleted = response.data.percentage_completed;
      updateProgress(index, percentCompleted);
      const uploadTime = Date.now() - startTime;
      return { success: true, uploadTime };
    } catch (error) {
      console.error(`Error uploading chunk ${chunkIndex}:`, error);
      return { success: false, error };
    }
  };

  // Main upload chunks function
  const uploadChunks = async (progress) => {
    let chunkIndex;
    let uploadedSize = progress.uploaded_size;
    const inProgress = new Set();
    const errors = [];
    let uploadSpeeds = [];
    let concurrentUploads = initialConcurrentUploads;

    const chunkData = await fetchChunkData(progress.video_id);
    let { gaps, nextUploadPosition } = analyzeChunkData(chunkData, file.size);

    const uploadNextChunk = async () => {
      let start, end;

      if (gaps.length > 0) {
        // Fill a gap
        const gap = gaps[0];
        start = gap.start;
        end = Math.min(gap.start + chunkSize, gap.end);
        chunkIndex = gap.index + 1;

        // Remove or adjust the gap
        if (end >= gap.end) {
          gaps.shift(); // Remove the gap if it's fully covered
        } else {
          gap.start = end; // Adjust the gap start if partially covered
          gap.index = chunkIndex;
        }
      } else if (nextUploadPosition < totalSize) {
        // Upload new chunk
        chunkIndex = chunkIndex + 1;
        start = nextUploadPosition;
        end = Math.min(start + chunkSize, totalSize);
      } else {
        return null; // No more chunks to upload
      }

      nextUploadPosition = end;
      // console.log(
      //   `Uploading chunk: start=${start}, end=${end}, index=${chunkIndex}`,
      // );

      const result = await uploadChunkWithRetry(
        start,
        end,
        chunkIndex,
        progress.video_id,
      );
      if (result.success) {
        chunkData[chunkIndex] = { start, end };
        uploadedSize += end - start;
        chunkSize = adaptChunkSize(result.uploadTime);
        ({ uploadSpeeds, newConcurrentUploads: concurrentUploads } =
          updateUploadMetrics(
            uploadSpeeds,
            result.uploadTime,
            end - start,
            concurrentUploads,
          ));
        updateProgress(index, Math.round((uploadedSize / file.size) * 100));
      } else {
        errors.push({ chunkIndex, error: 'Failed after multiple attempts' });
      }

      return result;
    };

    while (uploadedSize < file.size) {
      const uploadPromises = [];
      while (
        inProgress.size < concurrentUploads &&
        (gaps.length > 0 || nextUploadPosition < file.size)
      ) {
        const promise = uploadNextChunk().then((result) => {
          if (result) inProgress.delete(promise);
        });
        inProgress.add(promise);
        uploadPromises.push(promise);
      }
      await Promise.all(uploadPromises);

      if (errors.length > 0) {
        console.error('Some chunks failed to upload:', errors);
        throw new Error('Failed to upload all chunks');
      }

      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait a bit before next batch
    }
  };

  try {
    const progress = await initializeUpload(); // done
    console.log(progress);
    await uploadChunks(progress);
    mergeFileChunks(file, progress.video_id);
    console.log('File upload completed successfully');
  } catch (error) {
    console.log('Error occurred during file upload.');
  }
};

export default uploadFile;
