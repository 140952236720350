import React from 'react';
import './DraggableVideoItem.scss';

interface DraggableVideoItemProps {
    video: {
        id: string;
        videoName: string;
        isPinned: boolean;
    };
    index: number;
    isDragging: boolean;
    isDraggedOver: boolean;
    onDragStart: (index: number) => void;
    onDragEnter: (index: number) => void;
    onDragEnd: () => void;
    onPin: (id: string) => void;
    onRemove: (id: string) => void;
}

const DraggableVideoItem: React.FC<DraggableVideoItemProps> = ({
    video,
    index,
    isDragging,
    isDraggedOver,
    onDragStart,
    onDragEnter,
    onDragEnd,
    onPin,
    onRemove,
}) => {
    return (
        <div
            className={`playlist-video-item ${isDragging ? 'dragging' : ''} ${isDraggedOver ? 'drag-over' : ''}`}
            draggable
            onDragStart={() => onDragStart(index)}
            onDragEnter={() => onDragEnter(index)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={onDragEnd}
        >
            <span className="video-name">{video.videoName}</span>
            <div className="button-group">
                <button className="pin-button" onClick={() => onPin(video.id)}>
                    {video.isPinned ? "取消釘選" : "釘選"}
                </button>
                <button className="remove-button" onClick={() => onRemove(video.id)}>
                    移除
                </button>
            </div>
        </div>
    );
};

export default DraggableVideoItem;