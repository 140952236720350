import React from 'react';
import { formatTime } from 'src/utils/utils';
import { IconButton, Tooltip, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { updateBallDataAPI, deleteBallDataAPI } from 'src/api/videoService';
import { convertToBallData } from '../utils';
import {
  UpdateBallDataParams,
  DeleteBallDataParams,
  BallData,
} from 'types/videoTypes';

import {
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballIdIndex,
  ballRoundIdIndex,
  roundDataIndex,
  roundStartTimeIndex,
  roundIdIndex,
  ballDataIndex,
} from 'src/constants';

import './BallDetailsBar.scss';

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
  filename: string;
  currentTotalScore: number;
}

interface BallDetailsBarProps {
  roundResults: any[][][];
  selectedRound: number;
  selectedBall: number;
  videoRef: React.RefObject<HTMLVideoElement>;
  token: string;
  matchId: number;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
  setSelectedBall: React.Dispatch<React.SetStateAction<number>>;
  setSelectedRound: React.Dispatch<React.SetStateAction<number>>;
  setSelectedDp: React.Dispatch<React.SetStateAction<number>>;
}

interface Ball {
  [ballHomePlayerScoreIndex]: number;
  [ballAwayPlayerScoreIndex]: number;
  [ballStartTimeIndex]: number;
  [ballEndTimeIndex]: number;
}

const BallDetails: React.FC<{
  ball: Ball;
  onClick?: () => void;
  onDelete?: () => void;
}> = ({ ball, onClick, onDelete }) =>
    ball ? (
      <div className="ball-details" onClick={onClick}>
        <div className="ball-number">
          <span className="number">
            第{ball[ballHomePlayerScoreIndex] + ball[ballAwayPlayerScoreIndex]}球
          </span>
          <button title="刪除此球" className="delete-button" onClick={onDelete}>
            <CloseOutlinedIcon />
          </button>
        </div>
        <span className="time-info">
          開始:{' '}
          <span className="start-time">
            {formatTime(ball[ballStartTimeIndex])}
          </span>
        </span>
        <span className="time-info">
          結束:{' '}
          <span className="end-time">{formatTime(ball[ballEndTimeIndex])}</span>
        </span>
        <span className="score-info">
          比分:{' '}
          <span className="score">
            {ball[ballHomePlayerScoreIndex]}:{ball[ballAwayPlayerScoreIndex]}
          </span>
        </span>
      </div>
    ) : null;

const BallDetailsBar: React.FC<BallDetailsBarProps> = ({
  roundResults,
  videoRef,
  token,
  matchId,
  selectedBall,
  selectedRound,
  setVideoData,
  setSelectedBall,
  setSelectedDp,
  setSelectedRound,
}) => {
  if (
    !roundResults ||
    roundResults.length === 0 ||
    !roundResults[selectedRound]
  ) {
    return (
      <div className="ball-details-bar" style={{ color: 'white' }}>
        Loading...
      </div>
    );
  }

  const currentRound = roundResults[selectedRound];
  const currentBall = currentRound[1][selectedBall];

  const totalRounds = roundResults.length;
  const totalBallsInCurrentRound = currentRound[1].length;

  const skipToTime = (time: number, ball: number, round: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time + 0.1;
      setSelectedRound(round);
      setSelectedDp(roundResults[round][ballDataIndex][
        ball
      ][ballAwayPlayerScoreIndex] + roundResults[round][ballDataIndex][
      ball
      ][ballHomePlayerScoreIndex])
      setSelectedBall(ball);
    }
  };

  const getPrevBall = () => {
    if (selectedBall > 0) {
      return { ball: currentRound[1][selectedBall - 1], ballNumber: selectedBall - 1, roundNumber: selectedRound };
    } else if (selectedRound > 0) {
      const prevRound = roundResults[selectedRound - 1];
      return { ball: prevRound[1][prevRound[1].length - 1], ballNumber: prevRound[1].length - 1, roundNumber: selectedRound - 1 };
    }
    return null;
  };

  const getNextBall = () => {
    if (selectedBall < totalBallsInCurrentRound - 1) {
      return { ball: currentRound[1][selectedBall + 1], ballNumber: selectedBall + 1, roundNumber: selectedRound };
    } else if (selectedRound < totalRounds - 1) {
      const nextRound = roundResults[selectedRound + 1];
      return { ball: nextRound[1][0], ballNumber: 0, roundNumber: selectedRound + 1 };
    }
    return null;
  };

  async function updateBall(ballData: BallData) {
    const params: UpdateBallDataParams = {
      filename: null,
      match_id: matchId,
      primary_attribute: `video edited (id) : ${matchId}`,
      secondary_attribute: `add ball to round id : ${ballData.round_id} ${ballData.start_time}s to ${ballData.end_time}s`,
      new_row: ballData,
    };

    try {
      const response = await updateBallDataAPI(params, token);
      console.log('Ball data updated successfully:', response);
      return response;
    } catch (error) {
      console.error('Failed to update ball data:', error);
      throw error;
    }
  }

  async function deleteBall(ballId: number, roundId: number) {
    const params: DeleteBallDataParams = {
      ball_id: ballId,
      round_id: roundId,
      match_id: matchId,
      primary_attribute: `video edited (id) : ${matchId}`,
      secondary_attribute: `delete ball from round id : ${roundId}`,
    };

    // set prev ball
    // if (selectedBall > 0) {
    //   setSelectedBall(selectedBall - 1)
    //   setSelectedDp(roundResults[selectedRound][ballDataIndex][
    //     selectedBall - 1
    //   ][ballAwayPlayerScoreIndex] + roundResults[selectedRound][ballDataIndex][
    //   selectedBall - 1
    //   ][ballHomePlayerScoreIndex])
    //   //set lastball from prev round
    // } else if (selectedBall === 0 && selectedRound > 0) {
    //   const prevRound = roundResults[selectedRound - 1];
    //   setSelectedBall(prevRound[1].length - 1)
    //   setSelectedDp(roundResults[selectedRound][ballDataIndex][
    //     prevRound[1].length - 1
    //   ][ballAwayPlayerScoreIndex] + roundResults[selectedRound][ballDataIndex][
    //   prevRound[1].length - 1
    //   ][ballHomePlayerScoreIndex])
    //   setSelectedRound(selectedRound - 1)
    // }

    try {
      const response = await deleteBallDataAPI(params, token);
      console.log('Ball data deleted successfully:', response);

      return response;
    } catch (error) {
      console.error('Failed to delete ball data:', error);
      throw error;
    }
  }

  const handleInsertBall = async (newBallData: BallData) => {
    try {
      await updateBall(newBallData);
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));

      if (videoRef.current) {
        await videoRef.current.play();
      } else {
        console.warn('Video reference is not available');
      }
    } catch (error) {
      console.error('Error inserting ball:', error);
    }
  };

  const handleDeleteBall = async (
    ballIndex: number,
    roundId: number,
    ballId: number,
  ) => {
    try {
      await deleteBall(ballId, roundId);
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
        currentBall:
          ballIndex === selectedBall
            ? Math.max(0, ballIndex - 1)
            : selectedBall,
      }));
    } catch (error) {
      console.error('Error deleting ball:', error);
    }
  };

  const renderInsertButton = (
    ballIndex: number,
    ballData: number[],
    position: 'before' | 'after',
  ) => {
    const isRoundChange =
      (position === 'after' && ballIndex === totalBallsInCurrentRound - 1) ||
      (position === 'before' && ballIndex === 0);

    const roundChangeIndicator = (
      <Divider
        orientation="vertical"
        flexItem
        className="round-change-indicator"
      />
    );

    return (
      <>
        {isRoundChange && position === 'before' && roundChangeIndicator}
        <Tooltip
          title={`在球${position === 'before' ? '前' : '後'}添加新球${isRoundChange ? '' : '(這回合）'
            }`}
          arrow
        >
          <IconButton
            onClick={() =>
              handleInsertBall(
                convertToBallData(
                  ballData,
                  true,
                  position === 'after',
                  // isRoundChange,
                  false,
                ),
              )
            }
            className={`add-ball-button ${isRoundChange ? 'round-change' : ''}`}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        {isRoundChange && position === 'after' && roundChangeIndicator}
      </>
    );
  };

  if (currentRound[1].length === 0) {
    return (
      <div
        className="ball-details-bar"
        style={{ backgroundColor: 'transparent' }}
      >
        No ball data available
        {renderInsertButton(
          0,
          [
            -1,
            roundResults[selectedRound][roundDataIndex][roundIdIndex],
            roundResults[selectedRound][roundDataIndex][
            roundStartTimeIndex
            ] + 1,
            roundResults[selectedRound][roundDataIndex][
            roundStartTimeIndex
            ] + 5,
            0,
            0,
            false,
            false,
            false,
          ],
          'before',
        )}
      </div>
    );
  }

  const prevBall = getPrevBall();
  const nextBall = getNextBall();


  return (
    <div className="ball-details-bar">
      {prevBall && (
        <div className="prev-ball">
          <BallDetails
            ball={prevBall.ball}
            onClick={() => skipToTime(prevBall.ball[ballStartTimeIndex], prevBall.ballNumber, prevBall.roundNumber)}
            onDelete={() =>
              handleDeleteBall(
                selectedBall - 1,
                prevBall.ball[ballRoundIdIndex],
                prevBall.ball[ballIdIndex],
              )
            }
          />
        </div>
      )}
      {renderInsertButton(
        selectedBall,
        currentRound[1][selectedBall],
        'before',
      )}
      <div className="current-ball">
        <BallDetails
          ball={currentBall}
          onClick={() => skipToTime(currentBall.ball[ballStartTimeIndex], selectedBall, selectedRound)}
          onDelete={() =>
            handleDeleteBall(
              selectedBall,
              currentRound[1][selectedBall][ballRoundIdIndex],
              currentRound[1][selectedBall][ballIdIndex],
            )
          }
        />
      </div>
      {renderInsertButton(
        selectedBall,
        currentRound[1][selectedBall],
        'after',
      )}
      {nextBall && (
        <div className="next-ball">
          <BallDetails
            ball={nextBall.ball}
            onClick={() => skipToTime(nextBall.ball[ballStartTimeIndex], nextBall.ballNumber, nextBall.roundNumber)}
            onDelete={() =>
              handleDeleteBall(
                selectedBall + 1,
                nextBall.ball[ballRoundIdIndex],
                nextBall.ball[ballIdIndex],
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default BallDetailsBar;
