import { useGameDetails, useGameResult } from "../../hooks/useGameDetails";
import { isPlayerInTeam } from "../../utils/game/getWinner";
import { NODE_STYLES } from "../GraphStyles";
import { ExtendedEdgeDisplayData } from "../GraphType";
import { MultipleSelectionParams, SingleSelectionParams } from "./types";

export const handleMultipleSelection = ({
  newData,
  source,
  target,
  selectedNode,
  selectedOpponent,
  selectedCommonOpponent,
  showCommonOpponents,
  connectionsData,
  findCommonGameIds,
  gameDetailsMap = {}, // Add this parameter
  playerData,
}: MultipleSelectionParams): Partial<ExtendedEdgeDisplayData> => {
  // Find common games between selected nodes
  const redOrangeGames = findCommonGameIds(
    selectedNode,
    selectedOpponent,
    connectionsData
  );

  // Early return for hidden edge default state
  const hiddenEdge = {
    ...newData,
    hidden: true,
  };

  // Handle common opponents mode
  if (showCommonOpponents) {
    // Only process if there's a common opponent selected
    if (!selectedCommonOpponent) return hiddenEdge;

    const yellowOrangeGames = findCommonGameIds(
      selectedOpponent,
      selectedCommonOpponent,
      connectionsData
    );

    // Check if this edge connects opponent with common opponent through their games
    const isYellowOrangeEdge =
      (source === selectedOpponent ||
        target === selectedOpponent ||
        source === selectedCommonOpponent ||
        target === selectedCommonOpponent) &&
      (yellowOrangeGames.includes(parseInt(source)) ||
        yellowOrangeGames.includes(parseInt(target)));

    return isYellowOrangeEdge
      ? applyEdgeStyle(newData, "yellowOrange")
      : applyEdgeStyle(newData, "hidden");
  }

  // Handle regular mode (showing games between selected player and opponent)
  const isRedOrangeEdge =
    (source === selectedNode ||
      target === selectedNode ||
      source === selectedOpponent ||
      target === selectedOpponent) &&
    (redOrangeGames.includes(parseInt(source)) ||
      redOrangeGames.includes(parseInt(target)));

  if (isRedOrangeEdge) {
    const gameId = target.toString();
    const gameDetails = gameDetailsMap[gameId];

    if (gameDetails) {
      const playerName = playerData[source];

      // Check if player is in teams
      const isTeam1 =
        playerName && isPlayerInTeam(gameDetails.metadata.team1, playerName);
      const isTeam2 =
        playerName && isPlayerInTeam(gameDetails.metadata.team2, playerName);

      console.log("GameDetailsBox", playerName, isTeam1, isTeam2);

      if (!playerName || (!isTeam1 && !isTeam2))
        return applyEdgeStyle(newData, "redOrange");

      const score1 = gameDetails.metadata.finalscore1;
      const score2 = gameDetails.metadata.finalscore2;

      // If both scores are 0 or equal, treat as no result rather than tie
      if ((score1 === 0 && score2 === 0) || score1 === score2) {
        return applyEdgeStyle(newData, "redOrange"); // Use default color for no result
      }

      let playerWon = false;
      if (isTeam1) {
        playerWon = score1 > score2;
      } else {
        playerWon = score2 > score1;
      }

      // For edges FROM player TO game (source is player, target is game)
      if (
        source === playerName ||
        source === selectedNode ||
        source === selectedOpponent
      ) {
        return playerWon
          ? applyEdgeStyle(newData, "win") // Green arrow going out
          : applyEdgeStyle(newData, "loss"); // Red arrow going out
      }
      // For edges FROM game TO player (source is game, target is player)
      else {
        return playerWon
          ? applyEdgeStyle(newData, "loss") // Red arrow coming in
          : applyEdgeStyle(newData, "win"); // Green arrow coming in
      }
    }
  }

  return isRedOrangeEdge
    ? applyEdgeStyle(newData, "redOrange")
    : applyEdgeStyle(newData, "hidden");
};

const determineGameResult = (sourceScore: number, targetScore: number) => {
  if (sourceScore > targetScore) return "win";
  if (sourceScore < targetScore) return "loss";
  return "tie";
};

type EdgeStyleType =
  | "yellowOrange" // For common opponent connections
  | "redOrange" // For direct opponent connections
  | "win" // For winning games
  | "loss" // For losing games
  | "tie" // For tied games
  | "hidden"; // For hidden edges

const getEdgeStyle = (type: EdgeStyleType) => {
  switch (type) {
    case "yellowOrange":
      return {
        hidden: false,
        color: NODE_STYLES.edge.yellowOrange,
        size: 2,
      };
    case "redOrange":
      return {
        hidden: false,
        color: NODE_STYLES.edge.selected,
        size: 2,
      };
    case "win":
      return {
        hidden: false,
        color: "#4CAF50", // Green
        size: 2.5,
      };
    case "loss":
      return {
        hidden: false,
        color: "#F44336", // Red
        size: 2.5,
      };
    case "tie":
      return {
        hidden: false,
        color: "#9E9E9E", // Gray
        size: 2.5,
      };
    case "hidden":
    default:
      return {
        hidden: true,
      };
  }
};

const applyEdgeStyle = (
  baseData: Partial<ExtendedEdgeDisplayData>,
  type: EdgeStyleType
) => ({
  ...baseData,
  ...getEdgeStyle(type),
});

export const handleSingleSelection = ({
  newData,
  source,
  target,
  selectedNode,
}: SingleSelectionParams): Partial<ExtendedEdgeDisplayData> => {
  const isConnectedToSelected =
    source === selectedNode || target === selectedNode;
  return {
    ...newData,
    hidden: !isConnectedToSelected,
    color: isConnectedToSelected ? NODE_STYLES.edge.selected : newData.color,
    size: isConnectedToSelected ? 1 : newData.size,
  };
};
