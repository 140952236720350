import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    CircularProgress,
    Snackbar,
} from '@mui/material';
import {
    Add as AddIcon,
    Close as CloseIcon,
    PlaylistAdd as PlaylistAddIcon,
    PlaylistRemove as PlaylistRemoveIcon,
    Check as CheckIcon, Error as ErrorIcon
} from '@mui/icons-material';

import { fetchPlaylistDataAPI, addMatchToPlaylistAPI, removeMatchFromPlaylistAPI } from 'src/api/playlistService';
import { Playlist } from 'types/playlist';
import './AddPlaylist.scss';

import Cookies from 'js-cookie';

interface AddPlaylistProps {
    userInfo: {
        user_type: string;
        user_name: string;
    };
    matchId: number;
}

const AddPlaylist: React.FC<AddPlaylistProps> = ({ userInfo, matchId }) => {
    const token = Cookies.get('token');

    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' as 'success' | 'error'
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const showSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const fetchPlaylists = async () => {
        try {
            setLoading(true);
            const [playlistData] = await fetchPlaylistDataAPI({
                user_type: userInfo.user_type,
                user_name: userInfo.user_name,
            }, token as string);
            setPlaylists(playlistData);
        } catch (error) {
            showSnackbar('無法載入播放清單', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handlePlaylistAction = async (playlist: Playlist) => {
        const isInPlaylist = playlist.match_order.includes(matchId);
        setActionLoading(playlist.playlist_id);

        try {
            if (isInPlaylist) {
                await removeMatchFromPlaylistAPI(playlist.playlist_id, matchId, token as string);
                showSnackbar('已從播放清單中移除影片', 'success');
                setPlaylists(playlists.map(p => {
                    if (p.playlist_id === playlist.playlist_id) {
                        return {
                            ...p,
                            match_order: p.match_order.filter(id => id !== matchId)
                        };
                    }
                    return p;
                }));
            } else {
                await addMatchToPlaylistAPI(playlist.playlist_id, matchId, token as string);
                showSnackbar('已新增影片至播放清單', 'success');
                setPlaylists(playlists.map(p => {
                    if (p.playlist_id === playlist.playlist_id) {
                        return {
                            ...p,
                            match_order: [...p.match_order, matchId]
                        };
                    }
                    return p;
                }));
            }
        } catch (error) {
            showSnackbar(
                isInPlaylist ? '無法從播放清單移除影片' : '無法新增影片至播放清單',
                'error'
            );
        } finally {
            setActionLoading(null);
        }
    };

    return (
        <>
            <IconButton
                size="small"
                onClick={() => {
                    setOpen(true);
                    fetchPlaylists();
                }}
                className="add-playlist-button"
            >
                <AddIcon fontSize="small" />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                className="playlist-dialog"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle className="dialog-title">
                    加入播放清單
                    <IconButton
                        onClick={handleClose}
                        className="close-button"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="dialog-content">
                    {loading ? (
                        <div className="loading-container">
                            <CircularProgress size={32} />
                        </div>
                    ) : (
                        <List className="playlist-list">
                            {playlists.length > 0 ? (
                                playlists.map((playlist) => {
                                    const isInPlaylist = playlist.match_order.includes(matchId);
                                    const isLoading = actionLoading === playlist.playlist_id;

                                    return (
                                        <ListItem className="playlist-item" disablePadding key={playlist.playlist_id}>
                                            <ListItemButton
                                                onClick={() => handlePlaylistAction(playlist)}
                                                disabled={isLoading}
                                                className={`playlist-button ${isInPlaylist ? 'in-playlist' : ''}`}
                                            >
                                                <ListItemIcon>
                                                    {isLoading ? (
                                                        <CircularProgress size={24} className="playlist-icon" />
                                                    ) : isInPlaylist ? (
                                                        <PlaylistRemoveIcon className="playlist-icon remove-icon" />
                                                    ) : (
                                                        <PlaylistAddIcon className="playlist-icon" />
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    className="playlist-text"
                                                    primary={<span className="playlist-name">{playlist.playlist_name}</span>}
                                                    secondary={
                                                        playlist.description &&
                                                        <span className="playlist-description">{playlist.description}</span>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })
                            ) : (
                                <ListItem>
                                    <ListItemText
                                        primary="尚無播放清單"
                                        sx={{ textAlign: 'center', color: 'text.secondary' }}
                                    />
                                </ListItem>
                            )}
                        </List>
                    )}
                </DialogContent>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                className="modern-snackbar"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <div className="snackbar-content">
                    <div className={snackbar.severity === 'success' ? 'success-bar' : 'error-bar'} />
                    <div className="snackbar-message">
                        {snackbar.severity === 'success' ? (
                            <CheckIcon className="snackbar-icon success" />
                        ) : (
                            <ErrorIcon className="snackbar-icon error" />
                        )}
                        {snackbar.message}
                    </div>
                </div>
            </Snackbar>
        </>
    );
};

export default AddPlaylist;