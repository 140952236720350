import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import VideoMetaDataForm from './VideoMetaDataForm';
import FileUploadForm from './FileUploadForm';
import Review from './Review';
import VideoDelete from './VideoDelete';
import { FileUploadStatus } from './FileUploadStatus';

import './VideoEditForm.scss';

import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        乒乓球
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const steps = ['视频资料', '確認信息', '分析數據', '其他選項'];

export const VideoEditForm = ({
  matchData,
  setMatchData,
  userInfo,
  token,
  defaultStep,
}) => {
  const [activeStep, setActiveStep] = useState(defaultStep ? defaultStep : 0);
  const [reportFile, setReportFile] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [playerList, setPlayerList] = useState([]);
  const [CustomTagList, setCustomTagList] = useState([]);

  // Function to handle the button click
  const handleButtonClick = () => {
    if (activeStep === steps.length - 1) {
      // Show the popup warning
      setOpenPopup(true);
    } else {
      // Proceed to the next step
      setActiveStep(parseInt(activeStep) + 1);
    }
  };

  const handleCoachButtonClick = () => {
    setOpenPopup(true);
  };

  // Function to handle confirming and uploading (triggered by the popup)
  const handleConfirmUpload = () => {
    setOpenPopup(false);
    setActiveStep(steps.length);
  };

  useEffect(() => {
    const fetchPlayerList = async () => {
      try {
        const response = await axios.get(`/api/playersList`, {
          params: {
            accountType: userInfo.accountType,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;

        responseData.sort((a, b) => {
          const playerA = a[0].toLowerCase();
          const playerB = b[0].toLowerCase();

          if (playerA < playerB) {
            return -1;
          }
          if (playerA > playerB) {
            return 1;
          }
          return 0;
        });
        setPlayerList(responseData);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCustomTagList = async () => {
      try {
        const response = await axios.get('/api/customTagList', {
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;
        setCustomTagList(responseData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlayerList();
    fetchCustomTagList();
  }, []);

  const getStepContent = (step) => {
    switch (parseInt(step)) {
      case 0:
        return (
          <VideoMetaDataForm
            setMatchData={setMatchData}
            matchData={matchData}
            playerList={playerList}
            CustomTagList={CustomTagList}
          />
        );
      case 1:
        return <Review matchData={matchData} />;
      case 2:
        return (
          <FileUploadForm
            setMatchData={setMatchData}
            matchData={matchData}
            setReportFile={setReportFile}
          />
        );
      case 3:
        return <VideoDelete matchData={matchData} token={token} />;
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <div className="main-file-upload">
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="absolute"
          color="default"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        ></AppBar>
        <Container
          className="file-upload-container"
          component="main"
          maxWidth="sm"
          sx={{ mb: 4 }}
        >
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography className="edit-form-title" align="center">
              視頻編輯
            </Typography>
            <Stepper
              className="video-form-steps"
              activeStep={activeStep}
              sx={{ pt: 1, pb: 3 }}
            >
              {steps.map((label, key) => (
                <Step key={label} onClick={() => setActiveStep(key)}>
                  <StepLabel>{label}</StepLabel>
                  <span class="tooltiptext">Press to skip</span>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <FileUploadStatus
                matchData={matchData}
                reportFile={reportFile}
                token={token}
              />
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box
                  sx={
                    activeStep === 0
                      ? { display: 'flex', justifyContent: 'flex-end' }
                      : { display: 'flex', justifyContent: 'space-between' }
                  }
                >
                  {activeStep != 0 && (
                    <Button
                      onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      返回
                    </Button>
                  )}
                  {activeStep === 2 || activeStep === 1 ? (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => handleCoachButtonClick()}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        確認并上傳
                      </Button>
                    </>
                  ) : null}
                  {activeStep !== steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={() => handleButtonClick()}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      下一步
                    </Button>
                  )}
                  <Dialog
                    className="edit-video-dialog"
                    open={openPopup}
                    onClose={() => setOpenPopup(false)}
                  >
                    <DialogTitle className="edit-video-dialog-title">
                      確認
                    </DialogTitle>
                    <DialogContent className="edit-video-dialog-content">
                      {activeStep === 1 ? (
                        <p>確認上傳?</p>
                      ) : (
                        <p>
                          請確保在上傳「球數據文件(OCR)」或「Drop Point Raw
                          Data」之前已選擇了球員。
                        </p>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenPopup(false)}>取消</Button>
                      <Button onClick={() => handleConfirmUpload()}>
                        上傳(已選擇球員)
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </React.Fragment>
            )}
          </Paper>
          <Copyright />
        </Container>
      </React.Fragment>
    </div>
  );
};
