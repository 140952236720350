import Typography from '@mui/material/Typography';
import './Review.scss';

export default function Review({ matchData }) {
  // Group definitions
  const groups = {
    videoInfo: {
      title: '視頻信息',
      fields: ['videoName', 'videoDescription', 'fileUrl'],
    },
    matchInfo: {
      title: '比賽信息',
      fields: [
        'matchEvent',
        'matchParentEvent',
        'matchCategory',
        'stage',
        'matchDate',
        'location',
      ],
    },
    playerInfo: {
      title: '球員信息',
      fields: ['homePlayer', 'awayPlayer', 'startingPlayer', 'topRightPlayer'],
    },
    customInfo: {
      title: '其他信息',
      fields: ['customTag'],
    },
  };

  const translationDict = {
    videoName: '视频名称',
    videoDescription: '比赛详情',
    stage: '阶段',
    matchDate: '比赛日期',
    location: '比赛地点',
    homePlayer: '主場球員',
    awayPlayer: '客場球員',
    fileUrl: '视频檔案',
    matchEvent: '乒乓球賽事',
    matchParentEvent: '主辦機構',
    matchCategory: '比賽類型',
    customTag: '自訂標籤',
    startingPlayer: '發球員',
    topRightPlayer: '上/右的球員',
  };

  return (
    <div className="review-wrapper">
      <Typography className="review-title" variant="h6">
        確認數據
      </Typography>

      <div className="review-grid">
        {Object.entries(groups).map(([groupKey, group]) => (
          <div key={groupKey} className="review-group" data-group={groupKey}>
            <div className="group-header">
              <Typography variant="subtitle1">{group.title}</Typography>
            </div>
            <div className="group-content">
              {group.fields.map(
                (field) =>
                  matchData[field] && (
                    <div key={field} className="review-row">
                      <div className="row-label">{translationDict[field]}</div>
                      <div className="row-value">
                        {matchData[field].toString()}
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
