import { useState, useEffect, useCallback } from 'react';
import { Box, Paper, Button, Typography } from '@mui/material';
import VideoFileUploadForm from './VideoFileUploadForm';
import { UploadStatus } from './UploadStatus';
import { fetchPlaylistDataAPI } from 'src/api/playlistService';
import { getUserUploadStatusAPI } from 'src/api/vqService';
import { fetchUserListAPI } from 'src/api/userService';
import Padding from 'src/components/padding';

import axios from 'axios';
import Cookies from 'js-cookie';

import './VideoUploadForm.scss';

const steps = ['选择视频'];

export const VideoUploadForm = ({ userInfo }) => {
  const token = Cookies.get('token');
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [matchData, setMatchData] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState({});
  const [playerList, setPlayerList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchPlaylistDataAPI(
          { accountType: userInfo.accountType, user_name: userInfo.identifier },
          token,
        );
        setPlaylists(responseData);
      } catch (error) {
        console.error('Failed to fetch playlist data:', error);
      }
      try {
        const responseData = await getUserUploadStatusAPI(
          { username: userInfo.identifier },
          token,
        );
        // for match_id in match_ids get the ind match data then if user upload file_url is same, auto complete
        setUploaded(responseData);
      } catch (error) {
        console.error('Failed to fetch upload data:', error);
      }
    };

    const fetchPlayerList = async () => {
      try {
        const response = await axios.get(`/api/playersList`, {
          params: {
            accountType: userInfo.accountType,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;

        responseData.sort((a, b) => {
          const playerA = a[0].toLowerCase();
          const playerB = b[0].toLowerCase();

          if (playerA < playerB) {
            return -1;
          }
          if (playerA > playerB) {
            return 1;
          }
          return 0;
        });
        setPlayerList(responseData);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUserList = async () => {
      try {
        const response = await fetchUserListAPI(token);

        let category;
        switch (userInfo.accountType) {
          case 'm':
            category = 'intellectual';
            break;
          case 'p':
            category = 'physical';
            break;
          default:
            category = 'able';
        }

        const filteredUsers = response
          .filter((user) => user.category === category)
          .map((user) => ({
            id: user.user_id,
            label: user.username,
          }));
        setUserOptions(filteredUsers);
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };

    fetchData();
    fetchPlayerList();
    fetchUserList();
  }, [userInfo.accountType, token]);

  const handlePlaylistSelect = useCallback(
    (matchIndex, selectedPlaylistIds) => {
      setSelectedPlaylists((prev) => ({
        ...prev,
        [matchIndex]: selectedPlaylistIds,
      }));
    },
    [],
  );

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <div className="main-upload">
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, mx: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h2" variant="h4" align="center">
          視頻上傳
        </Typography>
        {activeStep === steps.length ? (
          <UploadStatus
            userInfo={userInfo}
            files={files}
            matchData={matchData}
            selectedPlaylists={selectedPlaylists}
          />
        ) : (
          <>
            <VideoFileUploadForm
              setFiles={setFiles}
              files={files}
              matchData={matchData}
              setMatchData={setMatchData}
              playlists={playlists}
              selectedPlaylists={selectedPlaylists}
              handlePlaylistSelect={handlePlaylistSelect}
              uploaded={uploaded}
              playerList={playerList}
              userOptions={userOptions}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  返回
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 1 ? '確認并上傳' : '下一步'}
              </Button>
            </Box>
          </>
        )}
        <Padding />
      </Paper>
    </div>
  );
};
