// shared/GameDetailsSection.tsx
import React from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GameClickInfo } from "../types/graph.types";

interface GameDetailsSectionProps {
  gameDetails: GameClickInfo[];
  isLoading: boolean;
  onViewDetails: (game: GameClickInfo) => void;
}

export const GameDetailsSection = ({
  gameDetails,
  isLoading,
  onViewDetails,
}: GameDetailsSectionProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", py: 2 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <>
      {gameDetails.map((game) => (
        <Box
          key={game.gameId}
          sx={{
            mb: 2,
            p: 2,
            bgcolor: "background.default",
            borderRadius: 1,
            border: 1,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {game.metadata.eventName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {game.metadata.date} - {game.metadata.round}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Typography>
              {game.metadata.team1} vs {game.metadata.team2}
            </Typography>
            <Typography color="text.secondary">
              Score: {game.metadata.finalscore1} - {game.metadata.finalscore2}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onViewDetails(game)}
            sx={{ mt: 1 }}
          >
            {t("selectionDrawer.viewDetails")}
          </Button>
        </Box>
      ))}
    </>
  );
};
