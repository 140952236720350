import { useState, useCallback } from 'react';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText,
  Switch,
  FormControlLabel,
  Typography,
  InputLabel,
  OutlinedInput,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { countryCodeMapping } from 'src/constants';
import * as Flags from 'country-flag-icons/react/3x2';

import './VideoUploadOptions.scss';

const CATEGORIES = {
  MSingles: '男單打',
  MDouble: '男雙打',
  FSingle: '女單打',
  FDouble: '女雙打',
  MixedDouble: '混合雙打',
};

const STAGES = [
  'Final',
  'Semi-Final',
  'Quarter-Final',
  'R16',
  'R32',
  'R64',
  'R128',
];

const EVENTS = [
  { subEvent: 'Contender', parentEvent: 'WTT' },
  { subEvent: 'Star Contender', parentEvent: 'WTT' },
  { subEvent: 'Champions', parentEvent: 'WTT' },
  { subEvent: 'Cup Finals', parentEvent: 'WTT' },
  { subEvent: 'Grand Smash', parentEvent: 'WTT' },
  { subEvent: 'Others(WTT)', parentEvent: 'WTT' },
  { subEvent: 'National Games', parentEvent: 'Games' },
  { subEvent: 'Asian Games', parentEvent: 'Games' },
  { subEvent: 'Olympic Games', parentEvent: 'Games' },
  { subEvent: 'Universiade', parentEvent: 'Games' },
  { subEvent: 'Asian Para Games', parentEvent: 'Games' },
  { subEvent: 'Paralympic Games', parentEvent: 'Games' },
  { subEvent: 'World Abilitysport Games', parentEvent: 'Games' },
  { subEvent: 'Virtus Global Games', parentEvent: 'Games' },
  { subEvent: 'Others(Games)', parentEvent: 'Games' },
  { subEvent: 'ITTF World Championships', parentEvent: 'ITTF' },
  { subEvent: 'ITTF Mixed Team World Cup', parentEvent: 'ITTF' },
  { subEvent: 'ITTF World Youth Championships', parentEvent: 'ITTF' },
  {
    subEvent: 'Asia Continental Stage of 2023 WTTC Finals',
    parentEvent: 'ITTF',
  },
  { subEvent: 'ITTF Para Open', parentEvent: 'ITTF' },
  { subEvent: 'championships', parentEvent: 'ITTF' },
  { subEvent: 'Others(ITTF)', parentEvent: 'ITTF' },
  { subEvent: 'Others', parentEvent: 'Others' },
];

const translationDict = {
  Final: '決賽',
  'Semi-Final': '半決賽',
  'Quarter-Final': '4強',
  R16: '16強',
  R32: '32強',
  R64: '64強',
  R128: '128強',
  MSingles: '男單打',
  MDouble: '男雙打',
  FSingle: '女單打',
  FDouble: '女雙打',
  MixedDouble: '混合雙打',
};

const VideoUploadOptions = ({
  matchData,
  file,
  index,
  handleRemoveFile,
  handleModifyMatchData,
  playlists,
  selectedPlaylists,
  handlePlaylistSelect,
  playerList,
  userOptions,
}) => {
  const [isManualMode, setIsManualMode] = useState(false);

  const handlePlaylistCheckboxChange = (playlistId) => {
    const currentSelectedPlaylists = selectedPlaylists[index] || [];
    const isSelected = currentSelectedPlaylists.includes(playlistId);

    let updatedPlaylists;
    if (isSelected) {
      updatedPlaylists = currentSelectedPlaylists.filter(
        (id) => id !== playlistId,
      );
    } else {
      updatedPlaylists = [...currentSelectedPlaylists, playlistId];
    }

    handlePlaylistSelect(index, updatedPlaylists);
  };

  const generateVideoName = useCallback((data) => {
    const priorityFields = [
      {
        field: 'HomePlayer',
        condition: (val) => val !== '_',
        format: (val) => val,
      },
      {
        field: 'AwayPlayer',
        condition: (val) => val !== '_',
        format: (val) => `vs ${val}`,
      },
      { field: 'stage', condition: (val) => val !== '_', format: (val) => val },
      {
        field: 'location',
        condition: (val) => val !== '_',
        format: (val) => val,
      },
      { field: 'matchEvent', condition: (val) => val, format: (val) => val },
      {
        field: 'matchParentEvent',
        condition: (val) => val,
        format: (val) => `(${val})`,
      },
      {
        field: 'matchDate',
        condition: (val) => val !== '2022-03-14',
        format: (val) => val,
      },
      {
        field: 'matchCategory',
        condition: (val) => val,
        format: (val) => CATEGORIES[val] || val,
      },
    ];

    const parts = priorityFields
      .filter(({ field, condition }) => condition(data[field]))
      .map(({ field, format }) => format(data[field]))
      .slice(0, 5);

    return parts.length > 0 ? parts.join(' - ') : 'MissingData';
  }, []);

  const handleInputChange = useCallback(
    (field, value) => {
      handleModifyMatchData(index, field, value);

      if (field !== 'videoName' && !isManualMode) {
        const updatedData = { ...matchData, [field]: value };
        const newVideoName = generateVideoName(updatedData);
        handleModifyMatchData(index, 'videoName', newVideoName);
      }
    },
    [handleModifyMatchData, matchData, isManualMode, generateVideoName, index],
  );

  const handleEventInputChange = useCallback(
    (_, value) => {
      if (value) {
        handleModifyMatchData(index, 'matchEvent', value.subEvent);
        handleModifyMatchData(index, 'matchParentEvent', value.parentEvent);
      }
    },
    [handleModifyMatchData, index],
  );

  const handlePlayerChange = useCallback(
    (field, newValue) => {
      const playerName = newValue ? newValue[0] : '_';
      handleInputChange(field, playerName);
    },
    [handleInputChange],
  );

  const handlePermittedUserChange = useCallback(
    (_, newValue) => {
      handleModifyMatchData(index, 'permittedUser', newValue);
    },
    [handleModifyMatchData, index],
  );

  return (
    <div
      className={`video-upload-container ${matchData.isPriority ? 'priority' : ''}`}
    >
      <div className="video-upload-header">
        <Typography variant="h6" className="file-name">
          {file.name}
        </Typography>
        <div className="header-actions">
          <FormControlLabel
            control={
              <Switch
                checked={matchData.isPriority || false}
                onChange={(e) =>
                  handleInputChange('isPriority', e.target.checked)
                }
                className={`priority-switch ${matchData.isPriority ? 'priority-active' : ''}`}
              />
            }
            label="優先處理"
          />
          <Button
            onClick={() => handleRemoveFile(index)}
            className="remove-button"
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
      <div className="video-upload-content">
        <div className="video-name-section">
          <TextField
            className="video-name-input"
            value={
              matchData.videoName === 'MissingData' ? '' : matchData.videoName
            }
            onChange={(e) =>
              handleInputChange('videoName', e.target.value || file.name)
            }
            label="影片名"
            disabled={!isManualMode}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isManualMode}
                onChange={(e) => setIsManualMode(e.target.checked)}
              />
            }
            label="手動輸入"
            className="manual-mode-switch"
          />
        </div>

        <div className="form-section">
          <div className="player-section">
            <Autocomplete
              className="player-input"
              options={playerList || []}
              getOptionLabel={(option) => option[0]}
              value={
                playerList?.find(
                  (player) => player[0] === matchData.HomePlayer,
                ) || null
              }
              onChange={(_, newValue) =>
                handlePlayerChange('HomePlayer', newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="主場選手"
                  variant="outlined"
                  fullWidth
                />
              )}
              isOptionEqualToValue={(option, value) => option[0] === value[0]}
              renderOption={(props, option) => {
                const countryCode = countryCodeMapping[option[1]] || '';
                const Flag = Flags[countryCode];

                return (
                  <li {...props} className="edit-video-home-player-select">
                    {Flag && <Flag className="player-smallflag" />}
                    {option[0]}
                  </li>
                );
              }}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            />

            <Autocomplete
              className="player-input"
              options={playerList || []}
              getOptionLabel={(option) => option[0]}
              value={
                playerList?.find(
                  (player) => player[0] === matchData.AwayPlayer,
                ) || null
              }
              onChange={(_, newValue) =>
                handlePlayerChange('AwayPlayer', newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="客場選手"
                  variant="outlined"
                  fullWidth
                />
              )}
              isOptionEqualToValue={(option, value) => option[0] === value[0]}
              renderOption={(props, option) => {
                const countryCode = countryCodeMapping[option[1]] || '';
                const Flag = Flags[countryCode];

                return (
                  <li {...props} className="edit-video-away-player-select">
                    {Flag && <Flag className="player-smallflag" />}
                    {option[0]}
                  </li>
                );
              }}
              sx={{ color: 'black', paddingRight: '1vw', width: '100%' }}
            />
          </div>

          <div className="match-details-section">
            <FormControl className="form-control">
              <InputLabel>賽事階段</InputLabel>
              <Select
                value={matchData.stage}
                onChange={(e) => handleInputChange('stage', e.target.value)}
                input={<OutlinedInput label="賽事階段" />}
              >
                {STAGES.map((stage) => (
                  <MenuItem key={stage} value={stage}>
                    {translationDict[stage]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className="form-control"
              label="比賽日期"
              type="date"
              value={matchData.matchDate || '2022-03-14'}
              onChange={(e) => handleInputChange('matchDate', e.target.value)}
            />

            <TextField
              className="form-control"
              label="地點"
              value={matchData.location === '_' ? '' : matchData.location}
              onChange={(e) =>
                handleInputChange('location', e.target.value || '_')
              }
            />

            <Autocomplete
              className="form-control"
              options={EVENTS}
              groupBy={(option) => option.parentEvent}
              getOptionLabel={(option) => option.subEvent}
              onChange={handleEventInputChange}
              renderInput={(params) => (
                <TextField {...params} label="乒乓球賽事" />
              )}
            />
            <div className="permitted-users-section">
              <Autocomplete
                multiple
                className="form-control"
                options={userOptions || []}
                value={matchData.permittedUser || []}
                onChange={handlePermittedUserChange}
                renderInput={(params) => (
                  <TextField {...params} label="授權用戶" variant="outlined" />
                )}
              />
              {matchData.permittedUser?.length > 0 && (
                <span className="privacy-warning">
                  如為私人影片將不會進行分析
                </span>
              )}
            </div>
          </div>

          <FormControl className="playlist-section">
            <InputLabel>播放清單</InputLabel>
            <Select
              multiple
              value={selectedPlaylists[index] || []}
              input={<OutlinedInput label="播放清單" />}
            >
              {Array.isArray(playlists) &&
                playlists[0].map((playlist) => (
                  <MenuItem
                    key={playlist.playlist_id}
                    value={playlist.playlist_id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      py: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        gap: '8px',
                      }}
                    >
                      <Checkbox
                        checked={(selectedPlaylists[index] || []).includes(
                          playlist.playlist_id,
                        )}
                        onChange={() =>
                          handlePlaylistCheckboxChange(playlist.playlist_id)
                        }
                      />
                      <div style={{ flex: 1, cursor: 'default' }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {playlist.playlist_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            mt: 0.5,
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            lineHeight: 1.4,
                          }}
                        >
                          {playlist.description || '無描述'}
                        </Typography>
                      </div>
                    </div>
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>播放清單</FormHelperText>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default VideoUploadOptions;
