import React, { useState, useRef, useEffect } from 'react';
import { updateDPDataAPI, updateBallDataAPI } from 'src/api/videoService';
import { UpdateDPDataParams, UpdateBallDataParams } from 'types/videoTypes';
import { TimedAlert } from 'src/components/alert/alert';
import { formatTime } from 'src/utils/utils';
import InputPanel from '../InputPanel/InputPanel';
import Cookies from 'js-cookie';
import './EditDpPanel.scss';

interface Position {
  x: string;
  y: string;
  time: number; // Changed from Float to number
}

interface BallData {
  ball_id: number;
  round_id: number;
  start_time: number;
  end_time: number;
  home_player_score: number;
  away_player_score: number;
  highlight: boolean;
  critical_point: boolean;
  deuce: boolean;
}

interface EditDpPanelProps {
  currentRound: number;
  currentBall: number;
  filename: string;
  setVideoData: React.Dispatch<React.SetStateAction<any>>;
  ballData: any[];
  videoRef: React.RefObject<HTMLVideoElement>;
  dpResults: any[];
}

const EditDpPanel: React.FC<EditDpPanelProps> = ({
  currentRound,
  currentBall,
  filename,
  setVideoData,
  ballData,
  videoRef,
  dpResults,
}) => {
  const token = Cookies.get('token') as string;

  const [clickPositions, setClickPositions] = useState<Position[]>([]);
  const [newBallData, setNewBallData] = useState<BallData>({
    ball_id: -1,
    round_id: -1,
    start_time: 0,
    end_time: 0,
    home_player_score: 0,
    away_player_score: 0,
    highlight: false,
    critical_point: false,
    deuce: false,
  });
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dpResults && dpResults.length > 0) {
      const points = dpResults[1];
      if (Array.isArray(points)) {
        const initialPositions: Position[] = points.map(([x, y, time]) => ({
          x: `${x * 100}%`,
          y: `${y * 100}%`,
          time: time,
        }));
        setClickPositions(initialPositions);
      }
    } else {
      setClickPositions([]);
    }
  }, [dpResults]);

  useEffect(() => {
    if (ballData && ballData.length > 6) {
      const formattedBallData: BallData = {
        ball_id: ballData[0],
        round_id: ballData[1],
        start_time: ballData[2],
        end_time: ballData[3],
        home_player_score: ballData[4],
        away_player_score: ballData[5],
        highlight: false,
        critical_point: false,
        deuce: false,
      };
      setNewBallData(formattedBallData);
    }
  }, [ballData]);

  const handleChartClick = (indexToDelete: number) => {
    setClickPositions((prevPositions) =>
      prevPositions.filter((_, idx) => idx !== indexToDelete),
    );
  };

  const handleClearAll = () => {
    setClickPositions([]);
  };

  const handleSave = async () => {
    const convertedArray: [number, number, number][] = clickPositions.map(
      (pos) => [
        Math.round((parseFloat(pos.x) / 100) * 800),
        Math.round((parseFloat(pos.y) / 100) * 1280),
        pos.time,
      ],
    );

    const ballParams: UpdateBallDataParams = {
      filename: filename,
      match_id: null,
      primary_attribute: `video edited  : ${filename}`,
      secondary_attribute: `add ball to round id : ${newBallData?.ball_id} ${newBallData?.start_time}s to ${newBallData?.end_time}s`,
      new_row: newBallData,
    };

    try {
      const dpData: UpdateDPDataParams = {
        filename: filename,
        new_dp: {
          round_num: currentRound,
          ball_num: currentBall,
          droppoints: convertedArray,
        },
      };


      const dpResponse = await updateDPDataAPI(dpData, token);
      const ballResponse = await updateBallDataAPI(ballParams, token);

      setAlertMessage('Data saved successfully!');

      setVideoData((prevState: any) => ({
        ...prevState,
        refresh: true,
      }));
    } catch (error) {
      console.error('Error saving DP data:', error);
      setAlertMessage('Error saving data. Please try again.');
    }
  };

  const handleAddClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (chartRef.current && videoRef.current) {
      const chartRect = chartRef.current.getBoundingClientRect();
      const xPos = `${((event.clientX - chartRect.left) / chartRect.width) * 100
        }%`;
      const yPos = `${((event.clientY - chartRect.top) / chartRect.height) * 100
        }%`;
      const currentTime = videoRef.current.currentTime;

      setClickPositions((prevPositions) => [
        ...prevPositions,
        { x: xPos, y: yPos, time: currentTime },
      ]);
    }
  };

  return (
    <div className="edit-dp-container">
      <div className="input-dp-container">
        <span className="table-label top-right">上/右球員</span>
        <div
          className="table-tennis-table"
          ref={chartRef}
          onClick={handleAddClick}
        >
          <div className="middle-line"></div>
          {[...Array(4)].map((_, rowIndex) => (
            <div key={rowIndex} className="table-row">
              {[...Array(3)].map((__, cellIndex) => (
                <div key={cellIndex} className="table-cell"></div>
              ))}
            </div>
          ))}
          {clickPositions.map((position, index) => (
            <div
              key={index}
              className="position-marker"
              style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="dot" style={{ color: 'red' }}>
                •
              </div>
              <div className="index-label" style={{ color: 'black' }}>
                {index + 1}
                <span
                  className="delete-marker"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleChartClick(index);
                  }}
                >
                  &times;
                </span>
              </div>
              {hoveredIndex === index && (
                <div className="time-tooltip">{formatTime(position.time)}</div>
              )}
            </div>
          ))}
        </div>
        <span className="table-label bottom-left">下/左球員</span>
      </div>
      <div className="edit-dp-options-container">
        <div className="edit-dp-options-panel">
          <span style={{ textAlign: 'center' }}>分數/時間</span>
          <InputPanel
            ballData={newBallData}
            videoRef={videoRef}
            setNewBallData={setNewBallData}
          />
        </div>
        <button className="clear-button" onClick={handleClearAll}>
          清除所有落點
        </button>
        <button className="save-button" onClick={handleSave}>
          儲存
        </button>
        {alertMessage && (
          <TimedAlert
            message={alertMessage}
            onClose={() => setAlertMessage(null)}
          />
        )}
      </div>
    </div>
  );
};

export default EditDpPanel;
