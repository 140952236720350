import { JSX } from 'react';
import Title from './Title';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { fetchMatchDataAPI } from 'src/api/videoService';

import Cookies from 'js-cookie';

import './RecentUpload.scss';
import { matchIdIndex } from 'src/constants';

export default function RecentUpload({
  latestUpload,
}: {
  latestUpload: Record<string, any>;
}): JSX.Element {
  const token = Cookies.get('token') || '';
  const navigate = useNavigate();

  const handleViewVideo = async (): Promise<void> => {
    const response = await fetchMatchDataAPI(
      {
        accountType: 'all',
        admin: 'true',
        file_name: latestUpload['primary_attribute'].split(': ')[1],
      },
      token,
    );
    const matchData = response.videoData[0];
    const matchId = matchData[matchIdIndex];
    navigate(`/video-player/${matchId}`);
  };

  return (
    <div className="recent-upload">
      <Title>Recent Upload</Title>
      <Typography component="p" variant="h5">
        {latestUpload &&
          latestUpload['primary_attribute'] &&
          latestUpload['primary_attribute'].split(':')[1].substring(0, 17)}
      </Typography>
      <Typography className="upload-user">
        {latestUpload && latestUpload['activity_user']}(
        {latestUpload && latestUpload['account_type']})
      </Typography>
      <Typography color="text.secondary">
        {latestUpload && latestUpload['activity_date'].substring(0, 17)}
      </Typography>
      <Typography color="text.secondary">
        Location : {latestUpload && latestUpload['activity_location']}
      </Typography>
      <div>
        <button onClick={() => handleViewVideo()}> View video </button>
      </div>
    </div>
  );
}
