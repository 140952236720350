import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import Flag from 'react-world-flags';
import { countryToCodeMapping } from 'src/constants';

import './PlayerHomeFilter.scss';

import { countries } from 'src/constants';
import { interestedCountries } from 'src/constants';

const CountryFlag = ({ country }) => {
  const code = countryToCodeMapping[country];

  if (code === 'TPE') {
    return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
        className="player-smallflag"
        alt="Taiwan Flag"
      />
    );
  }

  if (code) {
    return <Flag code={code} className="player-smallflag" />;
  }

  return null;
};

const PlayerHomeFilterOption = ({
  setFilterCriteria,
  appliedFilterCriteria,
}) => {
  const [selectedCountries, setSelectedCountries] = useState(() => {
    return appliedFilterCriteria ? appliedFilterCriteria.country : countries;
  });

  const sortedCountries = [...countries].sort((a, b) => {
    const aInterested = interestedCountries.includes(a);
    const bInterested = interestedCountries.includes(b);

    if (aInterested && !bInterested) return -1;
    if (!aInterested && bInterested) return 1;
    return a.localeCompare(b);
  });

  const handleApplyOptions = () => {
    const updatedFilterCriteria = {
      ...appliedFilterCriteria,
      country: selectedCountries,
    };
    setFilterCriteria(updatedFilterCriteria);
  };

  const handleSetHongKong = () => {
    setSelectedCountries(['Hong Kong, China']);
  };

  useEffect(() => {
    setSelectedCountries(appliedFilterCriteria['country']);
  }, [appliedFilterCriteria]);

  return (
    <div className="filter-player-home-panel">
      <Popup
        trigger={
          <button className="popup-button">
            <FilterListIcon />
          </button>
        }
        modal
        closeOnDocumentClick={false}
      >
        {(close) => (
          <Paper className="player-home-filter-popup-main-content">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
              }}
            >
              <Typography variant="h6">選擇國家/地區</Typography>
              <button
                className="close-button"
                onClick={() => {
                  close();
                }}
              >
                <CloseIcon />
              </button>
            </Box>

            <FormControl fullWidth sx={{ px: 2 }}>
              <Autocomplete
                multiple
                limitTags={5}
                options={sortedCountries}
                value={selectedCountries}
                onChange={(event, newValue) => {
                  setSelectedCountries(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="國家"
                    placeholder="請選擇國家/地區"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props} className="country-option">
                    <CountryFlag country={option} />
                    {interestedCountries.includes(option) ? (
                      <Typography
                        component="span"
                        sx={{
                          ml: 1,
                          color: 'primary.main',
                        }}
                      >
                        {option}
                      </Typography>
                    ) : (
                      <span>{option}</span>
                    )}
                  </Box>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    return (
                      <Chip
                        {...tagProps}
                        key={option}
                        className="country-chip"
                        label={
                          <span className="chip-content">
                            <CountryFlag country={option} />
                            <span className="country-name">{option}</span>
                          </span>
                        }
                      />
                    );
                  })
                }
                ListboxProps={{
                  style: {
                    maxHeight: '250px',
                  },
                }}
              />
              <Box className="quick-select-buttons" sx={{ mt: 2 }}>
                <button
                  className="select-all-button"
                  onClick={() => setSelectedCountries([...countries])}
                >
                  全選
                </button>
                <button
                  className="hong-kong-button"
                  onClick={handleSetHongKong}
                >
                  <CountryFlag country="Hong Kong" />
                  <span>只選香港</span>
                </button>
                <button
                  className="clear-all-button"
                  onClick={() => setSelectedCountries([])}
                >
                  清除全部
                </button>
              </Box>
            </FormControl>

            <button
              className="apply-filter"
              onClick={() => {
                handleApplyOptions();
                close();
              }}
            >
              確認
            </button>
          </Paper>
        )}
      </Popup>
    </div>
  );
};

export default PlayerHomeFilterOption;
