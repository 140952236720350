// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-container {
  background-color: #f5f5f5;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}
.users-container .content-wrapper {
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/userPages/AdminPage/Users/Users.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;AACJ;AACI;EACE,aAAA;AACN","sourcesContent":[".users-container {\n    background-color: #f5f5f5;\n    flex-grow: 1;\n    height: 100vh;\n    overflow: auto;\n  \n    .content-wrapper {\n      padding: 1rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
