import { FC, useState, useEffect } from "react";
import {
  SigmaContainer,
  ControlsContainer,
  ZoomControl,
  FullScreenControl,
  SearchControl,
} from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import {
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineFullscreenExit,
  AiOutlineFullscreen,
} from "react-icons/ai";
import { MdFilterCenterFocus } from "react-icons/md";
import {
  Box,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import { RestartAlt } from "@mui/icons-material";

import LayoutsControl from "../utils/LayoutsControl";
import { SIGMA_SETTINGS } from "../constants/graph.constants";
import { MyGraphFromCombinedData } from "./GraphDataLoader";
import { LayoutName, Selection_, SignalGraphProps } from "../types/graph.types";
import SelectionEdgeDrawer from "../utils/SelectionEdgeDrawer";
import SelectionPaper from "../utils/SelectionPaper";

export const SignalGraph: FC<SignalGraphProps> = ({
  playerData,
  connectionsData,
  sx = { height: "100vh" },
  hideSearchBar = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCommonOpponents, setShowCommonOpponents] = useState(false);


  const ResetButton = () => (
    <Box
      sx={{
        backgroundColor: "background.paper",
        borderRadius: 1,
        boxShadow: 1,
        color: "text.primary",
        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
    >
      <Tooltip title="Reset View">
        <IconButton
          onClick={handleReset}
          size="small"
          sx={{
            width: 40,
            height: 40,
            color: "inherit",
          }}
        >
          <RestartAlt />
        </IconButton>
      </Tooltip>
    </Box>
  );

  // Then in SignalGraph, update the state initialization:
  const [selection, setSelection] = useState<Selection_>({
    selectedPlayer: null,
    selectedOpponent: null,
    selectedCommonOpponent: null,
    connectedPlayers: [],
    selectedGameInfo: null,
  });

  // Reset state when data changes
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    setSelection({
      selectedPlayer: null,
      connectedPlayers: [],
      selectedOpponent: null,
      selectedCommonOpponent: null,
    });
    setShowCommonOpponents(false);
  }, [playerData, connectionsData]);

  const [shouldReset, setShouldReset] = useState(false);
  const [currentLayout, setCurrentLayout] =
    useState<LayoutName>("twoLayerCircle");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // And in the reset:
  const handleReset = () => {
    setShouldReset(true);
    setSelection({
      selectedPlayer: null,
      selectedOpponent: null,
      selectedCommonOpponent: null,
      connectedPlayers: [],
      selectedGameInfo: null,
    });
    setTimeout(() => setShouldReset(false), 100);
    setShowCommonOpponents(false);
  };

  // In the useEffect:
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    setSelection({
      selectedPlayer: null,
      selectedOpponent: null,
      selectedCommonOpponent: null,
      connectedPlayers: [],
      selectedGameInfo: null,
    });
    setShowCommonOpponents(false);
  }, [playerData, connectionsData]);

  // In SignalGraph component
  useEffect(() => {
    if (selection.selectedGameInfo) {
      // Only open for game info
      setIsDrawerOpen(true);
    }
  }, [selection.selectedGameInfo]);

  const handleCloseInfo = () => {
    setIsDrawerOpen(false);
    if (selection.selectedGameInfo) {
      // Only clear game info and preserve all player selections
      setSelection((prev) => ({
        selectedPlayer: prev.selectedPlayer, // Keep player
        selectedOpponent: prev.selectedOpponent, // Keep opponent
        selectedCommonOpponent: prev.selectedCommonOpponent, // Keep common opponent
        connectedPlayers: prev.connectedPlayers, // Keep connected players
        selectedGameInfo: null, // Only clear game info
      }));
    }
  };

  return (
    <Box
      sx={{
        ...sx,
        position: "relative",
        width: "100%",
        overflow: "hidden",
        bgcolor: "background.default",
      }}
    >
      <SigmaContainer
        key={key} // Force recreation of SigmaContainer when data changes
        settings={{
          ...SIGMA_SETTINGS,
          labelSize: isMobile ? 12 : 14,
          labelDensity: isMobile ? 0.7 : 1,
          labelGridCellSize: isMobile ? 60 : 100,
          renderLabels: true,
          defaultNodeColor: theme.palette.primary.light,
          defaultEdgeColor: theme.palette.divider,
        }}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: theme.palette.background.paper,
          touchAction: "none",
          zIndex: theme.zIndex.drawer - 1,
        }}
      >
        <MyGraphFromCombinedData
          onSelectionChange={setSelection}
          shouldReset={shouldReset}
          currentLayout={currentLayout}
          isMobile={isMobile}
          showCommonOpponents={showCommonOpponents}
          playerData={playerData}
          connectionsData={connectionsData}
        />

        {/* Reset Button - Mobile Only */}
        {isMobile && (
          <ControlsContainer position={"top-left"}>
            <ResetButton />
          </ControlsContainer>
        )}

        {!hideSearchBar && (
          <ControlsContainer position={"top-right"}>
            <Box>
              <SearchControl
                style={{
                  width: isMobile ? "150px" : "200px",
                  color: theme.palette.text.primary,
                }}
              />
            </Box>
          </ControlsContainer>
        )}

        <ControlsContainer position={"bottom-right"} style={{ bottom: "56px" }}>
          <Box>
            <ZoomControl>
              <AiOutlineZoomIn />
              <AiOutlineZoomOut />
              <MdFilterCenterFocus />
            </ZoomControl>
            <FullScreenControl>
              <AiOutlineFullscreen />
              <AiOutlineFullscreenExit />
            </FullScreenControl>
            <LayoutsControl
              currentLayout={currentLayout}
              onLayoutChange={setCurrentLayout}
            />
          </Box>
        </ControlsContainer>
      </SigmaContainer>

      {/* Selection Drawer */}
      {isMobile && (
        <SelectionEdgeDrawer
          isOpen={isDrawerOpen}
          onClose={handleCloseInfo} // Use handleCloseInfo instead of setIsDrawerOpen(false)
          onOpen={() => setIsDrawerOpen(true)}
          selection={selection}
          playerData={playerData}
          connectionsData={connectionsData}
          showCommonOpponents={showCommonOpponents}
          setShowCommonOpponents={setShowCommonOpponents}
          handleReset={handleReset}
          handleCloseInfo={handleCloseInfo} // Add this new prop
          isMobile={isMobile}
        />
      )}
      {!isMobile && (
        <SelectionPaper
          selection={selection}
          playerData={playerData}
          connectionsData={connectionsData}
          showCommonOpponents={showCommonOpponents}
          setShowCommonOpponents={setShowCommonOpponents}
          handleReset={handleReset}
          handleCloseInfo={handleCloseInfo} // Add this new prop
          isMobile={isMobile}
        />
      )}
    </Box>
  );
};

export default SignalGraph;
