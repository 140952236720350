import { useState, useEffect, useRef, useCallback } from 'react';
import VideoList from './VideoSelectBlock/VideoList';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { Button } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Padding from 'src/components/padding';

const WINDOW_SIZE = 20;
const THRESHOLD = 5; // Load more when within 5 items of edge
const MAX_LOADED_VIDEOS = 60;

function calculateVideoWindow(videoListLength, middleIndex) {
  const WINDOW_SIZE = 20;

  // Handle case where video list is shorter than window size
  if (videoListLength <= WINDOW_SIZE) {
    return {
      start: 0,
      end: videoListLength,
      effectiveMiddle: Math.min(middleIndex, videoListLength - 1),
    };
  }

  // Calculate ideal window where middle index would be centered
  let idealStart = middleIndex - Math.floor(WINDOW_SIZE / 2);
  let idealEnd = idealStart + WINDOW_SIZE;

  // Adjust window if it goes beyond list boundaries
  if (idealStart < 0) {
    return {
      start: 0,
      end: WINDOW_SIZE,
      effectiveMiddle: middleIndex,
    };
  }

  if (idealEnd > videoListLength) {
    return {
      start: videoListLength - WINDOW_SIZE,
      end: videoListLength,
      effectiveMiddle: middleIndex,
    };
  }

  // Return ideal window when it fits within boundaries
  return {
    start: idealStart,
    end: idealEnd,
    effectiveMiddle: middleIndex,
  };
}

function getNearestCenterIndex(containerRef) {
  if (!containerRef.current) {
    console.log('No container ref');
    return null;
  }

  // Get viewport height and center
  const viewportHeight = window.innerHeight;
  const viewportCenter = viewportHeight / 2;

  let nearestItem = null;
  let minDistance = Infinity;

  // Get all video items
  const videoItems = containerRef.current.querySelectorAll('.home-video-item');

  if (videoItems.length === 0) {
    console.log('No video items found');
    return null;
  }

  videoItems.forEach((item) => {
    const rect = item.getBoundingClientRect();
    // Calculate distance from item's center to viewport center
    const itemCenter = rect.top + rect.height / 2;
    const distance = Math.abs(viewportCenter - itemCenter);

    if (distance < minDistance) {
      minDistance = distance;
      nearestItem = item;
    }
  });

  if (!nearestItem) {
    console.log('No nearest item found');
    return null;
  }

  const index = parseInt(nearestItem.dataset.index, 10);
  //   console.log('Found nearest index:', index, 'at distance:', minDistance);
  return index;
}

const PaginatedVideoList = ({
  isLoading,
  videoList,
  playlistName,
  playlists,
  userInfo,
}) => {
  const containerRef = useRef(null);
  const [middleIndex, setMiddleIndex] = useState(10);
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(WINDOW_SIZE);
  const [filteredVideos, setFilteredVideos] = useState([]);

  // Throttle scroll handler
  const scrollTimeout = useRef(null);

  const getFilteredVideos = useCallback(() => {
    if (playlistName === 'All') {
      return videoList.sort((a, b) => (b.top ? 1 : 0) - (a.top ? 1 : 0));
    }
    const currentPlaylist = playlists.find(
      (playlist) => playlist.playlist_name === playlistName,
    );
    if (!currentPlaylist) return [];
    return videoList
      .filter((video) => currentPlaylist.match_order.includes(video.matchId))
      .sort((a, b) => (b.top ? 1 : 0) - (a.top ? 1 : 0));
  }, [videoList, playlistName, playlists]);

  useEffect(() => {
    setFilteredVideos(getFilteredVideos());
  }, [playlistName, playlists, videoList]);

  const updateDisplayedVideos = useCallback(
    (start, end, middle) => {
      const newVideos = filteredVideos.slice(start, end);
      setDisplayedVideos(newVideos);
      setCurrentStartIndex(start);
      setCurrentEndIndex(end);
      setMiddleIndex(middle);
    },
    [filteredVideos],
  );

  const handleScroll = useCallback(
    (event) => {
      //   console.log('Scroll event triggered');
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        const newMiddleIndex = getNearestCenterIndex(containerRef);
        // console.log('New middle index:', newMiddleIndex);
        if (newMiddleIndex === null) return;

        const distanceFromStart = newMiddleIndex - currentStartIndex;
        const distanceFromEnd = currentEndIndex - newMiddleIndex;
        // console.log('Distances:', { distanceFromStart, distanceFromEnd });

        // Load previous chunk
        if (distanceFromStart <= THRESHOLD && currentStartIndex > 0) {
          //   console.log('Loading previous chunk');
          const newStart = Math.max(0, currentStartIndex - WINDOW_SIZE);
          const newEnd = currentEndIndex;
          const adjustedEnd =
            newEnd - newStart > MAX_LOADED_VIDEOS
              ? newStart + MAX_LOADED_VIDEOS
              : newEnd;
          updateDisplayedVideos(newStart, adjustedEnd, newMiddleIndex);
        }

        // Load next chunk
        if (
          distanceFromEnd <= THRESHOLD &&
          currentEndIndex < filteredVideos.length
        ) {
          //   console.log('Loading next chunk');
          const newEnd = Math.min(
            filteredVideos.length,
            currentEndIndex + WINDOW_SIZE,
          );
          const newStart = currentStartIndex;
          const adjustedStart =
            newEnd - newStart > MAX_LOADED_VIDEOS
              ? newEnd - MAX_LOADED_VIDEOS
              : newStart;
          updateDisplayedVideos(adjustedStart, newEnd, newMiddleIndex);
        }

        setMiddleIndex(newMiddleIndex);
      }, 150);
    },
    [
      currentStartIndex,
      currentEndIndex,
      updateDisplayedVideos,
      filteredVideos.length,
    ],
  );

  useEffect(() => {
    // Initial load
    const { start, end, effectiveMiddle } = calculateVideoWindow(
      filteredVideos.length,
      middleIndex,
    );
    updateDisplayedVideos(start, end, effectiveMiddle);
  }, [filteredVideos, updateDisplayedVideos]);

  useEffect(() => {
    const options = {
      root: null, // Use viewport
      rootMargin: '0px',
      threshold: 0.5, // Trigger when element is 50% visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleScroll();
        }
      });
    }, options);

    // Observe all video items
    const videoItems =
      containerRef.current?.querySelectorAll('.home-video-item');
    if (videoItems) {
      videoItems.forEach((item) => observer.observe(item));
    }

    return () => {
      if (videoItems) {
        videoItems.forEach((item) => observer.unobserve(item));
      }
    };
  }, [handleScroll, displayedVideos]);

  const handleBackToTop = useCallback(() => {
    // Calculate window for index 0
    const { start, end, effectiveMiddle } = calculateVideoWindow(
      filteredVideos.length,
      0, // Set target index to 0
    );

    // Update the displayed videos to show the first chunk
    updateDisplayedVideos(start, end, effectiveMiddle);

    // Find and scroll to the first video element
    const firstVideo = containerRef.current?.querySelector('.home-video-item');
    if (firstVideo) {
      firstVideo.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredVideos.length, updateDisplayedVideos]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('zh-TW', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const renderPlaylistHeader = () => {
    if (playlistName === 'All') return null;

    const currentPlaylist = playlists.find(
      (playlist) => playlist.playlist_name === playlistName,
    );

    if (!currentPlaylist) return null;

    return (
      <div className="playlist-header">
        <div className="playlist-info">
          <div className="info-group">
            <span className="info-caption">描述</span>
            <p className="playlist-description">
              {currentPlaylist.description}
            </p>
          </div>
          <div className="playlist-meta">
            <p className="playlist-creator">
              建立者：{currentPlaylist.created_by}
            </p>
            <p className="playlist-date">
              建立時間：{formatDate(currentPlaylist.date_created)}
            </p>
          </div>
          <div className="info-group">
            <span className="info-caption">標籤：</span>
            <div className="playlist-tags">
              {currentPlaylist.custom_tags.map((tag, index) => (
                <span key={index} className="tag">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="playlist-container">
      {renderPlaylistHeader()}
      {userInfo.accountType && (
        <div className="videos-container">
          <div className="video-list" ref={containerRef}>
            {displayedVideos.map((videoData, index) => (
              <div
                key={`${videoData.matchId}-${currentStartIndex + index}`}
                className="home-video-item"
                data-index={currentStartIndex + index}
              >
                <VideoList videoData={videoData} userInfo={userInfo} />
              </div>
            ))}
            <Padding />
          </div>
          {/* Back to top button */}
          <Button
            variant="contained"
            className="back-to-top-button"
            onClick={handleBackToTop}
            sx={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              minWidth: '40px',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              padding: '8px',
              backgroundColor: 'white',
              color: 'primary.main',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              '&:hover': {
                backgroundColor: 'grey.100',
              },
              zIndex: 1000,
            }}
          >
            <KeyboardDoubleArrowUpIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export default PaginatedVideoList;
