import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';

const drawerWidth = 240;

const SidePanel = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: open ? drawerWidth : 'fit-content',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  height: '100vh',
  backgroundColor: '#f5f5f5',
  borderRight: '1px solid #e0e0e0',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  ...(open && {
    width: drawerWidth,
  }),
  ...(!open && {
    width: theme.spacing(7) + 1,
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 40,
  color: '#000',
  justifyContent: 'center',
});

const StyledListItemText = styled(ListItemText)(({ open }) => ({
  padding: '0 8px',
  fontSize: 12,
  textAlign: 'center',
  color: '#000',
  display: open ? 'block' : 'none',
}));

const StyledListItem = styled(ListItem)(({ open }) => ({
  padding: '8px 16px',
  display: 'flex',
  flexDirection: open ? 'row' : 'column',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  textAlign: open ? 'left' : 'center',
}));

const HomeSidePanel = ({
  isCollapsed,
  setIsCollapsed,
  isCoach,
  accountType,
}) => {
  const togglePanel = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <SidePanel open={!isCollapsed}>
      <List>
        <StyledListItem onClick={togglePanel}>
          <MenuIcon />
        </StyledListItem>

        <StyledListItem
          button
          component={Link}
          to="/video-upload"
          open={!isCollapsed}
        >
          <StyledListItemIcon>
            <UploadFileOutlinedIcon />
          </StyledListItemIcon>
          <StyledListItemText primary="上傳視頻" open={!isCollapsed} />
        </StyledListItem>

        <StyledListItem
          button
          component={Link}
          to="/player-analysis"
          open={!isCollapsed}
        >
          <StyledListItemIcon>
            <Groups2Icon />
          </StyledListItemIcon>
          <StyledListItemText primary="球員" open={!isCollapsed} />
        </StyledListItem>
        <StyledListItem
          button
          component={Link}
          to="/playlist"
          open={!isCollapsed}
        >
          <StyledListItemIcon>
            <SubscriptionsOutlinedIcon />
          </StyledListItemIcon>
          <StyledListItemText primary="影片列表" open={!isCollapsed} />
        </StyledListItem>
        {accountType === 'a' && (
          <StyledListItem
            button
            component={Link}
            to="/player-graph"
            open={!isCollapsed}
          >
            <StyledListItemIcon>
              <SettingsEthernetIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="關係圖" open={!isCollapsed} />
          </StyledListItem>
        )}
      </List>
    </SidePanel>
  );
};

export default HomeSidePanel;
