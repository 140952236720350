import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { updateProgressDataAPI, getProgressDataAPI } from 'src/api/vqService';
import Cookies from 'js-cookie';

import './VideoFlag.scss';

interface FlagVideoProps {
  defaultOpen: boolean;
  ocr: boolean,
  dp: boolean,
  matchId: number;
  setVideoData: React.Dispatch<React.SetStateAction<Record<string, unknown>>> | null;
}

const FlagVideo: React.FC<FlagVideoProps> = ({
  defaultOpen = false,
  ocr = true,
  dp = false,
  setVideoData,
  matchId,
}) => {
  const token = Cookies.get('token');
  const [open, setOpen] = useState(defaultOpen);
  const [flagComment, setFlagComment] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProgressDataAPI(
          { matchId: matchId, videoId: null },
          token as string,
        );
        setFlagComment(response.flagged_comment);
      } catch (error) {
        console.log('');
      }
    };

    fetchData();
  }, [matchId, token]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsEditing(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  const handleCommentSubmission = () => {
    let newFlagComment = flagComment;

    if (flagComment === '' || flagComment === '_') {
      if (ocr) {
        newFlagComment = 'ocr';
      } else if (dp) {
        newFlagComment = 'dp';
      }
    } else if (flagComment === 'ocr') {
      if (ocr) {
        newFlagComment = '_'; // remove 'ocr'
      } else if (dp) {
        newFlagComment = 'dp ocr'; // add 'dp'
      }
    } else if (flagComment === 'dp') {
      if (dp) {
        newFlagComment = '_'; // remove 'dp'
      } else if (ocr) {
        newFlagComment = 'dp ocr'; // add 'ocr'
      }
    }

    else if (flagComment === 'dp ocr') {
      if (dp) {
        newFlagComment = 'ocr'; // remove 'dp'
      } else if (ocr) {
        newFlagComment = 'dp'; // add 'ocr'
      }
    }

    handleSubmitComment(newFlagComment);
  };

  const handleSubmitComment = async (flagged_comment = flagComment) => {
    try {
      const params = {
        matchId,
        flaggedcomment: flagged_comment,
      };
      await updateProgressDataAPI(params, token as string);
      if (setVideoData !== null) {
        setVideoData((prevState: any) => ({
          ...prevState,
          refresh: true,
        }));
      }
      handleClose();
    } catch (error) {
      console.error('Error updating flag comment:', error);
    }
  };


  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFlagComment(event.target.value);
  };


  // const handleRemoveFlag = async () => {
  //   try {
  //     const params = {
  //       matchId,
  //       flaggedcomment: '_',
  //     };
  //     await updateProgressDataAPI(params, token as string);
  //     setFlagComment('');
  //     handleClose();
  //   } catch (error) {
  //     console.error('Error removing flag:', error);
  //   }
  // };

  const handleEditFlag = () => {
    setIsEditing(true);
  };

  return (
    <div className="flag-video" title="回饋">
      <button onClick={() => (ocr || dp) ? handleCommentSubmission() : handleClickOpen()}>
        <span style={{ color: 'white' }}>回饋錯誤</span>
        {ocr ?
          (<FlagOutlinedIcon className={flagComment.includes('ocr') ? 'flagged' : 'not-flagged'} />) :
          dp ?
            (<FlagOutlinedIcon className={flagComment.includes('dp') ? 'flagged' : 'not-flagged'} />) :
            (<FlagOutlinedIcon className={flagComment === '' || flagComment === '_' ? 'not-flagged' : 'flagged'} />)
        }
      </button>
      <Dialog open={open} onClose={handleClose} className="flag-video-dialog">
        {isEditing ? (
          <>
            <DialogTitle>
              {isEditing ? '編輯回饋原因' : '回饋視頻的原因'}
            </DialogTitle>
            <DialogContent>
              <TextField
                className="reasons"
                placeholder="原因"
                value={flagComment}
                onChange={handleTextFieldChange}
                fullWidth
                multiline
                rows={3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>取消</Button>
              <Button onClick={() => handleSubmitComment()}>{isEditing ? 'Update' : 'Confirm'}</Button>
            </DialogActions>
          </>
        ) : (
          <div className="flag-comment-container">
            <DialogTitle>回饋原因</DialogTitle>
            <DialogContent>
              <p>{flagComment ? flagComment : '_'}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditFlag} startIcon={<EditIcon />}>
                編輯
              </Button>
              <Button
                onClick={() => handleSubmitComment('_')}
                startIcon={<DoneOutlineOutlinedIcon />}
              >
                問題已解決
              </Button>
              <Button onClick={handleClose} startIcon={<CloseIcon />}>
                關閉
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default FlagVideo;
