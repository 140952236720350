import React, { useEffect } from 'react';
import './alert.scss';

export const TimedAlert: React.FC<{ message: string; onClose: () => void }> = ({
  message,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return <div className="timed-alert">{message}</div>;
};
