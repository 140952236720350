import React, { useState } from 'react';
import { GridView, ViewList, Search } from '@mui/icons-material';
import { TextField, InputAdornment, Box } from '@mui/material';
import './PlaylistVideoPanel.scss';

interface VideoListItem {
    matchId: string;
    videoName: string;
    stage: string;
    date: string;
    location: string;
    homePlayer: string;
    awayPlayer: string;
    TNUrl: string;
    subEvent: string;
    category: string;
    uploadUser: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;
}

interface PlaylistVideoPanelProps {
    videoList: VideoListItem[];
    addedVideoIds: Set<string>;
    onAddVideo: (video: VideoListItem) => void;
}

const PlaylistVideoPanel: React.FC<PlaylistVideoPanelProps> = ({
    videoList,
    addedVideoIds,
    onAddVideo,
}) => {
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [searchTerm, setSearchTerm] = useState('');

    const filteredVideos = videoList.filter(video =>
        video.videoName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.homePlayer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.awayPlayer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const renderGridItem = (video: VideoListItem) => (
        <div className="video-content">
            <h4>{video.videoName}</h4>
            <p>{video.homePlayer} vs {video.awayPlayer}</p>
            <div className='img-score-container'>
                <img
                    src={video.TNUrl}
                    className="video-thumbnail"
                    alt={video.videoName}
                />
                <p>{video.homePlayerMatchScore} - {video.awayPlayerMatchScore}</p>
            </div>
            <button
                onClick={() => onAddVideo(video)}
                disabled={addedVideoIds.has(video.matchId)}
            >
                {addedVideoIds.has(video.matchId) ? '已添加' : '添加'}
            </button>
        </div>
    );

    const renderListItem = (video: VideoListItem) => (
        <div className="video-content">
            <h4>{video.videoName}</h4>
            <div className="video-info">
                <div className="list-header">
                    <p>{video.homePlayer} vs {video.awayPlayer} ({video.homePlayerMatchScore} - {video.awayPlayerMatchScore})</p>
                    <p>{video.date.slice(-4)} | {video.stage}</p>
                </div>
                <button
                    onClick={() => onAddVideo(video)}
                    disabled={addedVideoIds.has(video.matchId)}
                >
                    {addedVideoIds.has(video.matchId) ? '已添加' : '添加'}
                </button>
            </div>
        </div>
    );

    return (
        <div className="playlist-video-panel">
            <Box className="panel-header" sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <h3>可用視頻：</h3>
                    <TextField
                        size="small"
                        placeholder="搜索視頻..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            width: '100%',
                            maxWidth: '25rem',
                            marginRight: '2px',
                            backgroundColor: 'rgba(255, 255, 255, 0.06)',
                            borderRadius: '0.25rem',
                            '& .MuiOutlinedInput-root': {
                                color: '#fff',
                                '& fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.23)',
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    '& fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.7)',
                                    }
                                },
                                '&.Mui-focused': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    '& fieldset': {
                                        borderColor: '#1976d2',
                                    }
                                },
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '0.875rem',
                                '&::placeholder': {
                                    color: 'rgba(255, 255, 255, 0.5)',
                                    opacity: 1,
                                },
                            },
                        }}
                    />
                </Box>
                <div className="view-toggles">
                    <button
                        className={`view-toggle ${viewMode === 'grid' ? 'active' : ''}`}
                        onClick={() => setViewMode('grid')}
                    >
                        <GridView />
                    </button>
                    <button
                        className={`view-toggle ${viewMode === 'list' ? 'active' : ''}`}
                        onClick={() => setViewMode('list')}
                    >
                        <ViewList />
                    </button>
                </div>
            </Box>

            <div className={`video-container ${viewMode}`}>
                {filteredVideos.map((video) => (
                    <div
                        key={video.matchId}
                        className={`video-item ${addedVideoIds.has(video.matchId) ? 'disabled' : ''}`}
                    >
                        {viewMode === 'grid' ? renderGridItem(video) : renderListItem(video)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlaylistVideoPanel;