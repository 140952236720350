import { GraphSettings } from "./GraphType";

export const getGraphSettings = (isMobile: boolean): GraphSettings => ({
    labelSize: isMobile ? 10 : 12,
    labelDensity: 0.6,
    labelGridCellSize: isMobile ? 100 : 140,
    labelRenderedSizeThreshold: 1,
    minCameraRatio: 0.1,
    maxCameraRatio: 2,
    defaultNodeColor: "#D3D3D3",
    defaultEdgeColor: "#D3D3D3",
    labelFont: "Arial",
    labelWeight: "400",
  });
  