import axios from 'axios';
import {
  MatchParams,
  PlaylistParams,
  UpdatePlaylistParams,
  UpdateVideoDataParams,
  GetDropPointsParams,
  UploadPlaylistDataAPIParams,
  deleteHiglightDataParams,
  UpdateHighlightDataParams,
  UpdateDPDataParams,
  UpdateBallDataParams,
  DeleteBallDataParams,
} from 'types/videoTypes';
import { HandleAPIError } from './auth';

export const fetchMatchDataAPI = async (
  params: MatchParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/videodata`, {
      params: params,
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const fetchIndMatchDataAPI = async (
  videoId: number,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get(`/api/videodata/${videoId}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

// export const fetchPlaylistDataAPI = async (
//   params: PlaylistParams,
//   token: string,
// ): Promise<any> => {
//   try {
//     const response = await axios.get(`/api/playlistManagement`, {
//       params: params,
//       headers: {
//         Authorization: `${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     HandleAPIError(error);
//     throw error;
//   }
// };

export const uploadPlaylistDataAPI = async (
  params: UploadPlaylistDataAPIParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(
      `/api/playlistManagement/upload`,
      params,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

// export const updatePlaylistDataAPI = async (
//   params: UpdatePlaylistParams,
//   token: string,
// ): Promise<any> => {
//   try {
//     const response = await axios.put(`/api/playlistManagement`, params, {
//       headers: {
//         Authorization: `${token}`,
//         'Content-Type': 'application/json', // Specify the content type as JSON
//       },
//     });
//     return response.data;
//   } catch (error) {
//     HandleAPIError(error);
//     throw error;
//   }
// };

export const fetchDropPointsAPI = async (
  params: GetDropPointsParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.get('/api/dpData', {
      params: {
        ...params,
        match_option: params.match_option,
        opponent_option: params.opponent_option,
      },
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const updateVideoDataAPI = async (
  params: UpdateVideoDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(`/api/uploadMetaData`, null, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const deleteHighlightDataAPI = async (
  params: deleteHiglightDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.delete(`/api/highlightManagement`, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const updateHighlightDataAPI = async (
  params: UpdateHighlightDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(`/api/highlightManagement`, null, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const updateDPDataAPI = async (
  params: UpdateDPDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(`/api/dpUpdate`, params, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const updateBallDataAPI = async (
  params: UpdateBallDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.post(`/api/editballdata`, params, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

export const deleteBallDataAPI = async (
  params: DeleteBallDataParams,
  token: string,
): Promise<any> => {
  try {
    const response = await axios.delete(`/api/editballdata`, {
      params,
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    HandleAPIError(error);
    throw error;
  }
};

// export const addVideoMetaData = async (videoData: VideoMetadata) => {
//   try {
//     const response = await axios.post(`${API_URL}/videosMetaData`, videoData);
//     console.log('respose', response);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// export const addVideoFile = async (videoData: FormData) => {
//   try {
//     const response = await axios.post(`${API_URL}/videosFile`, videoData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     console.log('videoData:', videoData);
//     console.log('response', response);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// export const getVideoMetaData = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/videosMetaData`);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };
