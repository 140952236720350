import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import VideoInterface from './videoPages/VideoInterface/VideoInterface.js';
import HighlightEditor from './videoPages/HighlightEditor/HighlightEditor.js';
import { VideoUploadForm } from './videoPages/UploadVideo/VideoUploadForm.js';
import POVEditor from './videoPages/POVEditor/POVEditor.js';
import LoginForm from './pages/Login/Login.js';
import SignUp from './pages/SignUp/SignUp.js';
import EditVideoData from './videoPages/EditVideo/EditVideoData.js';
import PlayerHome from './playerPages/PlayerHome.js';
import PlayerAnalysis from './playerPages/PlayerAnalysis.js';
import AdminPage from './userPages/AdminPage/AdminPage.js';
import Home from './pages/Home/Home.js';
import PlayerGraph from './playerPages/PlayerGraph/GraphComponent.tsx';
import SignalGraphWrapper from './playerPages/PlayerGraphV2/common/components/SignalGraphWrapper.tsx';
import SignalGraphWrapperV3 from './playerPages/PlayerGraphV3/common/components/SignalGraphWrapper.tsx';
import PlaylistPage from './videoPages/Playlist/Playlist.tsx';

import NavBar from 'src/components/NavBar/NavBar';

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import './App.scss';
import './variables.scss';

const App = () => {
  const [userInfo, setUserInfo] = useState({
    identifier: null,
    isCoach: null,
    accountType: null,
    admin: null,
    subadmin: null,
  });
  const [token, setToken] = useState(Cookies.get('token'));
  const navigate = useNavigate();

  useEffect(() => {
    const newToken = Cookies.get('token');

    if (newToken) {
      const decodedToken = jwtDecode(newToken);
      setUserInfo((prevState) => ({
        ...prevState,
        identifier: decodedToken.identifier,
        isCoach: decodedToken.is_coach,
        accountType: decodedToken.account_type,
        admin: decodedToken.admin,
        subadmin: decodedToken.subadmin,
      }));
    }

    // const userAgent = navigator.userAgent.toLowerCase();
    // const checkMobile =
    //   /iphone|ipod|ipad|android|iemobile|blackberry|webos|tablet|kindle|playbook|silk|windows phone|opera mini|\b(cr)e(\b|\/)/i.test(
    //     userAgent,
    //   );
    // const { outerWidth, outerHeight } = window;
    // const aspectRatio = outerWidth / outerHeight;

    // if (outerWidth > 768 && outerHeight > 768) {
    //   setIsMobile(false);
    // }

    // hide error
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message ===
          'ResizeObserver loop completed with undelivered notifications'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, [token]);

  const PrivateRoute = ({ element }) => {
    return token ? element : <Navigate to="/login" />;
  };

  const CoachRoute = ({ element }) => {
    return userInfo.isCoach === true ? element : navigate(-1);
  };

  const AdminRoute = ({ element }) => {
    return userInfo.admin === true || userInfo.subadmin === true
      ? element
      : navigate(-1);
  };

  return (
    <div className="App">
      <NavBar
        setToken={setToken}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
      <Routes>
        <Route
          path="/"
          element={<PrivateRoute element={<Home userInfo={userInfo} />} />}
        />
        <Route
          path="/video-player/:videoId"
          element={
            <PrivateRoute element={<VideoInterface userInfo={userInfo} />} />
          }
        />
        <Route
          path="/video-upload"
          element={
            <PrivateRoute element={<VideoUploadForm userInfo={userInfo} />} />
          }
        />
        <Route
          path="/playlist"
          element={
            <PrivateRoute
              element={
                <PlaylistPage
                  userType={userInfo.accountType}
                  userName={userInfo.identifier}
                />
              }
            />
          }
        />
        <Route
          path="/edit-metadata/:videoId/:step"
          element={
            <PrivateRoute element={<EditVideoData userInfo={userInfo} />} />
          }
        />
        <Route
          path="/player-analysis"
          element={
            <PrivateRoute element={<PlayerHome userInfo={userInfo} />} />
          }
        />
        <Route
          path="/player-analysis/:playerId"
          element={
            <PrivateRoute element={<PlayerAnalysis userInfo={userInfo} />} />
          }
        />
        <Route path="/login" element={<LoginForm setToken={setToken} />} />
        <Route
          path="/admin-sign-up"
          element={<AdminRoute element={<SignUp />} />}
        />
        <Route
          path="/admin-page"
          element={<AdminRoute element={<AdminPage userInfo={userInfo} />} />}
        />
        <Route
          path="/player-graphV0"
          element={
            <PrivateRoute
              element={<PlayerGraph setToken={setToken} userInfo={userInfo} />}
            />
          }
        />
        <Route
          path="/player-graphv2"
          // thie is v2
          element={
            <PrivateRoute
              element={
                <SignalGraphWrapper userInfo={userInfo} setToken={setToken} />
              }
            />
          }
        />
        <Route
          path="/player-graph"
          // thie is v3
          element={
            <PrivateRoute
              element={
                <SignalGraphWrapperV3 userInfo={userInfo} setToken={setToken} />
              }
            />
          }
        />
        {/* <Route
          path="/manual-input/:videoId"
          element={
            <PrivateRoute element={<ManualInterface userInfo={userInfo} />} />
          }
        /> */}
      </Routes>
    </div>
  );
};

export default App;
