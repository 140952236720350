import React from 'react';
import SearchBar from 'src/components/SearchBar/SearchBar';
import HomeFilterOption from 'src/components/FilterPanel/HomeFilterOption';
import ActiveFilters from './HomeFilterPanel';

import './HomeOptionsBar.scss';

interface FilterCriteria {
    matchName: string;
    matchDate: string;
    stages: string[];
    location: string;
    events: string[];
    categories: string[];
    startDate: string;
    endDate: string;
    customTags: string[];
}

interface FilterStats {
    stageCounts: Record<string, number>;
    subEventCounts: Record<string, number>;
    customTagCounts: Record<string, number>;
    categoryCounts: Record<string, number>;
}

interface UserInfo {
    accountType: string;
    admin: boolean;
    subadmin: boolean;
}

interface HomeOptionsBarProps {
    filterCriteria: FilterCriteria;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria>>;
    sortCriteria: number;
    setSortCriteria: React.Dispatch<React.SetStateAction<number>>;
    filterStats: FilterStats;
    CustomTagList: string[];
    userInfo: UserInfo;
}

const HomeOptionsBar: React.FC<HomeOptionsBarProps> = ({
    filterCriteria,
    setFilterCriteria,
    sortCriteria,
    setSortCriteria,
    filterStats,
    CustomTagList,
    userInfo,
}) => {

    return (
        <div className="home-options-container">
            <div className="home-options-bar">
                <div className="search-section">
                    <SearchBar
                        player={false}
                        setFilterCriteria={setFilterCriteria}
                        searchName={filterCriteria.matchName}
                    />
                </div>

                <div className="filter-controls">
                    <HomeFilterOption
                        setFilterCriteria={setFilterCriteria}
                        appliedFilterCriteria={filterCriteria}
                        CustomTagList={CustomTagList}
                        userInfo={userInfo}
                        filterStats={filterStats}
                        setSortCriteria={setSortCriteria}
                        sortCriteria={sortCriteria}
                    />
                </div>
            </div>

            <div className="filter-panel-section">
                <ActiveFilters
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                />
            </div>
        </div>
    );
};

export default HomeOptionsBar;