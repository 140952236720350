import React, { useCallback, useEffect, useMemo } from "react";
import { FaProjectDiagram } from "react-icons/fa";
import { animateNodes } from "sigma/utils";
import { useSigma } from "@react-sigma/core";
import { useLayoutCircular } from "@react-sigma/layout-circular";
import { useLayoutCirclepack } from "@react-sigma/layout-circlepack";
import { useLayoutRandom } from "@react-sigma/layout-random";
import { WorkerLayoutControl } from "@react-sigma/layout-core";
import {
  useLayoutNoverlap,
  useWorkerLayoutNoverlap,
} from "@react-sigma/layout-noverlap";
import {
  useLayoutForce,
  useWorkerLayoutForce,
} from "@react-sigma/layout-force";
import {
  useLayoutForceAtlas2,
  useWorkerLayoutForceAtlas2,
} from "@react-sigma/layout-forceatlas2";
import { IconButton, Menu, MenuItem, Box, useTheme, Tooltip } from "@mui/material";
import { LayoutName, Layouts, LayoutsControlProps } from "../types/graph.types";
import { useTranslation } from "react-i18next";

const LayoutsControl: React.FC<LayoutsControlProps> = ({
  onLayoutChange,
  currentLayout,
}) => {
  const theme = useTheme();
  const sigma = useSigma();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const layoutCircular = useLayoutCircular();
  const layoutCirclepack = useLayoutCirclepack();
  const layoutRandom = useLayoutRandom();
  const layoutNoverlap = useLayoutNoverlap();
  const layoutForce = useLayoutForce({ maxIterations: 100 });
  const layoutForceAtlas2 = useLayoutForceAtlas2({ iterations: 100 });

  const getTwoLayerCircleLayout = useCallback(() => {
    const graph = sigma.getGraph();
    const positions: { [key: string]: { x: number; y: number } } = {};

    const playerNodes = new Set<string>();
    const gameNodes = new Set<string>();

    graph.forEachNode((nodeId: string) => {
      const label = graph.getNodeAttribute(nodeId, "label") as string;
      if (label && label.startsWith("Game")) {
        gameNodes.add(nodeId);
      } else {
        playerNodes.add(nodeId);
      }
    });

    const outerRadius = Math.max(400, gameNodes.size * 3);
    const innerRadius = outerRadius * 0.6;

    Array.from(playerNodes).forEach((id, index) => {
      const angle = (index / playerNodes.size) * 2 * Math.PI;
      positions[id] = {
        x: innerRadius * Math.cos(angle),
        y: innerRadius * Math.sin(angle),
      };
    });

    Array.from(gameNodes).forEach((id, index) => {
      const angle = (index / gameNodes.size) * 2 * Math.PI;
      positions[id] = {
        x: outerRadius * Math.cos(angle),
        y: outerRadius * Math.sin(angle),
      };
    });

    return positions;
  }, [sigma]);

  const layouts: Layouts = useMemo(() => {
    return {
      twoLayerCircle: {
        layout: {
          positions: getTwoLayerCircleLayout,
        },
      },
      circular: {
        layout: layoutCircular,
      },
      circlepack: {
        layout: layoutCirclepack,
      },
      random: {
        layout: layoutRandom,
      },
      noverlaps: {
        layout: layoutNoverlap,
        worker: useWorkerLayoutNoverlap,
      },
      forceDirected: {
        layout: layoutForce,
        worker: useWorkerLayoutForce,
      },
      forceAtlas: {
        layout: layoutForceAtlas2,
        worker: useWorkerLayoutForceAtlas2,
      },
    };
  }, [
    getTwoLayerCircleLayout,
    layoutCircular,
    layoutCirclepack,
    layoutRandom,
    layoutNoverlap,
    layoutForce,
    layoutForceAtlas2,
  ]);

  const applyLayout = useCallback(
    (layoutName: LayoutName) => {
      const layout = layouts[layoutName]?.layout;
      if (layout) {
        const positions = layout.positions();
        animateNodes(sigma.getGraph(), positions, { duration: 1000 });
        if (onLayoutChange) {
          onLayoutChange(layoutName);
        }
      }
      setAnchorEl(null);
    },
    [layouts, sigma, onLayoutChange]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: theme.zIndex.modal,
      }}
    >
      {layouts[currentLayout]?.worker && (
        <Box sx={{ mb: 1 }}>
          <WorkerLayoutControl
            layout={layouts[currentLayout].worker}
            settings={{}}
          />
        </Box>
      )}

      <Tooltip title={t('layouts.title')}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            bgcolor: "background.paper",
            "&:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <FaProjectDiagram />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              minWidth: 180,
              boxShadow: theme.shadows[2],
            },
          },
        }}
      >
        {(Object.keys(layouts) as LayoutName[]).map((name) => (
          <MenuItem
            key={name}
            onClick={() => applyLayout(name)}
            selected={currentLayout === name}
            sx={{
              py: 1,
              px: 2,
              minHeight: "auto",
              "&.Mui-selected": {
                bgcolor: "action.selected",
              },
              "&:hover": {
                bgcolor: "action.hover",
              },
            }}
          >
            {t(`layouts.types.${name}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LayoutsControl;