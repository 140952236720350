import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import all locales
import en from "../locales/en.json";
import zh_hk from "../locales/zh_hk.json";

i18n.use(initReactI18next).init({
  resources: {
    en,
    zh_hk,
  },
  lng: "zh_hk", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
