import React, { useState, useRef, useEffect } from 'react';
import { PlayCircleOutline, Close } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { fetchMatchDataAPI } from 'src/api/videoService';
import Cookies from 'js-cookie';
import OptimizedVideoPlayer from './OptimizedVideoPlayer';
import './PlayerSnippets.scss';

import {
    matchIdIndex,
    videoNameIndex,
    matchDescriptionIndex,
    stageIndex,
    locationIndex,
    homePlayerIndex,
    awayPlayerIndex,
    urlIndex,
    dateIndex,
    thumbnailIndex,
    reportIndex,
    subEventIndex,
    customTagIndex,
    homePlayerMatchScoreIndex,
    awayPlayerMatchScoreIndex,
    eventIndex,
    categoryIndex,
    isDoubleIndex,
    viewCountIndex,
    uploadUserIndex,
    parentEventIndex,
} from 'src/constants';

interface VideoData {
    matchId: string;
    videoName: string;
    matchDescription: string;
    homePlayer: string;
    awayPlayer: string;
    fileUrl: string;
}

interface Snippet {
    start: number;
    end: number;
}


interface VideoData {
    matchId: string;
    videoName: string;
    matchDescription: string;
    stage: string;
    date: string;
    location: string;
    homePlayer: string;
    awayPlayer: string;
    fileUrl: string;
    TNUrl: string;
    report: string;
    parentEvent: string;
    event: string;
    subEvent: string;
    category: string;
    isDouble: string;
    viewCount: string;
    uploadUser: string;
    customTag: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;
}

interface VideoDataResponse {
    videoData: Array<Array<string>>;
}

interface PlayerSnippetsProps {
    accountType: string;
    admin: boolean;
    identifier: string;
}


const PlayerSnippets: React.FC<PlayerSnippetsProps> = ({ accountType, admin, identifier }) => {
    const [videoList, setVideoList] = useState<VideoData[]>([]);
    const [currentVideo, setCurrentVideo] = useState('');
    const [currentSnippet, setCurrentSnippet] = useState<Snippet | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    // Mock snippets - replace with your actual snippet data source
    const getSnippetsForVideo = () => [
        { start: 10, end: 30 },
        { start: 145, end: 160 },
    ];

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response: VideoDataResponse = await fetchMatchDataAPI(
                    { accountType, admin, user: identifier },
                    Cookies.get('token') || ''
                );

                const responseData = response.videoData;
                const processedVideos = responseData.map((videoData) => ({
                    matchId: videoData[matchIdIndex],
                    videoName: videoData[videoNameIndex],
                    matchDescription: videoData[matchDescriptionIndex],
                    stage: videoData[stageIndex],
                    date: videoData[dateIndex].replace(/\s\d{2}:\d{2}:\d{2}\s\w{3}$/, ''),
                    location: videoData[locationIndex],
                    homePlayer: videoData[homePlayerIndex],
                    awayPlayer: videoData[awayPlayerIndex],
                    fileUrl: `${process.env.PUBLIC_URL}/videos/${videoData[
                        urlIndex
                    ].substring(0, videoData[urlIndex].lastIndexOf('.'))}/${videoData[urlIndex]
                        }`,
                    TNUrl: `${process.env.PUBLIC_URL}/videos/${videoData[
                        urlIndex
                    ].substring(0, videoData[urlIndex].lastIndexOf('.'))}/${videoData[thumbnailIndex]
                        }`,
                    report: videoData[reportIndex],
                    parentEvent: videoData[parentEventIndex],
                    event: videoData[eventIndex],
                    subEvent: videoData[subEventIndex],
                    category: videoData[categoryIndex],
                    isDouble: videoData[isDoubleIndex],
                    viewCount: videoData[viewCountIndex],
                    uploadUser: videoData[uploadUserIndex],
                    customTag: videoData[customTagIndex],
                    homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
                    awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
                }));
                setVideoList(processedVideos);
            } catch (error) {
                console.error('Error fetching videos:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchVideos();
    }, [accountType, admin, identifier]);

    useEffect(() => {
        const video = videoRef.current;
        if (!video || !currentSnippet) return;

        const handleTimeUpdate = () => {
            if (video.currentTime >= currentSnippet.end) {
                video.currentTime = currentSnippet.start;
            }
            if (video.currentTime < currentSnippet.start) {
                video.currentTime = currentSnippet.start;
            }
        };

        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => video.removeEventListener('timeupdate', handleTimeUpdate);
    }, [currentSnippet]);


    const handleSnippetClick = (videoUrl: string, snippet: Snippet) => {
        setCurrentVideo(videoUrl);
        setCurrentSnippet(snippet);
        setIsModalOpen(true);
        setIsPlaying(true);
    };

    const handleCloseModal = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
        setIsModalOpen(false);
        setIsPlaying(false);
        setCurrentVideo('');
        setCurrentSnippet(null);
    };


    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    if (isLoading) {
        return (
            <div className="player-snippets__loading">
                <CircularProgress />
            </div>
        );
    }

    return (
        <main className="player-snippets">
            {isModalOpen && (
                <div className="player-snippets__modal">
                    <div className="player-snippets__modal-content">
                        <button
                            className="player-snippets__close"
                            onClick={handleCloseModal}
                            aria-label="Close"
                        >
                            <Close />
                        </button>

                        <OptimizedVideoPlayer
                            videoUrl={currentVideo}
                            startTime={currentSnippet?.start || 0}
                            endTime={currentSnippet?.end || 0}
                        />

                    </div>
                </div>
            )}

            <div className="player-snippets__grid">
                {videoList.map((video) => (
                    <article key={video.fileUrl} className="player-snippets__card">
                        <header className="player-snippets__card-header">
                            <h2 className="player-snippets__title">
                                {video.videoName || video.matchDescription}
                            </h2>
                            <p className="player-snippets__subtitle">
                                {video.homePlayer} vs {video.awayPlayer}
                            </p>
                        </header>

                        <div className="player-snippets__snippets">
                            {getSnippetsForVideo().map((snippet, index) => (
                                <button
                                    key={index}
                                    className="player-snippets__snippet-button"
                                    onClick={() => handleSnippetClick(video.fileUrl, snippet)}
                                >
                                    <PlayCircleOutline />
                                    <span>
                                        {formatTime(snippet.start)} - {formatTime(snippet.end)}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </article>
                ))}
            </div>
        </main>
    );
};

export default PlayerSnippets;