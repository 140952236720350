import React from 'react';
import { TextField, Box, Button, Typography, styled } from '@mui/material';

interface BallData {
  ball_id: number;
  round_id: number;
  start_time: number;
  end_time: number;
  home_player_score: number;
  away_player_score: number;
  highlight: boolean;
  critical_point: boolean;
  deuce: boolean;
}

interface InputPanelProps {
  ballData: BallData | null;
  videoRef: React.RefObject<HTMLVideoElement>;
  setNewBallData: React.Dispatch<React.SetStateAction<BallData>>;
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  minWidth: 'auto',
  padding: theme.spacing(0.5, 1),
  marginTop: '0.3em',
  backgroundColor: 'grey'
}));

const InputPanel: React.FC<InputPanelProps> = ({
  ballData,
  videoRef,
  setNewBallData,
}) => {
  const handleScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isHomeScore: boolean
  ) => {
    if (!ballData) return;
    const newScore = Math.min(parseInt(event.target.value, 10), 50);
    if (isNaN(newScore)) return;

    setNewBallData({
      ...ballData,
      [isHomeScore ? 'home_player_score' : 'away_player_score']: newScore
    });
  };

  const handleTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isStartTime: boolean
  ) => {
    if (!ballData) return;

    const timeStr = event.target.value;
    const [minutes, seconds] = timeStr.split(':').map(Number);

    if (isNaN(minutes) || isNaN(seconds)) return;

    const totalSeconds = minutes * 60 + seconds;

    if (totalSeconds < 0 || seconds >= 60) return;

    setNewBallData({
      ...ballData,
      [isStartTime ? 'start_time' : 'end_time']: totalSeconds
    });
  };

  const formatTimeInput = (timeInSeconds: number): string => {
    if (typeof timeInSeconds !== 'number') return '00:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleSetCurrentTime = (isStartTime: boolean) => {
    if (!videoRef.current || !ballData) return;
    const currentTime = videoRef.current.currentTime;

    setNewBallData({
      ...ballData,
      [isStartTime ? 'start_time' : 'end_time']: currentTime
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TextField
          type="number"
          size="small"
          value={ballData?.home_player_score ?? ''}
          onChange={(e: any) => handleScoreChange(e, true)}
          inputProps={{ min: 0, max: 50 }}
          sx={{ width: 80 }}
        />
        <Typography variant="h6" sx={{ color: 'black' }}>:</Typography>
        <TextField
          type="number"
          size="small"
          value={ballData?.away_player_score ?? ''}
          onChange={(e: any) => handleScoreChange(e, false)}
          inputProps={{ min: 0, max: 50 }}
          sx={{ width: 80 }}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TextField
            type="text"
            size="small"
            value={formatTimeInput(ballData?.start_time ?? 0)}
            onChange={(e: any) => handleTimeChange(e, true)}
            placeholder="MM:SS"
          />
          <StyledButton
            variant="contained"
            size="small"
            onClick={() => handleSetCurrentTime(true)}
          >
            現在
          </StyledButton>
        </Box>


        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TextField
            type="text"
            size="small"
            value={formatTimeInput(ballData?.end_time ?? 0)}
            onChange={(e: any) => handleTimeChange(e, false)}
            placeholder="MM:SS"
          />
          <StyledButton
            variant="contained"
            size="small"
            onClick={() => handleSetCurrentTime(false)}
          >
            現在
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
};

export default InputPanel;