import SearchBar from 'src/components/SearchBar/SearchBar';
import PlayerHomeFilterOption from 'src/components/FilterPanel/PlayerHomeFilter';

import './PlayerSearchBar.scss';

const PlayerSearchBar = ({
  accountType,
  setFilterCriteria,
  appliedFilterCriteria,
  setSortCriteria,
  appliedSortCriteria,
  searchName,
}) => {
  return (
    <div className="player-searchbar-container">
      <SearchBar
        player={true}
        accountType={accountType}
        setFilterCriteria={setFilterCriteria}
        searchName={searchName}
      />
      <PlayerHomeFilterOption
        setFilterCriteria={setFilterCriteria}
        appliedFilterCriteria={appliedFilterCriteria}
      />
      <div className="sort-section">
        <select
          onChange={(e) => setSortCriteria(e.target.value)}
          value={appliedSortCriteria}
        >
          <option value="" disabled>
            选择排序方式
          </option>
          <option value={1}>Z - A</option>
          <option value={2}>A - Z</option>
          <option value={3}>影片數</option>
          <option value={4}>排名</option>
        </select>
      </div>
    </div>
  );
};

export default PlayerSearchBar;
