// File: app/common/constants/graph.constants.ts
import { Settings } from "sigma/settings";
import { CustomAttributes, GraphReducerParams } from "./GraphType";
import { createNodeReducer } from "./reducers/nodeReducer";
import { createEdgeReducer } from "./reducers/edgeReducer";

export const getGraphReducers = (
  params: GraphReducerParams
): Partial<Settings<CustomAttributes, CustomAttributes, CustomAttributes>> => ({
  nodeReducer: createNodeReducer(params),
  edgeReducer: createEdgeReducer(params),
});
