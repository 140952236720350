/* eslint-disable */
import React from 'react';
import {
  Menu,
  MenuItem,
  Typography,
  Box,
  Divider,
  Link,
  Badge,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Button,
  Collapse,
  useTheme,
  useMediaQuery,
  ListItemButton,
} from '@mui/material';
import { VideoData } from '../../types/GraphTypes';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface MenuProps {
  menuAnchor: { mouseX: number; mouseY: number } | null;
  handleMenuClose: () => void;
  selectedNodeData: any;
  videoData: VideoData[] | null;
  jsonData: any;
}

const MenuComponent: React.FC<MenuProps> = ({
  menuAnchor,
  handleMenuClose,
  selectedNodeData,
  videoData,
  jsonData,
}) => {
  console.log('jsonData', jsonData);
  console.log('selectedNodeData', selectedNodeData);
  console.log('videoData', videoData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isHorizontalMobile = useMediaQuery(
    '(max-width: 767px) and (orientation: landscape)',
  );

  const [filteredSelectedNodeData, setFilteredSelectedNodeData] =
    React.useState<any>(null);
  const [currentNode, setCurrentNode] = React.useState<string | null>(null);
  const [currentDepth, setCurrentDepth] = React.useState<number>(1);
  const [path, setPath] = React.useState<
    { nodeId: string; nodeName: string }[]
  >([]);
  const [expanded, setExpanded] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (selectedNodeData?.details) {
      const detailsWithUniqueVideoIds = selectedNodeData.details.map(
        (detail: any) => {
          const videoIds = detail.videoId
            .split(/[,;]/)
            .map((id: string) => id.trim());
          return { ...detail, videoId: Array.from(new Set(videoIds)) };
        },
      );

      const depth1VideoIds = new Set(
        detailsWithUniqueVideoIds
          .filter((detail: any) => detail.depth === 1)
          .flatMap((detail: any) => detail.videoId),
      );
      const filteredDetails = detailsWithUniqueVideoIds.filter(
        (detail: any) =>
          detail.depth !== 2 ||
          !detail.videoId.some((id: string) => depth1VideoIds.has(id)),
      );

      setFilteredSelectedNodeData({
        ...selectedNodeData,
        details: filteredDetails,
      });
      setCurrentNode(selectedNodeData.nodeId);
      setPath([
        {
          nodeId: selectedNodeData.nodeId,
          nodeName: selectedNodeData.nodeName,
        },
      ]);
    } else {
      resetState();
    }
  }, [selectedNodeData]);

  const resetState = () => {
    setFilteredSelectedNodeData(null);
    setCurrentNode(null);
    setPath([]);
    setCurrentDepth(1);
    setExpanded(null);
  };

  const handleNodeClick = (nodeId: string, nodeName: string, depth: number) => {
    if (nodeId !== currentNode && !path.some((p) => p.nodeId === nodeId)) {
      setCurrentNode(nodeId);
      setCurrentDepth(depth);
      setPath((prevPath) => [...prevPath, { nodeId, nodeName }]);
    }
  };

  const handleBackClick = () => {
    if (path.length > 1) {
      const newPath = path.slice(0, -1);
      const lastNode = newPath[newPath.length - 1];
      setCurrentNode(lastNode.nodeId);
      setCurrentDepth(currentDepth - 1);
      setPath(newPath);
    }
  };

  const handleReset = () => {
    if (selectedNodeData?.details) {
      const detailsWithUniqueVideoIds = selectedNodeData.details.map(
        (detail: any) => {
          const videoIds = detail.videoId
            .split(/[,;]/)
            .map((id: string) => id.trim());
          return { ...detail, videoId: Array.from(new Set(videoIds)) };
        },
      );

      const depth1VideoIds = new Set(
        detailsWithUniqueVideoIds
          .filter((detail: any) => detail.depth === 1)
          .flatMap((detail: any) => detail.videoId),
      );
      const filteredDetails = detailsWithUniqueVideoIds.filter(
        (detail: any) =>
          detail.depth !== 2 ||
          !detail.videoId.some((id: string) => depth1VideoIds.has(id)),
      );

      setFilteredSelectedNodeData({
        ...selectedNodeData,
        details: filteredDetails,
      });
      setCurrentNode(selectedNodeData.nodeId);
      setPath([
        {
          nodeId: selectedNodeData.nodeId,
          nodeName: selectedNodeData.nodeName,
        },
      ]);
      setCurrentDepth(1);
      setExpanded(null);
    } else {
      resetState();
    }
  };

  const calculateTotalDepths = (nodeId: string) => {
    const depths = new Set(
      filteredSelectedNodeData.details
        .filter((detail: any) => detail.from === nodeId || detail.to === nodeId)
        .map((detail: any) => detail.depth),
    );
    return depths.size;
  };

  const handleExpandClick = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };

  const renderColumn = (nodeId: string, depth: number) => {
    const childNodes = filteredSelectedNodeData.details.filter(
      (detail: any) => detail.depth === depth && detail.from === nodeId,
    );
    return (
      <Paper
        elevation={3}
        style={{
          margin: '8px',
          padding: '8px',
          maxHeight: '60vh',
          overflowY: 'auto',
        }}
      >
        <List>
          {childNodes.map((childNode: any) => {
            const hasVideos = filteredSelectedNodeData.details.some(
              (detail: any) =>
                detail.depth === depth + 1 &&
                detail.from === childNode.to &&
                detail.videoId.length > 0,
            );
            return hasVideos ? (
              <ListItemButton
                key={childNode.to}
                onClick={() =>
                  handleNodeClick(
                    childNode.to,
                    jsonData.name_lists[childNode.to] || childNode.to,
                    depth + 1,
                  )
                }
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText
                  primary={jsonData.name_lists[childNode.to] || childNode.to}
                />
                <Badge
                  badgeContent={calculateTotalDepths(childNode.to)}
                  color="primary"
                />
              </ListItemButton>
            ) : null;
          })}
        </List>
      </Paper>
    );
  };

  const renderVideoDetails = (nodeId: string, depth: number) => {
    const details = filteredSelectedNodeData.details.filter(
      (detail: any) =>
        detail.depth === depth &&
        (detail.from === nodeId || detail.to === nodeId),
    );
    const uniqueDetailsMap = new Map<string, any>();

    details.forEach((detail: any) => {
      detail.videoId.forEach((videoId: string) => {
        const video = videoData?.find(
          (v: VideoData) => v.id.toString() === videoId,
        );
        if (video) {
          const key = [video.player1, video.player2].sort().join('-');
          if (!uniqueDetailsMap.has(key)) {
            uniqueDetailsMap.set(key, []);
          }
          uniqueDetailsMap.get(key).push(video);
        }
      });
    });

    const sortedDetails = Array.from(uniqueDetailsMap.entries()).sort(
      (a, b) => b[1].length - a[1].length,
    );

    const VideoInfo = ({ video }: { video: any }) => {
      const videoUrl = `https://cpii-hksi-web-01.eastasia.cloudapp.azure.com/video-player/${video.id}`;

      return (
        <Box key={video.id} my={1}>
          <Typography variant="body1">ID: {video.id}</Typography>
          <Typography variant="body2">比賽輪次: {video.matchRound}</Typography>
          <Typography variant="body2">比賽日期: {video.matchDate}</Typography>
          <Typography variant="body2">地點: {video.location}</Typography>
          <Typography variant="body2">類別: {video.category}</Typography>
          <Typography variant="body2">賽事: {video.event}</Typography>
          <Typography variant="body2">系列: {video.series}</Typography>
          {video.id && (
            <Typography variant="body2">
              <Link href={videoUrl} target="_blank" rel="noopener">
                查看視頻
              </Link>
            </Typography>
          )}
          <Divider />
        </Box>
      );
    };
    return (
      <Box p={2} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        {sortedDetails.map(([key, videos], index) => (
          <Box key={index} mb={2}>
            <Button onClick={() => handleExpandClick(key)}>
              {videos[0].player1} & {videos[0].player2}{' '}
              <Badge badgeContent={videos.length} color="primary" />
            </Button>
            <Collapse in={expanded === key} timeout="auto" unmountOnExit>
              {videos.map((video: VideoData, idx: number) => (
                <VideoInfo key={idx} video={video} />
              ))}
            </Collapse>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={
        menuAnchor
          ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX }
          : undefined
      }
      open={Boolean(menuAnchor)}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          maxHeight: isMobile ? '90vh' : '75vh',
          overflowY: 'auto',
          width: isMobile ? '95vw' : '80vw',
        },
      }}
    >
      {filteredSelectedNodeData && currentNode && (
        <div>
          <MenuItem>
            <Typography>已選 {filteredSelectedNodeData.nodeName}</Typography>
          </MenuItem>
          <Box
            display="flex"
            alignItems="center"
            p={2}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <IconButton onClick={handleBackClick} disabled={path.length <= 1}>
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h6"
              style={{ flexGrow: 1, textAlign: isMobile ? 'center' : 'left' }}
            >
              {path.map((p, index) => (
                <React.Fragment key={p.nodeId}>
                  {index > 0 && ' > '}
                  {p.nodeName}
                </React.Fragment>
              ))}
            </Typography>
            <Button
              onClick={handleReset}
              style={{
                marginLeft: isMobile ? 0 : 'auto',
                marginTop: isMobile ? '10px' : 0,
              }}
              variant="contained"
              color="secondary"
            >
              重置
            </Button>
          </Box>
          <Grid container direction={isMobile ? 'column' : 'row'}>
            <Grid item xs={12} sm={6}>
              {renderColumn(currentNode, currentDepth)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderVideoDetails(currentNode, currentDepth)}
            </Grid>
          </Grid>
        </div>
      )}
    </Menu>
  );
};

export default MenuComponent;
