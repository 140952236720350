import React from 'react';
import { formatTime } from 'src/utils/utils';
import { IconButton, Tooltip, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { updateBallDataAPI, deleteBallDataAPI } from 'src/api/videoService';
import { convertToBallData } from '../utils';
import {
  UpdateBallDataParams,
  DeleteBallDataParams,
  BallData,
} from 'types/videoTypes';

import {
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballIdIndex,
  ballRoundIdIndex,
  roundDataIndex,
  roundStartTimeIndex,
  roundIdIndex,
} from 'src/constants';

import './BallDetailsBar.scss';

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
  filename: string;
}

interface BallDetailsBarProps {
  videoData: {
    currentRound: number;
    currentBall: number;
  };
  roundResults: any[][][];
  videoRef: React.RefObject<HTMLVideoElement>;
  token: string;
  matchId: number;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
}

interface Ball {
  [ballHomePlayerScoreIndex]: number;
  [ballAwayPlayerScoreIndex]: number;
  [ballStartTimeIndex]: number;
  [ballEndTimeIndex]: number;
}

const BallDetails: React.FC<{
  ball: Ball;
  onClick?: () => void;
  onDelete?: () => void;
}> = ({ ball, onClick, onDelete }) =>
  ball ? (
    <div className="ball-details" onClick={onClick}>
      <div className="ball-number">
        <span className="number">
          第{ball[ballHomePlayerScoreIndex] + ball[ballAwayPlayerScoreIndex]}球
        </span>
        <button title="刪除此球" className="delete-button" onClick={onDelete}>
          <CloseOutlinedIcon />
        </button>
      </div>
      <span className="time-info">
        開始:{' '}
        <span className="start-time">
          {formatTime(ball[ballStartTimeIndex])}
        </span>
      </span>
      <span className="time-info">
        結束:{' '}
        <span className="end-time">{formatTime(ball[ballEndTimeIndex])}</span>
      </span>
      <span className="score-info">
        比分:{' '}
        <span className="score">
          {ball[ballHomePlayerScoreIndex]}:{ball[ballAwayPlayerScoreIndex]}
        </span>
      </span>
    </div>
  ) : null;

const BallDetailsBar: React.FC<BallDetailsBarProps> = ({
  videoData,
  roundResults,
  videoRef,
  token,
  matchId,
  setVideoData,
}) => {
  if (
    !roundResults ||
    roundResults.length === 0 ||
    !roundResults[videoData.currentRound]
  ) {
    return (
      <div className="ball-details-bar" style={{ color: 'white' }}>
        Loading...
      </div>
    );
  }

  const currentRound = roundResults[videoData.currentRound];
  const currentBall = currentRound[1][videoData.currentBall];

  const totalRounds = roundResults.length;
  const totalBallsInCurrentRound = currentRound[1].length;

  const skipToTime = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time + 0.1;
    }
  };

  const getPrevBall = () => {
    if (videoData.currentBall > 0) {
      return { ball: currentRound[1][videoData.currentBall - 1] };
    } else if (videoData.currentRound > 0) {
      const prevRound = roundResults[videoData.currentRound - 1];
      return { ball: prevRound[1][prevRound[1].length - 1] };
    }
    return null;
  };

  const getNextBall = () => {
    if (videoData.currentBall < totalBallsInCurrentRound - 1) {
      return { ball: currentRound[1][videoData.currentBall + 1] };
    } else if (videoData.currentRound < totalRounds - 1) {
      const nextRound = roundResults[videoData.currentRound + 1];
      return { ball: nextRound[1][0] };
    }
    return null;
  };

  async function updateBall(ballData: BallData) {
    const params: UpdateBallDataParams = {
      filename: null,
      match_id: matchId,
      primary_attribute: `video edited (id) : ${matchId}`,
      secondary_attribute: `add ball to round id : ${ballData.round_id} ${ballData.start_time}s to ${ballData.end_time}s`,
      new_row: ballData,
    };

    try {
      const response = await updateBallDataAPI(params, token);
      console.log('Ball data updated successfully:', response);
      return response;
    } catch (error) {
      console.error('Failed to update ball data:', error);
      throw error;
    }
  }

  async function deleteBall(ballId: number, roundId: number) {
    const params: DeleteBallDataParams = {
      ball_id: ballId,
      round_id: roundId,
      match_id: matchId,
      primary_attribute: `video edited (id) : ${matchId}`,
      secondary_attribute: `delete ball from round id : ${roundId}`,
    };

    try {
      const response = await deleteBallDataAPI(params, token);
      console.log('Ball data deleted successfully:', response);
      return response;
    } catch (error) {
      console.error('Failed to delete ball data:', error);
      throw error;
    }
  }

  const handleInsertBall = async (newBallData: BallData) => {
    try {
      await updateBall(newBallData);
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
      }));

      if (videoRef.current) {
        await videoRef.current.play();
      } else {
        console.warn('Video reference is not available');
      }
    } catch (error) {
      console.error('Error inserting ball:', error);
    }
  };

  const handleDeleteBall = async (
    ballIndex: number,
    roundId: number,
    ballId: number,
  ) => {
    try {
      await deleteBall(ballId, roundId);
      setVideoData((prevState) => ({
        ...prevState,
        refresh: true,
        currentBall:
          ballIndex === videoData.currentBall
            ? Math.max(0, ballIndex - 1)
            : videoData.currentBall,
      }));
    } catch (error) {
      console.error('Error deleting ball:', error);
    }
  };

  const renderInsertButton = (
    ballIndex: number,
    ballData: number[],
    position: 'before' | 'after',
  ) => {
    const isRoundChange =
      (position === 'after' && ballIndex === totalBallsInCurrentRound - 1) ||
      (position === 'before' && ballIndex === 0);

    const roundChangeIndicator = (
      <Divider
        orientation="vertical"
        flexItem
        className="round-change-indicator"
      />
    );

    return (
      <>
        {isRoundChange && position === 'before' && roundChangeIndicator}
        <Tooltip
          title={`在球${position === 'before' ? '前' : '後'}添加新球${
            isRoundChange ? '(這回合）' : ''
          }`}
          arrow
        >
          <IconButton
            onClick={() =>
              handleInsertBall(
                convertToBallData(
                  ballData,
                  true,
                  position === 'after',
                  isRoundChange,
                ),
              )
            }
            className={`add-ball-button ${isRoundChange ? 'round-change' : ''}`}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        {isRoundChange && position === 'after' && roundChangeIndicator}
      </>
    );
  };

  if (!currentBall) {
    return (
      <div
        className="ball-details-bar"
        style={{ backgroundColor: 'transparent' }}
      >
        No ball data available
        {renderInsertButton(
          0,
          [
            -1,
            roundResults[videoData.currentRound][roundDataIndex][roundIdIndex],
            roundResults[videoData.currentRound][roundDataIndex][
              roundStartTimeIndex
            ] + 1,
            roundResults[videoData.currentRound][roundDataIndex][
              roundStartTimeIndex
            ] + 5,
            0,
            0,
            false,
            false,
            false,
          ],
          'before',
        )}
      </div>
    );
  }

  const prevBall = getPrevBall();
  const nextBall = getNextBall();

  return (
    <div className="ball-details-bar">
      {prevBall && (
        <div className="prev-ball">
          <BallDetails
            ball={prevBall.ball}
            onClick={() => skipToTime(prevBall.ball[ballStartTimeIndex])}
            onDelete={() =>
              handleDeleteBall(
                videoData.currentBall - 1,
                prevBall.ball[ballRoundIdIndex],
                prevBall.ball[ballIdIndex],
              )
            }
          />
        </div>
      )}
      {renderInsertButton(
        videoData.currentBall,
        currentRound[1][videoData.currentBall],
        'before',
      )}
      <div className="current-ball">
        <BallDetails
          ball={currentBall}
          onDelete={() =>
            handleDeleteBall(
              videoData.currentBall,
              currentRound[1][videoData.currentBall][ballRoundIdIndex],
              currentRound[1][videoData.currentBall][ballIdIndex],
            )
          }
        />
      </div>
      {renderInsertButton(
        videoData.currentBall,
        currentRound[1][videoData.currentBall],
        'after',
      )}
      {nextBall && (
        <div className="next-ball">
          <BallDetails
            ball={nextBall.ball}
            onClick={() => skipToTime(nextBall.ball[ballStartTimeIndex])}
            onDelete={() =>
              handleDeleteBall(
                videoData.currentBall + 1,
                nextBall.ball[ballRoundIdIndex],
                nextBall.ball[ballIdIndex],
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default BallDetailsBar;
