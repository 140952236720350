import React, { useState, useEffect } from 'react';
import {
  ExpandMore,
  ExpandLess,
  Close,
  Refresh,
  SportsTennis,
} from '@mui/icons-material';
import {
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Switch,
} from '@mui/material';

import Cookies from 'js-cookie';
import { getProgressDataAPI, updateProgressDataAPI } from 'src/api/vqService';
import { UpdateDataParams } from 'types/vqTypes';

import './VideoAnalysisProgress.scss';

interface VideoData {
  refresh: boolean;
}

interface VideoAnalysisProgressProps {
  matchId: number;
  isAdmin: boolean;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
}

export type ProgressDataKey =
  | 'score_coordinates'
  | 'table_coordinates'
  | 'starting_player_id'
  | 'topright_player_id'
  | 'ocrcompleted'
  | 'ocrchecked'
  | 'dpcompleted'
  | 'dpchecked'
  | 'balldetectioncompleted'
  | 'formatchecked'
  | 'ispriority'
  | 'completiondate';

export interface ProgressDataItem {
  key: ProgressDataKey;
  label: string;
  completed: boolean;
  date: string | null;
}

interface ProgressGroup {
  name: string;
  items: ProgressDataItem[];
}

interface ProgressData {
  [key: string]: any;
}

// Dummy data for progressData
const dummyProgressData: ProgressData = {
  score_coordinates: [
    [100, 200],
    [300, 400],
  ],
  table_coordinates: [
    [50, 50],
    [450, 450],
  ],
  starting_player_id: 12345,
  topright_player_id: 67890,
  ocrcompleted: true,
  ocrchecked: true,
  ocrdate: '2023-05-15T10:30:00Z',
  ocrcheckeddate: '2023-05-15T11:00:00Z',
  dpcompleted: true,
  dpchecked: false,
  dpdate: '2023-05-15T12:00:00Z',
  dpcheckeddate: null,
  balldetectioncompleted: true,
  balltrackdate: '2023-05-15T13:00:00Z',
  formatchecked: true,
  formatcheckeddate: '2023-05-15T14:00:00Z',
  ispriority: false,
  completiondate: '2023-05-15T15:00:00Z',
};
const VideoAnalysisProgress: React.FC<VideoAnalysisProgressProps> = ({
  isAdmin,
  matchId,
  setVideoData,
}) => {
  const token = Cookies.get('token');

  const [progressData, setProgressData] =
    useState<ProgressData>(dummyProgressData);
  const [open, setOpen] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);

  const progressGroups: ProgressGroup[] = [
    {
      name: '比賽數據分析',
      items: [
        {
          key: 'score_coordinates',
          label: '得分牌定位',
          completed: Boolean(progressData?.score_coordinates?.length > 0),
          date: null,
        },
        {
          key: 'table_coordinates',
          label: '球檯定位',
          completed: Boolean(progressData?.table_coordinates?.length > 0),
          date: null,
        },
        {
          key: 'starting_player_id',
          label: '發球方',
          completed: Boolean(progressData?.starting_player_id),
          date: null,
        },
        {
          key: 'topright_player_id',
          label: '右上角球員',
          completed: Boolean(progressData?.topright_player_id),
          date: null,
        },
      ],
    },
    {
      name: '光學計分識別',
      items: [
        {
          key: 'ocrcompleted',
          label: '計分掃描完成',
          completed: Boolean(progressData?.ocrcompleted),
          date: progressData?.ocrdate ?? null,
        },
        {
          key: 'ocrchecked',
          label: '計分驗證',
          completed: Boolean(progressData?.ocrchecked),
          date: progressData?.ocrcheckeddate ?? null,
        },
      ],
    },
    {
      name: '球路分析',
      items: [
        {
          key: 'dpcompleted',
          label: '軌跡分析完成',
          completed: Boolean(progressData?.dpcompleted),
          date: progressData?.dpdate ?? null,
        },
        {
          key: 'dpchecked',
          label: '軌跡驗證',
          completed: Boolean(progressData?.dpchecked),
          date: progressData?.dpcheckeddate ?? null,
        },
        {
          key: 'balldetectioncompleted',
          label: '球追蹤完成',
          completed: Boolean(progressData?.balldetectioncompleted),
          date: progressData?.balltrackdate ?? null,
        },
      ],
    },
    {
      name: '輔助數據',
      items: [
        {
          key: 'formatchecked',
          label: '影片格式檢查',
          completed: Boolean(progressData?.formatchecked),
          date: progressData?.formatcheckeddate ?? null,
        },
        {
          key: 'ispriority',
          label: '優先分析',
          completed: Boolean(progressData?.ispriority),
          date: null,
        },
      ],
    },
  ];
  const calculateGroupProgress = (group: ProgressGroup) => {
    const completedItems = group.items.filter((item) => item.completed).length;
    return (completedItems / group.items.length) * 100;
  };

  const calculateOverallProgress = () => {
    const relevantGroups = progressGroups.slice(0, 3); // Exclude the last group (Auxiliary Data)
    const totalItems = relevantGroups.reduce(
      (acc, group) => acc + group.items.length,
      0,
    );
    const completedItems = relevantGroups.reduce(
      (acc, group) => acc + group.items.filter((item) => item.completed).length,
      0,
    );
    return (completedItems / totalItems) * 100;
  };

  useEffect(() => {
    const newOverallProgress = calculateOverallProgress();
    setOverallProgress(newOverallProgress);
  }, [progressData]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRefresh = () => {
    setVideoData((prevState: VideoData) => ({
      ...prevState,
      refresh: true,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProgressDataAPI(
          { matchId: matchId, videoId: null },
          token as string,
        );
        setProgressData(response);
      } catch (error) {
        console.log('error getting progress data');
      }
    };

    fetchData();
  }, [matchId, token]);

  const handleToggle = async (key: string, currentValue: boolean) => {
    try {
      const params: UpdateDataParams = {
        matchId: matchId,
        [key]: !currentValue,
      };
      const response = await updateProgressDataAPI(params, token as string);
      if (response.status === 'success') {
        setProgressData((prevData) => ({
          ...prevData,
          [key]: !currentValue,
          [`${key}date`]: new Date().toISOString(),
        }));
      }
    } catch (error) {
      console.error(`Error updating ${key}:`, error);
    }
  };

  const renderToggleButton = (item: ProgressDataItem) => {
    if (['ocrchecked', 'dpchecked', 'ispriority'].includes(item.key)) {
      return (
        <Box display="flex" alignItems="center">
          <Switch
            checked={item.completed}
            onChange={() => handleToggle(item.key, item.completed)}
            color="primary"
            size="small"
          />
          {item.completed && item.date && (
            <Typography variant="caption" style={{ marginLeft: '8px' }}>
              {new Date(item.date).toLocaleString()}
            </Typography>
          )}
        </Box>
      );
    }
    return item.completed ? (
      item.date && (
        <Typography className="item-date">
          {item.date.replace(/\s?GMT\s?/g, '')}
        </Typography>
      )
    ) : (
      <Close className="cross-icon" />
    );
  };

  //prettier-ignore
  return (
    <div className="analysis-container">
      <div className="header">
        <Typography variant="h6" className="title">
          乒乓球分析進度
        </Typography>
        <IconButton
          onClick={handleRefresh}
          size="small"
          aria-label="refresh"
          className="refresh-button"
        >
          <Refresh />
        </IconButton>
      </div>
      <Tooltip
        title={`總進度: ${overallProgress.toFixed(2)}%`}
        arrow
        placement="top"
      >
        <LinearProgress
          variant="determinate"
          value={overallProgress}
          className="progress-bar"
        />
      </Tooltip>
      <Button
        onClick={handleClickOpen}
        fullWidth
        variant="outlined"
        startIcon={open ? <ExpandLess /> : <ExpandMore />}
        className="control-panel-button"
      >
        {open ? '收起詳細資訊' : '查看詳細資訊'}
      </Button>
      <Dialog
        className="analysis-dialog"
        onClose={handleClose}
        open={open}
        classes={{ paper: 'dialog-paper' }}
      >
        <DialogTitle className="dialog-title">
          分析詳情
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-button"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          {progressGroups.map((group, index) => (
            <Accordion key={index} className="analysis-accordion">
              <AccordionSummary
                expandIcon={<ExpandMore className="expand-icon" />}
              >
                <Typography className="group-title">{group.name}</Typography>
                {index < 3 && (
                  <>
                    <LinearProgress
                      variant="determinate"
                      value={calculateGroupProgress(group)}
                      className="group-progress-bar"
                    />
                    {isAdmin && <Typography className="group-percentage">{`${calculateGroupProgress(
                      group,
                    ).toFixed(2)}%`}</Typography>}
                  </>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {group.items.map((item, itemIndex) => (
                  <Box key={itemIndex} className="item-box">
                    <SportsTennis
                      className={`tennis-icon ${item.completed ? 'completed' : ''}`}
                    />
                    <Typography className="item-label">{item.label}</Typography>
                    {renderToggleButton(item)}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoAnalysisProgress;
