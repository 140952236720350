import { useState } from 'react';
import UserManagement from './UserManagement';
import './Users.scss';

const Users = ({ userData, setRefreshData }) => {
  const [value, setValue] = useState('one');

  return (
    <main className="users-container">
      <div className="content-wrapper">
        <UserManagement userData={userData} setRefreshData={setRefreshData} />
      </div>
    </main>
  );
};

export default Users;
