// shared/PlayerSelectionSection.tsx
import React from "react";
import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PlayerOption } from "../types/graph.types";

interface PlayerSelectionProps {
  label: string;
  value: PlayerOption | null;
  onChange: (event: React.SyntheticEvent, value: PlayerOption | null) => void;
  options: PlayerOption[];
  disabled?: boolean;
  isMobile: boolean;
  placeholderKey: string;
  altPlaceholderKey?: string;
}

export const PlayerSelection = ({
  label,
  value,
  onChange,
  options,
  disabled,
  isMobile,
  placeholderKey,
  altPlaceholderKey,
}: PlayerSelectionProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <Autocomplete<PlayerOption>
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            size={isMobile ? "small" : "medium"}
            placeholder={t(
              disabled && altPlaceholderKey ? altPlaceholderKey : placeholderKey
            )}
          />
        )}
      />
    </Box>
  );
};
