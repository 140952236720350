import { useEffect } from 'react';

import { LineChart } from '@mui/x-charts/LineChart';

import { ballDataIndex, ballStartTimeIndex } from 'src/constants';

import './ScoreChart.scss';

const ScoreChart = ({
  score1,
  score2,
  matchData,
  currentRound,
  yAxis,
  xAxis,
  chartWidth,
  chartHeight,
  videoRef,
  roundResults,
  setVideoData,
}) => {
  // go to ball from scoreboard
  useEffect(() => {
    const handleClick = (event) => {
      if (
        event.type === 'click' ||
        (event.type === 'touchstart' && event.touches.length === 1)
      ) {
        // Check if showScorePlot is true and the event is a click or a single touch on a mobile device
        const divElement = document.querySelector('.score-plot-container');
        if (divElement) {
          const svgElement = divElement.querySelector('svg');
          if (svgElement) {
            const markElements = svgElement.querySelectorAll(
              '.MuiMarkElement-root',
            );
            const markElementsArray = Array.from(markElements);
            setTimeout(() => {
              const highlightedIndex = markElementsArray.findIndex((element) =>
                element.classList.contains('MuiMarkElement-highlighted'),
              );
              if (highlightedIndex !== -1) {
                videoRef.current.currentTime =
                  roundResults[currentRound][ballDataIndex][highlightedIndex][
                    ballStartTimeIndex
                  ] + 0.01;
                setVideoData((prevStatus) => ({
                  ...prevStatus,
                  skipped: true,
                }));
              }
            }, 100);
          }
        }
      }
    };

    const element = document.querySelector('.score-plot-container');
    if (element) {
      element.addEventListener('click', handleClick);
    }

    // Clean up the event listener when the component unmounts or when currentRound changes
    return () => {
      if (element) {
        element.removeEventListener('click', handleClick);
      }
    };
  }, [currentRound]);

  return (
    <div className="scoreChart-accordion">
      <h3 className="scoreChart-title">分數圖表</h3>
      <div className="score-plot-container">
        {score1 && score1[currentRound] ? (
          <LineChart
            series={[
              {
                curve: 'monotoneX',
                data: score1[currentRound],
                label:
                  matchData.homePlayer.length <= 15
                    ? matchData.homePlayer
                    : matchData.homePlayer.slice(0, 15) + '...',
              },
              {
                curve: 'monotoneX',
                data: score2[currentRound],
                label:
                  matchData.awayPlayer.length <= 15
                    ? matchData.awayPlayer
                    : matchData.awayPlayer.slice(0, 15) + '...',
              },
            ]}
            yAxis={[{ data: yAxis[currentRound], tickMinStep: 1, min: 0 }]}
            xAxis={[{ data: xAxis[currentRound], tickMinStep: 2, min: 1 }]}
            width={chartWidth}
            height={chartHeight}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'top', horizontal: 'middle' },
              },
            }}
          />
        ) : (
          <p className="no-score-plot">無分數圖表</p>
        )}
      </div>
    </div>
  );
};

export default ScoreChart;
