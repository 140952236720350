import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GameClickInfo } from '../types/graph.types';

interface GameDetailsBoxProps {
  game: GameClickInfo;
  onViewDetails: (game: GameClickInfo) => void;
}

export const GameDetailsBox: React.FC<GameDetailsBoxProps> = ({ game, onViewDetails }) => {
  const { t } = useTranslation();

  return (
    <Box
      key={game.gameId}
      sx={{
        mb: 2,
        p: 2,
        bgcolor: "background.default",
        borderRadius: 1,
        border: 1,
        borderColor: "divider",
      }}
    >
      <Typography variant="subtitle2">{game.metadata.eventName}</Typography>
      <Typography variant="body2" color="text.secondary">
        {game.metadata.date} - {game.metadata.round}
      </Typography>
      <Typography variant="body2">
        {game.metadata.team1} vs {game.metadata.team2}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Score: {game.metadata.finalscore1} - {game.metadata.finalscore2}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={() => onViewDetails(game)}
        sx={{ mt: 1 }}
      >
        {t("selectionDrawer.viewDetails")}
      </Button>
    </Box>
  );
};
