import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Cookies from 'js-cookie';

import BallDetailsBar from './InputPanel/BallDetailsBar';
import EditDpPanel from './InputPanel/EditDpPanel';
import EditRoundPanel from './EditRoundPanel';
import { formatTime, getLastScore } from 'src/utils/utils';

import {
  roundEndTimeIndex,
  roundStartTimeIndex,
  ballDataIndex,
  roundDataIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

import './ManualInput.scss';

interface UserInfo {
  isCoach: boolean;
  admin: boolean;
}

interface VideoData {
  numberOfRound: number;
  refresh: boolean;
  currentRound: number;
  currentBall: number;
  filename: string;
  currentTotalScore: number;
}

interface ManualInputProps {
  userInfo: UserInfo;
  havePlayerinfo: boolean;
  videoData: VideoData;
  setVideoData: React.Dispatch<React.SetStateAction<VideoData>>;
  matchId: number;
  roundResults: any[];
  videoRef: React.RefObject<HTMLVideoElement>;
  rawDpResults: any;
}

const ManualInput: React.FC<ManualInputProps> = ({
  havePlayerinfo,
  videoData,
  setVideoData,
  matchId,
  roundResults,
  videoRef,
  rawDpResults,
}) => {
  const token = Cookies.get('token') || '';
  const [ballData, setBallData] = useState<any[]>([]);
  const [selectedRound, setSelectedRound] = useState<number>(videoData.currentRound);
  const [selectedBall, setSelectedBall] = useState<number>(videoData.currentBall);
  const [selectedDp, setSelectedDp] = useState<number>(videoData.currentTotalScore);
  const dpResults = rawDpResults[selectedRound] ? rawDpResults[selectedRound] : [];
  useEffect(() => {
    if (roundResults && roundResults[selectedRound]) {
      setBallData(roundResults[selectedRound][ballDataIndex]);
    } else {
      setBallData([]);
    }
  }, [roundResults, selectedRound, selectedBall]);

  const roundNumbers = Array.from(
    { length: videoData.numberOfRound },
    (_, rIndex) => rIndex + 1,
  );

  // const permission = userInfo.isCoach || userInfo.admin;
  const permission = true;

  //prettier-ignore
  return (
    <>
      {permission && (
        <div className="manual-input-main-container">
          {havePlayerinfo ? (<>
            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="edit-round-panel-content"
                id="edit-round-panel-header"
              >
                {roundResults[selectedRound] ? (
                  <div className="current-round-info-panel">
                    <span className="round-number">
                      回合{' '}
                      <span className="number">{selectedRound + 1}</span>
                    </span>
                    <span className="time-info">
                      {' '}
                      <span className="start-time">
                        {formatTime(
                          roundResults[selectedRound][roundDataIndex][
                          roundStartTimeIndex
                          ],
                        )}
                      </span>
                      {' -- '}
                      <span className="end-time">
                        {formatTime(
                          roundResults[selectedRound][roundDataIndex][
                          roundEndTimeIndex
                          ],
                        )}
                      </span>
                    </span>
                    <span className="score-info">
                      {' '}
                      <span className="score">
                        {getLastScore(roundResults, selectedRound)}
                      </span>
                    </span>
                  </div>
                ) : (
                  <p
                    style={{ color: 'white', textAlign: 'center', width: '100%' }}
                  >
                    沒有回合
                  </p>
                )}
              </AccordionSummary>
              <AccordionDetails className="round-summary-container">
                <EditRoundPanel
                  currentRound={selectedRound}
                  roundNumbers={roundNumbers}
                  roundResults={roundResults}
                  matchId={matchId}
                  token={token}
                  setVideoData={setVideoData}
                  setSelectedRound={setSelectedRound}
                  videoRef={videoRef}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {roundResults[selectedRound] &&
                  roundResults[selectedRound][ballDataIndex][
                  selectedBall
                  ] ? (
                  <div className="current-round-info-panel">
                    <span className="round-number">
                      球{' '}
                      <span className="number">{selectedBall + 1}</span>
                    </span>
                    <span className="time-info">
                      <span className="start-time">
                        {formatTime(
                          roundResults[selectedRound][ballDataIndex][
                          selectedBall
                          ][ballStartTimeIndex],
                        )}
                      </span>
                      {' -- '}
                      <span className="end-time">
                        {formatTime(
                          roundResults[selectedRound][ballDataIndex][
                          selectedBall
                          ][ballEndTimeIndex],
                        )}
                      </span>
                    </span>
                    <span className="score-info">
                      {' '}
                      <span className="score">
                        {
                          roundResults[selectedRound][ballDataIndex][
                          selectedBall
                          ][ballHomePlayerScoreIndex]
                        }{' '}
                        :{' '}
                        {
                          roundResults[selectedRound][ballDataIndex][
                          selectedBall
                          ][ballAwayPlayerScoreIndex]
                        }
                      </span>
                    </span>
                  </div>
                ) : (
                  <p
                    style={{ color: 'white', textAlign: 'center', width: '100%' }}
                  >
                    沒有球
                  </p>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {roundResults && (
                  <BallDetailsBar
                    roundResults={roundResults}
                    videoRef={videoRef}
                    token={token}
                    matchId={matchId}
                    selectedBall={selectedBall}
                    selectedRound={selectedRound}
                    setVideoData={setVideoData}
                    setSelectedBall={setSelectedBall}
                    setSelectedRound={setSelectedRound}
                    setSelectedDp={setSelectedDp}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            {ballData[selectedBall] && (
              <div className="ball-input-main-panel">
                <EditDpPanel
                  currentRound={selectedRound}
                  currentBall={selectedBall}
                  setVideoData={setVideoData}
                  filename={videoData.filename}
                  ballData={ballData[selectedBall]}
                  videoRef={videoRef}
                  dpResults={dpResults ? dpResults.find((subArr: number[]) => subArr[3] === selectedDp - 1) || [] : []}
                />
              </div>
            )}
          </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p>
                no player info
              </p>
              <Link
                to={`/edit-metadata/${matchId}/0`}
                style={{ color: 'white' }}
                title="add players"
              >
                添加名稱
              </Link>
            </div>)}
          <div className="padding"></div>
        </div>
      )}
    </>
  );
};

export default ManualInput;
