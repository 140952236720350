import { Box, styled } from "@mui/material";

// File: app/common/utils/graph.utils.ts
// import { connectionsData, playerData } from '../data/graphData';
import {
  ConnectionsData,
  EnhancedLabelStyle,
  EnhancedLabelStyleOptions,
  GameModeOption,
  PlayerData,
} from "../types/graph.types";

export const GAME_MODES: GameModeOption[] = [
  // { value: "COM_single_player", label: "Singles" },
  { value: "COM_FSingle", label: "Women's Singles" },
  { value: "COM_MSingle", label: "Men's Singles" },
  // { value: "COM_multi_player", label: "All Doubles" },
  { value: "COM_FDouble", label: "Women's Doubles" },
  { value: "COM_MDouble", label: "Men's Doubles" },
  { value: "COM_MixedDouble", label: "Mixed Doubles" },
];

export const findCommonGameIds = (
  player1: string,
  player2: string,
  connectionsData: ConnectionsData
): number[] => {
  const player1Games = connectionsData[player1] || [];
  const player2Games = connectionsData[player2] || [];
  return player1Games.filter((id) => player2Games.includes(id));
};

export const getGameCount = (
  player1: string,
  player2: string,
  connectionsData: ConnectionsData
): number => {
  return findCommonGameIds(player1, player2, connectionsData).length;
};

export const findConnectedPlayers = (
  playerId: string,
  connectionsData: ConnectionsData,
  playerData: PlayerData
): string[] => {
  const connectedPlayers = new Set<string>();

  Object.entries(connectionsData).forEach(([id, connections]) => {
    if (playerData[id]) {
      const playerConnections = connectionsData[playerId] || [];
      const hasCommonGame = connections.some((connection) =>
        playerConnections.includes(connection)
      );
      if (hasCommonGame && id !== playerId) {
        connectedPlayers.add(id);
      }
    }
  });

  return Array.from(connectedPlayers);
};

export const getEnhancedLabelStyle = (
  options: Partial<EnhancedLabelStyleOptions> = {}
): EnhancedLabelStyle => ({
  labelSize: options.labelSize ?? 16,
  labelBorderSize: options.labelBorderSize ?? 4,
  labelBackgroundColor:
    options.labelBackgroundColor ?? "rgba(255, 255, 255, 0.8)",
  labelBorderColor: options.labelBorderColor ?? "rgba(255, 255, 255, 0.8)",
  shadowSize: options.shadowSize ?? 10,
  shadowColor: options.shadowColor ?? "rgba(0, 0, 0, 0.2)",
  shadowOffsetX: options.shadowOffset?.x ?? 2,
  shadowOffsetY: options.shadowOffset?.y ?? 2,
});

// Styled components
export const WIPBanner = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.contrastText,
  padding: theme.spacing(0.5),
  fontSize: "0.75rem",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem",
    padding: theme.spacing(0.25),
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 3,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

export const LoadingOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  zIndex: 1000,
});
