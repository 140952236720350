import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    CardContent,
    IconButton,
    CircularProgress
} from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';

interface VideoPlayerProps {
    videoUrl: string;
    startTime: number;
    endTime: number;
}

interface DebugTiming {
    loadStart?: number;
    loadEnd?: number;
    metadataLoaded?: number;
    firstFrameLoaded?: number;
    canPlay?: number;
}

const OptimizedVideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, startTime, endTime }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [debugLog, setDebugLog] = useState<string[]>([]);
    const [timing, setTiming] = useState<DebugTiming>({});

    const addDebugLog = (message: string) => {
        console.log(`[VideoPlayer Debug] ${message}`);
        setDebugLog(prev => [...prev, message]);
    };

    const formatTime = (startTime?: number) => {
        if (!startTime) return 'N/A';
        const elapsed = performance.now() - startTime;
        return `${elapsed.toFixed(2)}ms`;
    };

    useEffect(() => {
        const loadVideo = async () => {
            if (!videoRef.current) {
                addDebugLog('Video ref is not available');
                return;
            }

            const newTiming: DebugTiming = {
                loadStart: performance.now()
            };
            setTiming(newTiming);

            addDebugLog(`Starting to load video from URL: ${videoUrl}`);
            addDebugLog(`Start time: ${startTime}, End time: ${endTime}`);

            try {
                addDebugLog('Attempting direct video playback');
                const video = videoRef.current;
                video.src = videoUrl;

                // Add event listeners for debugging
                video.addEventListener('loadstart', () => {
                    setTiming(prev => ({ ...prev, loadStart: performance.now() }));
                    addDebugLog('Video loadstart event fired');
                });

                video.addEventListener('loadedmetadata', () => {
                    setTiming(prev => ({ ...prev, metadataLoaded: performance.now() }));
                    addDebugLog('Video loadedmetadata event fired');
                    addDebugLog(`Video duration: ${video.duration}`);
                    addDebugLog(`Video readyState: ${video.readyState}`);
                    video.currentTime = startTime;
                });

                video.addEventListener('loadeddata', () => {
                    setTiming(prev => ({ ...prev, firstFrameLoaded: performance.now() }));
                    addDebugLog('First frame loaded');
                });

                video.addEventListener('canplay', () => {
                    setTiming(prev => ({ ...prev, canPlay: performance.now() }));
                    addDebugLog('Video canplay event fired');
                    setIsLoading(false);
                });

                video.addEventListener('error', (e) => {
                    const error = video.error;
                    addDebugLog(`Video error: ${error?.code} - ${error?.message}`);
                    setTiming(prev => ({ ...prev, loadEnd: performance.now() }));
                });

                video.addEventListener('emptied', () => addDebugLog('Video emptied event fired'));

            } catch (error) {
                addDebugLog(`Error in loadVideo: ${error}`);
                setTiming(prev => ({ ...prev, loadEnd: performance.now() }));
                setIsLoading(false);
            }
        };

        loadVideo();

        return () => {
            if (videoRef.current) {
                addDebugLog('Cleaning up video element');
                videoRef.current.src = '';
            }
        };
    }, [videoUrl, startTime]);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handleTimeUpdate = () => {
            if (video.currentTime >= endTime) {
                video.currentTime = startTime;
            }
            if (video.currentTime < startTime) {
                video.currentTime = startTime;
            }
        };

        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => video.removeEventListener('timeupdate', handleTimeUpdate);
    }, [startTime, endTime]);

    const togglePlayPause = () => {
        if (!videoRef.current) {
            addDebugLog('Toggle attempted but video ref is null');
            return;
        }


        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(error => {
                addDebugLog(`Error playing video: ${error}`);
            });
        }
        // state will delay thus we switch
        addDebugLog(`Toggling playback. Current state: ${isPlaying ? 'paused' : 'playing'}`);
        setIsPlaying(!isPlaying);
    };

    return (
        <Card sx={{ maxWidth: '100%', position: 'relative' }}>
            <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
                {isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress sx={{ color: 'white' }} />
                    </div>
                )}
                <video
                    ref={videoRef}
                    style={{ width: '100%', display: 'block' }}
                    playsInline
                    autoPlay
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '16px',
                        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                    }}
                >
                    <IconButton
                        onClick={togglePlayPause}
                        sx={{
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                        }}
                        aria-label={isPlaying ? 'Pause' : 'Play'}
                    >
                        {isPlaying ? <Pause /> : <PlayArrow />}
                    </IconButton>
                </div>

                {/* Debug log display */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: 'rgba(0,0,0,0.7)',
                        color: 'white',
                        padding: '10px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        fontSize: '12px',
                        zIndex: 2,
                    }}
                >
                    <div style={{ marginBottom: '10px', borderBottom: '1px solid white' }}>
                        <div>URL: {videoUrl}</div>
                        <div>Start: {startTime}s End: {endTime}s</div>
                        <div>Load Start: {formatTime(timing.loadStart)}</div>
                        <div>Metadata Loaded: {formatTime(timing.metadataLoaded)}</div>
                        <div>First Frame: {formatTime(timing.firstFrameLoaded)}</div>
                        <div>Can Play: {formatTime(timing.canPlay)}</div>
                        <div>Total Load Time: {timing.canPlay ?
                            `${(timing.canPlay - (timing.loadStart || 0)).toFixed(2)}ms` :
                            'Loading...'}</div>
                    </div>
                    {debugLog.map((log, i) => (
                        <div key={i}>{log}</div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default OptimizedVideoPlayer;