import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import HomeSidePanel from 'src/components/HomeSidePanel/HomeSidePanel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Tabs, Tab } from '@mui/material';
import { fetchMatchDataAPI } from 'src/api/videoService';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchPlaylistDataAPI } from 'src/api/playlistService';

import HomeOptionsBar from './HomeOptionsBar/HomeOptionsBar';
import PaginatedVideoList from './PaginatedVideoList';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  dateIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  customTagIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  eventIndex,
  categoryIndex,
  isDoubleIndex,
  viewCountIndex,
  uploadUserIndex,
  parentEventIndex,
} from 'src/constants';

import './Home.scss';

const CACHE_EXPIRY_HOURS = 1;

const getWithExpiry = (key) => {
  const item = localStorage.getItem(key);
  if (!item) return null;

  const parsed = JSON.parse(item);
  const now = new Date();

  // Check if the item has expired
  if (parsed.expiry && now.getTime() > parsed.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return parsed.value;
};

const setWithExpiry = (key, value) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + CACHE_EXPIRY_HOURS * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

// Default filter criteria
const defaultFilterCriteria = {
  matchName: '',
  matchDate: '',
  stages: [],
  location: '',
  events: [],
  categories: [],
  startDate: '',
  endDate: '',
  customTags: [],
};

// Custom hooks for filter and sort criteria
const useFilterCriteria = () => {
  const [filterCriteria, setFilterCriteria] = useState(() => {
    const cachedCriteria = getWithExpiry('filterCriteria');
    return cachedCriteria || defaultFilterCriteria;
  });

  const updateFilterCriteria = (newCriteria) => {
    setFilterCriteria(newCriteria);
    setWithExpiry('filterCriteria', newCriteria);
  };

  return [filterCriteria, updateFilterCriteria];
};

const useSortCriteria = () => {
  const [sortCriteria, setSortCriteria] = useState(() => {
    const cachedSortCriteria = getWithExpiry('sortCriteria');
    return cachedSortCriteria ?? 0;
  });

  const updateSortCriteria = (newCriteria) => {
    setSortCriteria(newCriteria);
    setWithExpiry('sortCriteria', newCriteria);
  };

  return [sortCriteria, updateSortCriteria];
};

const Home = ({ userInfo }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const token = Cookies.get('token');
  const [videoList, setVideoList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [CustomTagList, setCustomTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filterCriteria, setFilterCriteria] = useFilterCriteria();
  const [sortCriteria, setSortCriteria] = useSortCriteria();
  const [filterStats, setFilterStats] = useState({
    stageCounts: {},
    subEventCounts: {},
    customTagCounts: {},
    categoryCounts: {},
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchMatchData = useCallback(async () => {
    setIsLoading(true);
    const {
      matchName,
      matchDate,
      stages,
      location,
      events,
      categories,
      startDate,
      endDate,
      customTags,
    } = filterCriteria;

    try {
      const matchResponse = await fetchMatchDataAPI(
        {
          match_name: matchName,
          match_date: matchDate,
          location: location,
          stage: stages.join(','),
          accountType: userInfo.accountType,
          events: events.join(','),
          categories: categories.join(','),
          startDate: startDate,
          endDate: endDate,
          sortCriteria: sortCriteria,
          customTags: customTags.join(','),
          admin: userInfo.admin,
          user: userInfo.identifier,
        },
        token,
      );

      const responseData = matchResponse.videoData;
      setFilterStats({
        stageCounts: matchResponse.stageCounts,
        subEventCounts: matchResponse.subEventCounts,
        customTagCounts: matchResponse.customTagCounts,
        categoryCounts: matchResponse.categoryCounts,
      });

      const mergedData = responseData.map((videoData) => ({
        matchId: videoData[matchIdIndex],
        videoName: videoData[videoNameIndex],
        matchDescription: videoData[matchDescriptionIndex],
        stage: videoData[stageIndex],
        date: videoData[dateIndex].replace(/\s\d{2}:\d{2}:\d{2}\s\w{3}$/, ''),
        location: videoData[locationIndex],
        homePlayer: videoData[homePlayerIndex],
        awayPlayer: videoData[awayPlayerIndex],
        fileUrl: `${process.env.PUBLIC_URL}/videos/${videoData[
          urlIndex
        ].substring(0, videoData[urlIndex].lastIndexOf('.'))}/${
          videoData[urlIndex]
        }`,
        TNUrl: `${process.env.PUBLIC_URL}/videos/${videoData[
          urlIndex
        ].substring(0, videoData[urlIndex].lastIndexOf('.'))}/${
          videoData[thumbnailIndex]
        }`,
        report: videoData[reportIndex],
        parentEvent: videoData[parentEventIndex],
        event: videoData[eventIndex],
        subEvent: videoData[subEventIndex],
        category: videoData[categoryIndex],
        isDouble: videoData[isDoubleIndex],
        viewCount: videoData[viewCountIndex],
        uploadUser: videoData[uploadUserIndex],
        customTag: videoData[customTagIndex],
        homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
        awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
      }));

      setVideoList(mergedData);
    } catch (error) {
      console.error('Error fetching and merging data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [filterCriteria, sortCriteria, token]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch playlists
        const playlistResponse = await fetchPlaylistDataAPI(
          { user_name: userInfo.identifier, user_type: userInfo.accountType },
          token,
        );
        setPlaylists(playlistResponse[0]);

        // Fetch custom tag list
        const customTagResponse = await axios.get('/api/customTagList', {
          headers: { Authorization: `${token}` },
        });
        setCustomTagList(customTagResponse.data);

        // Fetch match data
        await fetchMatchData();
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [fetchMatchData, token]);

  const allTabs = [{ playlist_name: 'All' }, ...playlists];

  const generateDummyData = (count) => {
    return Array.from({ length: count }, (_, i) => ({
      matchId: (i + 1).toString(),
      videoName: `Test Match ${i + 1}`,
      matchDescription: `Description for test match ${i + 1}`,
      stage: i % 2 === 0 ? 'Quarter Final' : 'Semi Final',
      date: new Date(2024, 0, i + 1).toISOString().split('T')[0],
      location: `Court ${(i % 5) + 1}`,
      homePlayer: `Player ${String.fromCharCode(65 + (i % 26))}`,
      awayPlayer: `Player ${String.fromCharCode(65 + ((i + 1) % 26))}`,
      fileUrl: `https://example.com/video${i + 1}.mp4`,
      TNUrl: `https://example.com/thumbnail${i + 1}.jpg`,
      report: `Match Report ${i + 1}`,
      parentEvent: `Tournament ${Math.floor(i / 10) + 1}`,
      event: i % 2 === 0 ? 'Singles' : 'Doubles',
      subEvent: i % 2 === 0 ? "Men's Singles" : "Women's Singles",
      category: 'Professional',
      isDouble: i % 2 !== 0,
      viewCount: 100 + i * 10,
      uploadUser: 'admin',
      customTag: i % 3 === 0 ? ['highlight', 'important'] : ['regular'],
      homePlayerMatchScore: '21-19, 21-15',
      awayPlayerMatchScore: '19-21, 15-21',
      top: i < 5, // Make first 5 videos "top" videos
    }));
  };

  // Use it in your test component
  const dummyData = generateDummyData(50); // Generate 50 test videos

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        minWidth: '100vw',
        overflow: 'auto',
        maxWidth: '100vw',
      }}
    >
      {!isMobile && (
        <HomeSidePanel
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          isCoach={userInfo.isCoach}
          accountType={userInfo.accountType}
        />
      )}
      <div className="home-main-container">
        <HomeOptionsBar
          filterCriteria={filterCriteria}
          setFilterCriteria={setFilterCriteria}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
          filterStats={filterStats}
          CustomTagList={CustomTagList}
          userInfo={userInfo}
        />
        <Paper sx={{ mt: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="playlist tabs"
            >
              {allTabs.map((tab, index) => (
                <Tab
                  key={tab.playlist_name}
                  label={tab.playlist_name}
                  id={`playlist-tab-${index}`}
                  aria-controls={`playlist-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </Box>

          {allTabs.map((tab, index) => (
            <Box
              key={tab.playlist_name}
              role="tabpanel"
              hidden={activeTab !== index}
              id={`playlist-tabpanel-${index}`}
              aria-labelledby={`playlist-tab-${index}`}
              sx={{ p: 2 }}
            >
              {activeTab === index && (
                <PaginatedVideoList
                  isLoading={isLoading}
                  // videoList={dummyData}
                  videoList={videoList}
                  playlistName={tab.playlist_name}
                  playlists={playlists}
                  userInfo={userInfo}
                />
              )}
            </Box>
          ))}
        </Paper>
      </div>
    </Box>
  );
};

export default Home;
