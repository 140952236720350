import { useState } from 'react';
import PlayerVideoList from './PlayerVideoList';
import PlayerVideoBlock from './PlayerVideoBlock';
import { Button, ButtonGroup } from '@mui/material';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import ListIcon from '@mui/icons-material/List';

import './PlayerVideos.scss';

const PlayerVideos = ({ playerData, accountType, videoList, playerStats }) => {
  const [viewMode, setViewMode] = useState('list');

  return (
    <div className="analysis-video-table-container">
      <div className="player-analysis-view-toggle">
        <ButtonGroup variant="contained" aria-label="view mode button group">
          <Button
            onClick={() => setViewMode('grid')}
            variant={viewMode === 'grid' ? 'contained' : 'outlined'}
          >
            <ViewAgendaOutlinedIcon />
          </Button>
          <Button
            onClick={() => setViewMode('list')}
            variant={viewMode === 'list' ? 'contained' : 'outlined'}
          >
            <ListIcon />
          </Button>
        </ButtonGroup>
      </div>

      {videoList &&
        videoList.map((row) =>
          viewMode === 'list' ? (
            <PlayerVideoList
              key={row.id}
              matchDetails={row}
              playerData={playerData}
              accountType={accountType}
              videoPage={false}
              playerStats={playerStats}
            />
          ) : (
            <PlayerVideoBlock
              key={row.id}
              matchDetails={row}
              playerData={playerData}
              accountType={accountType}
              videoPage={false}
            />
          ),
        )}
      <p className="padding"></p>
    </div>
  );
};

export default PlayerVideos;
