// Types
type HighlightPlayersType = {
  readonly male: readonly string[];
  readonly female: readonly string[];
};

// Constants
const HIGHLIGHT_PLAYERS: HighlightPlayersType = {
  male: [
    "WONG CHUN TING",
    "HO KWAN KIT",
    "LAM SIU HANG",
    "CHAN BALDWIN",
    "CHAN BALDWIN HO WAH",
    "YIU KWAN TO",
    "KWAN MAN HO",
    "PAU YIK MAN",
  ] as const,
  female: [
    "LAM YEE LOK",
    "DOO HOI KEM",
    "ZHU CHENGZHU",
    "LEE HO CHING",
    "NG WING LAM",
    "WONG HOI TUNG",
    "SU TSZ TUNG",
    "LEE HOI MAN KAREN",
    "KONG TSZ LAM",
  ] as const,
};

// Modified utility function to handle combined names
const isHighlightedPlayer = (playerName: string): boolean => {
  // Handle cases where names are combined with '|'
  if (playerName.includes("|")) {
    const names = playerName.split("|").map((name) => name.trim());
    // Return true if any of the players in the combination is highlighted
    return names.some((name) => {
      const allHighlightedPlayers: readonly string[] = [
        ...HIGHLIGHT_PLAYERS.male,
        ...HIGHLIGHT_PLAYERS.female,
      ];
      return allHighlightedPlayers.includes(name);
    });
  }

  // Handle single player name
  const allHighlightedPlayers: readonly string[] = [
    ...HIGHLIGHT_PLAYERS.male,
    ...HIGHLIGHT_PLAYERS.female,
  ];
  return allHighlightedPlayers.includes(playerName);
};

// Modified gender function to handle combined names
const getPlayerGender = (playerName: string): PlayerGender | null | "mixed" => {
  if (playerName.includes("|")) {
    const names = playerName.split("|").map((name) => name.trim());
    const genders = names.map((name) => {
      if (HIGHLIGHT_PLAYERS.male.includes(name)) return "male";
      if (HIGHLIGHT_PLAYERS.female.includes(name)) return "female";
      return null;
    });

    // If we have different genders, return 'mixed'
    if (genders.includes("male") && genders.includes("female")) {
      return "mixed";
    }
    // Return the gender if all players are the same gender
    if (genders.every((g) => g === "male")) return "male";
    if (genders.every((g) => g === "female")) return "female";
    return null;
  }

  // Handle single player name
  if (HIGHLIGHT_PLAYERS.male.includes(playerName)) return "male";
  if (HIGHLIGHT_PLAYERS.female.includes(playerName)) return "female";
  return null;
};

// Updated type to include 'mixed' option
type PlayerGender = "male" | "female" | "mixed";

export type { HighlightPlayersType, PlayerGender };
export { HIGHLIGHT_PLAYERS, isHighlightedPlayer, getPlayerGender };
