import React, { useCallback, useEffect } from "react";
import { FaProjectDiagram } from "react-icons/fa";
import { animateNodes } from "sigma/utils";
import { useSigma } from "@react-sigma/core";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  useTheme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { LayoutName, LayoutsControlProps } from "../../types/graph.types";
import { useTranslation } from "react-i18next";
import { useGraphLayouts } from "../../hooks/useGraphLayouts";

const LayoutsControl: React.FC<LayoutsControlProps> = ({
  onLayoutChange,
  currentLayout,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sigma = useSigma();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const layouts = useGraphLayouts();

  // Filter out force-based layouts for mobile
  const availableLayouts = Object.keys(layouts).filter((name) => {
    if (isMobile) {
      return !name.toLowerCase().includes("force");
    }
    return true;
  }) as LayoutName[];

  // Apply initial layout on mount
  useEffect(() => {
    if (currentLayout && layouts[currentLayout]?.layout) {
      const positions = layouts[currentLayout].layout.positions();
      animateNodes(sigma.getGraph(), positions, { duration: 1000 });
    }
  }, [sigma, currentLayout, layouts]);

  const applyLayout = useCallback(
    (layoutName: LayoutName) => {
      // Additional check to prevent force layouts on mobile
      if (isMobile && layoutName.toLowerCase().includes("force")) {
        console.log("Force layouts are disabled on mobile devices");
        return;
      }

      const layout = layouts[layoutName]?.layout;

      console.log("Applying layout:", layoutName);
      if (layout) {
        const positions = layout.positions();
        animateNodes(sigma.getGraph(), positions, { duration: 1000 });
        if (onLayoutChange) {
          onLayoutChange(layoutName);
        }
      }
      setAnchorEl(null);
    },
    [layouts, sigma, onLayoutChange, isMobile]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: theme.zIndex.modal,
      }}
    >
      <Tooltip title={t("layouts.title")}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            bgcolor: "background.paper",
            "&:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <FaProjectDiagram />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              minWidth: 180,
              boxShadow: theme.shadows[2],
            },
          },
        }}
      >
        {availableLayouts.map((name) => (
          <MenuItem
            key={name}
            onClick={() => applyLayout(name)}
            selected={currentLayout === name}
            sx={{
              py: 1,
              px: 2,
              minHeight: "auto",
              "&.Mui-selected": {
                bgcolor: "action.selected",
              },
              "&:hover": {
                bgcolor: "action.hover",
              },
            }}
          >
            {t(`layouts.types.${name}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LayoutsControl;
