import axios from "axios";
import Cookies from "js-cookie";

export type VideoId = number;
export type FrameNumber = number;
export type Score = number;

export interface fetchVideoInfoDataState {
  data: VideoDataStructure | null;
  isLiveData: boolean;
}

export const fetchVideoInfoData = async (
  videoId?: number
): Promise<fetchVideoInfoDataState> => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(`/api/videodata/${videoId}`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    return {
      data: parseVideoData(response.data),
      isLiveData: true,
    };
  } catch (error) {
    console.error("Error fetching fetchVideoInfoDataState: ", error);
    console.log("We will use the dummy data instead");
    return {
      data: parseVideoData(dummy_video_data),
      isLiveData: false,
    };
  } finally {
    console.log("fetchVideoInfoDataState executed");
  }
};

// Helper function to convert array to structured data
export function parseVideoData(data: any[]): VideoDataStructure {
  const [metadataArr, gamesArr] = data;
  const metadata = arrayToMetadata(metadataArr[0]);
  const games = gamesArr.map((game: any[]) => ({
    gameInfo: arrayToGameInfo(game[0]),
    points: game[1].map(arrayToPointInfo),
    emptyArray1: game[2],
    emptyArray2: game[3],
  }));

  return { metadata, games };
}

// Metadata (first element)
export interface VideoMetadata {
  id: VideoId; // [0]  e.g. 545
  filename: string; // [1]  e.g. "76_Quarterfinals_LAM..."
  hash: string; // [2]  e.g. "WTT Star Contender..."
  round: string; // [3]  e.g. "Quarter-Final"
  date: string; // [4]  e.g. "Wed, 26 Apr 2023..."
  location: string; // [5]  e.g. "Bangkok"
  team1: string; // [6]  e.g. "LAM Siu Hang/ZHU..."
  team2: string; // [7]  e.g. "ROBLES Alvaro/XIAO..."
  videoFilename: string; // [8]  same as [1] with .mp4
  thumbnailFilename: string; // [9]  same as [1] with .jpg
  duration: number | null; // [10] null or video duration
  startFrame: FrameNumber; // [11] e.g. 206947
  endFrame: FrameNumber; // [12] e.g. 207010
  isPublic: boolean; // [13] e.g. true
  isDeleted: boolean; // [14] e.g. false
  isHidden: boolean; // [15] e.g. false
  category: string; // [16] e.g. "Others"
  subcategory: string | null; // [17] e.g. null
  categoryType: string; // [18] e.g. "others"
  gameType: string; // [19] e.g. "MixedDouble"
  isPending: boolean; // [20] e.g. false
  priority: number; // [21] e.g. 0
  source: string; // [22] e.g. "internal_push"
  eventName: string; // [23] e.g. "WTT Star Contender..."
  finalscore1: number; // [24] e.g. 2
  finalscore2: number; // [25] e.g. 2
}

// Game Info (part of second element)
export interface GameInfo {
  id: number; // [0] e.g. 2197
  matchId: VideoId; // [1] e.g. 545
  gameNumber: number; // [2] e.g. 1
  winner: number; // [3] e.g. 0
  setNumber: number; // [4] e.g. 1
  startFrame: FrameNumber; // [5] e.g. 0
  endFrame: FrameNumber; // [6] e.g. 304
  timeout: string | null; // [7] e.g. null
  totalPoints: number; // [8] e.g. 15
}

// Point Info (part of second element)
export interface PointInfo {
  id: number; // [0] e.g. 25405
  gameId: number; // [1] e.g. 2197
  startFrame: FrameNumber; // [2] e.g. 0
  endFrame: FrameNumber; // [3] e.g. 18
  team1Score: Score; // [4] e.g. 0
  team2Score: Score; // [5] e.g. 1
  technicalTimeout: null | string; // [6] e.g. null
  isGamePoint: boolean; // [7] e.g. false
  isSetPoint: boolean; // [8] e.g. false
}

// Game Data Structure
export interface GameData {
  gameInfo: GameInfo;
  points: PointInfo[];
  emptyArray1: never[];
  emptyArray2: never[][];
}

// Complete Video Data Structure
export interface VideoDataStructure {
  metadata: VideoMetadata;
  games: GameData[];
}

// Helper functions to convert arrays to typed objects
function arrayToMetadata(arr: any[]): VideoMetadata {
  return {
    id: arr[0],
    filename: arr[1],
    hash: arr[2],
    round: arr[3],
    date: arr[4],
    location: arr[5],
    team1: arr[6],
    team2: arr[7],
    videoFilename: arr[8],
    thumbnailFilename: arr[9],
    duration: arr[10],
    startFrame: arr[11],
    endFrame: arr[12],
    isPublic: arr[13],
    isDeleted: arr[14],
    isHidden: arr[15],
    category: arr[16],
    subcategory: arr[17],
    categoryType: arr[18],
    gameType: arr[19],
    isPending: arr[20],
    priority: arr[21],
    source: arr[22],
    eventName: arr[23],
    finalscore1: arr[24],
    finalscore2: arr[25],
  };
}

function arrayToGameInfo(arr: any[]): GameInfo {
  return {
    id: arr[0],
    matchId: arr[1],
    gameNumber: arr[2],
    winner: arr[3],
    setNumber: arr[4],
    startFrame: arr[5],
    endFrame: arr[6],
    timeout: arr[7],
    totalPoints: arr[8],
  };
}

function arrayToPointInfo(arr: any[]): PointInfo {
  return {
    id: arr[0],
    gameId: arr[1],
    startFrame: arr[2],
    endFrame: arr[3],
    team1Score: arr[4],
    team2Score: arr[5],
    technicalTimeout: arr[6],
    isGamePoint: arr[7],
    isSetPoint: arr[8],
  };
}

export const dummy_video_data = [
  [
    [
      632,
      "Sun-Doo(Sample Video)",
      "Time Stamp and highlight/Pov example:\n\nClick here @28:40 to skip to target time.\n\nnotification 10 seconds before a highlight will be shown\n\nExample highlight at notification of highlight @29:00 will be shown @28:51 where coach POV zoom in on coach of team china @29:00 Highlight/ball comments are be view and edit  at 亮點面板",
      "R32",
      "Mon, 10 Apr 2023 00:00:00 GMT",
      "XinXiang",
      "SUN Yingsha",
      "DOO Hoi Kem",
      "Sun-Doo(Demo).mp4",
      "Sun-Doo(Demo).jpg",
      null,
      131163,
      115543,
      true,
      false,
      false,
      "WTT",
      "WTT Series",
      "Champions",
      "FSingle",
      false,
      144,
      "CpiiAdminAccount",
      "",
      3,
      0,
      [],
    ],
  ],
  [
    [
      [2197, 545, 1, 0, 1, 0, 304, null, 15],
      [
        [25405, 2197, 0, 18, 0, 1, null, false, false],
        [25406, 2197, 18, 28, 0, 2, null, false, false],
        [25407, 2197, 28, 46, 1, 2, null, false, false],
        [25408, 2197, 46, 54, 1, 3, null, false, false],
        [25409, 2197, 54, 70, 1, 4, null, false, false],
        [25410, 2197, 70, 80, 2, 4, null, false, false],
        [25411, 2197, 80, 104, 2, 5, null, false, false],
        [25412, 2197, 104, 120, 2, 6, null, false, false],
        [25413, 2197, 120, 132, 2, 7, null, false, false],
        [25414, 2197, 132, 156, 2, 8, null, false, false],
        [25415, 2197, 156, 174, 3, 8, null, false, false],
        [25416, 2197, 174, 190, 4, 8, null, false, false],
        [25417, 2197, 190, 214, 4, 9, null, false, false],
        [25418, 2197, 214, 232, 4, 10, null, false, false],
        [25419, 2197, 232, 244, 4, 11, null, false, false],
      ],
      [],
      [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
    ],
    [
      [2198, 545, 2, 1, 1, 304, 922, null, 24],
      [
        [25420, 2198, 304, 356, 0, 1, null, false, false],
        [25421, 2198, 356, 358, 1, 1, null, false, false],
        [25422, 2198, 358, 368, 2, 1, null, false, false],
        [25423, 2198, 368, 400, 3, 1, null, false, false],
        [25424, 2198, 400, 420, 3, 2, null, false, false],
        [25425, 2198, 420, 436, 3, 3, null, false, false],
        [25426, 2198, 436, 454, 4, 3, null, false, false],
        [25427, 2198, 454, 464, 5, 3, null, false, false],
        [25428, 2198, 464, 478, 5, 4, null, false, false],
        [25429, 2198, 478, 498, 6, 4, null, false, false],
        [25430, 2198, 498, 510, 7, 4, null, false, false],
        [25431, 2198, 510, 532, 8, 4, null, false, false],
        [25432, 2198, 532, 554, 8, 5, null, false, false],
        [25433, 2198, 554, 574, 8, 6, null, false, false],
        [25434, 2198, 574, 590, 8, 7, null, false, false],
        [25435, 2198, 590, 700, 8, 8, null, true, false],
        [25436, 2198, 700, 720, 9, 8, null, true, false],
        [25437, 2198, 720, 734, 9, 9, null, true, false],
        [25438, 2198, 734, 752, 9, 10, null, true, false],
        [25439, 2198, 752, 770, 10, 10, null, true, true],
        [25440, 2198, 770, 794, 11, 10, null, true, true],
        [25441, 2198, 794, 814, 11, 11, null, true, true],
        [25442, 2198, 814, 840, 12, 11, null, true, true],
        [25443, 2198, 840, 862, 13, 11, null, true, true],
      ],
      [],
      [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ],
    ],
    [
      [2199, 545, 3, 1, 2, 922, 1430, null, 22],
      [
        [25444, 2199, 922, 970, 1, 0, null, false, false],
        [25445, 2199, 970, 986, 2, 0, null, false, false],
        [25446, 2199, 986, 1010, 3, 0, null, false, false],
        [25447, 2199, 1010, 1024, 3, 1, null, false, false],
        [25448, 2199, 1024, 1046, 4, 1, null, false, false],
        [25449, 2199, 1046, 1050, 5, 1, null, false, false],
        [25450, 2199, 1050, 1078, 5, 2, null, false, false],
        [25451, 2199, 1078, 1096, 6, 2, null, false, false],
        [25452, 2199, 1096, 1108, 7, 2, null, false, false],
        [25453, 2199, 1108, 1124, 7, 3, null, false, false],
        [25454, 2199, 1124, 1142, 7, 4, null, false, false],
        [25455, 2199, 1142, 1160, 8, 4, null, false, false],
        [25456, 2199, 1160, 1178, 8, 5, null, false, false],
        [25457, 2199, 1178, 1190, 8, 6, null, false, false],
        [25458, 2199, 1190, 1214, 9, 6, null, false, false],
        [25459, 2199, 1214, 1230, 9, 7, null, false, false],
        [25460, 2199, 1230, 1248, 9, 8, null, true, false],
        [25461, 2199, 1248, 1264, 9, 9, null, true, false],
        [25462, 2199, 1264, 1296, 10, 9, null, true, false],
        [25463, 2199, 1296, 1320, 10, 10, null, true, true],
        [25464, 2199, 1320, 1342, 10, 11, null, true, true],
        [25465, 2199, 1342, 1372, 10, 12, null, true, true],
      ],
      [],
      [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ],
    ],
    [
      [2200, 545, 4, 2, 2, 1430, 99999, null, 20],
      [
        [25466, 2200, 1430, 1476, 0, 1, null, false, false],
        [25467, 2200, 1476, 1488, 0, 2, null, false, false],
        [25468, 2200, 1488, 1502, 0, 3, null, false, false],
        [25469, 2200, 1502, 1524, 1, 3, null, false, false],
        [25470, 2200, 1524, 1550, 2, 3, null, false, false],
        [25471, 2200, 1550, 1572, 2, 4, null, false, false],
        [25472, 2200, 1572, 1596, 2, 5, null, false, false],
        [25473, 2200, 1596, 1608, 3, 5, null, false, false],
        [25474, 2200, 1608, 1632, 4, 5, null, false, false],
        [25475, 2200, 1632, 1650, 5, 5, null, false, false],
        [25476, 2200, 1650, 1676, 6, 5, null, false, false],
        [25477, 2200, 1676, 1780, 7, 5, null, false, false],
        [25478, 2200, 1780, 1802, 7, 6, null, false, false],
        [25479, 2200, 1802, 1824, 8, 6, null, false, false],
        [25480, 2200, 1824, 1842, 8, 7, null, false, false],
        [25481, 2200, 1842, 1858, 8, 8, null, true, false],
        [25482, 2200, 1858, 1882, 8, 9, null, true, false],
        [25483, 2200, 1882, 1912, 9, 9, null, true, false],
        [25484, 2200, 1912, 1944, 9, 10, null, true, false],
        [25485, 2200, 1944, 1960, 10, 10, null, true, true],
      ],
      [],
      [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ],
    ],
  ],
];
