import { FC } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Switch,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { findCommonGameIds, getGameCount } from "../utils/graph.utils";
import { SelectionPaperProps } from "../types/graph.types";
import GameInfoRenderer from "./renderGameInfo";

const SelectionPaper: FC<SelectionPaperProps> = ({
  selection,
  playerData,
  connectionsData,
  showCommonOpponents,
  setShowCommonOpponents,
  handleReset,
  isMobile,
  handleCloseInfo,

}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderPlayerSelection = () => (
    <>
      <Typography
        variant={isMobile ? "subtitle1" : "h6"}
        gutterBottom
        color="text.primary"
      >
        {t("selection.title")}
      </Typography>

      {selection.selectedPlayer ? (
        <Box>
          <Typography variant="body1" color="text.primary">
            {t("selection.selected")}: {playerData[selection.selectedPlayer]}
          </Typography>
          {selection.selectedOpponent && (
            <>
              <Typography variant="body1" color="text.primary">
                {t("selection.opponent")}:{" "}
                {playerData[selection.selectedOpponent]}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("selection.gamesPlayed")}:{" "}
                {getGameCount(
                  selection.selectedPlayer,
                  selection.selectedOpponent,
                  connectionsData
                )}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("selection.gameIds")}:{" "}
                {findCommonGameIds(
                  selection.selectedPlayer,
                  selection.selectedOpponent,
                  connectionsData
                ).join(", ")}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={showCommonOpponents}
                    onChange={(e) => setShowCommonOpponents(e.target.checked)}
                    color="primary"
                  />
                }
                label={t("selection.showCommonOpponents")}
                sx={{ mt: 1, display: "block" }}
              />
            </>
          )}
          <Button
            variant="contained"
            color="error"
            size={isMobile ? "small" : "medium"}
            onClick={handleReset}
            sx={{ mt: 1 }}
          >
            {t("selection.resetSelection")}
          </Button>
        </Box>
      ) : (
        <Typography variant="body1" color="text.secondary">
          {t("selection.clickToStart")}
        </Typography>
      )}
    </>
  );

  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: theme.spacing(2),
        left: theme.spacing(2),
        p: theme.spacing(2),
        borderRadius: 1,
        zIndex: theme.zIndex.drawer + 2,
        bgcolor: "background.paper",
        maxWidth: "400px",
        [theme.breakpoints.down("sm")]: {
          p: theme.spacing(1),
          top: theme.spacing(1),
          left: theme.spacing(1),
        },
      }}
    >
      {selection.selectedGameInfo ? (
        <GameInfoRenderer
          gameInfo={selection.selectedGameInfo}
          onClose={handleCloseInfo}
          variant="paper"
        />
      ) : (
        renderPlayerSelection()
      )}
    </Paper>
  );
};

export default SelectionPaper;
