import React, { useState, useEffect } from 'react';
import { fetchMatchDataAPI } from 'src/api/videoService';
import { fetchUserListAPI } from 'src/api/userService';
import { createPlaylistAPI, editPlaylistAPI } from 'src/api/playlistService';
import DraggableVideoItem from './DraggableVideo';
import PlaylistVideoPanel from './PlaylistVideoPanel';
import { Autocomplete, TextField, Chip, Button } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Cookies from 'js-cookie';
import './playlistManagement.scss';

import {
    matchIdIndex,
    videoNameIndex,
    stageIndex,
    locationIndex,
    homePlayerIndex,
    awayPlayerIndex,
    urlIndex,
    dateIndex,
    thumbnailIndex,
    subEventIndex,
    homePlayerMatchScoreIndex,
    awayPlayerMatchScoreIndex,
    categoryIndex,
    uploadUserIndex,
} from 'src/constants';

interface PlaylistManagementProps {
    username: string;
    accountType: string;
    onClose: () => void;
    initialPlaylist?: any;
    mode: 'create' | 'edit';
    allTags: string[];
    onSaveComplete?: () => Promise<void>;
}

interface Tag {
    id: string;
    label: string;
}

interface Video {
    id: string;
    isPinned: boolean;
    videoName: string;
}

interface User {
    user_id: number;
    username: string;
    category: 'intellectual' | 'physical' | 'able';
}

interface UserOption {
    id: number;
    label: string;
}

interface VideoListItem {
    matchId: string;
    videoName: string;
    stage: string;
    date: string;
    location: string;
    homePlayer: string;
    awayPlayer: string;
    TNUrl: string;
    subEvent: string;
    category: string;
    uploadUser: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;
}

const PlaylistManagement: React.FC<PlaylistManagementProps> = ({
    username,
    accountType,
    onClose,
    initialPlaylist,
    mode,
    allTags,
    onSaveComplete,
}) => {
    const token = Cookies.get('token');

    const [playlistName, setPlaylistName] = useState(initialPlaylist?.playlist_name || '');
    const [playlistDescription, setPlaylistDescription] = useState(initialPlaylist?.description || '');
    const [videoList, setVideoList] = useState<VideoListItem[]>([]);
    const [videos, setVideos] = useState<Video[]>(() => {
        if (!initialPlaylist) return [];

        return initialPlaylist.match_order.map((id: number) => {
            const videoData = videoList.find(video => video.matchId === id.toString());
            return {
                id: id.toString(),
                isPinned: initialPlaylist.is_pin.includes(id),
                videoName: videoData ? videoData.videoName : `Video ${id}`
            };
        });
    });
    const [viewPermission, setViewPermission] = useState<UserOption[]>(
        initialPlaylist?.available_user_ids.map((id: number) => ({
            id,
            label: 'Loading...',
        })) || []
    );
    const [addedVideoIds, setAddedVideoIds] = useState<Set<string>>(new Set());
    const [userOptions, setUserOptions] = useState<UserOption[]>([]);
    const [tags, setTags] = useState<Tag[]>(
        initialPlaylist?.custom_tags.map((tag: string) => ({ id: tag, label: tag })) || []
    );

    const allTagOptions: Tag[] = allTags.map(tag => ({ id: tag, label: tag }));


    const [draggedItem, setDraggedItem] = useState<number | null>(null);
    const [draggedOverItem, setDraggedOverItem] = useState<number | null>(null);
    const [showForm, setShowForm] = useState(true);

    const handleDragStart = (index: number) => {
        setDraggedItem(index);
    };

    const handleDragEnter = (index: number) => {
        setDraggedOverItem(index);
    };

    const handleDragEnd = () => {
        if (draggedItem !== null && draggedOverItem !== null) {
            const newVideos = [...videos];
            const [reorderedItem] = newVideos.splice(draggedItem, 1);
            newVideos.splice(draggedOverItem, 0, reorderedItem);
            setVideos(newVideos);
        }
        setDraggedItem(null);
        setDraggedOverItem(null);
    };

    const handleTagChange = (event: React.SyntheticEvent, newValue: (Tag | string)[]) => {
        const updatedTags = newValue.map(tag => {
            if (typeof tag === 'string') {
                // This is a new tag
                return { id: tag, label: tag };
            }
            return tag;
        });
        setTags(updatedTags as Tag[]);
    };

    const toggleView = () => {
        setShowForm(!showForm);
    };

    useEffect(() => {
        // Initialize addedVideoIds with the IDs of videos already in the playlist
        setAddedVideoIds(new Set(videos.map(video => video.id)));
    }, []);


    useEffect(() => {
        if (videoList.length > 0) {
            setVideos(prevVideos => prevVideos.map(video => {
                const videoData = videoList.find(v => v.matchId == video.id);
                return {
                    ...video,
                    videoName: videoData ? videoData.videoName : video.videoName
                };
            }));
        }
    }, [videoList]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const matchResponse = await fetchMatchDataAPI({ accountType: accountType, user: username }, token as string);
                const responseData = matchResponse.videoData;

                const mergedData = responseData.map((videoData: any[]) => {
                    const fileUrl = videoData[urlIndex];
                    const urlWithoutSuffix = fileUrl.substring(0, fileUrl.lastIndexOf('.'));
                    const TNUrl = videoData[thumbnailIndex];
                    const newFileUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
                    const newTNUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${TNUrl}`;

                    const fetchedMatchDate = videoData[dateIndex];
                    const dateWithoutTime = fetchedMatchDate.replace(/\s\d{2}:\d{2}:\d{2}\s\w{3}$/, '');

                    return {
                        matchId: videoData[matchIdIndex],
                        videoName: videoData[videoNameIndex],
                        stage: videoData[stageIndex],
                        date: dateWithoutTime,
                        location: videoData[locationIndex],
                        homePlayer: videoData[homePlayerIndex],
                        awayPlayer: videoData[awayPlayerIndex],
                        TNUrl: newTNUrl,
                        subEvent: videoData[subEventIndex],
                        category: videoData[categoryIndex],
                        uploadUser: videoData[uploadUserIndex],
                        homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
                        awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
                    };
                });

                setVideoList(mergedData);
            } catch (error) {
                console.error("Error fetching match data:", error);
            }
        };

        const fetchUserList = async () => {
            try {
                const response: User[] = await fetchUserListAPI(token as string);

                let category: User['category'];
                switch (accountType) {
                    case 'm':
                        category = 'intellectual';
                        break;
                    case 'p':
                        category = 'physical';
                        break;
                    default:
                        category = 'able';
                }

                const filteredUsers = response
                    .filter((user: User) => user.category === category)
                    .map((user: User) => ({
                        id: user.user_id,
                        label: user.username,
                    }));
                setUserOptions(filteredUsers);

                setViewPermission(prev =>
                    prev.map(permission => ({
                        ...permission,
                        label: filteredUsers.find(user => user.id === permission.id)?.label || 'Unknown User',
                    }))
                );

            } catch (error) {
                console.error('Error fetching user list:', error);
            }
        };

        fetchData();
        fetchUserList();
    }, [token, accountType]);

    const handleAddVideo = (videoToAdd: VideoListItem) => {
        setVideos(prevVideos => [...prevVideos, { id: videoToAdd.matchId, isPinned: false, videoName: videoToAdd.videoName }]);
        setAddedVideoIds(prevIds => new Set(prevIds).add(videoToAdd.matchId));
    };

    const handleRemoveVideo = (videoId: string) => {
        setVideos(prevVideos => prevVideos.filter(video => video.id !== videoId));
        setAddedVideoIds(prevIds => {
            const newIds = new Set(prevIds);
            newIds.delete(videoId);
            return newIds;
        });
    };

    const handleSave = async () => {
        const playlistData = {
            created_by: username,
            playlist_name: playlistName,
            description: playlistDescription,
            custom_tags: tags.map(tag => tag.label),
            physical: accountType === 'p',
            mental: accountType === 'm',
            available_user_ids: viewPermission.map(user => user.id),
            is_pin: videos.filter(v => v.isPinned).map(v => parseInt(v.id)),
            match_order: videos.map(v => parseInt(v.id)),
        };

        try {
            if (mode === 'create') {
                const response = await createPlaylistAPI(playlistData, token as string);
                console.log('Playlist created:', response);
                // You might want to do something with the returned playlist_id
            } else if (mode === 'edit' && initialPlaylist) {
                const editData = {
                    ...playlistData,
                    playlist_id: initialPlaylist.playlist_id,
                };
                const response = await editPlaylistAPI(initialPlaylist.playlist_id, editData, token as string);
                console.log('Playlist updated:', response);
            }
            onClose(); // Close the dialog after successful save
            if (onSaveComplete) {
                await onSaveComplete();
            }
        } catch (error) {
            console.error('Error saving playlist:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handlePinVideo = (videoId: string) => {
        setVideos(prevVideos => {
            const videoIndex = prevVideos.findIndex(video => video.id === videoId);
            if (videoIndex === -1) return prevVideos;

            const updatedVideos = [...prevVideos];
            const video = { ...updatedVideos[videoIndex] };

            // Toggle the isPinned status
            video.isPinned = !video.isPinned;

            // Remove the video from its current position
            updatedVideos.splice(videoIndex, 1);

            if (video.isPinned) {
                // If pinned, add to the top
                updatedVideos.unshift(video);
            } else {
                // If unpinned, add to the bottom
                updatedVideos.push(video);
            }

            return updatedVideos;
        });
    };


    const handleViewPermissionChange = (event: React.SyntheticEvent, newValue: UserOption[]) => {
        setViewPermission(newValue);
    };


    return (
        <div className="playlist-management-overlay">
            <div className="playlist-management-dialog">
                <h2>{mode === 'create' ? '創建播放清單' : '編輯播放清單'}</h2>

                <Button onClick={toggleView} variant="contained" color="primary" style={{ marginBottom: '20px' }}>
                    {showForm ? '顯示視頻列表' : '顯示表單'}
                </Button>

                {showForm ? (
                    <div className="form-group">
                        <label htmlFor="playlistName">播放清單名稱：</label>
                        <input
                            id="playlistName"
                            type="text"
                            value={playlistName}
                            onChange={(e) => setPlaylistName(e.target.value)}
                        />
                        <label htmlFor="playlistDescription">播放清單評論：</label>
                        <input
                            id="playlistDescription"
                            type="text"
                            value={playlistDescription}
                            onChange={(e) => setPlaylistDescription(e.target.value)}
                        />
                        <label htmlFor="viewPermission">播放清單權限：</label>
                        <Autocomplete
                            multiple
                            id="viewPermission"
                            options={userOptions}
                            value={viewPermission}
                            onChange={handleViewPermissionChange}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="選擇用戶"
                                    value={viewPermission}
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.label}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                        <label htmlFor="playlistTags">播放清單標籤：</label>
                        <Autocomplete
                            multiple
                            id="playlistTags"
                            options={allTagOptions}
                            value={tags}
                            onChange={handleTagChange}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                            filterOptions={(options, params) => {
                                const filtered = options.filter(option =>
                                    option.label.toLowerCase().includes(params.inputValue.toLowerCase())
                                );

                                if (params.inputValue !== '' && !filtered.some(option => option.label.toLowerCase() === params.inputValue.toLowerCase())) {
                                    filtered.push({
                                        id: params.inputValue,
                                        label: params.inputValue,
                                    });
                                }

                                return filtered;
                            }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="添加標籤"
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.label}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                    </div>
                ) : (
                    <div className='playlist-video-container'>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>影片選擇面板</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PlaylistVideoPanel
                                    videoList={videoList}
                                    addedVideoIds={addedVideoIds}
                                    onAddVideo={handleAddVideo}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>播放清單視頻</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="playlist-videos">
                                    {videos.map((video, index) => (
                                        <DraggableVideoItem
                                            key={video.id}
                                            video={video}
                                            index={index}
                                            isDragging={draggedItem === index}
                                            isDraggedOver={draggedOverItem === index}
                                            onDragStart={handleDragStart}
                                            onDragEnter={handleDragEnter}
                                            onDragEnd={handleDragEnd}
                                            onPin={handlePinVideo}
                                            onRemove={handleRemoveVideo}
                                        />
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}

                <div className="dialog-actions">
                    <button onClick={handleSave}>保存</button>
                    <button onClick={onClose}>取消</button>
                </div>
            </div>
        </div>
    );
};

export default PlaylistManagement;