import React, { useState, useRef, useEffect } from 'react';
import { PlayCircleOutline, Close } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { fetchMatchDataAPI, fetchDpMatchDataAPI } from 'src/api/videoService';
import Cookies from 'js-cookie';
import OptimizedVideoPlayer from './OptimizedVideoPlayer';
import './PlayerSnippets.scss';

interface DropPoint {
    ballId: number;
    ballNum: number;
    roundId: number;
    topPlayer: number;
    bottomPlayer: number;
    topPlayerName: string | null;
    bottomPlayerName: string | null;
    dropPoints: number[][];
    criticalPoint: boolean;
    deuce: boolean;
}

interface VideoData {
    matchId: number;
    videoName: string;
    homePlayer: string;
    awayPlayer: string;
    homePlayerId: number;
    awayPlayerId: number;
    homePlayerMatchScore: number;
    awayPlayerMatchScore: number;
    fileUrl: string;
    dropPoints: DropPoint[];
}

interface Snippet {
    start: number;
    end: number;
}

interface VideoDataResponse {
    success: boolean;
    data: VideoData[];
}

interface PlayerSnippetsProps {
    accountType: string;
    admin: boolean;
    identifier: string;
    playerId: number;
}


const PlayerSnippets: React.FC<PlayerSnippetsProps> = ({ accountType, admin, identifier, playerId }) => {
    const token = Cookies.get('token') || ''
    const [videoList, setVideoList] = useState<VideoData[]>([]);
    const [currentVideo, setCurrentVideo] = useState('');
    const [currentSnippet, setCurrentSnippet] = useState<Snippet | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    // Mock snippets - replace with your actual snippet data source
    const getSnippetsForVideo = () => [
        { start: 10, end: 30 },
        { start: 145, end: 160 },
    ];

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response: VideoDataResponse = await fetchDpMatchDataAPI(playerId, token);

                // Process videos while keeping all original data
                const processedVideos = response.data.map((videoData) => ({
                    ...videoData, // Keep all original data
                    fileUrl: `${process.env.PUBLIC_URL}/videos/${videoData.fileUrl.substring(0, videoData.fileUrl.lastIndexOf('.'))
                        }/${videoData.fileUrl}`
                }));

                setVideoList(processedVideos);
            } catch (error) {
                console.error('Error fetching videos:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (playerId && token) {
            fetchVideos();
        }
    }, [playerId, token]);

    useEffect(() => {
        const video = videoRef.current;
        if (!video || !currentSnippet) return;

        const handleTimeUpdate = () => {
            if (video.currentTime >= currentSnippet.end) {
                video.currentTime = currentSnippet.start;
            }
            if (video.currentTime < currentSnippet.start) {
                video.currentTime = currentSnippet.start;
            }
        };

        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => video.removeEventListener('timeupdate', handleTimeUpdate);
    }, [currentSnippet]);


    const handleSnippetClick = (videoUrl: string, snippet: Snippet) => {
        setCurrentVideo(videoUrl);
        setCurrentSnippet(snippet);
        setIsModalOpen(true);
        setIsPlaying(true);
    };

    const handleCloseModal = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
        setIsModalOpen(false);
        setIsPlaying(false);
        setCurrentVideo('');
        setCurrentSnippet(null);
    };


    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    if (isLoading) {
        return (
            <div className="player-snippets__loading">
                <CircularProgress />
            </div>
        );
    }

    return (
        <main className="player-snippets">
            {isModalOpen && (
                <div className="player-snippets__modal">
                    <div className="player-snippets__modal-content">
                        <button
                            className="player-snippets__close"
                            onClick={handleCloseModal}
                            aria-label="Close"
                        >
                            <Close />
                        </button>

                        <OptimizedVideoPlayer
                            videoUrl={currentVideo}
                            startTime={currentSnippet?.start || 0}
                            endTime={currentSnippet?.end || 0}
                        />

                    </div>
                </div>
            )}

            <div className="player-snippets__grid">
                {videoList.map((video) => (
                    <article key={video.fileUrl} className="player-snippets__card">
                        <header className="player-snippets__card-header">
                            <h2 className="player-snippets__title">
                                {video.videoName}
                            </h2>
                            <p className="player-snippets__subtitle">
                                {video.homePlayer} vs {video.awayPlayer}
                            </p>
                        </header>

                        <div className="player-snippets__snippets">
                            {getSnippetsForVideo().map((snippet, index) => (
                                <button
                                    key={index}
                                    className="player-snippets__snippet-button"
                                    onClick={() => handleSnippetClick(video.fileUrl, snippet)}
                                >
                                    <PlayCircleOutline />
                                    <span>
                                        {formatTime(snippet.start)} - {formatTime(snippet.end)}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </article>
                ))}
            </div>
        </main>
    );
};

export default PlayerSnippets;