import { Box, Typography, Button, Stack } from "@mui/material";
import { GameInfoProps } from "../../types/graph.types";
import { useTranslation } from "react-i18next";

const GameInfoRenderer = ({
  gameInfo,
  onClose,
  variant = "drawer",
}: GameInfoProps) => {
  const { metadata, gameScores, players } = gameInfo;
  const isPaper = variant === "paper";
  const { t } = useTranslation();

  const handleWatchVideo = () => {
    window.open(
      `/video-player/${metadata.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: isPaper ? 0 : 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
        }}
      >
        <Typography variant={isPaper ? "h6" : "h6"} gutterBottom>
          {t("gameInfo.title")}
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.eventInfo.title")}
          </Typography>
          <Typography noWrap sx={{ maxWidth: "100%" }}>
            {metadata.eventName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {metadata.round} - {metadata.location}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {metadata.date ? metadata.date : t("gameInfo.eventInfo.date")}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.teams.title")}
          </Typography>
          <Typography noWrap sx={{ maxWidth: "100%" }}>
            {metadata.team1} {t("gameInfo.teams.vs")} {metadata.team2}
          </Typography>
          <Typography variant={isPaper ? "body1" : "h6"}>
            {t("gameInfo.teams.finalScore")}: {metadata.finalscore1} -{" "}
            {metadata.finalscore2}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.gameScores.title")}
          </Typography>
          {gameScores.map((game) => (
            <Box key={game.gameNumber} sx={{ mb: 1 }}>
              <Typography>
                {t("gameInfo.gameScores.game")} {game.gameNumber}:{" "}
                {game.finalTeam1Score} - {game.finalTeam2Score}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.players.title")}
          </Typography>
          {players.map((player) => (
            <Typography key={player.playerId} noWrap sx={{ maxWidth: "100%" }}>
              {player.playerName}
            </Typography>
          ))}
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.additionalInfo.title")}
          </Typography>
          <Typography>
            {t("gameInfo.additionalInfo.matchId")}: {metadata.id}
          </Typography>
          <Typography
            noWrap
            sx={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {t("gameInfo.additionalInfo.filename")}: {metadata.filename}
          </Typography>
        </Box>
      </Box>

      <Stack spacing={1} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleWatchVideo}
        >
          {t("gameInfo.buttons.watchVideo")}
        </Button>
        <Button variant="contained" color="error" fullWidth onClick={onClose}>
          {t("gameInfo.buttons.close")}
        </Button>
      </Stack>
    </Box>
  );
};

export default GameInfoRenderer;
