interface Point {
    x: number;
    y: number;
}

interface dpPoint {
    x: number;
    y: number;
    dpTime: number;
}

export class PerspectiveTransform {
    private static readonly TABLE_HEIGHT = 1280;
    private static readonly TABLE_WIDTH = 800;
    private transformMatrix: number[][];

    constructor(source: Point[]) {
        const destination = [
            { x: 0, y: 0 },
            { x: PerspectiveTransform.TABLE_WIDTH, y: 0 },
            { x: PerspectiveTransform.TABLE_WIDTH, y: PerspectiveTransform.TABLE_HEIGHT },
            { x: 0, y: PerspectiveTransform.TABLE_HEIGHT },
        ];
        this.transformMatrix = this.getPerspectiveTransform(source, destination);
    }

    private createMatrix(): number[][] {
        const matrix: number[][] = [];
        for (let i = 0; i < 8; i++) {
            matrix[i] = new Array(8).fill(0);
        }
        return matrix;
    }

    private createVector(): number[] {
        return new Array(8).fill(0);
    }

    private solveSystem(A: number[][], b: number[]): number[] {
        const n = 8;
        const x = new Array(n).fill(0);

        // Forward elimination
        for (let i = 0; i < n; i++) {
            let maxRow = i;
            for (let j = i + 1; j < n; j++) {
                if (Math.abs(A[j][i]) > Math.abs(A[maxRow][i])) {
                    maxRow = j;
                }
            }

            [A[i], A[maxRow]] = [A[maxRow], A[i]];
            [b[i], b[maxRow]] = [b[maxRow], b[i]];

            for (let j = i + 1; j < n; j++) {
                const factor = A[j][i] / A[i][i];
                b[j] -= factor * b[i];
                for (let k = i; k < n; k++) {
                    A[j][k] -= factor * A[i][k];
                }
            }
        }

        // Back substitution
        for (let i = n - 1; i >= 0; i--) {
            let sum = 0;
            for (let j = i + 1; j < n; j++) {
                sum += A[i][j] * x[j];
            }
            x[i] = (b[i] - sum) / A[i][i];
        }

        return x;
    }

    private getPerspectiveTransform(source: Point[], dest: Point[]): number[][] {
        const A = this.createMatrix();
        const b = this.createVector();

        // Fill matrix A and vector b with corresponding coefficients
        for (let i = 0; i < 4; i++) {
            const srcX = source[i].x;
            const srcY = source[i].y;
            const dstX = dest[i].x;
            const dstY = dest[i].y;

            const row1 = i * 2;
            const row2 = i * 2 + 1;

            // First row for each point
            A[row1][0] = srcX;
            A[row1][1] = srcY;
            A[row1][2] = 1;
            A[row1][6] = -srcX * dstX;
            A[row1][7] = -srcY * dstX;
            b[row1] = dstX;

            // Second row for each point
            A[row2][3] = srcX;
            A[row2][4] = srcY;
            A[row2][5] = 1;
            A[row2][6] = -srcX * dstY;
            A[row2][7] = -srcY * dstY;
            b[row2] = dstY;
        }

        const h = this.solveSystem(A, b);
        
        return [
            [h[0], h[1], h[2]],
            [h[3], h[4], h[5]],
            [h[6], h[7], 1]
        ];
    }

    public transformPoint(point: Point, dpTime:number): dpPoint {
        const [x, y] = [point.x, point.y];
        const w = this.transformMatrix[2][0] * x + this.transformMatrix[2][1] * y + this.transformMatrix[2][2];
        const transformedX = (this.transformMatrix[0][0] * x + this.transformMatrix[0][1] * y + this.transformMatrix[0][2]) / w;
        const transformedY = (this.transformMatrix[1][0] * x + this.transformMatrix[1][1] * y + this.transformMatrix[1][2]) / w;
        
        return { x: transformedX, y: transformedY, dpTime };
    }
}

