// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-videoData-main-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100vw;
  background-color: var(--darkgrey);
}
.edit-videoData-main-container a {
  padding-left: var(--small-font-size);
}
.edit-videoData-main-container .edit-video-back-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100%;
  max-height: 100%;
  min-width: var(--large-font-size);
  background-color: var(--apple-grey);
  color: white;
  font-size: var(--small-font-size);
  border: 0;
  text-decoration: none;
  cursor: pointer;
}
.edit-videoData-main-container .edit-video-back-button svg {
  color: var(--apple-green);
  font-size: var(--large-font-size);
}`, "",{"version":3,"sources":["webpack://./src/videoPages/EditVideo/EditVideoData.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,YAAA;EACA,iCAAA;AACF;AAAE;EACE,oCAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,gBAAA;EACA,iCAAA;EACA,mCAAA;EACA,YAAA;EACA,iCAAA;EACA,SAAA;EACA,qBAAA;EACA,eAAA;AAEJ;AADI;EACE,yBAAA;EACA,iCAAA;AAGN","sourcesContent":[".edit-videoData-main-container {\n  display: flex;\n  flex-direction: column-reverse;\n  justify-content: space-between;\n  width: 100vw;\n  background-color: var(--darkgrey);\n  a {\n    padding-left: var(--small-font-size);\n  }\n  .edit-video-back-button {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: space-between;\n    min-height: 100%;\n    max-height: 100%;\n    min-width: var(--large-font-size);\n    background-color: var(--apple-grey);\n    color: white;\n    font-size: var(--small-font-size);\n    border: 0;\n    text-decoration: none;\n    cursor: pointer;\n    svg {\n      color: var(--apple-green);\n      font-size: var(--large-font-size);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
