// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes matrixRain {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
.timed-alert {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #00ff00;
  font-size: 1.2rem;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  z-index: 9999;
  animation: fadeOut 0.3s 4.7s forwards;
  font-family: "Courier New", monospace;
  letter-spacing: 1px;
  border: 1px solid #00ff00;
  min-width: 300px;
  text-align: center;
}
.timed-alert::before {
  content: ">";
  margin-right: 8px;
  animation: blink 0.7s infinite;
}
.timed-alert::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 255, 0, 0.1) 25%, rgba(0, 255, 0, 0.2) 50%, rgba(0, 255, 0, 0.1) 75%);
  background-size: 100% 300%;
  animation: matrixRain 3s linear infinite;
  pointer-events: none;
  z-index: -1;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/alert/alert.scss"],"names":[],"mappings":"AAEA;EACE;IACE,UAAA;EADF;EAGA;IACE,UAAA;EADF;AACF;AAIA;EACE;IACE,0BAAA;EAFF;EAIA;IACE,4BAAA;EAFF;AACF;AAKA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,oCAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yCAAA;EACA,aAAA;EACA,qCACE;EAEF,qCAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AALF;AAOE;EACE,YAAA;EACA,iBAAA;EACA,8BAAA;AALJ;AAQE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,+GAAA;EAMA,0BAAA;EACA,wCAAA;EACA,oBAAA;EACA,WAAA;AAXJ;;AAeA;EACE;IAEE,UAAA;EAbF;EAeA;IACE,UAAA;EAbF;AACF","sourcesContent":["// alert.scss\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes matrixRain {\n  0% {\n    background-position: 0% 0%;\n  }\n  100% {\n    background-position: 0% 100%;\n  }\n}\n\n.timed-alert {\n  position: fixed;\n  top: 10%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: rgba(0, 0, 0, 0.8);\n  color: #00ff00;\n  font-size: 1.2rem;\n  padding: 10px 15px;\n  border-radius: 5px;\n  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);\n  z-index: 9999;\n  animation:\n    fadeOut 0.3s 4.7s forwards;\n\n  font-family: 'Courier New', monospace;\n  letter-spacing: 1px;\n  border: 1px solid #00ff00;\n  min-width: 300px;\n  text-align: center;\n\n  &::before {\n    content: '>';\n    margin-right: 8px;\n    animation: blink 0.7s infinite;\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: linear-gradient(\n      0deg,\n      rgba(0, 255, 0, 0.1) 25%,\n      rgba(0, 255, 0, 0.2) 50%,\n      rgba(0, 255, 0, 0.1) 75%\n    );\n    background-size: 100% 300%;\n    animation: matrixRain 3s linear infinite;\n    pointer-events: none;\n    z-index: -1;\n  }\n}\n\n@keyframes blink {\n  0%,\n  100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
