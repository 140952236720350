// File: app/common/constants/graph.constants.ts
import { createNodeBorderProgram } from "@sigma/node-border";
import type { EdgeDisplayData } from "sigma/types";
import { Settings } from "sigma/settings";

import { ConnectionsData, PlayerData } from "../types/graph.types";
import { Attributes } from "react";

// Base required properties from NodeDisplayData
type BaseNodeDisplayData = {
  x: number;
  y: number;
  size: number;
  color: string;
  label: string | null;
};

// Extend NodeDisplayData with our custom properties
interface ExtendedNodeDisplayData extends BaseNodeDisplayData {
  highlighted?: boolean;
  hidden?: boolean;
  borderSize?: number;
  borderColor?: string;
  labelSize?: number;
  labelBorderSize?: number;
  labelBackgroundColor?: string;
  forceLabel?: boolean;
  zIndex?: number;
  type?: string;
}

// Extend EdgeDisplayData with our custom properties
interface ExtendedEdgeDisplayData extends Omit<EdgeDisplayData, "hidden"> {
  hidden?: boolean;
  size: number;
  color: string;
}

// Define custom attributes that extend the base Attributes
interface CustomAttributes extends Attributes {
  x?: number;
  y?: number;
  size?: number;
  color?: string;
  borderSize?: number;
  borderColor?: string;
  labelSize?: number;
  labelBorderSize?: number;
  labelBackgroundColor?: string;
  forceLabel?: boolean;
  zIndex?: number;
  hidden?: boolean;
  type?: string;
  label?: string | null;
}

// Type for our settings
type GraphSettings = Partial<
  Settings<CustomAttributes, CustomAttributes, CustomAttributes>
> & {
  labelSize: number;
  labelDensity: number;
  labelGridCellSize: number;
  labelRenderedSizeThreshold: number;
  minCameraRatio: number;
  maxCameraRatio: number;
  defaultNodeColor: string;
  defaultEdgeColor: string;
  labelFont: string;
  labelWeight: string;
};

interface GraphReducerParams {
  sigma: any;
  isMobile: boolean;
  selectedNode: string | null;
  selectedOpponent: string | null;
  selectedCommonOpponent: string | null;
  showCommonOpponents: boolean;
  playerData: PlayerData;
  connectionsData: ConnectionsData;
  findCommonGameIds: (
    player1: string,
    player2: string,
    connections: ConnectionsData
  ) => number[];
  findConnectedPlayers: (nodeId: string) => string[];
  getGameCount: (
    player1: string,
    player2: string,
    connections: ConnectionsData
  ) => number;
  getEnhancedLabelStyle: () => {
    label?: string;
    labelSize?: number;
    labelBorderSize?: number;
    labelBackgroundColor?: string;
  };
}

export const getGraphSettings = (isMobile: boolean): GraphSettings => ({
  labelSize: isMobile ? 10 : 12,
  labelDensity: 0.6,
  labelGridCellSize: isMobile ? 100 : 140,
  labelRenderedSizeThreshold: 1,
  minCameraRatio: 0.1,
  maxCameraRatio: 2,
  defaultNodeColor: "#D3D3D3",
  defaultEdgeColor: "#D3D3D3",
  labelFont: "Arial",
  labelWeight: "400",
});

export const SIGMA_SETTINGS = {
  allowInvalidContainer: true,
  defaultNodeType: "bordered",
  nodeProgramClasses: {
    bordered: createNodeBorderProgram({
      borders: [
        {
          size: { attribute: "borderSize", defaultValue: 0 },
          color: { attribute: "borderColor" },
        },
        { size: { fill: true }, color: { attribute: "color" } },
      ],
    }),
  },
};

// Graph reducer params interface
interface GraphReducerParams {
  sigma: any;
  isMobile: boolean;
  selectedNode: string | null;
  selectedOpponent: string | null;
  selectedCommonOpponent: string | null;
  showCommonOpponents: boolean;
  playerData: PlayerData;
  connectionsData: ConnectionsData;
  findCommonGameIds: (
    player1: string,
    player2: string,
    connections: ConnectionsData
  ) => number[];
  findConnectedPlayers: (nodeId: string) => string[];
  getGameCount: (
    player1: string,
    player2: string,
    connections: ConnectionsData
  ) => number;
  getEnhancedLabelStyle: () => {
    label?: string;
    labelSize?: number;
    labelBorderSize?: number;
    labelBackgroundColor?: string;
  };
}

// Node styles constant
export const NODE_STYLES = {
  player: {
    mobile: {
      size: 9,
      color: "#4169E1",
    },
    desktop: {
      size: 15,
      color: "#4169E1",
    },
  },
  game: {
    mobile: {
      size: 4,
      color: "#B0C4DE",
    },
    desktop: {
      size: 8,
      color: "#B0C4DE",
    },
  },
} as const;

// Update the return type for the node reducer
export const getGraphReducers = ({
  isMobile,
  selectedNode,
  selectedOpponent,
  selectedCommonOpponent,
  showCommonOpponents,
  playerData,
  connectionsData,
  findCommonGameIds,
  findConnectedPlayers,
  getGameCount,
  getEnhancedLabelStyle,
  sigma,
}: GraphReducerParams): Partial<
  Settings<CustomAttributes, CustomAttributes, CustomAttributes>
> => ({
  nodeReducer: (
    node: string,
    data: CustomAttributes
  ): Partial<ExtendedNodeDisplayData> => {
    const newData = { ...data } as Partial<ExtendedNodeDisplayData>;

    const sizeMultiplier = isMobile ? 1.2 : 1.4;

    if (!selectedNode) {
      const isPlayer = playerData[node] !== undefined;
      return {
        ...newData,
        color: isPlayer
          ? NODE_STYLES.player[isMobile ? "mobile" : "desktop"].color
          : NODE_STYLES.game[isMobile ? "mobile" : "desktop"].color,
        size: isPlayer
          ? NODE_STYLES.player[isMobile ? "mobile" : "desktop"].size
          : NODE_STYLES.game[isMobile ? "mobile" : "desktop"].size,
        label: isPlayer ? playerData[node] : null,
      };
    }

    const redOrangeGames = selectedOpponent
      ? findCommonGameIds(selectedNode, selectedOpponent, connectionsData)
      : [];
    const yellowRedGames = selectedCommonOpponent
      ? findCommonGameIds(selectedNode, selectedCommonOpponent, connectionsData)
      : [];
    const yellowOrangeGames =
      selectedOpponent && selectedCommonOpponent
        ? findCommonGameIds(
            selectedOpponent,
            selectedCommonOpponent,
            connectionsData
          )
        : [];

    // Handle game nodes first
    const isGameNode = !playerData[node];
    if (isGameNode) {
      const nodeId = parseInt(node);

      // Check if this game node is connected to selected nodes
      const isYellowOrangeGame =
        selectedCommonOpponent && yellowOrangeGames.includes(nodeId);
      const isRedOrangeGame =
        !selectedCommonOpponent && redOrangeGames.includes(nodeId);

      if (isYellowOrangeGame) {
        return {
          ...newData,
          color: "#FFB266", // Matching orange color for yellow-orange connected games
          size: (data.size ?? 1) * 1,
          zIndex: 1,
          label: `Game ${node}`,
          labelSize: 14,
          labelBorderSize: 3,
          labelBackgroundColor: "rgba(255, 255, 255, 0.8)",
        };
      } else if (isRedOrangeGame) {
        return {
          ...newData,
          color: "#6495ED", // Blue for red-orange connected games
          size: (data.size ?? 1) * 1,
          zIndex: 1,
          label: `Game ${node}`,
          labelSize: 14,
          labelBorderSize: 3,
          labelBackgroundColor: "rgba(255, 255, 255, 0.8)",
        };
      } else {
        return {
          ...newData,
          color: "#E2E2E2",
          size: (data.size ?? 1) * 0.8,
          label: undefined,
          hidden: false,
        };
      }
    }

    // Get connected players information
    const firstPlayerOpponents = findConnectedPlayers(selectedNode);
    let commonOpponents: string | string[] = [];
    if (selectedOpponent && showCommonOpponents) {
      const secondPlayerOpponents = findConnectedPlayers(selectedOpponent);
      commonOpponents = firstPlayerOpponents.filter(
        (id) =>
          secondPlayerOpponents.includes(id) &&
          id !== selectedNode &&
          id !== selectedOpponent
      );
    }

    // Priority order for node styling:
    // 1. Selected common opponent (highest priority)
    if (showCommonOpponents && node === selectedCommonOpponent) {
      return {
        ...newData,
        color: "#FFD700", // Gold
        size: (data.size ?? 1) * sizeMultiplier * 1,
        zIndex: 3,
        label:
          playerData[node] && selectedOpponent
            ? `${playerData[node]} (${getGameCount(
                selectedNode,
                node,
                connectionsData
              )}/ COMM: ${getGameCount(
                selectedOpponent,
                node,
                connectionsData
              )} games)`
            : playerData[node],
        ...getEnhancedLabelStyle(),
      };
    }

    // 2. Main selected player
    if (node === selectedNode) {
      return {
        ...newData,
        color: "#FF4444", // Red
        size: (data.size ?? 1) * sizeMultiplier,
        zIndex: 2,
        ...getEnhancedLabelStyle(),
      };
    }

    // 3. Selected opponent
    if (selectedOpponent && node === selectedOpponent) {
      return {
        ...newData,
        color: "#FF8C00", // Orange
        size: (data.size ?? 1) * sizeMultiplier,
        zIndex: 2,
        ...getEnhancedLabelStyle(),
      };
    }

    // 4. Common opponents
    if (showCommonOpponents && commonOpponents.includes(node)) {
      return {
        ...newData,
        color: "#9370DB", // Purple
        size: (data.size ?? 1) * sizeMultiplier,
        zIndex: 2,
        label:
          playerData[node] && selectedOpponent
            ? `${playerData[node]} (${getGameCount(
                selectedNode,
                node,
                connectionsData
              )}/ COMM: ${getGameCount(
                selectedOpponent,
                node,
                connectionsData
              )} games)`
            : playerData[node],
        ...getEnhancedLabelStyle(),
      };
    }

    // 5. Regular opponents
    if (firstPlayerOpponents.includes(node) && !showCommonOpponents) {
      return {
        ...newData,
        color: "#6495ED", // Blue
        size: (data.size ?? 1) * sizeMultiplier,
        zIndex: 1,
        label: playerData[node]
          ? `${playerData[node]} (${getGameCount(
              selectedNode,
              node,
              connectionsData
            )} games)`
          : undefined,
      };
    }

    // 6. Disabled opponents (when showing common opponents)
    if (showCommonOpponents && firstPlayerOpponents.includes(node)) {
      return {
        ...newData,
        color: "#c6d2f6", // Light blue-grey
        size: (data.size ?? 1) * 0.8,
        label: undefined,
        hidden: false,
        zIndex: 0,
      };
    }

    // 7. Irrelevant nodes (lowest priority)
    return {
      ...newData,
      color: "#E2E2E2",
      size: (data.size ?? 1) * 0.8,
      label: undefined,
      hidden: false,
    };
  },

  edgeReducer: (
    edge: string,
    data: CustomAttributes
  ): Partial<ExtendedEdgeDisplayData> => {
    const newData = { ...data } as Partial<ExtendedEdgeDisplayData>;
    const graph = sigma.getGraph();
    const [source, target] = graph.extremities(edge);

    if (selectedNode && selectedOpponent) {
      const redOrangeGames = findCommonGameIds(
        selectedNode,
        selectedOpponent,
        connectionsData
      );
      const yellowOrangeGames = selectedCommonOpponent
        ? findCommonGameIds(
            selectedOpponent,
            selectedCommonOpponent,
            connectionsData
          )
        : [];

      // Check if this edge connects to a game node
      const isRedOrangeEdge =
        (source === selectedNode &&
          redOrangeGames.includes(parseInt(target))) ||
        (target === selectedNode &&
          redOrangeGames.includes(parseInt(source))) ||
        (source === selectedOpponent &&
          redOrangeGames.includes(parseInt(target))) ||
        (target === selectedOpponent &&
          redOrangeGames.includes(parseInt(source)));

      const isYellowOrangeEdge =
        selectedCommonOpponent &&
        ((source === selectedOpponent &&
          yellowOrangeGames.includes(parseInt(target))) ||
          (target === selectedOpponent &&
            yellowOrangeGames.includes(parseInt(source))) ||
          (source === selectedCommonOpponent &&
            yellowOrangeGames.includes(parseInt(target))) ||
          (target === selectedCommonOpponent &&
            yellowOrangeGames.includes(parseInt(source))));

      if (showCommonOpponents) {
        // When showing common opponents, only show yellow-orange connections
        if (isYellowOrangeEdge) {
          return {
            ...newData,
            hidden: false,
            color: "#FFB266", // Lighter orange for yellow-orange connections
            size: 2,
          };
        }
        return {
          ...newData,
          hidden: true,
        };
      } else {
        // When not showing common opponents, only show red-orange connections
        if (isRedOrangeEdge) {
          return {
            ...newData,
            hidden: false,
            color: "#999999", // Gray for red-orange connections
            size: 2,
          };
        }
        return {
          ...newData,
          hidden: true,
        };
      }
    } else if (selectedNode) {
      // Single selection case - show all connections to selected node
      const isConnectedToSelected =
        source === selectedNode || target === selectedNode;
      return {
        ...newData,
        hidden: !isConnectedToSelected,
        color: isConnectedToSelected ? "#999999" : newData.color,
        size: isConnectedToSelected ? 1 : newData.size,
      };
    }

    // Default state (no selection)
    return {
      ...newData,
      hidden: false,
      color: "#D3D3D3",
      size: 0.5,
    };
  },
});

export const getResetSelection = ({
  sigma,
  currentLayout,
  getTwoLayerPositions,
  playerData,
  isMobile,
}: {
  sigma: any;
  currentLayout: string | null | undefined;
  getTwoLayerPositions: () => { [key: string]: { x: number; y: number } };
  playerData: Record<string, string>;
  isMobile: boolean;
}): void => {
  const graph = sigma.getGraph();
  const nodePositions =
    currentLayout === "twoLayerCircle" ? getTwoLayerPositions() : {};

  graph.forEachNode((node: string) => {
    const isKnownPlayer = playerData[node] !== undefined;
    const position = nodePositions[node] || {
      x: graph.getNodeAttribute(node, "x"),
      y: graph.getNodeAttribute(node, "y"),
    };

    const styles = isKnownPlayer ? NODE_STYLES.player : NODE_STYLES.game;
    const deviceStyles = isMobile ? styles.mobile : styles.desktop;

    graph.setNodeAttribute(node, "x", position.x);
    graph.setNodeAttribute(node, "y", position.y);
    graph.setNodeAttribute(node, "color", deviceStyles.color);
    graph.setNodeAttribute(node, "size", deviceStyles.size);
    graph.setNodeAttribute(node, "label", playerData[node] || `Game ${node}`);
    graph.setNodeAttribute(node, "labelColor", "#000000");
    graph.setNodeAttribute(node, "zIndex", 0);
  });

  graph.forEachEdge((edge: string) => {
    graph.setEdgeAttribute(edge, "hidden", false);
    graph.setEdgeAttribute(edge, "color", "#D3D3D3");
    graph.setEdgeAttribute(edge, "size", 0.5);
  });
};
