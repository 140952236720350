// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoreChart-accordion {
  overflow-x: hidden;
}
.scoreChart-accordion .scoreChart-title {
  font-size: var(--medium-font-size);
  display: flex;
  justify-content: center;
}
.scoreChart-accordion .MuiChartsLegend-root {
  transform: translate(-10%, 10%);
}`, "",{"version":3,"sources":["webpack://./src/videoPages/VideoInterface/VideoDynamicPanel/ScorePanel/ScoreChart.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;AAAJ;AACA;EACI,kCAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAEA;EACI,+BAAA;AAAJ","sourcesContent":["\n.scoreChart-accordion{\n    overflow-x: hidden;\n.scoreChart-title{\n    font-size: var(--medium-font-size);\n    display: flex;\n    justify-content: center;\n    \n}\n.MuiChartsLegend-root{\n    transform: translate(-10%, 10%);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
