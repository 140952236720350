import { useState, useEffect } from 'react';
import { VideoEditForm } from './VideoEditForm';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import {
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  subEventIndex,
  parentEventIndex,
  categoryIndex,
  customTagIndex,
  matchIdIndex,
  homePlayerIdIndex,
  awayPlayerIdIndex,
} from 'src/constants';

import './EditVideoData.scss';

const EditVideoData = ({ userInfo }) => {
  const token = Cookies.get('token');
  const { videoId, step } = useParams();
  const [matchData, setMatchData] = useState({
    matchId: '',
    videoName: '',
    videoDescription: '',
    stage: '',
    matchDate: '',
    location: '',
    homePlayer: '',
    awayPlayer: '',
    homePlayerId: '',
    awayPlayerId: '',
    fileUrl: '',
    videoUrl: '',
    matchEvent: '',
    matchParentEvent: '',
    matchCategory: '',
    accountType: userInfo.accountType,
    customTag: '',
  });
  const [steps, setSteps] = useState(['视频资料', '確認信息']);

  const formatDate = (dateString) => {
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };

    const [dayOfWeek, day, monthAbbreviation, year] = dateString.split(' ');
    const month = months[monthAbbreviation];
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useEffect(() => {
    if (userInfo['admin'] === true) {
      setSteps(['视频资料', '確認信息', '分析數據', '其他選項']);
    }
  }, [userInfo]);

  // load data
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api/videodata/${videoId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        const responseData = response.data;
        // Destructure the response data
        const [matchResults, roundResults] = responseData;
        // change date and url
        let transformedList;
        transformedList = matchResults.map((videoData) => {
          const fetchedMatchDate = videoData[dateIndex];
          const dateWithoutTime = fetchedMatchDate.replace(
            /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
            '',
          );
          const formattedDate = formatDate(dateWithoutTime);

          const fileUrl = videoData[urlIndex];
          const urlWithoutSuffix = fileUrl.substring(
            0,
            fileUrl.lastIndexOf('.'),
          );
          const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;

          setMatchData((prevMatchData) => ({
            ...prevMatchData,
            matchId: videoData[matchIdIndex],
            videoName: videoData[videoNameIndex],
            videoDescription: videoData[matchDescriptionIndex],
            stage: videoData[stageIndex],
            matchDate: formattedDate,
            location: videoData[locationIndex],
            homePlayer: videoData[homePlayerIndex] || '',
            awayPlayer: videoData[awayPlayerIndex] || '',
            homePlayerId: videoData[homePlayerIdIndex] || '',
            awayPlayerId: videoData[awayPlayerIdIndex] || '',
            fileUrl: videoData[urlIndex],
            videoUrl: url,
            matchEvent: videoData[subEventIndex] || '',
            matchParentEvent: videoData[parentEventIndex],
            matchCategory: videoData[categoryIndex],
            accountType: userInfo.accountType,
            customTag: videoData[customTagIndex] || '',
          }));
        });
      } catch (error) {}
    };
    fetchVideoData();
  }, []);

  if (!matchData) {
    return null;
  }

  return (
    <div className="edit-videoData-main-container">
      <VideoEditForm
        matchData={matchData}
        setMatchData={setMatchData}
        userInfo={userInfo}
        token={token}
        defaultStep={step}
        steps={steps}
      />
      <a href={`/video-player/${videoId}`} className="edit-video-back-button">
        返回視頻
        <ArrowBackOutlinedIcon />
      </a>
    </div>
  );
};

export default EditVideoData;
