import { useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import './LoadingScreen.scss';

const LoadingScreen = ({ option = 1, color = 1 }) => {
  const [progress, setProgress] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 1 : prev));
    }, 40);

    return () => clearInterval(timer);
  }, []);

  const getStatusMessage = (progress) => {
    if (progress < 25) {
      return '正在讀取比賽影片數據...';
    } else if (progress < 50) {
      return '分析落點與技術數據中...';
    } else if (progress < 75) {
      return '計算得分統計與戰術分析...';
    } else {
      return '準備數據展示...';
    }
  };

  return (
    <div className="loading-screen">
      {option == 1 ? (
        <div className="loading-screen__radar">
          <div className="loading-screen__radar-circle"></div>
          <div className="loading-screen__radar-sweep"></div>
        </div>
      ) : (
        <div className="loading-screen__grid">
          {Array.from({ length: isMobile ? 6 : 12 }).map((_, index) => (
            <div
              key={index}
              className="loading-screen__grid-item"
              style={{
                animationDelay: `${index * 0.2}s`,
              }}
            ></div>
          ))}
        </div>
      )}

      <div className="loading-screen__info">
        <div className="loading-screen__title">
          <span
            className="loading-screen__title-text"
            data-text="系統初始化"
            style={color === 2 ? { color: 'white' } : undefined}
          >
            正在載入乒乓球比賽數據.....
          </span>
        </div>

        <div className="loading-screen__progress">
          <div
            className="loading-screen__progress-bar"
            style={{ width: `${progress}%` }}
          ></div>
          <span
            className="loading-screen__progress-text"
            style={color === 2 ? { color: 'white' } : undefined}
          >
            {progress}%
          </span>
        </div>

        <div
          className="loading-screen__status"
          style={color === 2 ? { color: 'white' } : undefined}
        >
          {getStatusMessage(progress)}
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
