import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import { Snackbar, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

import './VideoDelete.scss';

export default function VideoDelete({ matchData, token }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [backdropError, setBackdropError] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteInputValue('');
    setErrorMessage('');
  };

  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  };

  const handleCloseBackdrop = () => {
    setBackdropError(false); // Reset the error state to close the backdrop
  };

  const UploadErrorBackdrop = ({ onClose }) => {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }}>
        <div>
          <Typography variant="h6" component="div" color="error">
            失败，請重试。
            {backdropError.message}
          </Typography>
          <IconButton color="primary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Backdrop>
    );
  };

  const deleteData = () => {
    axios
      .delete(`/api/videodata/${matchData.matchId}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response
        setSnackbarMessage('數據已删除! 點擊這裡返回主頁');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        // Handle the error
        setBackdropError(error);
      });
  };

  const handleDelete = () => {
    // Handle delete
    const deleteVideo = async () => {
      const response = axios
        .delete(`/api/deleteVideo`, {
          data: { metadata: matchData },
          params: {
            primary_attribute: 'file deleted: ' + matchData.videoName,
          },
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          // Handle the successful response
          setSnackbarMessage('檔案已删除, 請不要退出，請等待數據被刪除!');
          setSnackbarOpen(true);
          deleteData();
        })
        .catch((error) => {
          // Handle the error
          setBackdropError(error);
        });
    };

    if (deleteInputValue === 'delete') {
      deleteVideo();
    } else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    }
  };

  const handleThumbnails = () => {
    matchData['thumbnailUrl'] = matchData['fileUrl'].replace(
      /\.[^.]+$/,
      '.jpg',
    );
    axios
      .post('/api/genTN', {
        metadata: matchData,
        match_id: matchData.matchId,
      })
      .then((response) => {
        // Handle the successful response
        setSnackbarMessage('已新增的縮圖!');
        setSnackbarOpen(true);
      })
      .catch((error) => {});
  };

  if (!matchData.videoName) {
    // Render a loading state or return null while matchData is loading
    return null;
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      {backdropError && <UploadErrorBackdrop onClose={handleCloseBackdrop} />}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={1000000}
        onClose={handleSnackbarClose}
        className="delete-snackbar"
        action={
          <Link to="/">
            <IconButton>
              <HomeOutlinedIcon sx={{ color: 'gold' }} />
            </IconButton>
          </Link>
        }
        message={snackbarMessage}
      />
      <div className="edit-other-option-panel">
        <Button onClick={() => handleThumbnails()}>
          <AccountBoxOutlinedIcon variant="outlined" />
          添加縮略圖
        </Button>
        <Button
          sx={{ color: 'red' }}
          endIcon={<DeleteForeverIcon sx={{ color: 'red' }} />}
          onClick={handleDeleteOpen}
        >
          刪除视频
        </Button>
        <Dialog
          className="delete-video-dialog"
          open={deleteOpen}
          onClose={handleDeleteClose}
        >
          <DialogTitle>請輸入「delete」以刪除视频</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="確認刪除"
              type="text"
              value={deleteInputValue}
              onChange={handleDeleteInputChange}
              error={errorMessage !== ''}
              helperText={errorMessage}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>取消</Button>
            <Button onClick={handleDelete} variant="contained" autoFocus>
              刪除
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
