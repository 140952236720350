import { useState } from 'react';

import { IconButton } from '@mui/material';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import AddPlaylist from 'src/components/AddPlaylist/AddPlaylist';

import {
  handleSeekBackward,
  handleSeekForward,
  handleSeekBackwardFrame,
  handleSeekForwardFrame,
  handleSeekBackwardBall,
  handleSeekForwardBall,
} from 'src/utils/videoControl';

import './VideoControlPanel.scss';

const formatTime = (startTime) => {
  if (!startTime) return 'N/A';
  const elapsed = performance.now() - startTime;
  return `${elapsed.toFixed(2)}ms`;
};

const VideoControlPanel = ({
  videoRef,
  roundResults,
  videoData,
  setVideoData,
  commentaryTimes,
  onZoomChange,
  scale,
  setScale,
  userInfo,
  matchData,
  currentTransformation,
  debugLog,
  timing,
  setDpOL,
  dpOL,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [showDebug, setShowDebug] = useState(false); // Toggle this for debug overlay

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const toggleOverlay = () => {
    setDpOL(!dpOL);
  };

  const handleZoomChange = (newScale) => {
    const clampedScale = Math.max(1, Math.min(newScale, 10));
    setScale(clampedScale);
    onZoomChange(clampedScale);
  };

  return (
    <div className={`video-control ${isMenuVisible ? 'visible' : 'hidden'}`}>
      {videoData.isFs !== null ? (
        <div
          className={`control-panel ${isMenuVisible ? 'visible' : 'hidden'}`}
        >
          <div className="options">
            <div
              className={`left-options ${isMenuVisible ? 'visible' : 'hidden'}`}
            >
              <IconButton
                title="上一个球"
                onClick={() =>
                  handleSeekBackwardBall(
                    videoRef,
                    commentaryTimes,
                    roundResults,
                    videoData.currentRound,
                    videoData.currentBall,
                    setVideoData,
                  )
                }
              >
                <SkipPreviousIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="前幀"
                onClick={() => handleSeekBackwardFrame(videoRef, setVideoData)}
              >
                <FastRewindIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="前5秒"
                onClick={() => handleSeekBackward(videoRef, setVideoData)}
              >
                <Replay5Icon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下5秒"
                onClick={() => handleSeekForward(videoRef, setVideoData)}
              >
                <Forward5Icon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下帧"
                onClick={() => handleSeekForwardFrame(videoRef, setVideoData)}
              >
                <FastForwardIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title="下一个球"
                onClick={() =>
                  handleSeekForwardBall(
                    videoRef,
                    commentaryTimes,
                    roundResults,
                    videoData.currentRound,
                    videoData.currentBall,
                    setVideoData,
                  )
                }
              >
                <SkipNextIcon sx={{ color: 'white' }} />
              </IconButton>
            </div>

            <div
              className={`others-menu ${isMenuVisible ? 'visible' : 'hidden'}`}
            >
              <div className="zoom-controls">
                <IconButton onClick={() => handleZoomChange(scale - 0.5)}>
                  <RemoveIcon className="zoom-icon" />
                </IconButton>
                <span
                  className="zoom-scale"
                  onClick={() => handleZoomChange(1)}
                >
                  {currentTransformation.scale.toFixed(1)}x
                </span>
                <IconButton onClick={() => handleZoomChange(scale + 0.5)}>
                  <AddIcon className="zoom-icon" />
                </IconButton>
              </div>
            </div>
            <div
              className={`right-content ${
                isMenuVisible ? 'visible' : 'hidden'
              }`}
            >
              <IconButton
                onClick={toggleMenu}
                className="toggle-button"
                aria-label={isMenuVisible ? 'Hide menu' : 'Show menu'}
              >
                {isMenuVisible ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
              {!isMenuVisible && (
                <>
                  <AddPlaylist
                    userInfo={userInfo}
                    matchId={matchData.matchId}
                  />
                  {userInfo.admin && (
                    <IconButton
                      onClick={toggleOverlay}
                      className="toggle-overlay-button"
                      aria-label={dpOL ? 'quit editing' : 'edit'}
                    >
                      {dpOL ? (
                        <ExitToAppOutlinedIcon />
                      ) : (
                        <ModeEditOutlinedIcon />
                      )}
                    </IconButton>
                  )}
                  <FlagVideo
                    matchId={matchData.matchId}
                    defaultOpen={false}
                    ocr={false}
                    setVideoData={setVideoData}
                  />
                  {/* {userInfo.admin && (
                    <IconButton
                      className="overlay-button debug-toggle"
                      title="Toggle Debug Overlay"
                      onClick={() => setShowDebug((prev) => !prev)}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        backgroundColor: showDebug
                          ? 'rgba(0, 150, 136, 0.8)'
                          : 'rgba(0, 0, 0, 0.5)',
                        '&:hover': {
                          backgroundColor: showDebug
                            ? 'rgba(0, 150, 136, 0.9)'
                            : 'rgba(0, 0, 0, 0.7)',
                        },
                      }}
                    >
                      <BugReportIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )}
                  {showDebug && (
                    <div className="debug-overlay">
                      <div>Load Start: {formatTime(timing.loadStart)}</div>
                      <div>
                        Metadata Loaded: {formatTime(timing.metadataLoaded)}
                      </div>
                      <div>
                        First Frame: {formatTime(timing.firstFrameLoaded)}
                      </div>
                      <div>Can Play: {formatTime(timing.canPlay)}</div>
                      <div>
                        Total Load Time:{' '}
                        {timing.canPlay
                          ? `${(timing.canPlay - (timing.loadStart || 0)).toFixed(2)}ms`
                          : 'Loading...'}
                      </div>
                      <div className="debug-logs">
                        {debugLog.slice(-5).map((log, i) => (
                          <div key={i}>{log}</div>
                        ))}
                      </div>
                    </div>
                  )} */}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default VideoControlPanel;
