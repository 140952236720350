// utils/layoutUtils.ts
export const calculateCircleLayout = (
  graph: any,
  isMobile: boolean,
  useFixedRadius = false
) => {
  const positions: { [key: string]: { x: number; y: number } } = {};
  const playerNodes = new Set<string>();
  const gameNodes = new Set<string>();

  // Get screen dimensions
  const width = window.innerWidth;
  const height = window.innerHeight;
  const smallestDimension = Math.min(width, height);

  // Determine radius based on mode
  const getRadius = () => {
    if (useFixedRadius) {
      return Math.max(400, gameNodes.size * 3);
    }
    return isMobile ? smallestDimension * 0.35 : smallestDimension * 0.4;
  };

  // Sort nodes
  graph.forEachNode((nodeId: string) => {
    const isGame = graph.getNodeAttribute(nodeId, "label")?.startsWith("Game");
    if (isGame) {
      gameNodes.add(nodeId);
    } else {
      playerNodes.add(nodeId);
    }
  });

  const outerRadius = getRadius();
  const innerRadius = useFixedRadius
    ? outerRadius * 0.6
    : isMobile
    ? outerRadius * 0.5
    : outerRadius * 0.6;

  // Position nodes
  Array.from(playerNodes).forEach((id, index) => {
    const angle = (index / playerNodes.size) * 2 * Math.PI - Math.PI / 2;
    positions[id] = {
      x: innerRadius * Math.cos(angle),
      y: innerRadius * Math.sin(angle),
    };
  });

  Array.from(gameNodes).forEach((id, index) => {
    const angle = (index / gameNodes.size) * 2 * Math.PI - Math.PI / 2;
    positions[id] = {
      x: outerRadius * Math.cos(angle),
      y: outerRadius * Math.sin(angle),
    };
  });

  return positions;
};
