import React, { useState, useEffect } from 'react';
import { Playlist } from 'src/types/playlist';
import * as playlistAPI from 'src/api/playlistService';
import PlaylistManagement from 'src/components/playlistManagment/playlistManagement';
import Padding from 'src/components/padding';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import Cookies from 'js-cookie';

import './Playlist.scss';

interface PlaylistPageProps {
    userType: string;
    userName: string;
}

interface MatchDict {
    [key: string]: string;
}



const PlaylistPage: React.FC<PlaylistPageProps> = ({ userType, userName }) => {
    const token = Cookies.get('token');

    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [matchDict, setMatchDict] = useState<MatchDict>({});
    const [allTags, setAllTags] = useState<string[]>([]);

    const handleOpenDialog = (playlist?: Playlist) => {
        setSelectedPlaylist(playlist || null);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedPlaylist(null);
    };

    useEffect(() => {
        fetchPlaylists();
    }, [userType, userName]);

    const fetchPlaylists = async () => {
        try {

            const response = await playlistAPI.fetchPlaylistDataAPI({ user_name: userName, user_type: userType }, token as string);
            setMatchDict(response[1])
            setPlaylists(response[0])

            // Extract all unique tags from the playlists
            const tags = new Set<string>();
            response[0].forEach((playlist: any) => {
                playlist.custom_tags.forEach((tag: string) => tags.add(tag));
            });
            setAllTags(Array.from(tags));
            setLoading(false)
        } catch (api_error) {
            console.error('Error fetching user list:', api_error);
            setError(api_error)
        }
    };

    const handleDeletePlaylist = async (playlistId: number) => {
        if (window.confirm('確定要刪除這個播放清單嗎？')) {
            try {

                const response = await playlistAPI.deletePlaylistAPI(playlistId, token as string);
                setPlaylists(playlists.filter(playlist => playlist.playlist_id !== playlistId));
            } catch (api_error) {
                console.error('Error deleting user list:', api_error);
            }
        }
    };

    if (loading) return <LoadingScreen />;
    if (error) return <div className="playlist-error">Error: {error}</div>;

    return (
        <div className="playlist-management">
            <div className='playlist-management-header'>
                <h2>{userName}的播放清單</h2>
                <div className="create-playlist">
                    <button className="button-primary" onClick={() => handleOpenDialog()}>添加清單</button>
                </div>
            </div>
            <div className="playlist-list">
                {playlists.map((playlist) => (
                    <div key={playlist.playlist_id} className="playlist-item">
                        <h3 onClick={() => handleOpenDialog(playlist)}>
                            {playlist.playlist_name}
                        </h3>
                        <div className="playlist-content">
                            <div className="playlist-metadata">
                                <p>{playlist.description}</p>
                                <p>比賽數：{playlist.match_order.length}</p>
                                <p>日期：{new Date(playlist.date_created).toLocaleDateString()}</p>
                                <p>標籤：{playlist.custom_tags.join('、')}</p>
                            </div>
                            <div className="match-list">
                                {playlist.match_order.map((matchId, index) => (
                                    <div
                                        key={matchId}
                                        className="match-item"
                                        data-index={`${index + 1}`}
                                    >
                                        {matchDict[matchId.toString()] || `Match ${matchId}`}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button
                            className="button-danger"
                            onClick={() => handleDeletePlaylist(playlist.playlist_id)}
                        >
                            刪除
                        </button>
                    </div>
                ))}
            </div>
            {isDialogOpen && (
                <PlaylistManagement
                    username={userName}
                    accountType={userType}
                    onClose={handleCloseDialog}
                    initialPlaylist={selectedPlaylist || undefined}
                    mode={selectedPlaylist ? 'edit' : 'create'}
                    allTags={allTags}
                    onSaveComplete={fetchPlaylists}
                />
            )}
            <Padding />
        </div>
    );
};

export default PlaylistPage;