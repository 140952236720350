// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  min-width: var(--medium-font-size);
}

.round {
  border-right: 1px solid black;
}

.player-name {
  font-size: var(--medium-font-size);
}

.score-table {
  background-color: var(--main-text-color);
  border-collapse: collapse;
  border: 1px solid black;
}
.score-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: var(--xlarge-font-size);
}

.player-score {
  cursor: pointer;
  border-right: 1px solid #141414;
}
.player-score td {
  font-size: var(--medium-font-size);
}
.player-score:hover {
  background-color: #f5f5f1;
}
.player-score.highlight {
  background-color: var(--apple-blue);
}
.player-score.current-ball {
  background-color: var(--apple-green);
}
.player-score.highlight.current-ball {
  background-color: var(--apple-pink);
}`, "",{"version":3,"sources":["webpack://./src/components/ScoreBoard/table.scss"],"names":[],"mappings":"AAEA;EACE,kCAAA;AADF;;AAIA;EACE,6BAAA;AADF;;AAIA;EACE,kCAAA;AADF;;AAIA;EACE,wCAAA;EACA,yBAAA;EACA,uBAAA;AADF;AAGE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,8BAAA;AADJ;;AAKA;EACE,eAAA;EACA,+BAAA;AAFF;AAGE;EACE,kCAAA;AADJ;AAIE;EACE,yBApCS;AAkCb;AAKE;EACE,mCAAA;AAHJ;AAME;EACE,oCAAA;AAJJ;AAOE;EACE,mCAAA;AALJ","sourcesContent":["$hoverColor: #f5f5f1;\n\ntd {\n  min-width: var(--medium-font-size);\n}\n\n.round {\n  border-right: 1px solid black;\n}\n\n.player-name {\n  font-size: var(--medium-font-size);\n}\n\n.score-table {\n  background-color: var(--main-text-color);\n  border-collapse: collapse;\n  border: 1px solid black;\n\n  td {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    text-align: left;\n    width: var(--xlarge-font-size);\n  }\n}\n\n.player-score {\n  cursor: pointer;\n  border-right: 1px solid #141414;\n  td {\n    font-size: var(--medium-font-size);\n  }\n\n  &:hover {\n    background-color: $hoverColor;\n  }\n\n  &.highlight {\n    background-color: var(--apple-blue);\n  }\n\n  &.current-ball {\n    background-color: var(--apple-green);\n  }\n\n  &.highlight.current-ball {\n    background-color: var(--apple-pink);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
