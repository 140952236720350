// src/handlers/graphInitializer.ts
import { UndirectedGraph } from "graphology";
import { PlayerData, ConnectionsData } from "../types/graph.types";

export const initializeGraph = (
  playerData: PlayerData,
  connectionsData: ConnectionsData,
  isMobile: boolean
) => {
  const graph = new UndirectedGraph();
  const width = window.innerWidth;
  const height = window.innerHeight;

  // Calculate responsive sizes
  const playerSize = isMobile ? 8 : 10;
  const gameSize = isMobile ? 6 : 8;

  // Use random positions for initial layout
  const getRandomPosition = () => ({
    x: (Math.random() - 0.5) * width * 0.8,
    y: (Math.random() - 0.5) * height * 0.8,
  });

  // Collect node IDs
  const playerIds = new Set<string>();
  const gameIds = new Set<string>();

  Object.keys(connectionsData).forEach((id) => {
    if (playerData[id]) {
      playerIds.add(id);
    }
  });

  Object.values(connectionsData).forEach((targets) => {
    targets.forEach((targetId) => {
      if (!playerData[targetId.toString()]) {
        gameIds.add(targetId.toString());
      }
    });
  });

  // Add nodes with random positions
  Array.from(playerIds).forEach((id) => {
    const pos = getRandomPosition();
    (graph as any).addNode(id, {
      label: playerData[id],
      x: pos.x,
      y: pos.y,
      size: playerSize,
      color: "#4169E1",
      borderSize: isMobile ? 1 : 2,
      borderColor: "#ffffff",
      type: "circle",
      labelSize: isMobile ? 8 : 10,
      labelOutlineSize: isMobile ? 2 : 3,
    });
  });

  Array.from(gameIds).forEach((id) => {
    const pos = getRandomPosition();
    (graph as any).addNode(id, {
      label: `Game ${id}`,
      x: pos.x,
      y: pos.y,
      size: gameSize,
      color: "#B0C4DE",
      borderSize: isMobile ? 0.5 : 1,
      borderColor: "#ffffff",
      type: "circle",
      labelSize: isMobile ? 6 : 8,
    });
  });

  // Add edges
  Object.entries(connectionsData).forEach(([sourceId, targetIds]) => {
    targetIds.forEach((targetId) => {
      const targetIdString = targetId.toString();
      try {
        if (!(graph as any).hasEdge(sourceId, targetIdString)) {
          (graph as any).addEdge(sourceId, targetIdString, {
            size: isMobile ? 0.3 : 0.5,
            color: "#D3D3D3",
          });
        }
      } catch (error) {
        console.error(
          `Failed to add edge between ${sourceId} and ${targetIdString}`
        );
      }
    });
  });

  return graph;
};
