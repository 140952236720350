import {
  ConnectionsData,
  PlayerData,
  Selection_,
} from "../../types/graph.types";

export const getAvailablePlayers = (
  connectionsData: ConnectionsData,
  playerData: PlayerData,
  excludePlayer?: string
) => {
  return Object.entries(connectionsData)
    .filter(([playerId, gameIds]) => {
      if (excludePlayer && playerId === excludePlayer) return false;
      return gameIds.length > 0;
    })
    .map(([id]) => ({
      id,
      name: playerData[id],
    }));
};

export const getAvailableOpponents = (
  playerId: string,
  connectionsData: ConnectionsData,
  playerData: PlayerData
) => {
  if (!playerId) return [];

  const playerGameIds = connectionsData[playerId] || [];

  return Object.entries(connectionsData)
    .filter(([id, games]) => {
      if (id === playerId) return false;
      return games.some((gameId) => playerGameIds.includes(gameId));
    })
    .map(([id]) => {
      const commonGames = connectionsData[id].filter((gameId) =>
        playerGameIds.includes(gameId)
      );
      return {
        id,
        name: `${playerData[id]} (${commonGames.length} games)`,
        commonGamesCount: commonGames.length,
      };
    })
    .sort((a, b) => b.commonGamesCount - a.commonGamesCount);
};

export const getGamesPlayedCount = (
  selection: Selection_,
  connectionsData: ConnectionsData
): number => {
  if (!selection.selectedPlayer || !selection.selectedOpponent) return 0;

  const playerGames = connectionsData[selection.selectedPlayer] || [];
  const opponentGames = connectionsData[selection.selectedOpponent] || [];

  const commonGames = playerGames.filter((gameId) =>
    opponentGames.includes(gameId)
  );

  return commonGames.length;
};

export const getCommonOpponents = (
  firstPlayer: string,
  secondPlayer: string,
  connectionsData: ConnectionsData,
  playerData: PlayerData
) => {
  if (!firstPlayer || !secondPlayer) return [];

  const firstPlayerGames = connectionsData[firstPlayer] || [];
  const secondPlayerGames = connectionsData[secondPlayer] || [];

  return Object.entries(connectionsData)
    .filter(([playerId, games]) => {
      if (playerId === firstPlayer || playerId === secondPlayer) return false;

      const gamesWithFirst = games.filter((gameId) =>
        firstPlayerGames.includes(gameId)
      );
      const gamesWithSecond = games.filter((gameId) =>
        secondPlayerGames.includes(gameId)
      );

      return gamesWithFirst.length > 0 && gamesWithSecond.length > 0;
    })
    .map(([id, games]) => {
      const gamesWithFirst = games.filter((gameId) =>
        firstPlayerGames.includes(gameId)
      );
      const gamesWithSecond = games.filter((gameId) =>
        secondPlayerGames.includes(gameId)
      );

      const commonGamesAllThree = games.filter(
        (gameId) =>
          firstPlayerGames.includes(gameId) &&
          secondPlayerGames.includes(gameId)
      );

      return {
        id,
        name: `${playerData[id]} (${gamesWithFirst.length} with ${playerData[firstPlayer]}, ${gamesWithSecond.length} with ${playerData[secondPlayer]}, ${commonGamesAllThree.length} common)`,
        commonGames: {
          withFirst: gamesWithFirst,
          withSecond: gamesWithSecond,
          withBoth: commonGamesAllThree,
        },
      };
    });
};
