// shared/CommonOpponentDetails.tsx
import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ConnectionsData,
  GameClickInfo,
  PlayerData,
} from "../types/graph.types";
import { fetchVideoInfoData } from "../data/VideoInfoData";
import { GameDetailsBox } from "./GameDetailsBox";

interface CommonOpponentDetailsProps {
  commonOpponentId: string;
  commonGames: {
    withFirst: number[];
    withSecond: number[];
    withBoth: number[];
  };
  firstPlayerId: string;
  secondPlayerId: string;
  playerData: PlayerData;
  connectionsData: ConnectionsData;
  onViewDetails: (game: GameClickInfo) => void;
}

export const CommonOpponentDetails: React.FC<CommonOpponentDetailsProps> = ({
  commonOpponentId,
  commonGames,
  firstPlayerId,
  secondPlayerId,
  playerData,
  connectionsData,
  onViewDetails,
}) => {
  const { t } = useTranslation();
  const [firstPlayerGames, setFirstPlayerGames] = React.useState<
    GameClickInfo[]
  >([]);
  const [secondPlayerGames, setSecondPlayerGames] = React.useState<
    GameClickInfo[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const loadGameDetails = async () => {
      setIsLoading(true);
      try {
        const [firstGames, secondGames] = await Promise.all([
          Promise.all(
            commonGames.withFirst.map((gameId) =>
              fetchVideoInfoData(gameId).catch(() => ({ data: null }))
            )
          ),
          Promise.all(
            commonGames.withSecond.map((gameId) =>
              fetchVideoInfoData(gameId).catch(() => ({ data: null }))
            )
          ),
        ]);

        const processGames = (
          responses: Array<{ data: any } | { data: null }>
        ) =>
          responses
            .filter(
              (response): response is { data: any } =>
                !!response.data?.metadata?.id &&
                !!response.data?.metadata?.eventName &&
                Array.isArray(response.data?.games)
            )
            .map((response) => {
              const gameId = response.data.metadata.id;
              return {
                gameId: gameId.toString(),
                metadata: response.data.metadata,
                gameScores: response.data.games
                  .filter((game: { points: any[] }) => game?.points?.length > 0)
                  .map(
                    (game: {
                      points: any[];
                      gameInfo: { gameNumber: any };
                    }) => {
                      const points = game.points || [];
                      const lastPoint = points[points.length - 1];
                      return {
                        gameNumber: game.gameInfo?.gameNumber ?? -1,
                        finalTeam1Score: lastPoint?.team1Score ?? null,
                        finalTeam2Score: lastPoint?.team2Score ?? null,
                      };
                    }
                  ),
                players: Object.entries(connectionsData)
                  .filter(([, games]) => games.includes(gameId))
                  .map(([playerId]) => ({
                    playerId,
                    playerName: playerData[playerId],
                  })),
              };
            });

        setFirstPlayerGames(processGames(firstGames));
        setSecondPlayerGames(processGames(secondGames));
      } catch (error) {
        console.error("Error loading game details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadGameDetails();
  }, [commonOpponentId, commonGames, connectionsData, playerData]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", py: 2 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      {/* TODO: This is not show on the graph, so we can remove this part */}
      {/* <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
        {t("selection.gamesWith", {
              playerName: playerData[secondPlayerId],
              count: secondPlayerGames.length
            })}
      </Typography>
      {firstPlayerGames.map((game) => (
        <GameDetailsBox
          key={game.gameId}
          game={game}
          onViewDetails={onViewDetails}
        />
      ))} */}

      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ mt: 3, mb: 1 }}
      >
        {t("selection.gamesWith", {
          playerName: playerData[secondPlayerId],
          count: secondPlayerGames.length,
        })}
      </Typography>
      {secondPlayerGames.map((game) => (
        <GameDetailsBox
          key={game.gameId}
          game={game}
          onViewDetails={onViewDetails}
        />
      ))}
    </Box>
  );
};
