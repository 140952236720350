// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-analysis {
  display: block;
  width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
  background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/playerPages/PlayerAnalysis.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;AACF","sourcesContent":[".player-analysis {\n  display: block;\n  width: 100vw;\n  max-height: 100vh;\n  overflow-y: auto;\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
