import { useState, useEffect } from 'react';

import './AnalysisMainPanel.scss';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

import PlayerInfoPanel from '../PlayerInfoPanel/PlayerInfoPanel';

import PlayerSnippets from '../PlayerSnippets/PlayerSnippets';
import PlayerVideos from '../PlayerVideos/PlayerVideos';

import { fetchIndMatchDataAPI, fetchMatchDataAPI } from 'src/api/videoService';
import Cookies from 'js-cookie';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  dateIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  ballDataIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
  awayPlayerIdIndex,
  homePlayerIdIndex,
} from 'src/constants';

const AnalysisMainPanel = ({ playerData, playerList, userInfo }) => {
  const token = Cookies.get('token');
  const [error, setError] = useState('');
  const [playerTab, setPlayerTab] = useState(0);
  const [playerStats, setPlayerStats] = useState({});
  const [videoList, setVideoList] = useState([]);
  const [sortedVideoList, setSortedVideoList] = useState([]);

  const handlePlayerTabChange = (event, newValue) => {
    setPlayerTab(newValue);
  };

  const fetchIndMatchData = async (videoId) => {
    try {
      const responseData = await fetchIndMatchDataAPI(videoId, token);
      const [MatchData, rawRoundResults] = responseData;
      const scores = rawRoundResults.map((roundResult) => {
        const ballData = roundResult[ballDataIndex];
        if (ballData && ballData.length > 0) {
          const lastBallData = ballData[ballData.length - 1];
          return {
            homeScore: lastBallData[ballHomePlayerScoreIndex],
            awayScore: lastBallData[ballAwayPlayerScoreIndex],
          };
        }
        return { homeScore: 0, awayScore: 0 };
      });
      return scores;
    } catch (error) {
      setError(error.message);
      return [{ homeScore: 0, awayScore: 0 }];
    }
  };

  const transformVideoData = async (
    videoData,
    index,
    responseOpponentsData,
  ) => {
    const indMatchData = await fetchIndMatchData(videoData[0]);

    const fileUrl = videoData[urlIndex];
    const urlWithoutSuffix = fileUrl.substring(0, fileUrl.lastIndexOf('.'));
    const TNUrl = videoData[thumbnailIndex];
    const newFileUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
    const newTNUrl = `${process.env.PUBLIC_URL}/thumbnails/${TNUrl}`;
    const fetchedMatchDate = videoData[dateIndex];
    const dateWithoutTime = fetchedMatchDate.replace(
      /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
      '',
    );

    const opponentData =
      responseOpponentsData[index].find((potentialOpponent) => {
        const playerId = potentialOpponent['player_id'];
        return (
          playerId === videoData[awayPlayerIdIndex] ||
          playerId === videoData[homePlayerIdIndex]
        );
      }) || {};

    const opponent_head_shot = opponentData['head_shot'];
    const opponent_player2_head_shot = opponentData['player2_head_shot'];

    const last_updated_date = new Date(
      opponentData.last_updated,
    ).toLocaleDateString('en-GB');

    const birthDate = opponentData['dob']
      ? new Date(opponentData['dob'])
      : new Date('2000-01-01');
    const actual_age = calculateAge(birthDate);

    const opponent_head_shot_path = getHeadshotPath(opponent_head_shot);
    const opponent_player2_head_shot_path = getHeadshotPath(
      opponent_player2_head_shot,
    );

    const homePlayer = videoData[homePlayerIndex];
    const awayPlayer = videoData[awayPlayerIndex];

    const { playerScore, opponentScore } = getScores(
      homePlayer,
      awayPlayer,
      videoData,
      playerData,
    );

    const updatedIndMatchData = indMatchData.map(
      ({ homeScore, awayScore }) => ({
        [homePlayer]: homeScore,
        [awayPlayer]: awayScore,
      }),
    );

    return {
      matchId: videoData[matchIdIndex],
      videoName: videoData[videoNameIndex],
      matchDescription: videoData[matchDescriptionIndex],
      stage: videoData[stageIndex],
      transformedMatchDate: dateWithoutTime,
      location: videoData[locationIndex],
      transformedFileURL: newFileUrl,
      transformedTNUrl: newTNUrl,
      report: videoData[reportIndex],
      subEvent: videoData[subEventIndex],
      opponentGender: opponentData['gender'],
      opponentId: opponentData['player_id'] ?? 0,
      opponentName: opponentData['player_name'] ?? '_',
      opponentCountry: opponentData['country'],
      opponentCountryCode: opponentData['country_code'],
      opponentAge: actual_age,
      opponentLastUpdated: last_updated_date,
      opponentHand: opponentData['handedness'],
      opponentStyle: opponentData['grip'],
      opponentImg: opponent_head_shot_path,
      opponentPlayer2Img: opponent_player2_head_shot_path,
      opponentScore,
      playerScore,
      roundData:
        updatedIndMatchData.length > 0
          ? updatedIndMatchData
          : [{ [homePlayer]: 0, [awayPlayer]: 0 }],
    };
  };

  const fetchMatchData = async () => {
    try {
      const response = await fetchMatchDataAPI(
        {
          player: playerData.player_id,
          playerName: playerData.player_name,
          accountType: userInfo.accountType,
          user: userInfo.identifier,
          admin: true,
        },
        token,
      );

      const responseData = response.videoData;
      const responseOpponentsData = response.opponentsData;

      if (!responseData) {
        setVideoList([]);
        return;
      }

      const transformedList = await Promise.all(
        responseData.map((videoData, index) =>
          transformVideoData(videoData, index, responseOpponentsData),
        ),
      );

      setVideoList(transformedList);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchMatchData();
  }, []);

  useEffect(() => {
    if (videoList.length < 1) {
      return;
    }

    const sorted = [...videoList].sort(
      (a, b) =>
        new Date(b.transformedMatchDate) - new Date(a.transformedMatchDate),
    );
    setSortedVideoList(sorted);

    const stats = calculatePlayerStats(sorted);
    setPlayerStats(stats);
  }, [videoList]);

  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const getHeadshotPath = (headshot) => {
    if (headshot && !headshot.startsWith('https')) {
      return `${process.env.PUBLIC_URL}/players/headshots/${headshot}`;
    }
    return headshot;
  };

  const getScores = (homePlayer, awayPlayer, videoData, playerData) => {
    if (homePlayer === playerData.player_name) {
      return {
        playerScore: videoData[homePlayerMatchScoreIndex],
        opponentScore: videoData[awayPlayerMatchScoreIndex],
      };
    }
    return {
      playerScore: videoData[awayPlayerMatchScoreIndex],
      opponentScore: videoData[homePlayerMatchScoreIndex],
    };
  };

  const calculatePlayerStats = (sortedVideoList) => {
    const stats = {
      totalMatches: sortedVideoList.length,
      totalNonZeroMatches: 0,
      wins: 0,
      losses: 0,
      winRate: 0,
      stageStats: {},
      subEventStats: {},
      gripStats: {},
      handednessStats: {},
      countryStats: {},
      opponentStats: {},
    };

    sortedVideoList.forEach((match) => {
      const playerScore = parseInt(match.playerScore);
      const opponentScore = parseInt(match.opponentScore);

      // Skip if both scores are 0
      if (playerScore === 0 && opponentScore === 0) {
        return;
      }

      stats.totalNonZeroMatches++;
      const isWin = playerScore > opponentScore;
      stats.wins += isWin ? 1 : 0;
      stats.losses += isWin ? 0 : 1;

      updateStatCategory(stats.stageStats, match.stage, isWin);
      updateStatCategory(stats.subEventStats, match.subEvent, isWin);
      updateStatCategory(stats.gripStats, match.opponentStyle, isWin);
      updateStatCategory(stats.handednessStats, match.opponentHand, isWin);
      updateStatCategory(stats.countryStats, match.opponentCountry, isWin);

      // Update opponent stats
      if (!stats.opponentStats[match.opponentName]) {
        stats.opponentStats[match.opponentName] = {
          wins: 0,
          losses: 0,
          totalMatches: 0,
        };
      }
      stats.opponentStats[match.opponentName].totalMatches++;
      if (isWin) {
        stats.opponentStats[match.opponentName].wins++;
      } else {
        stats.opponentStats[match.opponentName].losses++;
      }
    });

    stats.winRate =
      stats.totalNonZeroMatches > 0
        ? ((stats.wins / stats.totalNonZeroMatches) * 100).toFixed(2)
        : 0;

    // Calculate win percentage for each opponent
    Object.keys(stats.opponentStats).forEach((opponent) => {
      const opponentData = stats.opponentStats[opponent];
      opponentData.winPercentage =
        opponentData.totalMatches > 0
          ? ((opponentData.wins / opponentData.totalMatches) * 100).toFixed(2)
          : 0;
    });

    return stats;
  };
  const updateStatCategory = (category, key, isWin) => {
    if (!category[key]) {
      category[key] = { wins: 0, losses: 0 };
    }
    if (isWin) {
      category[key].wins++;
    } else {
      category[key].losses++;
    }
  };

  return (
    <>
      <div className="player-analysis-info-container">
        <PlayerInfoPanel
          playerData={playerData}
          userInfo={userInfo}
          playerStats={playerStats}
        />
      </div>
      <div className="player-analysis-container">
        {userInfo.admin && (
          <Tabs
            className="analysis-main-tab-panel"
            value={playerTab}
            onChange={handlePlayerTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            indicatorColor="secondary"
            aria-label="player tag"
          >
            <Tab
              className="analysis-sec-tab"
              icon={<EmojiEventsOutlinedIcon />}
              label="比賽紀錄"
            />
            <Tab
              className="analysis-sec-tab"
              icon={<VideoLibraryOutlinedIcon />}
              label="球員dp"
            />
          </Tabs>
        )}
        {playerTab === 0 && (
          <PlayerVideos
            playerData={playerData}
            accountType={userInfo.accountType}
            videoList={sortedVideoList}
            playerStats={playerStats}
          />
        )}
        {playerTab === 1 && userInfo.admin && (
          <PlayerSnippets
            accountType={userInfo.accountType}
            admin={userInfo.admin}
            identifier={userInfo.identifier}
          />
        )}
        <div className="padding"></div>
      </div>
    </>
  );
};

export default AnalysisMainPanel;
