// File: app/common/constants/reducers/nodeHelpers.ts
import { getEnhancedLabelStyle } from "../../utils/graph/graph.utils";
import { NODE_STYLES } from "../GraphStyles";
import { CustomAttributes, ExtendedNodeDisplayData } from "../GraphType";
import { findShortestPath } from "./shortestPath";
import {
  GameNodeHandlerParams,
  SelectedCommonOpponentParams,
  SelectedNodeParams,
  SelectedOpponentParams,
  RemainingCasesParams,
  DefaultStateParams,
} from "./types";

export const handleDefaultState = ({
  newData,
  isPlayer,
  isHighlighted,
  deviceType,
  playerData,
  node,
}: DefaultStateParams): Partial<ExtendedNodeDisplayData> => {
  return {
    ...newData,
    color: isPlayer
      ? isHighlighted
        ? NODE_STYLES.player[deviceType].highlighted.color
        : NODE_STYLES.player[deviceType].color
      : NODE_STYLES.game[deviceType].states.default,
    size: isPlayer
      ? NODE_STYLES.player[deviceType].size
      : NODE_STYLES.game[deviceType].size,
    label: isPlayer ? playerData[node] : null,
  };
};

export const handleGameNode = ({
  node,
  data,
  deviceType,
  selectedNode,
  selectedOpponent,
  selectedCommonOpponent,
  connectionsData,
  findCommonGameIds,
}: GameNodeHandlerParams): Partial<ExtendedNodeDisplayData> => {
  const nodeId = parseInt(node);
  const redOrangeGames = selectedOpponent
    ? findCommonGameIds(selectedNode, selectedOpponent, connectionsData)
    : [];
  const yellowRedGames = selectedCommonOpponent
    ? findCommonGameIds(selectedNode, selectedCommonOpponent, connectionsData)
    : [];
  const yellowOrangeGames =
    selectedCommonOpponent && selectedOpponent
      ? findCommonGameIds(
          selectedOpponent,
          selectedCommonOpponent,
          connectionsData
        )
      : [];
  const isYellowOrangeGame =
    selectedCommonOpponent && yellowOrangeGames.includes(nodeId);
  const isRedOrangeGame =
    !selectedCommonOpponent && redOrangeGames.includes(nodeId);

  if (isYellowOrangeGame) {
    return {
      ...data,
      color: NODE_STYLES.game[deviceType].states.yellowOrange,
      size: (data.size ?? 1) * 1,
      zIndex: 1,
      label: `Game ${node}`,
      labelSize: 14,
      labelBorderSize: 3,
      labelBackgroundColor: "rgba(255, 255, 255, 0.8)",
    };
  }

  if (isRedOrangeGame) {
    return {
      ...data,
      color: NODE_STYLES.game[deviceType].states.connected,
      size: (data.size ?? 1) * 1,
      zIndex: 1,
      label: `Game ${node}`,
      labelSize: 14,
      labelBorderSize: 3,
      labelBackgroundColor: "rgba(255, 255, 255, 0.8)",
    };
  }

  return {
    ...data,
    color: NODE_STYLES.game[deviceType].states.disabled,
    size: (data.size ?? 1) * 0.8,
    label: undefined,
    hidden: false,
  };
};

export const handleSelectedCommonOpponent = ({
  node,
  data,
  isHighlighted,
  deviceType,
  sizeMultiplier,
  selectedNode,
  selectedOpponent,
  playerData,
  connectionsData,
  getGameCount,
  getEnhancedLabelStyle,
}: SelectedCommonOpponentParams): Partial<ExtendedNodeDisplayData> => {
  // Early return if required values are null
  if (!selectedNode || !selectedOpponent) {
    return {
      ...data,
      color: NODE_STYLES.player.states.irrelevant,
      size: (data.size ?? 1) * 0.8,
      label: undefined,
      hidden: false,
    };
  }

  return {
    ...data,
    color: isHighlighted
      ? NODE_STYLES.player[deviceType].highlighted.selectedColor
      : NODE_STYLES.player.states.commonOpponent,
    size: (data.size ?? 1) * sizeMultiplier * 1,
    zIndex: 3,
    label: playerData[node]
      ? `${playerData[node]} (${getGameCount(
          selectedNode,
          node,
          connectionsData
        )}/ COMM: ${getGameCount(
          selectedOpponent,
          node,
          connectionsData
        )} games)`
      : playerData[node],
    ...getEnhancedLabelStyle(),
  };
};

export const handleSelectedNode = ({
  data,
  sizeMultiplier,
  getEnhancedLabelStyle,
}: SelectedNodeParams): Partial<ExtendedNodeDisplayData> => {
  return {
    ...data,
    color: NODE_STYLES.player.states.selected,
    size: (data.size ?? 1) * sizeMultiplier,
    zIndex: 2,
    ...getEnhancedLabelStyle(),
  };
};

export const handleSelectedOpponent = ({
  data,
  sizeMultiplier,
  getEnhancedLabelStyle,
}: SelectedOpponentParams): Partial<ExtendedNodeDisplayData> => {
  return {
    ...data,
    color: NODE_STYLES.player.states.opponent,
    size: (data.size ?? 1) * sizeMultiplier,
    zIndex: 2,
    ...getEnhancedLabelStyle(),
  };
};

export const handleRemainingCases = ({
  node,
  data,
  isHighlighted,
  deviceType,
  sizeMultiplier,
  showCommonOpponents,
  commonOpponents,
  firstPlayerOpponents,
  selectedNode,
  selectedOpponent,
  playerData,
  connectionsData,
  getGameCount,
}: RemainingCasesParams): Partial<ExtendedNodeDisplayData> => {
  if (showCommonOpponents && commonOpponents.includes(node)) {
    return {
      ...data,
      color: isHighlighted
        ? NODE_STYLES.player[deviceType].highlighted.selectedColor
        : NODE_STYLES.player.states.commonOpponent,
      size: (data.size ?? 1) * sizeMultiplier,
      zIndex: 2,
      label:
        playerData[node] && selectedOpponent
          ? `${playerData[node]} (${getGameCount(
              selectedNode,
              node,
              connectionsData
            )}/ COMM: ${getGameCount(
              selectedOpponent,
              node,
              connectionsData
            )} games)`
          : playerData[node],
      ...getEnhancedLabelStyle(),
    };
  }

  if (firstPlayerOpponents.includes(node) && !showCommonOpponents) {
    return {
      ...data,
      color: NODE_STYLES.player.states.connected,
      size: (data.size ?? 1) * sizeMultiplier,
      zIndex: 1,
      label: playerData[node]
        ? `${playerData[node]} (${getGameCount(
            selectedNode,
            node,
            connectionsData
          )} games)`
        : undefined,
    };
  }

  if (showCommonOpponents && firstPlayerOpponents.includes(node)) {
    return {
      ...data,
      color: NODE_STYLES.player.states.disabled,
      size: (data.size ?? 1) * 0.8,
      label: undefined,
      hidden: false,
      zIndex: 0,
    };
  }

  return {
    ...data,
    color: isHighlighted
      ? NODE_STYLES.player[deviceType].highlighted.irrelevant
      : NODE_STYLES.player.states.irrelevant,
    size: (data.size ?? 1) * 0.8,
    label: undefined,
    hidden: false,
  };
};

interface HandleGamePathParams {
  node: string;
  data: Partial<ExtendedNodeDisplayData>;
  deviceType: "mobile" | "desktop";
  selectedNode: string;
  selectedOpponent: string | null;
  connectionsData: Record<string, any>;
  playerData: Record<string, string>;
  isShortestPath: boolean;
}

export const handleGamePath = ({
  node,
  data,
  deviceType,
  selectedNode,
  selectedOpponent,
  connectionsData,
  playerData,
  isShortestPath,
}: HandleGamePathParams): Partial<ExtendedNodeDisplayData> => {
  // Handle single player selection
  if (!selectedOpponent) {
    return {
      ...data,
      color: NODE_STYLES.game[deviceType].states.disabled,
      size: (data.size ?? 1) * 0.5,
      label: undefined,
      hidden: false,
    };
  }

  // Handle path between two players
  const { edges, isDirectPath } = findShortestPath(
    selectedNode,
    selectedOpponent,
    connectionsData,
    playerData
  );

  const isGameInPath = edges.some(
    ([source, target]) => source === node || target === node
  );

  if (isGameInPath) {
    return {
      ...data,
      color: NODE_STYLES.game[deviceType].states.connected,
      size: (data.size ?? 1) * 1,
      zIndex: 1,
      label: `Game ${node}`,
      labelSize: 14,
      labelBorderSize: 3,
      labelBackgroundColor: "rgba(255, 255, 255, 0.8)",
    };
  }

  return {
    ...data,
    color: NODE_STYLES.game[deviceType].states.disabled,
    size: (data.size ?? 1) * 0.8,
    label: undefined,
    hidden: false,
  };
};

// In nodeHelpers.ts

interface HandlePlayerPathParams {
  node: string;
  newData: Partial<ExtendedNodeDisplayData>;
  data: CustomAttributes;
  deviceType: "mobile" | "desktop";
  selectedNode: string;
  selectedOpponent: string | null;
  connectionsData: Record<string, any>;
  playerData: Record<string, string>;
  isHighlighted: boolean;
  sizeMultiplier: number;
  getEnhancedLabelStyle: () => any;
}

export const handlePlayerPath = ({
  node,
  newData,
  data,
  deviceType,
  selectedNode,
  selectedOpponent,
  connectionsData,
  playerData,
  isHighlighted,
  sizeMultiplier,
  getEnhancedLabelStyle,
}: HandlePlayerPathParams): Partial<ExtendedNodeDisplayData> => {
  // Handle single player selection
  if (!selectedOpponent) {
    return {
      ...newData,
      color:
        node === selectedNode
          ? NODE_STYLES.player.states.selected
          : isHighlighted
          ? NODE_STYLES.player[deviceType].highlighted.color
          : NODE_STYLES.player[deviceType].color,
      size:
        node === selectedNode
          ? (data.size ?? 1) * sizeMultiplier
          : NODE_STYLES.player[deviceType].size,
      label: playerData[node],
      zIndex: node === selectedNode ? 2 : 1,
      ...(node === selectedNode ? getEnhancedLabelStyle() : {}),
    };
  }

  // Handle path between two players
  const { path, isDirectPath } = findShortestPath(
    selectedNode,
    selectedOpponent,
    connectionsData,
    playerData
  );

  if (path.includes(node)) {
    return {
      ...newData,
      color: isDirectPath ? "#4CAF50" : "#FFA726",
      size: (data.size ?? 1) * sizeMultiplier,
      zIndex: 2,
      label: playerData[node],
      ...getEnhancedLabelStyle(),
    };
  }

  return {
    ...newData,
    color: isHighlighted
      ? NODE_STYLES.player[deviceType].highlighted.color
      : NODE_STYLES.player[deviceType].color,
    size: (data.size ?? 1) * 0.8,
    label: playerData[node],
    hidden: false,
    zIndex: 1,
  };
};
