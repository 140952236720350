import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Flag from 'react-world-flags';
import { countryCodeMapping } from 'src/constants';
import PlayerProfileCard from '../PlayerProfileCard/PlayerProfileCard';

import './PlayerVideoList.scss';

const translationDict = {
  Final: '决赛',
  'Semi-Final': '半决赛',
  'Quarter-Final': '4强',
  R16: '16强',
  R32: '32强',
  R64: '64强',
  R128: '128强',
  MSingles: '男單打',
  MDouble: '男雙打',
  FSingle: '女單打',
  FDouble: '女雙打',
  MixedDouble: '混合雙打',
};

interface PlayerVideoListProps {
  matchDetails: Record<string, any>;
  playerData: Record<string, any>;
  accountType: string;
  playerStats: {
    totalMatches: number;
    wins: number;
    losses: number;
    winRate: string;
    stageStats: Record<string, { wins: number; losses: number }>;
    subEventStats: Record<string, { wins: number; losses: number }>;
    gripStats: Record<string, { wins: number; losses: number }>;
    handednessStats: Record<string, { wins: number; losses: number }>;
    countryStats: Record<string, { wins: number; losses: number }>;
    opponentStats: Record<
      string,
      {
        wins: number;
        losses: number;
        totalMatches: number;
        winPercentage: string;
      }
    >;
  };
}

const PlayerVideoList: React.FC<PlayerVideoListProps> = ({
  matchDetails,
  playerData,
  accountType,
  playerStats,
}) => {
  const [showPlayerProfile, setShowPlayerProfile] = useState<string | null>(
    null,
  );

  const playerCode =
    countryCodeMapping[
    playerData.country_code as keyof typeof countryCodeMapping
    ] || '';
  const opponentCode =
    countryCodeMapping[
    matchDetails.opponentCountryCode as keyof typeof countryCodeMapping
    ] || '';

  const togglePlayerProfile = (playerType: string) => {
    setShowPlayerProfile((prevState) =>
      prevState === playerType ? null : playerType,
    );
  };

  const getOpponentStats = (opponentName: string) => {
    if (playerStats.opponentStats && playerStats.opponentStats[opponentName]) {
      const stats = playerStats.opponentStats[opponentName];
      return {
        wins: stats.losses,
        losses: stats.wins,
        totalMatches: stats.totalMatches,
        winPercentage: (100 - parseFloat(stats.winPercentage)).toFixed(2),
      };
    }
    return null;
  };

  const opponentStats = getOpponentStats(matchDetails.opponentName);

  return (
    <div className="video-list-item">
      <div className="video-list-main">
        <div className="video-info">
          <Link
            to={`/video-player/${matchDetails.matchId}`}
            className="video-name"
          >
            {matchDetails.videoName}
          </Link>
        </div>
        <div className="match-info">
          <span
            className="player-name"
            onClick={() => togglePlayerProfile('player')}
          >
            {playerData.player_name}
            {playerCode === 'TPE' ? (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
                className="player-flag"
              />
            ) : playerCode ? (
              <Flag
                code={playerCode}
                className="player-flag"
              />
            ) : (
              <p></p>
            )}
          </span>
          <span className="vs">vs</span>
          <span
            className="opponent-name"
            onClick={() => togglePlayerProfile('opponent')}
          >
            {matchDetails.opponentName}
            {opponentCode === 'TPE' ? (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Flag_of_Chinese_Taipei_for_Olympic_games.svg/1280px-Flag_of_Chinese_Taipei_for_Olympic_games.svg.png"
                className="player-flag"
              />
            ) : opponentCode ? (
              <Flag
                code={opponentCode}
                className="player-flag"
              />
            ) : (
              <p></p>
            )}
          </span>
        </div>
      </div>
      <div className="match-details">
        <span className="match-date">
          {matchDetails.transformedMatchDate}
        </span>
        <span>{matchDetails.location}</span>
        <span>
          {translationDict[
            matchDetails.stage as keyof typeof translationDict
          ] || matchDetails.stage}
        </span>
      </div>
      <div className="score-summary">
        <span
          className={
            matchDetails.playerScore > matchDetails.opponentScore ? 'win' : ''
          }
        >
          {matchDetails.playerScore}
        </span>
        -
        <span
          className={
            matchDetails.playerScore < matchDetails.opponentScore ? 'win' : ''
          }
        >
          {matchDetails.opponentScore}
        </span>
      </div>
      {/* <div className="opponent-stats">
        {opponentStats ? (
          <>
            <span>
              對戰紀錄: {opponentStats.wins}勝 {opponentStats.losses}敗
            </span>
            <span>勝率: {opponentStats.winPercentage}%</span>
          </>
        ) : (
          <span>無對戰紀錄</span>
        )}
      </div> */}

      <div className="round-scores">
        {matchDetails.roundData &&
          matchDetails.roundData.map(
            (data: Record<string, number>, index: number) => {
              const playerScore =
                data[playerData.player_name] || data[Object.keys(data)[0]];
              const opponentScore =
                data[matchDetails.opponentName] || data[Object.keys(data)[1]];
              return (
                <div key={index} className="round-score">
                  <span className={playerScore > opponentScore ? 'win' : ''}>
                    {playerScore}
                  </span>
                  -
                  <span className={playerScore < opponentScore ? 'win' : ''}>
                    {opponentScore}
                  </span>
                </div>
              );
            },
          )}
      </div>
      {showPlayerProfile === 'player' && (
        <div className="profile-card-container">
          <PlayerProfileCard
            playerData={playerData}
            accountType={accountType}
            opponent={false}
          />
        </div>
      )}
      {showPlayerProfile === 'opponent' && (
        <div className="profile-card-container">
          <PlayerProfileCard
            playerData={{
              gender: matchDetails.opponentGender,
              country_code: matchDetails.opponentCountryCode,
              actual_age: matchDetails.opponentAge,
              head_shot: matchDetails.opponentImg,
              player2_head_shot: matchDetails.opponentPlayer2Img,
              player_name: matchDetails.opponentName,
              country: matchDetails.opponentCountry,
              last_updated: matchDetails.opponentLastUpdated,
              grip: matchDetails.opponentStyle,
              handedness: matchDetails.opponentHand,
              player_id: matchDetails.opponentId,
            }}
            accountType={accountType}
            opponent={true}
          />
        </div>
      )}
    </div>
  );
};

export default PlayerVideoList;
