import { Typography, Grid, TextField } from '@mui/material';
import VideoUploadOptions from './VideoUploadOptions';
import { useCallback } from 'react';
import './VideoFileUploadForm.scss';

const VIDEO_FORMATS = ['mp4', 'avi', 'mov', 'mkv', 'webm'];

const createInitialMatchData = (fileName) => ({
  videoName: fileName.replace(/\.[^/.]+$/, ''),
  HomePlayer: '_',
  AwayPlayer: '_',
  stage: '_',
  location: '_',
  matchEvent: '',
  matchParentEvent: '',
  matchDate: '2022-03-14',
  matchCategory: '',
  fileUrl: fileName,
  thumbnail_url: fileName.replace(/\.[^/.]+$/, '.jpg'),
  isPriority: false,
  permittedUser: [],
});

const VideoFileUploadForm = ({
  setFiles,
  files,
  matchData,
  setMatchData,
  playlists,
  selectedPlaylists,
  handlePlaylistSelect,
  uploaded,
  playerList,
  userOptions,
}) => {
  const handleFileChange = useCallback(
    (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = selectedFiles.filter((file) => {
        const extension = file.name.split('.').pop().toLowerCase();
        return VIDEO_FORMATS.includes(extension);
      });

      if (validFiles.length !== selectedFiles.length) {
        alert(
          `警告：部分文件未被添加！\n\n僅支持以下視頻格式：${VIDEO_FORMATS.join(
            '、',
          )}。\n\n請仔細檢查您的文件，確保它們符合要求的格式。不符合要求的文件將被自動過濾。`,
        );
        event.target.value = '';
        if (validFiles.length === 0) return;
      }

      setFiles(validFiles);

      // Create an array of match data objects, one for each file
      const newMatchDataArray = validFiles.map((file) =>
        createInitialMatchData(file.name),
      );

      setMatchData(newMatchDataArray);
    },
    [setFiles, setMatchData],
  );

  const handleRemoveFile = useCallback(
    (index) => {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== index);
        return updatedFiles;
      });

      setMatchData((prevData) => {
        const updatedMatchData = [...prevData];
        updatedMatchData.splice(index, 1);
        return updatedMatchData;
      });

      const fileInput = document.getElementById('videoFiles');
      if (fileInput) fileInput.value = null;
    },
    [setFiles, setMatchData],
  );

  const handleModifyMatchData = useCallback(
    (index, field, value) => {
      setMatchData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
        };
        return updatedData;
      });
    },
    [setMatchData],
  );

  const incompleteUploads =
    uploaded.uploads?.filter((upload) => !upload.upload_completed) ?? [];

  return (
    <div className="main-upload-container">
      <Grid container spacing={2}>
        <Grid item xs={12} className="main-upload-panel">
          <TextField
            required
            id="videoFiles"
            name="videoFiles"
            label="视频文件"
            fullWidth
            autoComplete="videoFiles"
            variant="standard"
            type="file"
            accept={VIDEO_FORMATS.map((format) => `.${format}`).join(',')}
            onChange={handleFileChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { color: 'grey' },
              multiple: true,
            }}
          />
        </Grid>
      </Grid>

      {incompleteUploads.length > 0 && (
        <div className="past-upload-info-container">
          <Typography variant="h6" className="upload-header">
            由你暫停的上傳（如無法上傳，請檢查影片格式，必須為mp4）：
          </Typography>
          {incompleteUploads.map((upload, index) => (
            <div className="past-upload-info" key={index}>
              <span className="file-name">檔案名稱：{upload.video_name}</span>
              <span className="upload-date">
                於 {upload.upload_date.slice(0, 10)}
              </span>
            </div>
          ))}
        </div>
      )}

      {files.length > 0 && (
        <div className="file-info-container">
          <Typography variant="h6">選定的檔案:</Typography>
          <div>
            {files.map((file, index) => (
              <VideoUploadOptions
                key={`${file.name}-${index}`}
                matchData={matchData[index]}
                file={file}
                index={index}
                handleRemoveFile={handleRemoveFile}
                handleModifyMatchData={handleModifyMatchData}
                playlists={playlists}
                selectedPlaylists={selectedPlaylists}
                handlePlaylistSelect={handlePlaylistSelect}
                playerList={playerList}
                userOptions={userOptions}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoFileUploadForm;
