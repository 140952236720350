import React from 'react';
import { Link } from 'react-router-dom';
import './VideoPanel.scss';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { downloadMatchesDataAPI } from 'src/api/videoService';
import Cookies from 'js-cookie';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

interface MergedVideoData {
    // Video Data fields
    matchId: string;
    videoName: string;
    homePlayer: string;
    awayPlayer: string;
    category: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;

    // VQ Data fields
    balldetectioncompleted: boolean;
    balltrackdate: string | null;
    completiondate: string | null;
    dpchecked: boolean;
    dpcheckeddate: string | null;
    dpcompleted: boolean;
    dpdate: string | null;
    flagged_comment: string;
    formatchecked: boolean;
    formatcheckeddate: string | null;
    ispriority: boolean;
    ocrchecked: boolean;
    ocrcheckeddate: string | null;
    ocrcompleted: boolean;
    ocrdate: string | null;
    score_coordinates: number[][];
    table_coordinates: number[][];
    starting_player_id: number;
    topright_player_id: number;
    uploaded_size: number;
}

interface VideoPanelProps {
    videoList: MergedVideoData[];
    addSizeConstant: boolean;
}

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const VideoPanel: React.FC<VideoPanelProps> = ({ videoList, addSizeConstant }) => {
    const token = Cookies.get('token');
    const [selectedVideo, setSelectedVideo] = React.useState<MergedVideoData | null>(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const unTrackedVideoSize = 221.278

    const totalSizeGB = (videoList.reduce((acc, video) => acc + video.uploaded_size, 0) + (addSizeConstant ? unTrackedVideoSize : 0)).toFixed(2);
    // const totalSizeGB = (totalSize / (1024 * 1024 * 1024)).toFixed(2);

    const handleDownloadSummary = async () => {
        try {
            const response = await downloadMatchesDataAPI(
                token as string,
            );

            // Get current date in dd_mm_yyyy format
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();
            const dateStr = `${dd}_${mm}_${yyyy}`;

            // Create filename with traditional Chinese and date
            const filename = `比賽數據_${dateStr}.csv`;

            // Create a temporary URL for the downloaded file
            const url = window.URL.createObjectURL(new Blob([response]));
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            // Trigger the click event on the link to initiate the file download
            link.click();
            // Clean up the temporary link and URL
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleDrawer = (video: MergedVideoData | null, open: boolean) => {
        setSelectedVideo(video);
        setDrawerOpen(open);
    };

    const StatusIndicator: React.FC<{ completed: boolean; label: string }> = ({ completed, label }) => (
        <div className={`status-indicator ${completed ? 'completed' : 'pending'}`}>
            <div className="status-dot" />
            <span>{label}</span>
            <span className="status-text">{completed ? '已完成' : '未完成'}</span>
        </div>
    );

    return (
        <div className="admin-video-panel">
            <div className='top-admin-video-panel'>
                <button className='download-match-button' onClick={() => handleDownloadSummary()}>
                    下載比賽數據<DownloadOutlinedIcon />
                </button>
                <div className="panel-header">
                    <h2 className="panel-title">賽事影片分析</h2>
                    <div className="panel-stats">
                        <p className="panel-subtitle">總場數: {videoList.length}</p>
                        <p className="panel-subtitle">總容量: {totalSizeGB} GB</p>
                    </div>
                </div>
            </div>
            <div className="video-table-wrapper">
                <div className="video-table">
                    <div className="video-header">
                        <div className="header-cell name">影片名稱</div>
                        <div className="header-cell category">組別</div>
                        <div className="header-cell players">選手</div>
                        <div className="header-cell score">比分</div>
                    </div>
                    <div className="video-body">
                        {videoList.map((video, index) => (
                            <div
                                key={video.matchId}
                                className="video-row"
                                style={{
                                    animationDelay: `${index * 0.05}s`
                                }}
                            >
                                <div className="video-cell name">
                                    <Link
                                        to={`/video-player/${video.matchId}`}
                                        className="video-link"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {video.videoName.length > 40
                                            ? `${video.videoName.substring(0, 40)}...`
                                            : video.videoName}
                                    </Link>
                                </div>
                                <div className="video-cell category">
                                    <span className="category-tag">{video.category}</span>
                                </div>
                                <div className="video-cell players">
                                    <div className="players-container">
                                        <span className="home-player">{video.homePlayer}</span>
                                        <span className="vs">對</span>
                                        <span className="away-player">{video.awayPlayer}</span>
                                    </div>
                                </div>
                                <div className="video-cell score">
                                    <div className="score-container">
                                        <span className={`score ${video.homePlayerMatchScore > video.awayPlayerMatchScore ? 'winning' : ''}`}>
                                            {video.homePlayerMatchScore}
                                        </span>
                                        <span className="score-separator">-</span>
                                        <span className={`score ${video.awayPlayerMatchScore > video.homePlayerMatchScore ? 'winning' : ''}`}>
                                            {video.awayPlayerMatchScore}
                                        </span>
                                    </div>
                                </div>
                                <div className="video-cell actions">
                                    <button
                                        className="info-button"
                                        onClick={() => toggleDrawer(video, true)}
                                    >
                                        <InfoOutlinedIcon />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <SwipeableDrawer
                anchor="right"
                open={drawerOpen}
                onClose={() => toggleDrawer(null, false)}
                onOpen={() => { }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                className="video-info-drawer"
            >
                {selectedVideo && (
                    <div className="admin-drawer-content">
                        <div className="video-info-header">
                            <h3>{selectedVideo.videoName}</h3>
                            <button
                                className="close-button"
                                onClick={() => toggleDrawer(null, false)}
                            >
                                ×
                            </button>
                        </div>

                        <div className="info-section match-details">
                            <h4>比賽資訊</h4>
                            <div className="info-grid">
                                <div className="info-item">
                                    <span className="label">回饋</span>
                                    <span className="value">{selectedVideo.flagged_comment || '未回饋'}</span>
                                </div>
                                <div className="info-item">
                                    <span className="label">組別</span>
                                    <span className="value">{selectedVideo.category || '未分類'}</span>
                                </div>
                                <div className="info-item players">
                                    <span className="label">選手</span>
                                    <div className="value">
                                        {selectedVideo.homePlayer} vs {selectedVideo.awayPlayer}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <span className="label">比分</span>
                                    <div className="value score">
                                        {selectedVideo.homePlayerMatchScore} - {selectedVideo.awayPlayerMatchScore}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info-section processing-status">
                            <h4>處理狀態</h4>
                            <div className="status-grid">
                                <StatusIndicator
                                    completed={selectedVideo.balldetectioncompleted}
                                    label="球偵測"
                                />
                                <StatusIndicator
                                    completed={selectedVideo.dpcompleted}
                                    label="數據處理"
                                />
                                <StatusIndicator
                                    completed={selectedVideo.ocrcompleted}
                                    label="OCR"
                                />
                                <StatusIndicator
                                    completed={selectedVideo.formatchecked}
                                    label="格式檢查"
                                />
                            </div>
                        </div>

                        <div className="info-section technical-details">
                            <h4>技術細節</h4>
                            <div className="info-grid">
                                <div className="info-item">
                                    <span className="label">檔案大小</span>
                                    <span className="value">
                                        {selectedVideo.uploaded_size} GB
                                    </span>
                                </div>
                                <div className="info-item">
                                    <span className="label">座標狀態</span>
                                    <div className="value coordinates">
                                        <span>比分座標: {selectedVideo.score_coordinates?.length ? '已設定' : '未設定'}</span>
                                        <span>球台座標: {selectedVideo.table_coordinates?.length ? '已設定' : '未設定'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="info-section dates">
                            <h4>時間記錄</h4>
                            <div className="date-grid">
                                <div className="date-item">
                                    <span className="label">球偵測完成時間</span>
                                    <span className="value">{selectedVideo.balltrackdate}</span>
                                </div>
                                <div className="date-item">
                                    <span className="label">數據處理完成時間</span>
                                    <span className="value">{selectedVideo.dpdate}</span>
                                </div>
                                <div className="date-item">
                                    <span className="label">OCR完成時間</span>
                                    <span className="value">{selectedVideo.ocrdate}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </SwipeableDrawer>
        </div>
    );
};

export default VideoPanel;