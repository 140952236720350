// File: app/common/utils/shortestPath.ts

interface PathResult {
  path: string[];
  edges: Array<[string, string]>;
  isDirectPath: boolean;
  distance: number; // Added to track path length
  intermediateNodes: string[]; // Added to track intermediate nodes
}
export const findShortestPath = (
  startNode: string,
  endNode: string,
  connectionsData: Record<string, any>,
  playerData: Record<string, string>
): PathResult => {
  if (!startNode || !endNode || startNode === endNode) {
    return {
      path: [],
      edges: [],
      isDirectPath: false,
      distance: 0,
      intermediateNodes: [],
    };
  }

  // BFS with distance tracking
  const queue: Array<{
    node: string;
    path: string[];
    edges: Array<[string, string]>;
    distance: number;
  }> = [];
  const visited = new Set<string>();

  queue.push({
    node: startNode,
    path: [startNode],
    edges: [],
    distance: 0,
  });
  visited.add(startNode);

  while (queue.length > 0) {
    const { node, path, edges, distance } = queue.shift()!;
    const connections = connectionsData[node] || [];

    for (const gameId of connections) {
      // Get other players connected through this game
      const gamePlayers = Object.keys(connectionsData).filter(
        (playerId) =>
          playerData[playerId] && // Ensure it's a player node
          playerId !== node && // Not the current node
          connectionsData[playerId]?.includes(gameId) // Connected to this game
      );

      for (const nextPlayer of gamePlayers) {
        if (!visited.has(nextPlayer)) {
          if (nextPlayer === endNode) {
            // Found direct path to target
            const intermediateNodes = path.slice(1, -1);
            return {
              path: [...path, nextPlayer],
              edges: [
                ...edges,
                [node, gameId.toString()],
                [gameId.toString(), nextPlayer],
              ],
              isDirectPath: true,
              distance: distance + 1,
              intermediateNodes,
            };
          }

          visited.add(nextPlayer);
          queue.push({
            node: nextPlayer,
            path: [...path, nextPlayer],
            edges: [
              ...edges,
              [node, gameId.toString()],
              [gameId.toString(), nextPlayer],
            ],
            distance: distance + 1,
          });
        }
      }
    }
  }

  // If no direct path found, find best indirect path
  const alternativePaths = findAlternativePaths(
    startNode,
    endNode,
    connectionsData,
    playerData
  );

  if (alternativePaths.length > 0) {
    // Sort by distance and return shortest alternative path
    const bestPath = alternativePaths.sort(
      (a, b) => a.distance - b.distance
    )[0];
    return {
      ...bestPath,
      isDirectPath: false,
    };
  }

  // No path found
  return {
    path: [startNode, endNode],
    edges: [],
    isDirectPath: false,
    distance: Infinity,
    intermediateNodes: [],
  };
};

const findConnectedPlayers = (
  playerId: string,
  connectionsData: Record<string, any>,
  playerData: Record<string, string>
): string[] => {
  const connected = new Set<string>();
  const playerGames = connectionsData[playerId] || [];

  for (const gameId of playerGames) {
    for (const potentialPlayer of Object.keys(connectionsData)) {
      if (
        playerData[potentialPlayer] && // Is a player
        potentialPlayer !== playerId && // Not the same player
        connectionsData[potentialPlayer]?.includes(gameId) // Connected to this game
      ) {
        connected.add(potentialPlayer);
      }
    }
  }

  return Array.from(connected);
};

const findAlternativePaths = (
  startNode: string,
  endNode: string,
  connectionsData: Record<string, any>,
  playerData: Record<string, string>
): PathResult[] => {
  const paths: PathResult[] = [];
  const connectedToStart = findConnectedPlayers(
    startNode,
    connectionsData,
    playerData
  );
  const connectedToEnd = findConnectedPlayers(
    endNode,
    connectionsData,
    playerData
  );

  // Find all possible intermediate players
  const potentialIntermediates = connectedToStart.filter((player) =>
    connectedToEnd.includes(player)
  );

  for (const intermediate of potentialIntermediates) {
    const pathToIntermediate = findShortestPath(
      startNode,
      intermediate,
      connectionsData,
      playerData
    );

    const pathFromIntermediate = findShortestPath(
      intermediate,
      endNode,
      connectionsData,
      playerData
    );

    if (
      pathToIntermediate.path.length > 0 &&
      pathFromIntermediate.path.length > 0
    ) {
      paths.push({
        path: [
          ...pathToIntermediate.path,
          ...pathFromIntermediate.path.slice(1),
        ],
        edges: [...pathToIntermediate.edges, ...pathFromIntermediate.edges],
        isDirectPath: false,
        distance: pathToIntermediate.distance + pathFromIntermediate.distance,
        intermediateNodes: [
          intermediate,
          ...pathToIntermediate.intermediateNodes,
          ...pathFromIntermediate.intermediateNodes,
        ],
      });
    }
  }

  return paths;
};

// Add to edge reducer
export const isEdgeInPath = (
  source: string,
  target: string,
  pathEdges: Array<[string, string]>
): boolean => {
  return pathEdges.some(
    ([s, t]) => (s === source && t === target) || (s === target && t === source)
  );
};
