import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';

import './HomeFilterOption.scss';

const translationDict = {
  決賽: 'Final',
  半決賽: 'Semi-Final',
  '4強': 'Quarter-Final',
  '16強': 'R16',
  '32強': 'R32',
  '64強': 'R64',
  '128強': 'R128',
  男單打: 'MSingles',
  男雙打: 'MDouble',
  女單打: 'FSingle',
  女雙打: 'FDouble',
  混合雙打: 'MixedDouble',
};

const translationReverseDict = {
  Final: '決賽',
  'Semi-Final': '半決賽',
  'Quarter-Final': '4強',
  R16: '16強',
  R32: '32強',
  R64: '64強',
  R128: '128強',
  MSingles: '男單打',
  MDouble: '男雙打',
  FSingle: '女單打',
  FDouble: '女雙打',
  MixedDouble: '混合雙打',
};

const translateReverseFilterCriteria = (criteria) => {
  const translatedCriteria = { ...criteria };
  Object.keys(translatedCriteria).forEach((key) => {
    if (Array.isArray(translatedCriteria[key])) {
      translatedCriteria[key] = translatedCriteria[key].map(
        (value) => translationReverseDict[value] || value,
      );
    } else {
      translatedCriteria[key] =
        translationReverseDict[translatedCriteria[key]] ||
        translatedCriteria[key];
    }
  });
  return translatedCriteria;
};

const HomeFilterOption = ({
  setFilterCriteria,
  appliedFilterCriteria,
  CustomTagList,
  userInfo,
  filterStats,
  setSortCriteria,
  sortCriteria,
}) => {
  const startDateDefault = {
    Date: '2000-01-01',
  };

  const [open, setOpen] = useState(false);

  const [parentEventsState, setParentEventsState] = useState({
    WTT: false,
    Games: false,
    ITTF: false,
    Others: false,
  });

  const [selectedFilterOptions, setSelectedFilterOptions] = useState(
    appliedFilterCriteria
      ? translateReverseFilterCriteria(appliedFilterCriteria)
      : {
          matchName: '',
          matchDate: '',
          stages: [],
          location: '',
          events: [],
          categories: [],
          startDate: '',
          endDate: '',
          customTags: [],
        },
  );

  const stages = ['決賽', '半決賽', '4強', '16強', '32強', '64強', '128強'];

  const categories = ['男單打', '男雙打', '女單打', '女雙打', '混合雙打'];

  let events;
  if (userInfo.accountType === 'a') {
    events = [
      { subEvent: 'Contender', parentEvent: 'WTT' },
      { subEvent: 'Star Contender', parentEvent: 'WTT' },
      { subEvent: 'Champions', parentEvent: 'WTT' },
      { subEvent: 'Cup Finals', parentEvent: 'WTT' },
      { subEvent: 'Grand Smash', parentEvent: 'WTT' },
      { subEvent: 'Others(WTT)', parentEvent: 'WTT' },
      { subEvent: 'National Games', parentEvent: 'Games' },
      { subEvent: 'Asian Games', parentEvent: 'Games' },
      { subEvent: 'Universiade', parentEvent: 'Games' },
      { subEvent: 'Others(Games)', parentEvent: 'Games' },
      { subEvent: 'ITTF World Championships', parentEvent: 'ITTF' },
      { subEvent: 'ITTF Mixed Team World Cup', parentEvent: 'ITTF' },
      { subEvent: 'ITTF World Youth Championships', parentEvent: 'ITTF' },
      {
        subEvent: 'Asia Continental Stage of 2023 WTTC Finals',
        parentEvent: 'ITTF',
      },
      { subEvent: 'championships', parentEvent: 'ITTF' },
      { subEvent: 'Others(ITTF)', parentEvent: 'ITTF' },
      { subEvent: 'Others', parentEvent: 'Others' },
    ];
  } else if (userInfo.accountType === 'p') {
    events = [
      { subEvent: 'Asian Para Games', parentEvent: 'Games' },
      { subEvent: 'Paralympic Games', parentEvent: 'Games' },
      { subEvent: 'World Abilitysport Games', parentEvent: 'Games' },
      { subEvent: 'Others(Games)', parentEvent: 'Games' },
      { subEvent: 'Olympic Games', parentEvent: 'Games' },
      { subEvent: 'ITTF Para Open', parentEvent: 'ITTF' },
      { subEvent: 'Others(ITTF)', parentEvent: 'ITTF' },
      { subEvent: 'Others', parentEvent: 'Others' },
    ];
  } else {
    events = [
      { subEvent: 'Virtus Global Games', parentEvent: 'Games' },
      { subEvent: 'Asian Para Games', parentEvent: 'Games' },
      { subEvent: 'Paralympic Games', parentEvent: 'Games' },
      { subEvent: 'World Abilitysport Games', parentEvent: 'Games' },
      { subEvent: 'Others(Games)', parentEvent: 'Games' },
      { subEvent: 'Olympic Games', parentEvent: 'Games' },
      { subEvent: 'ITTF Para Open', parentEvent: 'ITTF' },
      { subEvent: 'Others(ITTF)', parentEvent: 'ITTF' },
      { subEvent: 'Others', parentEvent: 'Others' },
    ];
  }

  const eventsGroupedByParent = events.reduce((groupedEvents, event) => {
    const { subEvent, parentEvent } = event;

    groupedEvents[parentEvent] = groupedEvents[parentEvent] ?? [];
    groupedEvents[parentEvent].push(subEvent);

    return groupedEvents;
  }, {});

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const handleFilterChange = (event, filterType, parent) => {
    let value;

    const toggleValueInArray = (value, array) => {
      const index = array.indexOf(value);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(value);
      }
      return array;
    };

    const clearSubEventsForParent = (subEvents) => {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        events: prevOptions.events.filter(
          (event) => !subEvents.includes(event),
        ),
      }));
    };

    if (event) {
      value = event.target.value;
    } else {
      let subEvents;
      subEvents = events
        .filter((subEvent) => subEvent.parentEvent === parent)
        .map((subEvent) => subEvent.subEvent);

      clearSubEventsForParent(subEvents);
      if (parentEventsState[parent]) {
        setParentEventsState((prevState) => ({
          ...prevState,
          [parent]: !prevState[parent],
        }));
        return;
      } else {
        for (const subEvent of subEvents) {
          setSelectedFilterOptions((prevOptions) => ({
            ...prevOptions,
            events: toggleValueInArray(subEvent, prevOptions.events),
          }));
        }
        setParentEventsState((prevState) => ({
          ...prevState,
          [parent]: !prevState[parent],
        }));

        return;
      }
    }

    if (filterType === 'stage') {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        stages: toggleValueInArray(value, prevOptions.stages),
      }));
    } else if (filterType === 'event') {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        events: toggleValueInArray(value, prevOptions.events),
      }));
    } else if (filterType === 'category') {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        categories: toggleValueInArray(value, prevOptions.categories),
      }));
    } else if (filterType === 'startDate') {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        startDate: value,
      }));
    } else if (filterType === 'endDate') {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        endDate: value,
      }));
    } else if (filterType === 'customTags') {
      setSelectedFilterOptions((prevOptions) => ({
        ...prevOptions,
        customTags: toggleValueInArray(value, prevOptions.customTags),
      }));
    }
  };

  const handleParentSelect = (parent) => {
    const subEvents = events
      .filter((event) => event.parentEvent === parent)
      .map((event) => event.subEvent);

    setParentEventsState((prevState) => ({
      ...prevState,
      [parent]: !prevState[parent],
    }));

    setSelectedFilterOptions((prevOptions) => {
      const newEvents = prevOptions.events.filter(
        (event) => !subEvents.includes(event),
      );
      if (!parentEventsState[parent]) {
        newEvents.push(
          ...subEvents.filter((event) => !prevOptions.events.includes(event)),
        );
      }
      return {
        ...prevOptions,
        events: newEvents,
      };
    });
  };

  const handlePopupClose = (byApplyFilter) => {
    if (!byApplyFilter) {
      setSelectedFilterOptions(appliedFilterCriteria);
    }
    setOpen(false);
  };

  const handleApplyOptions = () => {
    const translatedFilterOptions = {
      ...selectedFilterOptions,
      stages: selectedFilterOptions.stages.map(
        (stage) => translationDict[stage],
      ),
      categories: selectedFilterOptions.categories.map(
        (category) => translationDict[category],
      ),
    };
    setFilterCriteria(translatedFilterOptions);
    setOpen(false);
  };

  useEffect(() => {
    setSelectedFilterOptions(
      translateReverseFilterCriteria(appliedFilterCriteria),
    );
  }, [appliedFilterCriteria]);

  const subEventCounts = filterStats.subEventCounts;
  const wttSubEventCounts = eventsGroupedByParent['WTT']
    ? eventsGroupedByParent['WTT'].reduce((counts, subEvent) => {
        const count = subEventCounts[subEvent] || 0;
        return counts + count;
      }, 0)
    : 0;
  const gamesSubEventCounts = eventsGroupedByParent['Games']
    ? eventsGroupedByParent['Games'].reduce((counts, subEvent) => {
        const count = subEventCounts[subEvent] || 0;
        return counts + count;
      }, 0)
    : 0;
  const ittfSubEventCounts = eventsGroupedByParent['ITTF']
    ? eventsGroupedByParent['ITTF'].reduce((counts, subEvent) => {
        const count = subEventCounts[subEvent] || 0;
        return counts + count;
      }, 0)
    : 0;
  const othersSubEventCounts = eventsGroupedByParent['Others']
    ? eventsGroupedByParent['Others'].reduce((counts, subEvent) => {
        const count = subEventCounts[subEvent] || 0;
        return counts + count;
      }, 0)
    : 0;

  return (
    <div className="filter-sort-container">
      <Select
        labelId="sort-simple-select-label"
        id="sort-simple-select"
        value={sortCriteria}
        onChange={handleSortChange}
        sx={{ flex: 1, maxWidth: '100px', height: '40px' }}
        displayEmpty
        renderValue={(value) => {
          switch (value) {
            case 1:
              return '最早';
            case -1:
              return '最遲';
            case 2:
              return '從A到Z';
            case 3:
              return '從Z到A';
            case 0:
              return '最新上傳';
            default:
              return '選擇排序方式';
          }
        }}
      >
        <MenuItem value={1}>最早</MenuItem>
        <MenuItem value={-1}>最遲</MenuItem>
        <MenuItem value={0}>最新上傳</MenuItem>
        <MenuItem value={2}>從A到Z</MenuItem>
        <MenuItem value={3}>從Z到A</MenuItem>
        {/* <MenuItem value={4}>自定義(未完成)</MenuItem> */}
      </Select>
      <Button variant="outlined" onClick={() => setOpen(true)} sx={{ ml: 1 }}>
        <FilterListIcon />
      </Button>

      <Dialog
        open={open}
        onClose={() => handlePopupClose(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handlePopupClose(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography className="metadata-title" gutterBottom>
            篩選條件
          </Typography>
          <TextField
            required
            id="matchDate-start"
            name="matchDate"
            label="最早比賽日期"
            fullWidth
            autoComplete="matchDate"
            variant="standard"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={startDateDefault.Date}
            onChange={(event) => handleFilterChange(event, 'startDate')}
          />
          <TextField
            required
            id="matchDate-end"
            name="matchDate"
            label="最晚比賽日期"
            fullWidth
            autoComplete="matchDate"
            variant="standard"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={appliedFilterCriteria.endDate}
            onChange={(event) => handleFilterChange(event, 'endDate')}
          />
          <Accordion className="stage-filter-container">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>選擇階段</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  {stages.map((stage) => (
                    <FormControlLabel
                      key={stage}
                      control={
                        <Checkbox
                          checked={
                            selectedFilterOptions.stages.indexOf(stage) > -1
                          }
                          onChange={(event) =>
                            handleFilterChange(event, 'stage')
                          }
                          value={stage}
                        />
                      }
                      label={`${stage} (${
                        filterStats.stageCounts[translationDict[stage]] || 0
                      })`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion className="event-filter-container">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>選擇賽事</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  <ListSubheader>
                    WTT {` (${wttSubEventCounts})`}
                    <Checkbox
                      checked={parentEventsState.WTT}
                      onChange={() => handleParentSelect('WTT')}
                    />
                  </ListSubheader>
                  {eventsGroupedByParent['WTT'] &&
                    eventsGroupedByParent['WTT'].map((subEvent) => (
                      <FormControlLabel
                        key={subEvent}
                        control={
                          <Checkbox
                            checked={
                              selectedFilterOptions.events.indexOf(subEvent) >
                              -1
                            }
                            onChange={(event) =>
                              handleFilterChange(event, 'event')
                            }
                            value={subEvent}
                          />
                        }
                        label={`${subEvent} (${
                          filterStats.subEventCounts[subEvent] || 0
                        })`}
                      />
                    ))}
                  <ListSubheader>
                    Games {` (${gamesSubEventCounts})`}
                    <Checkbox
                      checked={parentEventsState.Games}
                      onChange={() => handleParentSelect('Games')}
                    />
                  </ListSubheader>
                  {eventsGroupedByParent['Games'] &&
                    eventsGroupedByParent['Games'].map((subEvent) => (
                      <FormControlLabel
                        key={subEvent}
                        control={
                          <Checkbox
                            checked={
                              selectedFilterOptions.events.indexOf(subEvent) >
                              -1
                            }
                            onChange={(event) =>
                              handleFilterChange(event, 'event')
                            }
                            value={subEvent}
                          />
                        }
                        label={`${subEvent} (${
                          filterStats.subEventCounts[subEvent] || 0
                        })`}
                      />
                    ))}
                  <ListSubheader>
                    ITTF {` (${ittfSubEventCounts})`}
                    <Checkbox
                      checked={parentEventsState.ITTF}
                      onChange={() => handleParentSelect('ITTF')}
                    />
                  </ListSubheader>
                  {eventsGroupedByParent['ITTF'] &&
                    eventsGroupedByParent['ITTF'].map((subEvent) => (
                      <FormControlLabel
                        key={subEvent}
                        control={
                          <Checkbox
                            checked={
                              selectedFilterOptions.events.indexOf(subEvent) >
                              -1
                            }
                            onChange={(event) =>
                              handleFilterChange(event, 'event')
                            }
                            value={subEvent}
                          />
                        }
                        label={`${subEvent} (${
                          filterStats.subEventCounts[subEvent] || 0
                        })`}
                      />
                    ))}
                  <ListSubheader>
                    Others {` (${othersSubEventCounts})`}
                    <Checkbox
                      checked={parentEventsState.Others}
                      onChange={() => handleParentSelect('Others')}
                    />
                  </ListSubheader>
                  {eventsGroupedByParent['Others'] &&
                    eventsGroupedByParent['Others'].map((subEvent) => (
                      <FormControlLabel
                        key={subEvent}
                        control={
                          <Checkbox
                            checked={
                              selectedFilterOptions.events.indexOf(subEvent) >
                              -1
                            }
                            onChange={(event) =>
                              handleFilterChange(event, 'event')
                            }
                            value={subEvent}
                          />
                        }
                        label={`${subEvent} (${
                          filterStats.subEventCounts[subEvent] || 0
                        })`}
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion className="tag-filter-container">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>選擇標籤</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  <MenuItem disabled>
                    <em>請選擇自訂標籤</em>
                  </MenuItem>
                  {CustomTagList.map((customTag) => (
                    <FormControlLabel
                      key={customTag}
                      control={
                        <Checkbox
                          checked={
                            selectedFilterOptions.customTags.indexOf(
                              customTag,
                            ) > -1
                          }
                          onChange={(event) =>
                            handleFilterChange(event, 'customTags')
                          }
                          value={customTag}
                        />
                      }
                      label={`${customTag} (${
                        filterStats.customTagCounts[customTag] || 0
                      })`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion className="category-filter-container">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>選擇賽類</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  {categories.map((cat) => (
                    <FormControlLabel
                      key={cat}
                      control={
                        <Checkbox
                          checked={
                            selectedFilterOptions.categories.indexOf(cat) > -1
                          }
                          onChange={(event) =>
                            handleFilterChange(event, 'category')
                          }
                          value={cat}
                        />
                      }
                      label={`${cat} (${
                        filterStats.categoryCounts[translationDict[cat]] || 0
                      })`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleApplyOptions();
            }}
            variant="contained"
          >
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HomeFilterOption;
