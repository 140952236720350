import React, { useState, useEffect } from 'react';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import Cookies from 'js-cookie';
import { fetchPlayerDataAPI } from 'src/api/playerService';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton } from '@mui/material';
import { countryCodeMapping, countries, interestedCountries } from 'src/constants';
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    Typography,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PlayerPanel from './PlayerPanel/PlayerPanel';
import './PlayerManagement.scss';

interface PlayerManagementProps {
    userName: string;
    accountType: string;
}

interface Player {
    age: number;
    country: string;
    countryCode: string;
    head_shot: string;
    gender: string;
    player_name: string;
    player_id: number;
    country_code: string;
    handedness: string;
    grip: string;
    head_shot_file: string;
    player2_head_shot_file?: string;
    bio: string;
    win_rate: number;
    current_rank: number;
    dob: string;
    rubber_type?: string;
    flipped_rubber_type?: string;
}

type CountryCodeMapping = {
    [key: string]: string;
};

const PlayerManagement: React.FC<PlayerManagementProps> = ({ userName, accountType }) => {
    const token = Cookies.get('token');
    const allCountries = countries as string[];
    const [playerList, setPlayerList] = useState<Player[]>([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeFilters, setActiveFilters] = useState(new Set<string>());
    const [isExpanded, setIsExpanded] = useState(true);
    const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([]);

    useEffect(() => {
        if (refresh) {
            fetchPlayers();
        }
    }, [token, refresh]);

    // Single effect to handle both search and filter changes
    useEffect(() => {
        const timer = setTimeout(() => {
            filterAndSortPlayers(playerList, searchQuery, activeFilters);
        }, 300);
        return () => clearTimeout(timer);
    }, [searchQuery, activeFilters, playerList]);

    const fetchPlayers = async () => {
        try {
            const response = await fetchPlayerDataAPI(
                {
                    countries: allCountries.join(','),
                    existingMatch: false,
                    accountType: accountType,
                },
                token as string,
            );
            const responseData = response.data;

            if (responseData === undefined) {
                setPlayerList([]);
                setFilteredPlayers([]);
                return;
            }

            const playerData = responseData.map((player: any) => ({
                age: player.actual_age || player.age,
                country: player.country,
                countryCode: (countryCodeMapping as CountryCodeMapping)[player.country_code] || '',
                head_shot: player.head_shot,
                gender: player.gender,
                player_name: player.player_name,
                player_id: player.player_id,
                country_code: player.country_code,
                handedness: player.handedness,
                grip: player.grip,
                head_shot_file: player.head_shot_file,
                player2_head_shot_file: player.player2_head_shot_file,
                bio: player.bio,
                win_rate: player.win_rate,
                current_rank: player.current_rank,
                dob: player.dob,
                rubber_type: player.rubber_type,
                flipped_rubber_type: player.flipped_rubber_type
            }));

            setPlayerList(playerData);
            setFilteredPlayers(playerData);
            setLoading(false);
            setRefresh(false);
        } catch (api_error) {
            console.error('Error fetching player list:', api_error);
            setError(api_error);
        }
    };

    const filterAndSortPlayers = (players: Player[], search: string, filters: Set<string>) => {
        const searchTerm = search.toLowerCase();
        const interestedCountriesSet = new Set(interestedCountries);

        const filtered = players.filter(player => {
            // Search filter
            if (searchTerm &&
                !player.country?.toLowerCase().includes(searchTerm) &&
                !player.player_name?.toLowerCase().includes(searchTerm)) {
                return false;
            }

            // Category filters
            if (filters.size === 0) return true;

            return Array.from(filters).some(filter => {
                switch (filter) {
                    case 'noImage':
                        return !player.head_shot?.trim();
                    case 'noCountry':
                        return !player.country?.trim();
                    case 'noAge':
                        return !player.age || player.age === 0;
                    case 'noCategory':
                        return !player.gender || !['M', 'F', 'FF', 'MM', 'MF'].includes(player.gender);
                    default:
                        return false;
                }
            });
        });

        // Sort filtered results
        const sorted = filtered.sort((a, b) => {
            const aInterested = interestedCountriesSet.has(a.country);
            const bInterested = interestedCountriesSet.has(b.country);

            if (aInterested !== bInterested) {
                return aInterested ? -1 : 1;
            }

            return (a.current_rank || Infinity) - (b.current_rank || Infinity);
        });

        setFilteredPlayers(sorted);
    };

    const filterLabels = {
        'noImage': '未有照片',
        'noCountry': '未有國家',
        'noAge': '未有年齡',
        'noCategory': '未有分類'
    };

    const toggleFilter = (filterKey: string) => {
        const newFilters = new Set(activeFilters);
        if (newFilters.has(filterKey)) {
            newFilters.delete(filterKey);
        } else {
            newFilters.add(filterKey);
        }
        setActiveFilters(newFilters);
    };

    if (loading) return <LoadingScreen />;
    if (error) return <div className="playerM-error">Error: {error}</div>;

    return (
        <div className="player-management">
            <Box className="filter-panel">
                <Box
                    onClick={() => setIsExpanded(!isExpanded)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '12px 16px',
                        cursor: 'pointer',
                        bgcolor: 'rgba(0, 0, 0, 0.03)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                    }}
                >
                    <Typography variant="h6">篩選器</Typography>
                    <IconButton size="small">
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>

                <Collapse in={isExpanded}>
                    <Box className="filter-content">
                        <Box className="search-section">
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="搜尋選手名稱或國家..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    className: 'search-input'
                                }}
                            />
                        </Box>

                        <Box className="filter-header">
                            {activeFilters.size > 0 && (
                                <Box className="active-filters">
                                    {Array.from(activeFilters).map(filter => (
                                        <Chip
                                            key={filter}
                                            label={filterLabels[filter as keyof typeof filterLabels]}
                                            onDelete={() => toggleFilter(filter)}
                                            color="primary"
                                            variant="outlined"
                                            className="filter-chip"
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>
                        <ButtonGroup className="filter-buttons">
                            {Object.entries(filterLabels).map(([key, label]) => (
                                <Button
                                    key={key}
                                    onClick={() => toggleFilter(key)}
                                    variant={activeFilters.has(key) ? "contained" : "outlined"}
                                    className={`filter-button ${activeFilters.has(key) ? 'active' : ''}`}
                                >
                                    {label}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Box>
                </Collapse>
            </Box>
            <PlayerPanel
                playerList={filteredPlayers}
                accountType={accountType}
                setRefresh={setRefresh}
            />
        </div>
    );
};

export default PlayerManagement;