import axios from "axios";
import { ConnectionsData, PlayerData } from "../types/graph.types";

export const selectJsonGameModeName = (
  gameMode: keyof Omit<VideoGraphDataDummyType, "name_lists">,
  APIDataDemo: VideoGraphDataDummyType
): ConnectionsData => {
  let res = {} as ConnectionsData;

  if (gameMode in APIDataDemo) {
    res = APIDataDemo[gameMode];
  }

  return res;
};

export type VideoGraphDataDummyType = {
  name_lists: PlayerData;
  COM_multi_player: ConnectionsData;
  COM_single_player: ConnectionsData;
  COM_FSingle: ConnectionsData;
  COM_FDouble: ConnectionsData;
  COM_MSingle: ConnectionsData;
  COM_MDouble: ConnectionsData;
  COM_MixedDouble: ConnectionsData;
};

export const VideoGraphDataDummy: VideoGraphDataDummyType = {
  name_lists: {
    "100439": "AHMED SALEH",
    "102832": "PATRICK FRANZISKA",
    "105159": "LEE HO CHING",
    "108226": "ELIZABETA SAMARA",
    "110797": "CHENG I CHING",
    "111012": "BERNADETTE SZOCS",
    "111066": "SABINE WINTER",
    "111849": "XIAONA SHAN",
    "112019": "CHEN MENG",
    "112035": "NIMA ALAMIAN",
    "112092": "QUADRI ARUNA",
    "112221": "LILY ZHANG",
    "112463": "MELANIE DIAZ",
    "112620": "WONG CHUN TING",
    "113681": "IBRAHIMA DIAW",
    "114105": "CHEN SZU YU",
    "114706": "CHENG HSIEN TZU",
    "114726": "NINA MITTELHAM",
    "114897": "BERNADETT BALINT",
    "114936": "JANG WOOJIN",
    "115009": "ADRIANA DIAZ",
    "115396": "JOAO GERALDO",
    "115543": "DOO HOI KEM",
    "115546": "LAM SIU HANG",
    "115641": "HUGO CALDERANO",
    "116152": "LAM YEE LOK",
    "117357": "LIM JONGHOON",
    "117463": "ADINA DIACONU",
    "117820": "MIU HIRANO",
    "117821": "MIMA ITO",
    "118762": "YING HAN",
    "118777": "YUAN WAN",
    "118994": "JEON JIHEE",
    "121404": "FAN ZHENDONG",
    "121558": "WANG CHUQIN",
    "121582": "LIN YUN JU",
    "122428": "ANDREEA DRAGOMAN",
    "123113": "KIM HAYEONG",
    "123505": "ZHU CHENGZHU",
    "123575": "WONG XIN RU",
    "123672": "HINA HAYATA",
    "123690": "HO KWAN KIT",
    "123977": "SHIN YUBIN",
    "123980": "TOMOKAZU HARIMOTO",
    "124110": "WANG YIDI",
    "124165": "CHIEN TUNG CHUAN",
    "131086": "FRANZISKA SCHREINER",
    "131100": "PRITHIKA PAVADE",
    "131163": "SUN YINGSHA",
    "131342": "ZENG JIAN",
    "131401": "CHOI HYOJOO",
    "132318": "NG WING LAM",
    "132724": "CHANG LI SIAN ALICE",
    "132824": "KAO CHENG JUI",
    "133694": "SHUNSUKE TOGAMI",
    "133814": "KIM NAYEONG",
    "133867": "CHAN BALDWIN",
    "134906": "ABBEY WEBB",
    "135179": "HARUNA OJIO",
    "136711": "MIWA HARIMOTO",
    "145016": "MARKHABO MAGDIEVA",
    "200413": "JOO CHEONHUI",
    "201504": "ADITYA SAREEN",
    "206911": "AFZALKHON MAHMUDOV | UBAYDULLO SULTONOV",
    "206912": "FAN ZHENDONG | WANG CHUQIN",
    "206913": "LAM SIU HANG | NG PAK NAM",
    "206914": "ABDULLAH ABDULHUSSEIN | MOHAMMED ABDULHUSSEIN",
    "206916": "CHEONG CHI HUN | HE CHON FAI",
    "206920": "DOO HOI KEM | ZHU CHENGZHU",
    "206921": "CHA SU YONG | PYON SONG GYONG",
    "206922": "HO KWAN KIT | WONG CHUN TING",
    "206924": "KALLBERG CHRISTINA | MOREGARD TRULS",
    "206925": "FRANZISKA PATRICK | OVTCHAROV DIMITRIJ",
    "206927": "JANG WOOJIN | JEON JIHEE",
    "206928": "CHEN SZU YU | LIN YUN JU",
    "206929": "ASSAR OMAR | MESHREF DINA",
    "206930": "HARIMOTO TOMOKAZU | HAYATA HINA",
    "206931": "MITTELHAM NINA | QIU DANG",
    "206934": "TOGAMI SHUNSUKE | UDA YUKIYA",
    "206935": "CHAN BALDWIN HO WAH | WONG CHUN TING",
    "206936": "LIAO CHENG TING | LIN YUN JU",
    "206937": "WANG CHUQIN | WANG MANYU",
    "206938": "DUDA BENEDIKT | QIU DANG",
    "206939": "KALLBERG ANTON | MOREGARD TRULS",
    "206940": "JANG WOOJIN | LIM JONGHOON",
    "206941": "CHAN BALDWIN | LEE HOI MAN KAREN",
    "206942": "CHAN BALDWIN | YIU KWAN TO",
    "206943": "HARIMOTO TOMOKAZU | SHINOZUKA HIROTO",
    "206944": "CHENG I CHING | LIN YUN JU",
    "206946": "LEBESSON EMMANUEL | LEBRUN FELIX",
    "206947": "LAM SIU HANG | ZHU CHENGZHU",
    "206948": "HARIMOTO MIWA | TOGAMI SHUNSUKE",
    "206949": "CHENG I CHING | LI YU JHUN",
    "206950": "LUTZ CAMILLE | PAVADE PRITHIKA",
    "206952": "ZENG JIAN | ZHOU JINGYI",
    "206953": "SUN YINGSHA | WANG MANYU",
    "206954": "CHEN SZU YU | HUANG YI HUA",
    "206955": "JEON JIHEE | SHIN YUBIN",
    "206957": "KIM HAYEONG | SHIN YUBIN",
    "206959": "LI CHING WAN | ZHU CHENGZHU",
    "206960": "JOO CHEONHUI | LEE ZION",
    "206961": "WONG XIN RU | ZHOU JINGYI",
    "206962": "LI CHING WAN | NG WING LAM",
    "206963": "NG WING LAM | ZHU CHENGZHU",
    "206964": "CHEN SZU YU | LEE HO CHING",
    "206965": "GOI RUI XUAN | WONG XIN RU",
    "206967": "LEE HO CHING | SHAO JIENI",
    "206969": "CHOI HYOJOO | LEE ZION",
    "206970": "MITTELHAM NINA | WINTER SABINE",
    "206971": "DOO HOI KEM | LEE HO CHING",
    "206972": "CHENG HSIEN TZU | LIU HSING YIN",
    "206973": "HAYATA HINA | ITO MIMA",
    "206976": "LAM YEE LOK | LEE HOI MAN KAREN",
    "206978": "CIOBANU IRINA | SAMARA ELIZABETA",
    "206979": "ITO MIMA | MIZUTANI JUN",
    "206982": "LIN GAOYUAN | WANG YIDI",
    "206983": "LEE EUNHYE | YANG HAEUN",
    "206984": "NG WING LAM | WONG CHUN TING",
    "206985": "SUN YINGSHA | WANG CHUQIN",
    "206986": "LEBRUN ALEXIS | LEBRUN FELIX",
    "206989": "HAN YING | MITTELHAM NINA",
    "206991": "JANG WOOJIN | SHIN YUBIN",
    "206992": "HO KWAN KIT | LEE HO CHING",
    "206993": "LEBESSON EMMANUEL | YUAN JIA NAN",
    "206995": "HO KWAN KIT | MITTELHAM NINA",
    "207000": "HAYATA HINA | TOGAMI SHUNSUKE",
    "207002": "GAUZY SIMON | PAVADE PRITHIKA",
    "207004": "NG WING LAM | YIU KWAN TO",
    "207005": "IONESCU OVIDIU | SZOCS BERNADETTE",
    "207006": "LEE HO CHING | SOO WAI YAM MINNIE",
    "207007": "CHEN SZU YU | CHENG HSIEN TZU",
    "207008": "_",
    "207010": "ROBLES ALVARO | XIAO MARIA",
    "207022": "KUAI MAN | LIN SHIDONG",
    "207023": "DOO HOI KEM | WONG CHUN TING",
    "207024": "CHO DAE SEONG | KIM NAYEONG",
    "207026": "LIM JEONG HOON | SHIN YU BIN",
    "207027": "TATIANA KUKULKOVA | WANG YANG",
    "207028": "LIN GAOYUAN | WANG YIDI",
    "207029": "GAUZY SIMON | PRITHIKA PAVADE",
    "207030": "QUEK IZAAC | ZHOU JING YI",
    "207031": "FAN ZHENDONG | WANG MANYU",
    "207032": "HANAGODA | HO KWAN KIT",
    "207036": "JEON JIHEE | YI SANG SOO",
    "207037": "GIORGIA PICCOLIN | STOYANOV",
    "207038": "POLCANOVA SOFIA | SZOCS BERNADETTE",
    "207039": "SABINE | SHAN XIAONA",
    "207040": "WU YANGCHEN | XU HAIDONG",
    "207042": "JIA NAN YUAN | LE BESSON",
    "207043": "ECHEKI | MADARASZ DORA",
    "207044": "HARIMOTO MIWA | TOGAMI SHUNSUKE",
    "207045": "MATSUSHIMA KISORA | MIWA HARIMOTO",
    "207046": "CHITALE | SHAH",
    "207047": "MIXED DOUBLES 1ST ROUND ROBLES ALVARO | XIAO MARIA",
    "207048": "ABDULWAHHAB.M | FARAMARZI.M",
    "207049": "MIXED DOUBLES 1ST ROUND IONESCU OVIDIU | SZOCS BERNADETTE",
    "207050": "FRANZISKA PATRICK | SORYA",
    "207051": "LING YE | PANG KEHEN",
    "207052": "SZU YU | ZHONG JIAN",
    "207053": "KIHARA MIYUU | YUKIYA UDA",
    "207056": "CHU | ZENG JIAN",
    "207057": "LIANG JINGKUN | QIAN TIANYI",
    "207058": "SUN YINGSHA | WANG MANYU",
    "207059": "GARDOS | POLCANOVA SOFIA",
    "207061": "MA LONG | WANG CHUQIN",
    "207062": "LIAO ZHEN JU | LIN YUN JU",
    "207063": "LIN GAOYUAN | WANG MANYU",
    "207064": "FAN ZHENDONG | KUAI MAN",
    "207065": "MA LONG | QIAN TIANYI",
    "207066": "HO KWAN KIT | WONG CHUN TING",
    "207067": "MIXED DOUBLES QUARTERFINALS SUDI | PELGEL",
    "207068": "LIM JEONGHUN | SHIN YUBIN",
    "207069": "BADOWSKI | BAJOR NATALIA",
    "207070": "ITO MIMA | YUKIYA UDA",
    "207071": "HARIMOTO TOMOKAZU | HAYATA HINA",
    "207072": "JEON JIHEE | YANG HAEUN",
    "207073": "HIRANO MIU | ISHIKAWA KASUMI",
    "207074": "HAYATA HINA | KIHARA MIYUU",
    "207075": "HAYATA_HINA | ITO_MIMA",
    "207076": "HAYATA HINA | ITO MIMA",
    "207077": "CHAN YI | KUAI MAN",
    "207078": "ITO MIMA | UDA YUKIYA",
    "207079": "MADARASZ DORA | POTA GEORGINA",
    "207080": "CHEN SZU YU | CHUANG CHIH YUAN",
    "207081": "BAJOR | NATALIA MOLE",
    "207082": "CHEN SZU YU | _FENG YI HSIN",
    "207083": "KIHARA MIYU | NAGASAKI MIYU",
    "207084": "P.SORYA | SHAN XIAONA",
    "207085": "ITO MIMA | JUN MIZUTANI",
    "207086": "CHEN SZU YU | CHENG SZU YU",
    "207087": "CHEN MENG | WANG MANYUVS",
    "207088": "HO KWAN KIT | LEE HO CHING",
    "207089": "CHO | KIM HAYEONG",
    "207091": "KALLBERG CHRISTINA | KARLSSON KRISTIAN",
    "207092": "NAGASAKI MIYU | TANAKA YUTA",
    "207093": "KIM KUM YONG | RI JONG SIK",
    "207096": "PYON SONGGYONG",
    "207097": "KIM KUM YONG | RI JONG SIK",
  },
  COM_multi_player: {
    "206911": [70],
    "206912": [73, 578, 131],
    "206913": [74, 212],
    "206914": [74],
    "206916": [75],
    "206920": [
      582, 456, 172, 173, 174, 79, 176, 81, 178, 179, 175, 181, 566, 182, 177,
    ],
    "206921": [79],
    "206922": [160, 162, 131, 70, 167, 168, 73, 139, 140, 147, 150, 153, 157],
    "206924": [134],
    "206925": [168, 140],
    "206927": [170, 141, 389],
    "206928": [
      547, 548, 520, 553, 555, 556, 525, 622, 559, 494, 142, 237, 531, 623, 239,
      411, 540,
    ],
    "206929": [143],
    "206930": [
      439, 68, 455, 488, 524, 621, 77, 623, 430, 628, 630, 567, 440, 541, 446,
      159,
    ],
    "206931": [644, 645, 455],
    "206934": [139, 151],
    "206935": [151],
    "206936": [153],
    "206937": [154, 451],
    "206938": [169, 157, 150],
    "206939": [160, 147],
    "206940": [162],
    "206941": [239],
    "206942": [164],
    "206943": [164],
    "206944": [200, 580, 165, 551],
    "206946": [167],
    "206947": [224, 545, 171, 225],
    "206948": [171],
    "206949": [448, 179, 172],
    "206950": [201, 173],
    "206952": [175],
    "206953": [193, 484, 582, 176, 575],
    "206954": [184, 177, 412, 191],
    "206955": [178, 419, 188, 183],
    "206957": [182],
    "206959": [184, 185, 186],
    "206960": [185],
    "206961": [186, 174],
    "206962": [188],
    "206963": [191, 183],
    "206964": [192, 193, 195, 452, 453, 199],
    "206965": [192],
    "206967": [444],
    "206969": [195],
    "206970": [198],
    "206971": [220, 205, 198],
    "206972": [453],
    "206973": [457, 450, 451, 199],
    "206976": [201],
    "206978": [205],
    "206979": [206, 494],
    "206982": [68],
    "206983": [81],
    "206984": [236, 190],
    "206985": [
      133, 518, 135, 136, 137, 529, 531, 542, 553, 554, 560, 561, 562, 563, 564,
      565, 567, 568, 573, 574, 190, 576, 577, 450, 579, 580, 581, 583, 203, 76,
      480, 486,
    ],
    "206986": [212],
    "206989": [220],
    "206991": [204],
    "206992": [229, 165, 389, 200, 170, 204, 77, 206, 222],
    "206993": [640, 224, 222, 223],
    "206995": [223],
    "207000": [158],
    "207002": [227, 229],
    "207004": [597, 622, 230],
    "207005": [546, 517, 230, 552, 521, 522, 524, 557, 532, 411, 541, 479],
    "207006": [496, 231],
    "207007": [231],
    "207010": [
      544, 545, 519, 521, 554, 523, 555, 233, 234, 236, 528, 624, 597, 538, 539,
      637, 543,
    ],
    "207022": [517, 528, 560, 562, 564],
    "207023": [
      640, 133, 518, 135, 136, 134, 141, 526, 143, 142, 529, 527, 533, 154, 542,
      158, 159, 169, 558, 430, 180, 75, 76, 203, 480, 226, 227, 486, 488, 233,
      234, 237, 628, 633, 634, 637, 639,
    ],
    "207024": [519],
    "207026": [225, 226, 644, 645, 520, 522, 624, 568, 633],
    "207027": [523],
    "207028": [525],
    "207029": [526],
    "207030": [527],
    "207031": [532],
    "207032": [533],
    "207036": [538],
    "207037": [539, 559],
    "207038": [485, 456, 402, 181, 444, 575],
    "207039": [402, 429],
    "207040": [540],
    "207042": [551, 543],
    "207043": [544, 561, 558, 639],
    "207044": [546],
    "207045": [547],
    "207046": [548],
    "207047": [549],
    "207048": [549],
    "207049": [550],
    "207050": [550],
    "207051": [552],
    "207052": [556],
    "207053": [557],
    "207056": [563],
    "207057": [565],
    "207058": [566],
    "207059": [440],
    "207061": [572],
    "207062": [572],
    "207063": [573],
    "207064": [576],
    "207065": [577],
    "207066": [578],
    "207067": [579],
    "207068": [581],
    "207069": [583],
    "207070": [408],
    "207071": [408],
    "207072": [412],
    "207073": [416, 419, 495],
    "207074": [416],
    "207075": [429],
    "207076": [484, 485, 452, 433, 441, 443, 445],
    "207077": [433],
    "207078": [439],
    "207079": [441],
    "207080": [443],
    "207081": [445],
    "207082": [446],
    "207083": [448],
    "207084": [457],
    "207085": [479],
    "207086": [495],
    "207087": [496],
    "207088": [509],
    "207089": [509],
    "207091": [625, 629, 621],
    "207092": [625],
    "207093": [629, 630],
    "207097": [634],
  },
  COM_single_player: {
    "100439": [217, 218],
    "102832": [310],
    "105159": [483, 487, 498, 119, 121, 442, 477, 510],
    "108226": [449, 386, 388, 357, 427, 492, 398, 470, 347, 380, 349, 414],
    "110797": [
      385, 386, 387, 382, 514, 449, 424, 362, 333, 461, 403, 500, 501, 436, 474,
      349, 478,
    ],
    "111012": [384, 391, 426, 458, 397, 465, 435, 409],
    "111066": [321, 482, 437, 394],
    "111849": [481, 417, 395, 398, 431, 338, 434, 468],
    "112019": [648, 95, 208],
    "112035": [325],
    "112092": [207],
    "112221": [646],
    "112463": [118],
    "112620": [
      641, 47, 310, 55, 71, 328, 72, 207, 210, 213, 217, 99, 101, 102, 359, 103,
      105, 106, 107, 108, 109, 110, 111, 505,
    ],
    "113681": [311],
    "114105": [513, 516, 391, 489, 114, 469, 405, 121, 508],
    "114706": [635],
    "114726": [418, 467, 116, 504, 413],
    "114897": [356],
    "114936": [107],
    "115009": [113, 130],
    "115396": [112, 110],
    "115543": [
      128, 130, 516, 404, 420, 423, 46, 208, 80, 470, 474, 476, 478, 95, 123,
      500, 502, 632, 507, 124, 125, 638, 127,
    ],
    "115546": [112, 506, 214, 215],
    "115641": [216, 213, 214, 215],
    "116152": [315],
    "117357": [210, 99],
    "117463": [209, 507],
    "117820": [
      385, 647, 396, 404, 406, 407, 415, 425, 428, 431, 438, 442, 454, 491, 492,
      498, 499, 379, 510,
    ],
    "117821": [
      513, 515, 413, 418, 428, 432, 436, 325, 458, 333, 461, 464, 465, 468, 469,
      475, 482, 356, 357, 489, 490, 491, 497, 501, 502, 503,
    ],
    "118762": [
      512, 471, 514, 421, 390, 490, 396, 432, 497, 466, 499, 435, 405, 503, 473,
    ],
    "118777": [397],
    "118994": [515, 390, 447, 459, 464, 475, 472, 473, 379, 477, 414, 383],
    "121404": [641, 109, 55],
    "121558": [102, 71],
    "121582": [72, 101],
    "122428": [508, 406],
    "123113": [362],
    "123505": [642, 113, 114, 82, 209, 117, 116, 115, 635, 511],
    "123575": [137],
    "123672": [
      384, 643, 387, 383, 395, 403, 407, 409, 410, 415, 417, 420, 423, 424, 425,
      426, 437, 438, 321, 454, 459, 467, 471, 472, 476, 481, 483, 487, 504, 123,
      380, 511,
    ],
    "123690": [216, 218],
    "123977": [643, 388, 646, 647, 648, 394, 466, 410, 347, 447],
    "123980": [359, 328, 108, 111, 505, 506],
    "124110": [82, 125, 117],
    "124165": [642],
    "131086": [309],
    "131100": [128],
    "131163": [632, 127],
    "131342": [119],
    "131401": [46],
    "132318": [309, 118],
    "132724": [315],
    "132824": [103],
    "133694": [105, 106, 47],
    "133814": [434, 338, 427],
    "133867": [311],
    "134906": [674],
    "135179": [180],
    "136711": [382],
    "145016": [80],
    "200413": [115, 124, 421],
    "201504": [674],
    "207008": [365],
    "207096": [638],
  },
  COM_FSingle: {
    "105159": [483, 487, 498, 119, 121, 442, 477, 510],
    "108226": [449, 386, 388, 357, 427, 492, 398, 470, 347, 380, 349, 414],
    "110797": [
      385, 386, 387, 382, 514, 449, 424, 362, 333, 461, 403, 500, 501, 436, 474,
      349, 478,
    ],
    "111012": [384, 391, 426, 458, 397, 465, 435, 409],
    "111066": [321, 482, 437, 394],
    "111849": [481, 417, 395, 398, 431, 338, 434, 468],
    "112019": [648, 95, 208],
    "112035": [325],
    "112221": [646],
    "112463": [118],
    "114105": [513, 516, 391, 489, 114, 469, 405, 121, 508],
    "114706": [635],
    "114726": [418, 467, 116, 504, 413],
    "114897": [356],
    "115009": [113, 130],
    "115543": [
      128, 130, 516, 404, 420, 423, 46, 208, 80, 470, 474, 476, 478, 95, 123,
      500, 502, 632, 507, 124, 125, 638, 127,
    ],
    "116152": [315],
    "117463": [209, 507],
    "117820": [
      385, 647, 396, 404, 406, 407, 415, 425, 428, 431, 438, 442, 454, 491, 492,
      498, 499, 379, 510,
    ],
    "117821": [
      513, 515, 413, 418, 428, 432, 436, 325, 458, 333, 461, 464, 465, 468, 469,
      475, 482, 356, 357, 489, 490, 491, 497, 501, 502, 503,
    ],
    "118762": [
      512, 471, 514, 421, 390, 490, 396, 432, 497, 466, 499, 435, 405, 503, 473,
    ],
    "118777": [397],
    "118994": [515, 390, 447, 459, 464, 475, 472, 473, 379, 477, 414, 383],
    "122428": [508, 406],
    "123113": [362],
    "123505": [642, 113, 114, 82, 209, 117, 116, 115, 635, 511],
    "123672": [
      384, 643, 387, 383, 395, 403, 407, 409, 410, 415, 417, 420, 423, 424, 425,
      426, 437, 438, 321, 454, 459, 467, 471, 472, 476, 481, 483, 487, 504, 123,
      380, 511,
    ],
    "123977": [643, 388, 646, 647, 648, 394, 466, 410, 347, 447],
    "124110": [82, 125, 117],
    "124165": [642],
    "131086": [309],
    "131100": [128],
    "131163": [632, 127],
    "131342": [119],
    "131401": [46],
    "132318": [309, 118],
    "132724": [315],
    "133814": [434, 338, 427],
    "136711": [382],
    "145016": [80],
    "200413": [115, 124, 421],
    "207096": [638],
  },
  COM_FDouble: {
    "206921": [79],
    "206950": [201, 173],
    "206952": [175],
    "206953": [193, 484, 582, 176, 575],
    "206954": [184, 177, 412, 191],
    "206955": [178, 419, 188, 183],
    "206957": [182],
    "206959": [184, 185, 186],
    "206960": [185],
    "206961": [186, 174],
    "206962": [188],
    "206963": [191, 183],
    "206964": [192, 193, 195, 452, 453, 199],
    "206965": [192],
    "206967": [444],
    "206969": [195],
    "206970": [198],
    "206971": [220, 205, 198],
    "206972": [453],
    "206973": [457, 450, 451, 199],
    "206976": [201],
    "206978": [205],
    "206983": [81],
    "206989": [220],
    "207006": [496, 231],
    "207038": [485, 456, 402, 181, 444, 575],
    "207039": [402, 429],
    "207058": [566],
    "207072": [412],
    "207073": [416, 419, 495],
    "207074": [416],
    "207075": [429],
    "207076": [484, 485, 452, 433, 441, 443, 445],
    "207081": [445],
    "207084": [457],
    "207086": [495],
    "207087": [496],
  },
  COM_MSingle: {
    "100439": [217, 218],
    "102832": [310],
    "112092": [207],
    "112620": [
      641, 47, 310, 55, 71, 328, 72, 207, 210, 213, 217, 99, 101, 102, 359, 103,
      105, 106, 107, 108, 109, 110, 111, 505,
    ],
    "113681": [311],
    "114936": [107],
    "115396": [112, 110],
    "115546": [112, 506, 214, 215],
    "115641": [216, 213, 214, 215],
    "117357": [210, 99],
    "121404": [641, 109, 55],
    "121558": [102, 71],
    "121582": [72, 101],
    "123690": [216, 218],
    "123980": [359, 328, 108, 111, 505, 506],
    "132824": [103],
    "133694": [105, 106, 47],
    "133867": [311],
  },
  COM_MDouble: {
    "206911": [70],
    "206912": [73, 578, 131],
    "206913": [74, 212],
    "206914": [74],
    "206922": [160, 162, 131, 70, 167, 168, 73, 139, 140, 147, 150, 153, 157],
    "206925": [168, 140],
    "206934": [139, 151],
    "206935": [151],
    "206936": [153],
    "206939": [160, 147],
    "206940": [162],
    "206942": [164],
    "206943": [164],
    "206946": [167],
    "206986": [212],
    "207052": [556],
  },
  COM_MixedDouble: {
    "206916": [75],
    "206920": [
      582, 456, 172, 173, 174, 79, 176, 81, 178, 179, 175, 181, 566, 182, 177,
    ],
    "206924": [134],
    "206927": [170, 141, 389],
    "206928": [
      547, 548, 520, 553, 555, 556, 525, 622, 559, 494, 142, 237, 531, 623, 239,
      411, 540,
    ],
    "206929": [143],
    "206930": [
      439, 68, 455, 488, 524, 621, 77, 623, 430, 628, 630, 567, 440, 541, 446,
      159,
    ],
    "206931": [644, 645, 455],
    "206937": [154, 451],
    "206938": [169, 157, 150],
    "206941": [239],
    "206944": [200, 580, 165, 551],
    "206947": [224, 545, 171, 225],
    "206948": [171],
    "206949": [448, 179, 172],
    "206979": [206, 494],
    "206982": [68],
    "206984": [236, 190],
    "206985": [
      133, 518, 135, 136, 137, 529, 531, 542, 553, 554, 560, 561, 562, 563, 564,
      565, 567, 568, 573, 574, 190, 576, 577, 450, 579, 580, 581, 583, 203, 76,
      480, 486,
    ],
    "206991": [204],
    "206992": [229, 165, 389, 200, 170, 204, 77, 206, 222],
    "206993": [640, 224, 222, 223],
    "206995": [223],
    "207000": [158],
    "207002": [227, 229],
    "207004": [597, 622, 230],
    "207005": [546, 517, 230, 552, 521, 522, 524, 557, 532, 411, 541, 479],
    "207007": [231],
    "207010": [
      544, 545, 519, 521, 554, 523, 555, 233, 234, 236, 528, 624, 597, 538, 539,
      637, 543,
    ],
    "207022": [517, 528, 560, 562, 564],
    "207023": [
      640, 133, 518, 135, 136, 134, 141, 526, 143, 142, 529, 527, 533, 154, 542,
      158, 159, 169, 558, 430, 180, 75, 76, 203, 480, 226, 227, 486, 488, 233,
      234, 237, 628, 633, 634, 637, 639,
    ],
    "207024": [519],
    "207026": [225, 226, 644, 645, 520, 522, 624, 568, 633],
    "207027": [523],
    "207028": [525],
    "207029": [526],
    "207030": [527],
    "207031": [532],
    "207032": [533],
    "207036": [538],
    "207037": [539, 559],
    "207040": [540],
    "207042": [551, 543],
    "207043": [544, 561, 558, 639],
    "207044": [546],
    "207045": [547],
    "207046": [548],
    "207047": [549],
    "207048": [549],
    "207049": [550],
    "207050": [550],
    "207051": [552],
    "207053": [557],
    "207056": [563],
    "207057": [565],
    "207059": [440],
    "207061": [572],
    "207062": [572],
    "207063": [573],
    "207064": [576],
    "207065": [577],
    "207066": [578],
    "207067": [579],
    "207068": [581],
    "207069": [583],
    "207070": [408],
    "207071": [408],
    "207077": [433],
    "207078": [439],
    "207079": [441],
    "207080": [443],
    "207082": [446],
    "207083": [448],
    "207085": [479],
    "207088": [509],
    "207089": [509],
    "207091": [625, 629, 621],
    "207092": [625],
    "207093": [629, 630],
    "207097": [634],
  },
};

export interface DataState {
  data: VideoGraphDataDummyType | null;
  isLiveData: boolean;
}

// fetch data from the API
export const fetchVideoGraphData = async (
  token?: string
): Promise<DataState> => {
  try {
    const response = await axios.get("/api/videoGraph", {
      headers: {
        Authorization: `${token}`,
      },
    });

    return {
      data: response.data,
      isLiveData: true,
    };
  } catch (error) {
    console.error("Error fetching fetchVideoGraphData: ", error);
    console.log("We will use the dummy data instead");
    return {
      data: VideoGraphDataDummy,
      isLiveData: false,
    };
  } finally {
    console.log("fetchVideoGraphData executed");
  }
};
