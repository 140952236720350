// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-analysis-info-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background: #202325;
}

.player-analysis-container {
  width: 100vw;
  min-height: 100vh;
  background-color: #202325;
  display: flex;
  flex-direction: column;
  position: relative;
}
.player-analysis-container .MuiTabs-root .css-1wxkzlj-MuiTabs-flexContainer {
  justify-content: center;
}
.player-analysis-container .MuiTabs-root .MuiTab-root {
  color: white !important;
}
.player-analysis-container .padding {
  min-height: 30vh;
  min-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/playerPages/AnalysisMainPanel/AnalysisMainPanel.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;AAGI;EACE,uBAAA;AADN;AAGI;EACE,uBAAA;AADN;AAME;EACE,gBAAA;EACA,cAAA;AAJJ","sourcesContent":[".player-analysis-info-container {\n  width: 100vw;\n  display: flex;\n  justify-content: space-between;\n  background: #202325;\n}\n\n.player-analysis-container {\n  width: 100vw;\n  min-height: 100vh;\n  background-color: #202325;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n\n  .MuiTabs-root {\n    .css-1wxkzlj-MuiTabs-flexContainer{\n      justify-content: center;\n    }\n    .MuiTab-root {\n      color: white !important;\n    }\n  }\n  \n\n  .padding {\n    min-height: 30vh;\n    min-width: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
