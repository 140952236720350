import { FC, useState, useMemo, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
} from "@mui/material";
import { SignalGraph } from "./SignalGraph";
import {
  VideoGraphDataDummyType,
  selectJsonGameModeName,
  fetchVideoGraphData,
  DataState,
} from "../data/VideoGraphData";
import {
  GAME_MODES,
  Header,
  LoadingOverlay,
  WIPBanner,
} from "../utils/graph.utils";
import { ServerToken } from "../types/graph.types";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "../i18n/i18n";

export const SignalGraphWrapper: FC<ServerToken> = ({ userInfo, setToken }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const [graphState, setGraphState] = useState<DataState>({
    data: null,
    isLiveData: false,
  });

  const [selectedGameMode, setSelectedGameMode] =
    useState<keyof Omit<VideoGraphDataDummyType, "name_lists">>("COM_FSingle");

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const token = Cookies.get('token');
        const result = await fetchVideoGraphData(token);
        setGraphState({
          data: result.data,
          isLiveData: result.isLiveData,
        });
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Failed to fetch data");
        console.error("Error loading graph data:", err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const currentData = useMemo(() => {
    if (!graphState.data) return null;

    const playerData = graphState.data.name_lists;
    const connectionsData = selectJsonGameModeName(
      selectedGameMode,
      graphState.data
    );
    return {
      playerData,
      connectionsData,
    };
  }, [selectedGameMode, graphState.data]);

  const containerStyles = useMemo(() => ({
    height: isMobile ? 'calc(100dvh - 40px)' : 'calc(100vh - 40px)',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: 'relative',
    // marginTop: '40px',
  }), [isMobile]);

  const graphContainerHeight = useMemo(() => {
    const bannerHeight = 24;
    const headerHeight = isMobile ? 48 : 64;
    const totalOffset = bannerHeight + headerHeight;
    
    return isMobile 
      ? `calc(100dvh - ${totalOffset + 40}px)`
      : `calc(100vh - ${totalOffset + 40}px)`;
  }, [isMobile]);

  const getBannerMessage = () => {
    if (error) return t('common.errorFallback');
    if (!graphState.isLiveData) return t('common.usingStaticData');
    return t('common.liveData');
  };

  const getBannerColor = (): 'error' | 'warning' | 'success' => {
    if (error) return "error";
    if (!graphState.isLiveData) return "warning";
    return "success";
  };

  return (
    <Box sx={containerStyles}>
      <WIPBanner
        sx={{
          backgroundColor: (theme) => theme.palette[getBannerColor()].light,
        }}
      >
        {getBannerMessage()}
      </WIPBanner>

      <Header>
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          alignItems: 'center',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          width: '100%',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            alignItems: 'center',
            flex: 1
          }}>
            <FormControl
              sx={{
                width: isMobile ? "50%" : 200,
                "& .MuiInputBase-root": {
                  fontSize: isMobile ? "0.875rem" : "inherit",
                },
              }}
              size="small"
            >
              <InputLabel
                id="game-mode-select-label"
                sx={{
                  fontSize: isMobile ? "0.875rem" : "inherit",
                }}
              >
                {t('common.gameMode')}
              </InputLabel>
              <Select
                labelId="game-mode-select-label"
                id="game-mode-select"
                value={selectedGameMode}
                label={t('common.gameMode')}
                onChange={(e) =>
                  setSelectedGameMode(
                    e.target.value as keyof Omit<VideoGraphDataDummyType, "name_lists">
                  )
                }
              >
                {GAME_MODES.map((mode) => (
                  <MenuItem
                    key={mode.value}
                    value={mode.value}
                    sx={{
                      fontSize: isMobile ? "0.875rem" : "inherit",
                      py: isMobile ? 0.75 : 1,
                    }}
                  >
                    {t(`gameModes.${mode.value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <Button
              variant="outlined"
              size="small"
              href="/player-graphV0"
              sx={{
                whiteSpace: 'nowrap',
                minWidth: 'fit-content',
                fontSize: isMobile ? "0.875rem" : "inherit",
              }}
            >
              {t('common.viewOldVersion')}
            </Button>
          </Box>

          <Button
            size="small"
            onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'zh_hk' : 'en')}
            sx={{
              minWidth: 'fit-content',
              fontSize: isMobile ? "0.875rem" : "inherit",
            }}
          >
            {i18n.language === 'en' ? '中文' : 'EN'}
          </Button>
        </Box>
      </Header>

      <Box
        sx={{
          flex: 1,
          position: "relative",
          height: graphContainerHeight,
          overflow: "hidden",
        }}
      >
        {loading && (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        )}

        {currentData && (
          <SignalGraph
            playerData={currentData.playerData}
            connectionsData={currentData.connectionsData}
            language={{ language: i18n.language }}
            sx={{
              height: "100%",
              "& .sigma-controls": {
                transform: isMobile ? "scale(0.85)" : "none",
                transformOrigin: "top right",
              },
              "& .sigma-search": {
                width: isMobile ? "140px !important" : "200px !important",
                fontSize: isMobile ? "0.875rem" : "inherit",
                "&::placeholder": {
                  content: `"${t('common.search')}"`
                }
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default SignalGraphWrapper;