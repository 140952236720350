// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis-video-table-container {
  min-width: 100%;
  max-width: 100%;
  overflow: scroll;
  flex: 1 1;
  margin-top: var(--medium-font-size);
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  justify-content: flex-start;
  align-items: center;
  gap: 1vw;
  padding-right: var(--small-font-size);
  background-color: transparent;
}
.analysis-video-table-container .player-analysis-view-toggle {
  position: absolute;
  top: 1rem;
  right: 5rem;
}
@media (max-width: 700px) and (orientation: portrait) {
  .analysis-video-table-container .player-analysis-view-toggle {
    right: 1px;
  }
}`, "",{"version":3,"sources":["webpack://./src/playerPages/PlayerVideos/PlayerVideos.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,mCAAA;EAEA,aAAA;EACA,sBAAA;EACA,6BAAA;EAWA,2BAAA;EACA,mBAAA;EACA,QAAA;EAEA,qCAAA;EACA,6BAAA;AAXF;AAHE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAKJ;AAJI;EAJF;IAKI,UAAA;EAOJ;AACF","sourcesContent":[".analysis-video-table-container {\n  min-width: 100%;\n  max-width: 100%;\n  overflow: scroll;\n  flex: 1;\n  margin-top: var(--medium-font-size);\n\n  display: flex;\n  flex-direction: column;\n  scroll-snap-type: y mandatory;\n\n  .player-analysis-view-toggle {\n    position: absolute;\n    top: 1rem;\n    right: 5rem;\n    @media (max-width: 700px) and (orientation: portrait) {\n      right:1px;\n    }\n  }\n\n  justify-content: flex-start;\n  align-items: center;\n  gap: 1vw;\n\n  padding-right: var(--small-font-size);\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
