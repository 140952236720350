import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import AddPlaylist from 'src/components/AddPlaylist/AddPlaylist';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PushPinIcon from '@mui/icons-material/PushPin';

const dummyVideoData = {
  count: 1,
  matchId: 'dummy-id',
  videoName: 'Dummy Video',
  matchDescription: 'This is a dummy description for the video.',
  stage: 'Final',
  date: '2024-01-01',
  location: 'Dummy Location',
  homePlayer: 'Player A',
  awayPlayer: 'Player B',
  fileUrl: 'https://google.com',
  TNUrl: 'https://via.placeholder.com/150',
  report: 'Dummy Report',
  parentEvent: 'Dummy Event',
  event: 'Dummy Event',
  subEvent: 'Dummy Sub Event',
  category: 'Dummy Category',
  isDouble: false,
  viewCount: 100,
  uploadUser: 'Dummy User',
  customTag: 'Dummy Tag',
  homePlayerMatchScore: 3,
  awayPlayerMatchScore: 2,
  dp: 'dummy-dp-file',
};

const VideoList = ({ videoData = dummyVideoData, ispin = false, userInfo }) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const hoverTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeoutRef.current);
    };
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 2,
        '@media (min-width: 600px)': {
          flexDirection: 'row',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {ispin && (
          <PushPinIcon
            sx={{
              position: 'absolute',
              top: 8,
              right: 8, // Move to the top-right corner
              color: 'yellow',
              zIndex: 2,
            }}
          />
        )}
        <CardMedia
          component={isHovered ? 'video' : 'img'}
          ref={videoRef}
          image={
            !isHovered &&
            (videoData.TNUrl &&
            videoData.TNUrl !== 'none' &&
            videoData.TNUrl !== ''
              ? videoData.TNUrl
              : undefined)
          }
          src={isHovered ? videoData.fileUrl : undefined}
          alt="無法顯示影片預覽"
          muted={isHovered}
          autoPlay={isHovered}
          preload="none"
          sx={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            '@media (min-width: 600px)': {
              width: '300px',
              height: '170px', // Adjusted height for a good aspect ratio
            },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            '@media (min-width: 600px)': {
              flexDirection: 'row',
              alignItems: 'center',
            },
          }}
        >
          <Box
            component={Link}
            to={`/video-player/${videoData.matchId}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              flexGrow: 1,
              maxWidth: 'calc(100% - 120px)', // This controls space for the score, adjust if necessary
              '@media (min-width: 600px)': {
                maxWidth: 'auto', // Allows more flexibility in row layout
              },
            }}
            title="播放視頻"
          >
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
                '@media (min-width: 600px)': {
                  whiteSpace: 'normal',
                  maxWidth: '100%', // Ensuring it takes the full available width on desktop
                },
              }}
            >
              {videoData.videoName.replace(/_/g, ' ')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
              minWidth: '120px',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>
              {videoData.homePlayerMatchScore}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              :
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
              {videoData.awayPlayerMatchScore}
            </Typography>
          </Box>
          <AddPlaylist userInfo={userInfo} matchId={videoData.matchId} />
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            '@media (min-width: 600px)': {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          }}
        >
          <Typography variant="body2">
            上傳者: {videoData.uploadUser}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            標籤: {videoData.customTag}
          </Typography>
        </CardActions>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              overflow: 'hidden',
            }}
          >
            <Typography variant="body2">更多詳情</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: '100%', overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  影片: {videoData.homePlayer} vs {videoData.awayPlayer} (
                  {videoData.date})
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  觀看次數: {videoData.viewCount}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  比賽描述: {videoData.matchDescription}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  mb: 1,
                }}
              >
                <Typography variant="body2">階段: {videoData.stage}</Typography>
                <Typography variant="body2">
                  地點: {videoData.location}
                </Typography>
                <Typography variant="body2">
                  賽事: {videoData.subEvent}
                </Typography>
                <Typography variant="body2">
                  賽項: {videoData.category}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Card>
  );
};

export default VideoList;
