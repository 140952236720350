import { useEffect } from 'react';
import DpInputOL from 'src/components/DpInputOL/DpInputOL';

const EnhancedVideoPlayer = ({
  source,
  videoRef,
  onLoadComplete,
  setVideoData,
  dpOL,
  matchId,
}) => {
  useEffect(() => {
    if (!videoRef.current || !source) return;
    const video = videoRef.current;
    video.src = source;
    video.load();

    const handleCanPlay = () => {
      if (onLoadComplete) onLoadComplete();
    };

    video.addEventListener('canplay', handleCanPlay);

    return () => {
      video.removeEventListener('canplay', handleCanPlay);
    };
  }, [source]);

  return (
    <div className="video-container" style={{ width: '100%' }}>
      {dpOL === true && (
        <DpInputOL
          videoRef={videoRef}
          setVideoData={setVideoData}
          matchId={matchId}
        />
      )}
      <video
        ref={videoRef}
        className="video-player"
        controls
        preload="auto"
        playsInline={true}
        onPlay={() => setVideoData((prev) => ({ ...prev, isPlaying: true }))}
        onPause={() => setVideoData((prev) => ({ ...prev, isPlaying: false }))}
      />
    </div>
  );
};

export default EnhancedVideoPlayer;
