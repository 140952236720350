import { useState } from 'react';

import { Snackbar, SnackbarContent } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TextField from '@mui/material/TextField';

import { formatTime } from 'src/utils/utils';

import axios from 'axios';
import Cookies from 'js-cookie';

import {
  ballAwayPlayerScoreIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballStartTimeIndex,
  ballRoundIdIndex,
} from 'src/constants';

import './BallEditDisplay.scss';

const EditBallDisplay = ({
  index,
  setIsEditingBall,
  ballData,
  setBallData,
  videoRef,
  videoData,
  setVideoData,
  matchId,
}) => {
  const token = Cookies.get('token');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleEditToggle = () => {
    setIsEditingBall((prevValue) => !prevValue);
    setVideoData((prevState) => ({
      ...prevState,
      refresh: true,
    }));
  };

  const handleSave = (index) => {
    const save = async (index) => {
      const ballDataDict = {
        ball_id: ballData[index][0],
        round_id: ballData[index][1],
        start_time: ballData[index][2],
        end_time: ballData[index][3],
        home_player_score: ballData[index][4],
        away_player_score: ballData[index][5],
        highlight: ballData[index][6],
        critical_point: ballData[index][7],
        deuce: ballData[index][8],
      };

      axios
        .post(
          `/api/editballdata`,
          {
            match_id: matchId,
            filename: null,
            new_row: ballDataDict,
            primary_attribute: `video edited (id) : ${matchId}`,
            secondary_attribute: `add ball to round id : ${ballData[index][ballRoundIdIndex]} ${ballData[index][ballStartTimeIndex]}s to ${ballData[index][ballEndTimeIndex]}s`,
          },
          {
            headers: { Authorization: token },
          },
        )
        .then((response) => {
          handleEditToggle();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (
      ballData[index][ballStartTimeIndex] >= ballData[index][ballEndTimeIndex]
    ) {
      setSnackbarMessage('Start time cannot be after end time');
      setSnackbarOpen(true);
    } else {
      save(index);
    }
  };

  const handleHomeScoreChange = (event) => {
    const updatedBallData = [...ballData];
    updatedBallData[index][ballHomePlayerScoreIndex] = parseInt(
      event.target.value,
    );
    setBallData(updatedBallData);
  };

  const handleAwayScoreChange = (event) => {
    const updatedBallData = [...ballData];
    updatedBallData[index][ballAwayPlayerScoreIndex] = parseInt(
      event.target.value,
    );
    setBallData(updatedBallData);
  };

  const handleTimeUpdate = (isStartTime) => {
    const videoTime = videoRef.current.currentTime;
    if (isStartTime) {
      const updatedBallData = [...ballData];
      updatedBallData[index][ballStartTimeIndex] = Math.floor(videoTime);
      setBallData(updatedBallData);
    } else {
      const updatedBallData = [...ballData];
      updatedBallData[index][ballEndTimeIndex] = Math.floor(videoTime);
      setBallData(updatedBallData);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="video-ball-edit-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="h-custom-snackbar"
        open={snackbarOpen}
        autoHideDuration={1000000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          className="h-custom-snackbar"
          message={snackbarMessage}
        />
      </Snackbar>
      <div className="ball-score-edit-panel">
        <TextField
          id="outlined-basic"
          type="number"
          value={ballData[index][ballHomePlayerScoreIndex]}
          onChange={handleHomeScoreChange}
        />

        <span>:</span>

        <TextField
          id="outlined-basic"
          type="number"
          value={ballData[index][ballAwayPlayerScoreIndex]}
          onChange={handleAwayScoreChange}
        />
        {index === videoData.currentBall && (
          <span className="current-ball">
            <ReportGmailerrorredIcon className="is-playing" />
          </span>
        )}
        <div className="ball-button-panel">
          <button onClick={() => handleEditToggle()} title="返回">
            <WestOutlinedIcon />
          </button>
          <button onClick={() => handleSave(index)} title="保存">
            <CheckOutlinedIcon />
          </button>
        </div>
      </div>
      <div className="ball-info-edit-panel">
        <button
          onClick={() => handleTimeUpdate(true)}
          title="將當前視頻時間設定為起始時間"
        >
          开始: {formatTime(ballData[index][ballStartTimeIndex])}
        </button>

        <button
          onClick={() => handleTimeUpdate(false)}
          title="將當前視頻時間設定為結束時間"
        >
          结束: {formatTime(ballData[index][ballEndTimeIndex])}
        </button>
      </div>
    </div>
  );
};

export default EditBallDisplay;
