import { FC, useEffect, useCallback, useState } from "react";
import {
  useSigma,
  useLoadGraph,
  useRegisterEvents,
  useSetSettings,
} from "@react-sigma/core";
import { UndirectedGraph } from "graphology";
import { GameClickInfo, GraphDataLoaderProps } from "../types/graph.types";
import {
  findCommonGameIds,
  getEnhancedLabelStyle,
  getGameCount,
} from "../utils/graph.utils";

import { animateNodes } from "sigma/utils";
import { fetchVideoInfoData } from "../data/VideoInfoData";
import {
  getGraphReducers,
  getGraphSettings,
  getResetSelection,
} from "../constants/graph.constants";

export const MyGraphFromCombinedData: FC<GraphDataLoaderProps> = ({
  onSelectionChange,
  selection, // Add this
  shouldReset,
  currentLayout,
  isMobile = false,
  showCommonOpponents = false, // Add showCommonOpponents to destructured props
  playerData,
  connectionsData,
}) => {
  const sigma = useSigma();
  const loadGraph = useLoadGraph();
  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [selectedOpponent, setSelectedOpponent] = useState<string | null>(null);
  const [selectedCommonOpponent, setSelectedCommonOpponent] = useState<
    string | null
  >(null);
  const [connectedPlayers, setConnectedPlayers] = useState<string[]>([]);
  const [initialLayoutApplied, setInitialLayoutApplied] = useState(false);

  // Function to get two-layer circle positions
  const getTwoLayerPositions = useCallback(() => {
    const graph = sigma.getGraph();
    const positions: { [key: string]: { x: number; y: number } } = {};
    const playerNodes = new Set<string>();
    const gameNodes = new Set<string>();

    graph.forEachNode((nodeId: string) => {
      const size = graph.getNodeAttribute(nodeId, "size");
      if (size === 15 || size === 9) {
        // Account for mobile size
        playerNodes.add(nodeId);
      } else {
        gameNodes.add(nodeId);
      }
    });

    // Calculate responsive radius based on screen size
    const width = window.innerWidth;
    const height = window.innerHeight;
    const smallestDimension = Math.min(width, height);

    // Adjust radius calculations for mobile
    const baseRadius = isMobile
      ? smallestDimension * 0.35 // Smaller radius on mobile
      : smallestDimension * 0.4; // Larger radius on desktop

    const outerRadius = baseRadius;
    const innerRadius = isMobile
      ? baseRadius * 0.5 // Tighter inner circle on mobile
      : baseRadius * 0.6; // Normal spacing on desktop

    // Position player nodes (inner circle)
    Array.from(playerNodes).forEach((id, index) => {
      const angle = (index / playerNodes.size) * 2 * Math.PI;
      // Rotate the starting position by -π/2 to start from the top
      const rotatedAngle = angle - Math.PI / 2;
      positions[id] = {
        x: innerRadius * Math.cos(rotatedAngle),
        y: innerRadius * Math.sin(rotatedAngle),
      };
    });

    // Position game nodes (outer circle)
    Array.from(gameNodes).forEach((id, index) => {
      const angle = (index / gameNodes.size) * 2 * Math.PI;
      // Rotate the starting position by -π/2 to start from the top
      const rotatedAngle = angle - Math.PI / 2;
      positions[id] = {
        x: outerRadius * Math.cos(rotatedAngle),
        y: outerRadius * Math.sin(rotatedAngle),
      };
    });

    return positions;
  }, [sigma, isMobile]);

  // Function to find all connected players
  const findConnectedPlayers = useCallback(
    (nodeId: string) => {
      const connectedPlayers = new Set<string>();

      Object.entries(connectionsData).forEach(([playerId, connections]) => {
        if (playerData[playerId]) {
          const selectedConnections = connectionsData[nodeId] || [];
          const hasCommonGame = connections.some((connection) =>
            selectedConnections.includes(connection)
          );
          if (hasCommonGame && playerId !== nodeId) {
            connectedPlayers.add(playerId);
          }
        }
      });

      return Array.from(connectedPlayers);
    },
    [connectionsData, playerData]
  );

  // Add this effect to sync drawer selections with graph
  useEffect(() => {
    // Skip if selection came from graph click
    if (
      selection.selectedPlayer === selectedNode &&
      selection.selectedOpponent === selectedOpponent
    ) {
      return;
    }

    // Update local state
    setSelectedNode(selection.selectedPlayer);
    setSelectedOpponent(selection.selectedOpponent);
    setSelectedCommonOpponent(selection.selectedCommonOpponent);

    if (selection.selectedPlayer) {
      const connected = findConnectedPlayers(selection.selectedPlayer);
      setConnectedPlayers(connected);
    } else {
      setConnectedPlayers([]);
    }
  }, [selection, findConnectedPlayers, selectedNode, selectedOpponent]);
  // Handle node click

  // Modify handle node click
  // In MyGraphFromCombinedData component, update the handleNodeClick function:

  const handleNodeClick = useCallback(
    async (event: { node: string }) => {
      const nodeId = event.node;
      const isPlayer = playerData[nodeId] !== undefined;
      const isGame = !isPlayer;

      // Handle game node clicks
      if (isGame) {
        console.log("Clicked Game Node:", {
          nodeId,
          gameIdNum: parseInt(nodeId),
        });

        const response = await fetchVideoInfoData(parseInt(nodeId));
        console.log("fetchVideoInfoData :", response);
        if (response.data) {
          const parsed = response.data;

          const gameScores = parsed.games.map((game) => {
            if (
              !game ||
              !game.points ||
              !Array.isArray(game.points) ||
              game.points.length === 0
            ) {
              return {
                gameNumber: game?.gameInfo?.gameNumber ?? -1,
                finalTeam1Score: null,
                finalTeam2Score: null,
              };
            }
            const lastPoint = game.points[game.points.length - 1];
            return {
              gameNumber: game.gameInfo.gameNumber,
              finalTeam1Score: lastPoint.team1Score,
              finalTeam2Score: lastPoint.team2Score,
            };
          });

          const gameInfo: GameClickInfo = {
            gameId: nodeId,
            metadata: {
              filename: parsed.metadata.filename,
              eventName: parsed.metadata.eventName,
              finalscore1: parsed.metadata.finalscore1,
              finalscore2: parsed.metadata.finalscore2,
              id: parsed.metadata.id,
              location: parsed.metadata.location,
              round: parsed.metadata.round,
              team1: parsed.metadata.team1,
              team2: parsed.metadata.team2,
              date: parsed.metadata.date,
            },
            gameScores: gameScores,
            players: Object.entries(connectionsData)
              .filter(([playerId, games]) => games.includes(parseInt(nodeId)))
              .map(([playerId]) => ({
                playerId,
                playerName: playerData[playerId],
              })),
          };

          if (onSelectionChange) {
            // Keep existing player selections when showing game info
            onSelectionChange({
              selectedPlayer: selectedNode,
              selectedOpponent: selectedOpponent,
              selectedCommonOpponent: selectedCommonOpponent,
              connectedPlayers: connectedPlayers,
              selectedGameInfo: gameInfo,
            });
          }
          return;
        }
      }

      // Handle player node clicks...
      if (!selectedNode && isPlayer) {
        setSelectedNode(nodeId);
        const connected = findConnectedPlayers(nodeId);
        setConnectedPlayers(connected);
        setSelectedOpponent(null);
        if (onSelectionChange) {
          onSelectionChange({
            selectedPlayer: nodeId,
            connectedPlayers: connected,
            selectedOpponent: null,
            selectedCommonOpponent: null,
            selectedGameInfo: null,
          });
        }
      } else if (
        selectedNode &&
        isPlayer &&
        nodeId !== selectedNode &&
        !showCommonOpponents
      ) {
        setSelectedOpponent(nodeId);
        if (onSelectionChange) {
          onSelectionChange({
            selectedPlayer: selectedNode,
            connectedPlayers,
            selectedOpponent: nodeId,
            selectedCommonOpponent: null,
            selectedGameInfo: null,
          });
        }
      } else if (
        selectedNode &&
        isPlayer &&
        nodeId !== selectedNode &&
        showCommonOpponents
      ) {
        setSelectedCommonOpponent(nodeId);
        if (onSelectionChange) {
          onSelectionChange({
            selectedPlayer: selectedNode,
            connectedPlayers,
            selectedOpponent,
            selectedCommonOpponent: nodeId,
            selectedGameInfo: null,
          });
        }
      }
    },
    [
      playerData,
      selectedNode,
      showCommonOpponents,
      findConnectedPlayers,
      connectionsData,
      connectedPlayers,
      onSelectionChange,
      selectedOpponent,
      selectedCommonOpponent,
    ]
  );
  
  // Modified reset function to preserve layout
  // In MyGraphFromCombinedData component
  const resetSelection = useCallback(() => {
    if (!sigma) return;

    setSelectedNode(null);
    setSelectedOpponent(null);
    setSelectedCommonOpponent(null);
    setConnectedPlayers([]);

    getResetSelection({
      sigma,
      currentLayout,
      getTwoLayerPositions,
      playerData,
      isMobile,
    });
  }, [sigma, currentLayout, getTwoLayerPositions, playerData, isMobile]);

  // Update the initialization effect with better mobile handling
  useEffect(() => {
    if (initialLayoutApplied) return;

    const graph = new UndirectedGraph();
    const width = window.innerWidth;
    const height = window.innerHeight;
    const smallestDimension = Math.min(width, height);

    // Calculate responsive sizes
    const playerSize = isMobile ? 9 : 15; // Smaller fixed size for mobile
    const gameSize = isMobile ? 4 : 8; // Smaller game nodes for mobile

    // Calculate responsive radius
    const baseRadius = isMobile
      ? smallestDimension * 0.35
      : smallestDimension * 0.4;

    const outerRadius = baseRadius;
    const innerRadius = isMobile ? baseRadius * 0.5 : baseRadius * 0.6;

    // Collect node IDs
    const playerIds = new Set<string>();
    const gameIds = new Set<string>();

    Object.keys(connectionsData).forEach((id) => {
      if (playerData[id]) {
        playerIds.add(id);
      }
    });

    Object.values(connectionsData).forEach((targets) => {
      targets.forEach((targetId) => {
        if (!playerData[targetId.toString()]) {
          gameIds.add(targetId.toString());
        }
      });
    });

    // Add nodes with mobile-optimized properties
    Array.from(playerIds).forEach((id, index) => {
      const angle = (index / playerIds.size) * 2 * Math.PI - Math.PI / 2;
      (graph as any).addNode(id, {
        label: playerData[id],
        x: innerRadius * Math.cos(angle),
        y: innerRadius * Math.sin(angle),
        size: playerSize,
        color: "#4169E1",
        borderSize: isMobile ? 1 : 2,
        borderColor: "#ffffff",
        type: "circle",
        labelSize: isMobile ? 12 : 14,
        labelOutlineSize: isMobile ? 2 : 3,
      });
    });

    Array.from(gameIds).forEach((id, index) => {
      const angle = (index / gameIds.size) * 2 * Math.PI - Math.PI / 2;
      (graph as any).addNode(id, {
        label: `Game ${id}`,
        x: outerRadius * Math.cos(angle),
        y: outerRadius * Math.sin(angle),
        size: gameSize,
        color: "#B0C4DE",
        borderSize: isMobile ? 0.5 : 1,
        borderColor: "#ffffff",
        type: "circle",
        labelSize: isMobile ? 8 : 10,
      });
    });

    // Add edges with mobile-optimized properties
    Object.entries(connectionsData).forEach(([sourceId, targetIds]) => {
      targetIds.forEach((targetId) => {
        const targetIdString = targetId.toString();
        try {
          if (!(graph as any).hasEdge(sourceId, targetIdString)) {
            (graph as any).addEdge(sourceId, targetIdString, {
              size: isMobile ? 0.3 : 0.5,
              color: "#D3D3D3",
            });
          }
        } catch (error) {
          console.error(
            `Failed to add edge between ${sourceId} and ${targetIdString}`
          );
        }
      });
    });

    loadGraph(graph);
    setInitialLayoutApplied(true);
  }, [loadGraph, initialLayoutApplied, isMobile, connectionsData, playerData]);

  // Register events
  useEffect(() => {
    registerEvents({
      clickNode: handleNodeClick,
    });
  }, [registerEvents, handleNodeClick]);

  // Handle reset
  useEffect(() => {
    if (shouldReset) {
      resetSelection();
    }
  }, [shouldReset, resetSelection]);

  // Notify parent of selection changes
  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange({
        selectedPlayer: selectedNode,
        connectedPlayers,
        selectedOpponent,
        selectedCommonOpponent,
      });
    }
  }, [
    selectedNode,
    connectedPlayers,
    selectedOpponent,
    selectedCommonOpponent,
    onSelectionChange,
  ]);

  // Update visual settings

  // Also update the nodeReducer in the settings effect to show different colors for common opponents:
  // Update the nodeReducer in the settings effect:
  useEffect(() => {
    if (!sigma) return;

    const reducers = getGraphReducers({
      isMobile,
      selectedNode,
      selectedOpponent,
      selectedCommonOpponent,
      showCommonOpponents,
      playerData,
      connectionsData,
      findCommonGameIds,
      findConnectedPlayers,
      getGameCount,
      getEnhancedLabelStyle,
      sigma, // Pass sigma instance
    });

    setSettings({
      ...getGraphSettings(isMobile),
      ...reducers,
    });
  }, [
    selectedNode,
    selectedOpponent,
    setSettings,
    sigma,
    findConnectedPlayers,
    isMobile,
    showCommonOpponents,
    selectedCommonOpponent,
    connectionsData,
    playerData,
  ]);
  // Handle layout changes
  useEffect(() => {
    if (!sigma || !initialLayoutApplied) return;

    // Apply layout changes when currentLayout changes
    if (currentLayout === "twoLayerCircle") {
      const positions = getTwoLayerPositions();
      animateNodes(sigma.getGraph(), positions, { duration: 1000 });
    }
  }, [currentLayout, sigma, initialLayoutApplied, getTwoLayerPositions]);

  return null;
};
